<template>
    <PageCard style="padding-top: 5px">
        <div style="max-width: 300px" class="mb-3">
            <div class="my-3"><strong>Report Type</strong></div>
            <SelectDropdown
                :id="`reporttype`"
                :options="reportTypes"
                :multiple="false"
                field="reporttype"
                :value="reportType"
                @change="handleReportChange"
            />
        </div>

        <AssetAllocationReport v-if="reportType == REPORT_TYPES.ASSET_ALLOCATION_REPORT" />
        <AttendanceSummaryReport v-else-if="reportType == REPORT_TYPES.ATTENDANCE_SUMMARY_REPORT" />
        <SalarySheetReport v-else-if="reportType == REPORT_TYPES.SALARY_SHEET_FOR_APPLICATION_PURPOSE" />
        <StllpTxtReport v-else-if="reportType == REPORT_TYPES.STLLP_TXT_DATA" />
        <PtReport v-else-if="reportType == REPORT_TYPES.PT_REPORT" />
        <div v-else>Please select the report type to load!</div>
    </PageCard>
</template>

<script>
import SelectDropdown from "../../../components/crud/SelectDropdown.vue"
import PageCard from "../../../components/layout/PageCard.vue"
import AssetAllocationReport from "./asset-allocation-report.vue"
import AttendanceSummaryReport from "./attendance-summary-report.vue"
import PtReport from "./pt-report.vue"
import SalarySheetReport from "./salary-sheet-report.vue"
import StllpTxtReport from "./stllp-txt-report.vue"

const REPORT_TYPES = {
    ASSET_ALLOCATION_REPORT: "ASSET_ALLOCATION_REPORT",
    ATTENDANCE_SUMMARY_REPORT: "ATTENDANCE_SUMMARY_REPORT",
    SALARY_SHEET_FOR_APPLICATION_PURPOSE: "SALARY_SHEET_FOR_APPLICATION_PURPOSE",
    STLLP_TXT_DATA: "STLLP_TXT_DATA",
    PT_REPORT: "PT_REPORT",
}

export default {
    components: {
        SelectDropdown,
        AssetAllocationReport,
        AttendanceSummaryReport,
        SalarySheetReport,
        StllpTxtReport,
        PtReport,
        PageCard,
    },
    data() {
        return {
            REPORT_TYPES,
            reportType: "",
            reportTypes: [
                { id: REPORT_TYPES.ASSET_ALLOCATION_REPORT, title: "Asset Allocation Report" },
                { id: REPORT_TYPES.ATTENDANCE_SUMMARY_REPORT, title: "Attendance Summary Report" },
                {
                    id: REPORT_TYPES.SALARY_SHEET_FOR_APPLICATION_PURPOSE,
                    title: "Salary Sheet for Application Purpose",
                },
                { id: REPORT_TYPES.STLLP_TXT_DATA, title: "STLLP Txt Data" },
                { id: REPORT_TYPES.PT_REPORT, title: "PT Report" },
            ],
        }
    },
    methods: {
        handleReportChange(e) {
            this.reportType = e
        },
    },
    watch: {},
}
</script>

<style></style>
