import { render, staticRenderFns } from "./MultiTextAdder.vue?vue&type=template&id=40f75b17&scoped=true"
import script from "./MultiTextAdder.vue?vue&type=script&lang=js"
export * from "./MultiTextAdder.vue?vue&type=script&lang=js"
import style0 from "./MultiTextAdder.vue?vue&type=style&index=0&id=40f75b17&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40f75b17",
  null
  
)

export default component.exports