<template>
    <div class="form-group">
        <CustomInputVue
            v-model="password"
            :label="label"
            :required="required"
            :disabled="disabled"
        />
        <div></div>
        <span v-if="editMode">Leave password empty to keep same as old</span>
        <ul class="my-2" style="list-style-type: disc; list-style-position: inside">
            <li :class="[{ 'success-password': hasMin1Num }]">Min 1 number</li>
            <li :class="[{ 'success-password': hasMin1SpecialChar }]">Min 1 special character</li>
            <li :class="[{ 'success-password': hasMin8Chars }]">Min 8 characters</li>
        </ul>
    </div>
</template>

<script>
import CustomInputVue from "./custom-input.vue"
export default {
    props: {
        label: { type: String, default: "Password" },
        value: [String, Number],
        disabled: { type: Boolean, default: false },
        required: { type: Boolean, default: false },
        editMode: { type: Boolean, default: false },
    },
    model: {
        prop: "value",
        event: "input",
    },
    data() {
        return { password: "" }
    },
    watch: {
        value(e, oldE) {
            if (e != oldE) {
                this.password = e
            }
        },
        password(e) {
            this.$emit("input", this.password)
        },
    },
    computed: {
        hasMin1Num() {
            return this.password && /\d/.test(this.password)
        },
        hasMin1SpecialChar() {
            return this.password && /[^a-zA-Z0-9 ]/.test(this.password)
        },
        hasMin8Chars() {
            return this.password && this.password.length > 8
        },
    },
    components: { CustomInputVue },
}
</script>

<style scoped>
.success-password {
    color: rgb(0, 152, 0);
}
</style>
