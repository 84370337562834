<template>
    <input
        type="text"
        :placeholder="`Search ${title || ''}...`"
        class="form-control form-control-sm"
        v-model="searchStr"
    />
</template>

<script>
export default {
    data() {
        return { searchStr: "", searchTimeout: null }
    },
    emits: ["search"],
    props: {
        title: {
            type: String,
        },
    },
    watch: {
        searchStr(e) {
            if (this.searchTimeout) window.clearTimeout(this.searchTimeout)
            this.searchTimeout = window.setTimeout(() => {
                this.$emit("search", this.searchStr)
            }, 1000)
        },
    },
}
</script>

<style></style>
