<template>
    <PageCard sm>
        <SectionTitle>Task Overview</SectionTitle>
        <TaskList :loading="false" :data="activeProjectTasks" :showDelete="false" @view="viewTask" @delete="deleteTask" />
        <div class="my-3">
            <router-link to="/project-tasks">
                <Button noMargin>View All</Button>
            </router-link>
        </div>
        <!-- <CustomTable>
            <template #head>
                <tr>
                    <th>ID</th>
                    <th>Employee</th>
                    <th>Role</th>
                    <th>Department</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Check In</th>
                    <th>Check Out</th>
                    <th>Work Hours</th>
                </tr>
            </template>
            <template #body>
            </template>
        </CustomTable> -->
    </PageCard>
</template>

<script>
import { mapActions, mapState } from "vuex"
import PageCard from "../layout/PageCard.vue"
import CustomTable from "../other/CustomTable.vue"
import Button from "../UI/Button.vue"
import SectionTitle from "../UI/SectionTitle.vue"
import TaskList from "../project-management/TaskList.vue"
import { http } from "../../utils/http"

export default {
    components: { PageCard, Button, SectionTitle, CustomTable, TaskList },
    props: {},
    computed: {
        ...mapState("misc", ["dashboardStats"]),
        ...mapState("project", ["activeProjectTasks"]),
    },
    data() {
        return {
            loadingData: false,
            tasks: [],
        }
    },
    async mounted() {
        this.fetchTasks()
    },
    methods: {
        ...mapActions("project", ["fetchTasks"]),
        async viewTask(slug) {
            window.open(`/project-tasks/${slug}`, "_self")
        },
        async deleteTask(id) {
            try {
                if (confirm("Are you sure?")) {
                    await http.delete(`/project-tasks/${id}`)
                    window.location.reload()
                }
            } catch (err) {
                console.log("error in deleting task", err)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.amount-value {
    color: #444;
    font-size: 1.3em;
}
.amount-label {
    color: #444;
    font-size: 0.8em;
}
</style>
