<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="Payroll Payments"
            :viewColumns="columns"
            :permission="PERMISSION_CODES.VIEW_PAYROLL_HISTORY"
            fetchUrl="/payroll-payments"
            editRouteName="payroll_history_detail"
            :createFields="createFields"
            :reloadOnSuccess="true"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { createField, createColumn } from "../../utils/common"
import { http } from "@/utils/http"
import { PERMISSION_CODES } from "../../store/permission"

export default {
    data() {
        return {
            loading: false,
            PERMISSION_CODES,
            createFields: [createField("Title*", "title", "text", true)],
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Generated On", "createdAt", "string"),
                // createColumn("Unique ID", "name", "string"),
                createColumn("Company Name", "companyName", "string"),
                createColumn("From Date", "startDate", "string"),
                createColumn("To Date", "endDate", "string"),
                createColumn("Year", "year", "string"),
                // createColumn("Active", "status", "string"),
            ],
        }
    },
    components: { CrudIndex },
    async mounted() {},
    methods: {},
}
</script>

<style></style>
