<template>
    <div>
        <CrudIndex
            :permission="PERMISSION_CODES.VIEW_DEPARTMENTS"
            title="Department"
            :viewColumns="columns"
            fetchUrl="/departments"
            createUrl="/departments"
            updateRootUrl="/departments"
            deleteRootUrl="/departments"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { PERMISSION_CODES } from "../../store/permission"
import { createField, createColumn } from "../../utils/common"

export default {
    data() {
        return {
            createFields: [
                createField("Name*", "name", "text", true),
                // createField("Status", "status", "switch", false, true),
            ],
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Name", "name", "string"),
                // createColumn("Active", "status", "string"),
            ],
            PERMISSION_CODES,
        }
    },
    components: { CrudIndex },
}
</script>

<style></style>
