<template>
    <PageCard title="Application Logs">
        <div class="mb-3 row">
            <div class="col-md-5">
                <div class="my-3"><strong>Select Log Type</strong></div>
                <SelectDropdown
                    v-if="logTypes && logTypes.length"
                    :id="`logtype`"
                    :options="logTypes"
                    :multiple="false"
                    field="logtype"
                    :value="logType"
                    @change="handlelogChange"
                />
            </div>
            <div class="col-md-5">
                <div class="my-3"><strong>Sub Type</strong></div>
                <SelectDropdown
                    v-if="logSubTypes && logSubTypes.length"
                    :id="`logsubtype`"
                    :options="logSubTypes"
                    :multiple="false"
                    field="logsubtype"
                    :value="logSubType"
                    @change="handlelogSubChange"
                />
            </div>
            <div class="col-md-12 mt-3">
                <Button :noMargin="true" @click="fetchLogs">{{
                    loading ? "Please wait..." : "View Logs"
                }}</Button>
            </div>
        </div>
        <div v-if="searched">
            <div v-if="result && result.length">
                <CustomModal
                    v-if="showLogDetails && logViewData"
                    :title="`Log Details`"
                    :show="showLogDetails"
                    @close="showLogDetails = false"
                >
                    <div class="my-3">
                        <div class="json-view-container">
                            <json-viewer :copyable="true" :value="logViewData"></json-viewer>
                        </div>
                        <div class="text-end">
                            <Button @click="closeModal">Close</Button>
                        </div>
                    </div>
                </CustomModal>

                <CustomTable v-if="!loading && result.length">
                    <template #head>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Type</th>
                            <th>Sub Type</th>
                            <th>Performed By</th>
                            <th>Timestamp</th>
                            <th>Info</th>
                            <th>Actions</th>
                        </tr>
                    </template>
                    <template #body>
                        <tr v-for="(item, idx) of result" :key="item.id">
                            <td>{{ idx + 1 }}</td>
                            <td>{{ item["type"] }}</td>
                            <td>{{ item["subType"] }}</td>
                            <td>{{ item["actionPerformedBy"] }}</td>
                            <td>{{ item["timestampFormatted"] }}</td>
                            <td>{{ item["info"] }}</td>
                            <td><Button @click="handleViewLog(item)">View Details</Button></td>
                        </tr>
                    </template>
                </CustomTable>
            </div>
            <div v-else>No Logs found</div>
        </div>
        <div v-else>Please select the log type to load!</div>
    </PageCard>
</template>

<script>
import Button from "../../components/UI/Button.vue"
import SelectDropdown from "../../components/crud/SelectDropdown.vue"
import CustomModal from "../../components/layout/CustomModal.vue"
import CustomTable from "../../components/other/CustomTable.vue"
import { http } from "../../utils/http"

import JsonViewer from "vue-json-viewer"
import "vue-json-viewer/style.css"
import PageCard from "../../components/layout/PageCard.vue"

export default {
    components: { PageCard, SelectDropdown, CustomTable, Button, CustomModal, JsonViewer },
    data() {
        return {
            logType: "",
            logSubType: "",
            logTypes: [],
            logSubTypes: [],
            result: [],
            searched: false,
            loading: false,
            showLogDetails: false,
            logViewData: null,
        }
    },
    mounted() {
        this.fetchLogTypes()
    },
    methods: {
        handleViewLog(item) {
            this.showLogDetails = true
            this.logViewData = { ...item, jsonData: JSON.parse(item.jsonData) }
        },
        closeModal() {
            this.showLogDetails = false
            this.logViewData = null
        },
        handlelogChange(e) {
            this.logType = e
        },
        handlelogSubChange(e) {
            this.logSubType = e
        },
        async fetchLogTypes() {
            try {
                const res = await http.get("/app-log-types")
                this.logTypes = res.data.result.APP_LOG_TYPES
                this.logSubTypes = res.data.result.APP_LOG_SUB_TYPES
            } catch (err) {
                console.log("error in fetching log types", err)
            }
        },
        async fetchLogs() {
            if (this.loading) return
            this.searched = true
            try {
                this.loading = true
                const res = await http.get(
                    `/app-logs?log_type=${this.logType}&log_sub_type=${this.logSubType}`
                )
                this.result = res.data.result
            } catch (err) {
                console.log("error in fetching logs", err)
            }
            this.loading = false
        },
    },
    watch: {},
}
</script>

<style lang="scss" scoped>
.json-view-container {
    max-height: 250px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    overflow-y: scroll;
}
</style>
