<template>
    <div class="attendance-user-list-item">
        <div class="row">
            <div class="col-md-6">
                <div class="employee-info">
                    <div class="" v-if="employeeMode">
                        Date:
                        <strong> {{ attendanceRecord.date }}/{{ attendanceRecord.month }}/{{ attendanceRecord.year }} </strong>
                    </div>
                    <div v-else>
                        <strong>{{ attendanceRecord.user.firstName }} {{ attendanceRecord.user.lastName }}</strong>
                    </div>
                    <div v-if="!employeeMode" class="mb-3">
                        <div v-if="!addNote">
                            <div v-if="attendanceRecord.comments"><u>Notes</u>:</div>
                            <p class="my-1" v-if="attendanceRecord.comments">
                                {{ attendanceRecord.comments }}
                            </p>
                            <button class="note-btn" @click="addNote = true">
                                {{ attendanceRecord.comments ? "Edit Note" : "Add Note" }}
                                <i v-if="attendanceRecord.comments" class="fas fa-edit"></i>
                                <i v-else class="fas fa-plus"></i>
                            </button>
                        </div>
                        <div v-else>
                            <textarea
                                placeholder="Notes..."
                                v-if="addNote"
                                v-model="note"
                                class="employee-notes w-100 form-control form-control-sm"
                            />
                            <button class="note-btn" @click="handleNotesUpdate">
                                {{ saving ? "Saving" : "Save" }}
                            </button>
                        </div>
                    </div>
                    <div v-else>
                        <p>{{ attendanceRecord.comments }}</p>
                    </div>
                    <div class="additional-info-wrapper shift-wrapper">
                        <div class="additional-info">
                            <div class="d-flex attendance-record-time" v-if="attendanceRecord.shift">
                                <div>Shift: {{ attendanceRecord.shift.name || "NA" }}</div>
                                <div class="mx-2">|</div>
                                <div>
                                    Timing: {{ attendanceRecord.shift.startTime || "NA" }} - {{ attendanceRecord.shift.endTime || "NA" }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="additional-info-wrapper">
                        <div class="additional-info">
                            <div class="d-flex attendance-record-time">
                                <div>Time In: {{ attendanceRecord.timeIn ? attendanceRecord.timeIn : "NA" }}</div>
                                <div class="mx-2">|</div>
                                <div>
                                    Time Out<span v-if="attendanceRecord.wasAutoCheckedOut">(Auto)</span>:
                                    {{ attendanceRecord.timeOut ? attendanceRecord.timeOut : "NA" }}

                                    <span v-if="attendanceRecord.timeOutTimestamp"
                                        >({{ attendanceRecord.timeOutTimestamp.slice(0, 10) }})</span
                                    >
                                </div>
                            </div>
                            <div v-if="!employeeMode && attendanceRecord.user.attendanceShift" class="d-flex attendance-record-time">
                                <div>
                                    Shift:
                                    {{ attendanceRecord.user.attendanceShift.name }} ({{
                                        attendanceRecord.user.attendanceShift.startTime
                                    }}
                                    - {{ attendanceRecord.user.attendanceShift.endTime }})
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="additional-info-wrapper"
                        v-if="attendanceRecord.checkInDistanceFromWorkLocationInKm || attendanceRecord.checkOutDistanceFromWorkLocationInKm"
                    >
                        <div class="additional-info">
                            <p>Distance From <br />Work Location</p>
                            <div class="d-flex attendance-record-time">
                                <div>
                                    Check In:
                                    {{
                                        attendanceRecord.checkInDistanceFromWorkLocationInKm
                                            ? `${attendanceRecord.checkInDistanceFromWorkLocationInKm} km`
                                            : "NA"
                                    }}
                                </div>
                                <div class="mx-2">|</div>
                                <div>
                                    Check Out:
                                    {{
                                        attendanceRecord.checkOutDistanceFromWorkLocationInKm
                                            ? `${attendanceRecord.checkOutDistanceFromWorkLocationInKm} km`
                                            : "NA"
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="additional-info-wrapper" v-if="attendanceRecord.isHalfDay">
                        <div>Half Day <i class="fas fa-check" /></div>
                    </div>
                    <div class="additional-info-wrapper" v-if="attendanceRecord.isOverTime">
                        <div>Overtime <i class="fas fa-check" /></div>
                        <div v-if="attendanceRecord.overtimeHours">Overtime Hours: {{ round2(attendanceRecord.overtimeHours) }}</div>
                        <div v-if="attendanceRecord.overtimeReason">Overtime Reason: {{ attendanceRecord.overtimeReason }}</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row gy-2 gx-3">
                    <div class="col-md-4" v-for="attendanceStatus of attendanceStatuses" :key="attendanceStatus.id">
                        <AttendanceStatusCard
                            :attendanceRecord="attendanceRecord"
                            :active="attendanceRecord.attendanceStatus && attendanceRecord.attendanceStatus.id === attendanceStatus.id"
                            @updated="$emit('updated')"
                            :attendanceStatus="attendanceStatus"
                        />
                    </div>
                    <!-- <div class="col-md-4">
                        <OvertimeHandleBtn />
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AttendanceStatusCard from "./attendance-status-card.vue"
import { mapState, mapActions } from "vuex"
import OvertimeHandleBtn from "./overtime-handle-btn.vue"
import { round2 } from "../../../utils/common"

export default {
    emits: ["updated"],
    components: { AttendanceStatusCard, OvertimeHandleBtn },
    data() {
        return { addNote: false, note: "", saving: false }
    },
    props: {
        attendanceRecord: { type: Object, default: null },
        employeeMode: { type: Boolean, default: false },
    },
    computed: {
        ...mapState("misc", ["attendanceStatuses", "attendanceDate"]),
    },
    mounted() {
        this.note = this.attendanceRecord.comments || ""
        // console.log("attendance record in attendance list item", this.attendanceRecord)
    },
    methods: {
        round2,
        ...mapActions("misc", ["updateAttendanceRecord"]),
        async handleNotesUpdate() {
            if (this.saving || !this.attendanceRecord || !this.note) return
            this.saving = true
            try {
                await this.updateAttendanceRecord({
                    id: this.attendanceRecord.id,
                    comments: this.note,
                })
                this.addNote = false
            } catch (err) {
                console.log("error in updating status", err)
            }
            this.$emit("updated")
            this.saving = false
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.attendance-user-list-item {
    border: 2px solid #ddd;
    background: white;
    border-bottom: 3px solid #ccc;
    margin-bottom: 15px;
    padding: 15px;
}
.employee-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
    align-items: stretch;
}
.employee-notes {
    flex: 1;
    resize: none;
}
.note-btn {
    font-size: 0.9em;
    color: rgba(2, 62, 190, 0.966);
}
.attendance-record-time {
    font-size: 0.9em;
}
.additional-info-wrapper {
    background: $secondaryColor;
    margin-top: 0px;
    padding: 5px;
    font-weight: bold;
    color: $primaryColor;
}
.additional-info {
    display: flex;
    justify-content: space-between;
}
.shift-wrapper {
    background: #eee;
    color: #333;
    margin-top: 5px;
    padding: 5px;
    font-size: 0.9em;
}
</style>
