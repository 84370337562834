<template>
    <div>
        <div class="my-2"><strong>Activity</strong></div>
        <div>
            <!-- <div class="d-flex items-center justify-content-between">
                <div class="d-flex items-center">
                    <div>Show:</div>
                    <button class="toggle-btn">All</button
                    ><button class="toggle-btn active">Comments</button
                    ><button class="toggle-btn">History</button
                    ><button class="toggle-btn">Work Log</button>
                </div>
                <div>
                    Newest first
                    <i class="fas fa-sort"></i>
                </div>
            </div> -->
            <CommentForm @inserted="handeInsert" :taskId="taskId" />
            <div class="comment-container">
                <div v-if="loading">Loading Comments...</div>
                <CommentItem
                    :taskId="taskId"
                    :comment="comment"
                    v-for="comment of comments"
                    :key="comment.id"
                    :task="task"
                    @update="handleUpdate"
                    @remove="handleRemove"
                />
                <div v-if="!loading && !comments.length">No Comments.</div>
            </div>
        </div>
    </div>
</template>

<script>
import HtmlEditor from "@/components/other/HtmlEditor"
import Button from "@/components/UI/Button"
import AttachmentUploader from "@/components/other/AttachmentUploader"
import CommentItem from "@/components/Lists/CommentItem"
import CommentForm from "@/components/Lists/CommentForm"
import { http } from "@/utils/http"

export default {
    data() {
        return {
            showCommentInput: false,
            comment: "",
            comments: [],
            loading: false,
            processingForm: false,
            removeFilesList: [],
            attachment1: null,
            attachment2: null,
            attachment3: null,
            attachment1Name: "",
            attachment2Name: "",
            attachment3Name: "",
            showAttachmentManager: true,
            task: null,
        }
    },
    props: { taskId: { type: [String, Number], default: "" } },
    components: { CommentItem, HtmlEditor, CommentForm, Button, AttachmentUploader },
    mounted() {
        this.fetchComments()
    },
    methods: {
        async fetchComments() {
            this.loading = true
            try {
                const res = await http.get("/project-comments?task_id=" + this.taskId)
                this.comments = res.data.result
                this.task = res.data.task
            } catch (err) {
                console.log("error in fetching comments", err)
            }
            this.loading = false
        },
        async handleRemove(comment) {
            this.comments = this.comments.filter((item) => item.id != comment.id)
        },
        async handeInsert(result) {
            this.comments = [result, ...this.comments]
        },
        handleUpdate(comment) {
            this.comments = this.comments.map((item) => {
                if (item.id == comment.id) {
                    item = comment
                }
                return item
            })
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../crud/form.scss";
.toggle-btn {
    font-weight: bold;
    background-color: #ddd;
    padding: 2px 7px;
    border-radius: 5px;
    margin-left: 10px;
    font-size: 0.9em;

    &.active {
        background-color: #555;
        color: white;
    }
}
.comment-input-container {
    input {
        font-size: 0.8em;
    }
}
.username-text {
    font-weight: bold;
    color: #444;
}
.attachment-manager {
    display: flex;
    gap: 15px;
    flex-flow: row wrap;
}
</style>
