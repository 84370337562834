import Vue from "vue"
import App from "./App.vue"
import router from "./router/index"
import store from "./store"
import "./assets/tailwind.css"
import VueToast from "vue-toast-notification"
import "vue-toast-notification/dist/theme-sugar.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

import VueGoodTablePlugin from "vue-good-table"

// import the styles
import "vue-good-table/dist/vue-good-table.css"

import BootstrapVue from "bootstrap-vue"
import VCalendar from "v-calendar"

Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.use(VueGoodTablePlugin)
Vue.use(VueToast, { duration: 4000 })
Vue.use(VCalendar, {
    // componentPrefix: "vc", // Use <vc-calendar />, <vc-date-picker /> for components
    // locales: {
    //     "en-US": {
    //         firstDayOfWeek: 2, // Optional: specify which day is the first day of the week
    //     },
    // },
})

import VueApexCharts from "vue-apexcharts"
Vue.use(VueApexCharts)
Vue.component("apexchart", VueApexCharts)

// Use v-calendar & v-date-picker components

const app = new Vue({
    router,
    store,
    render: (h) => h(App),
})

app.$mount("#app")
