<template>
    <PageCard sm title="Calendar" style="padding-top: 15px">
        <Spinner v-if="!dashboardStats" />
        <div v-else>
            <v-calendar @update:from-page="handleFromPageUpdate" style="width: 100%" :attributes="calendarAttributes" />
            <ColorLabelView
                :labels="[
                    { color: '#EF2B1F', title: 'Holidays' },
                    { color: '#1E88E5', title: 'Leaves' },
                    { color: '#7C2273', title: 'Birthdays' },
                ]"
            />
        </div>
    </PageCard>
</template>

<script>
import { mapState } from "vuex"
import PageCard from "../layout/PageCard.vue"
import CustomTable from "../other/CustomTable.vue"
import Button from "../UI/Button.vue"
import SectionTitle from "../UI/SectionTitle.vue"
import ColorLabelView from "./ColorLabelView.vue"
import { http } from "../../utils/http"
import { indianToIsoDate } from "../../utils/common"
import Spinner from "../UI/Spinner.vue"

export default {
    components: {
        PageCard,
        Button,
        SectionTitle,
        CustomTable,
        ColorLabelView,
        Spinner,
    },
    data() {
        return {
            calendarAttributes: [],
        }
    },
    props: {},
    computed: {
        ...mapState("misc", ["dashboardStats"]),
    },
    mounted() {
        this.fetchAttendanceData(new Date().getFullYear(), new Date().getMonth() + 1)
    },
    methods: {
        handleFromPageUpdate(e) {
            try {
                this.fetchAttendanceData(e.year, e.month)
            } catch (err) {}
        },
        handleViewChange(view) {
            console.log("handle view change", view)
            const { start } = view
            const year = start.getFullYear()
            const month = start.getMonth()
            this.fetchAttendanceData(year, month)
        },
        async fetchAttendanceData(year, month) {
            const res = await http.get(
                `/attendance-records/filter?stats=true&date=${year}-${month}-1&&search_month=true&search_date=false&limited_fields=true&return_general_leaves=true`
            )

            const attendanceData = res.data
            console.log("attendanceData", attendanceData)

            const attendanceDataFormatted = []

            for (let record of attendanceData.generalLeaves) {
                attendanceDataFormatted.push({
                    date: new Date(indianToIsoDate(record.startDateFormatted)),
                    status: "leaveday",
                    description: record.userName,
                })
            }
            for (let holiday of attendanceData.generalHolidays) {
                attendanceDataFormatted.push({
                    date: new Date(indianToIsoDate(holiday.startDateFormatted)),
                    status: "holiday",
                    description: holiday.name,
                })
            }
            for (let birthday of attendanceData.birthdays) {
                attendanceDataFormatted.push({
                    date: new Date(indianToIsoDate(birthday.startDateFormatted)).setFullYear(year),
                    status: "birthday",
                    description: birthday.userName,
                })
            }

            console.log("attendanceDataFormatted", attendanceDataFormatted)
            this.updateCalendarAttributes(attendanceDataFormatted)
        },
        updateCalendarAttributes(attendanceData) {
            this.calendarAttributes = attendanceData.map((item, index) => {
                let customClass = ""
                let dates = item.date

                let highlight = true
                if (item.status === "holiday") {
                    customClass = "holi-day"
                    highlight = "red"
                } else if (item.status === "birthday") {
                    customClass = "birth-day"
                    highlight = "purple"
                } else if (item.status === "leaveday") {
                    customClass = "leave-day"
                    highlight = "blue"
                }

                return {
                    key: `attribute-${index}`, // Ensure unique key for each attribute
                    highlight,
                    popover: {
                        label: item.description || "No info available",
                        visibility: "hover",
                    },
                    dates,
                    customData: { status: item.status },
                    class: customClass,
                }
            })
        },
    },
    watch: {
        calendarAttributes(e) {
            console.log("calendarAttributes updated", this.calendarAttributes)
        },
    },
}
</script>

<style lang="scss">
.holi-day {
    background-color: #ef2b1f;
    color: white;
}
.leave-day {
    background-color: #1e88e5;
    color: white;
}
.birth-day {
    background-color: #7c2273;
    color: white;
}
</style>
