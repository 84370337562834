<template>
    <div>
        <CrudIndex
            v-if="!loading"
            :permission="PERMISSION_CODES.VIEW_SETTINGS"
            title="Settings"
            :viewColumns="columns"
            fetchUrl="/settings"
            createUrl="/settings"
            updateRootUrl="/settings"
            :createFields="createFields"
            :reloadOnSuccess="true"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { createField, createColumn } from "../../utils/common"
import { http } from "@/utils/http"
import { PERMISSION_CODES } from "../../store/permission"

export default {
    data() {
        return {
            PERMISSION_CODES,
            loading: true,
            employees: [],
            managers: [],
            createFields: [
                createField("Name*", "name", "text", true),
                createField("Description*", "value", "text", true),
            ],
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Name", "name", "string"),
                createColumn("Value", "value", "string"),
                // createColumn("Active", "status", "string"),
            ],
        }
    },
    components: { CrudIndex },
    async mounted() {
        this.loading = false
    },
    methods: {},
}
</script>

<style></style>
