<template>
    <PageCard title="Communication Messages" hr>
        <div style="max-width: 600px">
            <div class="mx-2 mb-4">
                <Button noMargin @click="$router.push('/communication-groups')" class="">
                    <i class="fas fa-chevron-left"></i> Back to Communication Groups
                </Button>
            </div>
            <div>
                <div v-if="!loading && messages.length === 0" class="no-messages">
                    <p>No Messages Found</p>
                </div>
                <div v-else class="mx-2 message-list">
                    <h3 class="group-name my-3" v-if="communicationGroup">
                        {{ communicationGroup.name }}
                    </h3>
                    <div v-if="showConfirmationMessage" class="confirmation-dialog">
                        <p class="fs-6">Are you sure you want to delete all messages?</p>
                        <Button @click="confirmClearMessages" class="mr-2">{{ deleting ? "Please wait..." : "Yes" }}</Button>
                        <Button @click="showConfirmationMessage = false">No</Button>
                    </div>
                    <Button v-else style="background: red" noMargin class="my-2" @click="handleClear">Clear Messages</Button>

                    <div v-if="loading" class="my-3 flex justify-center items-center">
                        <div class="spinner-border spinner-border-sm"></div>
                    </div>
                    <div class="msg-style" v-for="message of messages" :key="message.id">
                        <div class="date-input">{{ formatIndianDate(message.createdAt) }}</div>

                        <div class="my-3" v-if="message.mediaSrc">
                            <div v-if="message.mediaIsImage">
                                <a target="_blank" :href="getAssetUrlPath(message.mediaSrc)">
                                    <img style="width: 100px; height: 100px" :src="getAssetUrlPath(message.mediaSrc)" />
                                </a>
                            </div>
                            <div class="my-2" v-else>
                                <a target="_blank" :href="getAssetUrlPath(message.mediaSrc)">
                                    <i class="fas fa-external-link"></i> Attached File
                                </a>
                            </div>
                        </div>
                        <div>{{ message.text }}</div>
                    </div>
                    <div class="my-3">
                        <a href="javascript:void(0)" class="more-link" @click="loadMoreMessages" v-show="!completed">
                            {{ loading ? "Please wait..." : "Load older messages" }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </PageCard>
</template>

<script>
import { http } from "@/utils/http"
import { PERMISSION_CODES } from "../../store/permission"
import PageCard from "../../components/layout/PageCard.vue"
import Button from "../../components/UI/Button.vue"
import { formatIndianDate, getAssetUrlPath } from "../../utils/common"

export default {
    data() {
        return {
            PERMISSION_CODES,
            loading: false,
            messages: [],
            showConfirmationMessage: false,
            page: 1,
            communicationGroup: null,
            completed: false,
            deleting: false,
        }
    },
    components: { PageCard, Button },
    async mounted() {
        this.fetchMessages()
    },
    methods: {
        getAssetUrlPath,
        formatIndianDate,
        handleClear() {
            this.showConfirmationMessage = true
        },
        async loadMoreMessages() {
            this.page++
            try {
                this.loading = true
                const res = await http.get(
                    `/communication-messages?is_group=true&page=${this.page}&limit=10&chat_id=${this.$route.params.id}&reverse_messages=false`
                )
                this.messages = [...this.messages, ...res.data.result]
                if (res.data.result.length < 10) {
                    this.completed = true
                }
            } catch (err) {
                console.log("error in getting messages", err)
            }
            this.loading = false
        },
        async confirmClearMessages() {
            try {
                if (this.deleting) return
                this.deleting = true
                // alert("Are you sure u want to delete it??");
                const response = await http.delete(`/communication-messages/${this.$route.params.id}`)
                if (response.status === 200) {
                    this.messages = []
                    this.showConfirmationMessage = false
                    this.$toast.success("Deleted successfully")
                    this.$router.push("/communication-groups")
                } else {
                    this.$toast.error("Failed to delete messages")
                }
            } catch (err) {
                console.log("error in clearing messages", err)
            }
            this.deleting = false
        },
        async fetchMessages() {
            this.loading = true
            try {
                const res = await http.get(`/communication-messages?is_group=true&page=1&limit=10&chat_id=${this.$route.params.id}`)
                this.communicationGroup = res.data.communicationGroup
                this.messages = res.data.result
                // console.log(this.messages);
            } catch (err) {
                console.log("error in getting messages", err)
            }
            this.loading = false
        },
    },
}
</script>

<style lang="scss" scoped>
.msg-style {
    margin: 10px 0;
    background: #f4f4f4;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
}
.date-input {
    font-size: 0.8em;
    margin: 5px 0;
}
.more-link {
    color: dodgerblue;
}
.group-name {
    font-size: 18px;
}
</style>
