<template>
    <div>
        <CrudIndex
            title="Attendance Shifts"
            :viewColumns="columns"
            fetchUrl="/attendance-shifts"
            :permission="PERMISSION_CODES.VIEW_ATTENDANCE_SHIFTS"
            createUrl="/attendance-shifts"
            updateRootUrl="/attendance-shifts"
            deleteRootUrl="/attendance-shifts"
            :createFields="createFields"
            :reloadOnSuccess="true"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { PERMISSION_CODES } from "../../store/permission"
import { createField, createColumn } from "../../utils/common"

export default {
    data() {
        return {
            createFields: [
                createField("Name*", "name", "text", true),
                createField("Select Weekdays*", "weekDays", "multiselect", true, "", [
                    { id: 0, title: "Sunday" },
                    { id: 1, title: "Monday" },
                    { id: 2, title: "Tuesday" },
                    { id: 3, title: "Wednesday" },
                    { id: 4, title: "Thursday" },
                    { id: 5, title: "Friday" },
                    { id: 6, title: "Saturday" },
                ]),
                createField("Start Time*", "startTime", "time", true),
                createField("End Time*", "endTime", "time", true),
                createField("Is Default", "isDefault", "switch", false, false),
                // createField(
                //     "Duration (hrs, max 24)*",
                //     'JSON|{"field":"durationHrs","max":24}',
                //     "number",
                //     true
                // ),
                // createField("Status", "status", "switch", false, true),
            ],
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Name", "name", "string"),
                createColumn("Start Time", "startTime", "string"),
                createColumn("End Time", "endTime", "string"),
                createColumn("Duration (hrs)", "durationFormatted", "number"),
                createField("Is Default", "isDefault", "string"),
                // createColumn("Active", "status", "string"),
            ],
            PERMISSION_CODES,
        }
    },
    components: { CrudIndex },
}
</script>

<style></style>
