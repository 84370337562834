import { http } from "@/utils/http"
import { round2, formatIsoDate } from "@/utils/common"

const state = {
    pageTitle: "",
    loadingTopUsers: false,
    topUsers: [],
    userRoles: [],
    companies: [],
    projectMeasurements: [],
    showSidebar: true,
    dashboardStats: null,
    countries: [],
    states: [],
    cities: [],
    bankMasters: [],
    // attendance related data
    loadingAttendanceData: false,
    attendanceStatusId: "",
    attendanceDate: formatIsoDate(),
    attendanceCompanyId: "",
    attendanceStatuses: [],
    attendanceHolidays: [],
    attendanceHolidayTypes: [],
    attendanceShifts: [],
    attendanceRecordsForDate: [],
    attendanceStats: null,

    attendanceComparisonData: null,
    attendancePerformanceData: null,
    departmentWiseAttendance: null,
    dashboardStatsAssetData: null,
    dashboardStatsLeaveRequestData: null,
}

const getters = {}

const mutations = {
    SET_DATA: (state, data) => {
        for (let key in data) {
            state[key] = data[key]
        }
    },
}

const actions = {
    setData(vuexContext, data) {
        vuexContext.commit("SET_DATA", data)
    },
    async updateAttendanceStatus({ state, dispatch }, statusId) {
        state.attendanceStatusId = statusId
    },
    async updateAttendanceDate({ state, dispatch }, date) {
        state.attendanceDate = date
    },
    async updateAttendanceCompany({ state, dispatch }, companyId) {
        state.attendanceCompanyId = companyId
        await dispatch("fetchRecordsByDate")
    },
    async updateAttendanceStats({ state, dispatch }) {
        let totalPresent = 0
        let totalAbsent = 0
        let totalLeave = 0
        let totalOvertimeHours = 0

        for (let attendanceRecord of state.attendanceRecordsForDate) {
            const status = attendanceRecord.attendanceStatus

            if (status) {
                const stName = status.name.toLowerCase()
                if (stName.includes("present")) {
                    totalPresent++
                } else if (stName.includes("absent")) {
                    totalAbsent++
                } else if (stName.includes("leave")) {
                    totalLeave++
                }
            }
            totalOvertimeHours += attendanceRecord.overtimeHours || 0
        }
        state.attendanceStats = {
            totalPresent,
            totalAbsent,
            totalLeave,
            totalOvertimeHours: round2(totalOvertimeHours),
        }
    },
    async fetchRecordsByDate({ state, dispatch }) {
        state.loadingAttendanceData = true
        try {
            const res = await http.get(
                `/attendance-records/filter?date=${state.attendanceDate}&company_id=${state.attendanceCompanyId}&status_id=${state.attendanceStatusId}`
            )
            state.attendanceRecordsForDate = res.data.result

            await dispatch("updateAttendanceStats")
        } catch (err) {
            console.log("error in loading attendance records by date", err)
        }
        state.loadingAttendanceData = false
    },
    async updateAttendanceRecord({ state, dispatch }, updateData) {
        try {
            const updateId = updateData.id
            delete updateData.id
            const res = await http.patch(`/attendance-records-crud/${updateId}`, updateData)
            const updatedResult = res.data.result

            // update the attendance records
            state.attendanceRecordsForDate = state.attendanceRecordsForDate.map((item) => {
                if (item.id === updateId) {
                    return updatedResult
                }
                return item
            })
            dispatch("updateAttendanceStats")
        } catch (err) {
            console.log("error in updating attendance record", err)
        }
    },
    async fetchAttendanceInfo({ state }) {
        state.loadingAttendanceData = true
        try {
            const res = await http.get("/misc/attendance-info")
            const result = res.data.result

            state.attendanceStatuses = result.attendanceStatuses
            state.attendanceHolidays = result.attendanceHolidays
            state.attendanceHolidayTypes = result.attendanceHolidayTypes
            state.attendanceShifts = result.attendanceShifts
        } catch (err) {
            console.log("error in loading attendance info", err)
        }
        state.loadingAttendanceData = false
    },
    async fetchDashboardStats({ state }) {
        try {
            const res = await http.get("/misc/dashboard-stats")
            state.dashboardStats = res.data.result
        } catch (err) {
            console.log("error in loading dashboard stats", err)
        }
    },
    async fetchDashboardStatsMasters({ state }) {
        try {
            const res = await http.get("/misc/dashboard-stats/masters")
            const result = res.data.result
            state.countries = result.countries
            state.companies = result.companies
            state.userRoles = result.userRoles
            state.bankMasters = result.bankMasters
            state.projectMeasurements = result.projectMeasurements
        } catch (err) {
            console.log("error in loading dashboard masters", err)
        }
    },
    async fetchDashboardStatsLeaveRequests({ state }, { month = "" } = {}) {
        // month num should start from 1
        try {
            const res = await http.get(`/misc/dashboard-stats/leave-requests`)
            state.dashboardStatsLeaveRequestData = res.data.result
        } catch (err) {
            console.log("error in loading dashboard performance", err)
        }
    },
    async fetchDashboardStatsAssetData({ state }, { month = "" } = {}) {
        // month num should start from 1
        try {
            const res = await http.get(`/misc/dashboard-stats/assets`)
            state.dashboardStatsAssetData = res.data.result
        } catch (err) {
            console.log("error in loading dashboard performance", err)
        }
    },
    async fetchDashboardStatsPerformance({ state }, { month = "" } = {}) {
        // month num should start from 1
        try {
            const res = await http.get(`/misc/dashboard-stats/performance?month=${month}`)
            state.attendancePerformanceData = res.data.attendancePerformanceData
        } catch (err) {
            console.log("error in loading dashboard performance", err)
        }
    },
    async fetchDashboardStatsAttendanceDepartmentWise({ state }, { attendanceComparisonMonthType = "daily" } = {}) {
        try {
            const res = await http.get(
                `/misc/dashboard-stats/attendance-department-wise?attendance_comparison_month_type=${attendanceComparisonMonthType}`
            )
            state.departmentWiseAttendance = res.data.departmentWiseAttendance
        } catch (err) {
            console.log("error in loading dashboard attendance", err)
        }
    },
    async fetchDashboardStatsAttendance({ state }, { attendanceComparisonMonthType = "daily" } = {}) {
        try {
            const res = await http.get(`/misc/dashboard-stats/attendance?attendance_comparison_month_type=${attendanceComparisonMonthType}`)
            state.attendanceComparisonData = res.data.attendanceComparisonData
        } catch (err) {
            console.log("error in loading dashboard attendance", err)
        }
    },
    async fetchCountries({ state }) {
        try {
            const res = await http.get("/misc/countries")
            state.countries = res.data.result
        } catch (err) {
            console.log("error in loading countries", err)
        }
    },
    async fetchStates({ state }, countryId) {
        try {
            const res = await http.get(`/misc/states?country_id=${countryId}`)
            state.states = res.data.result
        } catch (err) {
            console.log("error in loading dashboard states", err)
        }
    },
    async fetchCities({ state }, stateId) {
        try {
            const res = await http.get(`/misc/cities?state_id=${stateId}`)
            state.cities = res.data.result
        } catch (err) {
            console.log("error in loading cities", err)
        }
    },
    async fetchCompanies({ state }) {
        try {
            const res = await http.get("/companies")
            state.companies = res.data.result
        } catch (err) {
            console.log("error in loading companies", err)
        }
    },
    async fetchUserRoles({ state }) {
        try {
            const res = await http.get("/user-roles")
            state.userRoles = res.data.result
        } catch (err) {
            console.log("error in loading user roles", err)
        }
    },
    async fetchCountriesStatesCities({ state }) {
        try {
            const res = await http.get("/misc/countries-states-cities")
            const resData = res.data.result
            state.countries = resData.countries
            state.states = resData.states
            state.cities = resData.cities
        } catch (err) {
            console.log("error in fetch country state city", err)
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
