<template>
    <div>
        <SectionTitle>Asset Management</SectionTitle>
        <div class="my-3">
            <CustomTable v-if="!loading && assetAllocations.length">
                <template #head>
                    <tr>
                        <th>No.</th>
                        <th>Asset Name</th>
                        <th>Allocated Date</th>
                        <th>Status</th>
                        <th>Returned Date</th>
                        <th>Actions</th>
                    </tr>
                </template>
                <template #body>
                    <EmployeeAssetManagerRow
                        :slNo="i + 1"
                        :assetAllocation="assetAllocation"
                        v-for="(assetAllocation, i) of assetAllocations"
                        :key="assetAllocation.id"
                        @updateReturnedAssetAllocation="updateReturnedAssetAllocation"
                    />
                </template>
            </CustomTable>
            <div v-else>
                <p>No assets found!</p>
                <Button @click="$router.push('/asset-allocations')">Allocate Assets</Button>
            </div>
        </div>
    </div>
</template>

<script>
import { formatIndianDate } from "../../utils/common"
import { http } from "../../utils/http"
import CustomTable from "../other/CustomTable.vue"
import Button from "../UI/Button.vue"
import SectionTitle from "../UI/SectionTitle.vue"
import EmployeeAssetManagerRow from "./EmployeeAssetManagerRow.vue"

export default {
    data() {
        return {
            assetAllocations: [],
            loading: true,
        }
    },
    components: { EmployeeAssetManagerRow, CustomTable, Button, SectionTitle },
    props: {
        employeeId: {
            type: [String, Number],
            default: null,
        },
    },
    mounted() {
        this.fetchAssets()
    },
    methods: {
        formatIndianDate,
        updateReturnedAssetAllocation(allocation) {
            if (!allocation) return
            this.assetAllocations = this.assetAllocations.map((item) => {
                if (item.id == allocation.id) {
                    return {
                        ...item,
                        returned: true,
                        returnedDate: allocation.returnedDate,
                    }
                }
            })
        },
        async fetchAssets() {
            this.loading = true
            try {
                const res = await http.get(`/asset-allocations?employee_id=${this.employeeId}`)
                this.assetAllocations = res.data.result
            } catch (err) {
                console.log("error in loading assets", err)
            }
            this.loading = false
        },
    },
}
</script>

<style></style>
