<template>
    <div v-if="assetLog" class="asset-log-container">
        <div class="message">
            {{ assetLog.message }}
        </div>
        <div
            v-if="assetLog.user"
            @click="viewMore = !viewMore"
            style="color: dodgerblue; cursor: pointer"
            class="my-1"
        >
            View {{ viewMore ? "less" : "more" }}
        </div>
        <div v-if="viewMore">
            <div class="extra-details" v-if="assetLog.user">
                <div class="extra-detail-title">Employee Details</div>
                <hr />
                <div>
                    <div>
                        Full Name: {{ assetLog.user.firstName || "" }}
                        {{ assetLog.user.lastName || "" }}
                    </div>
                    <div>Employee Code: {{ assetLog.user.empCode || "" }}</div>
                    <div>
                        Email:
                        {{ assetLog.user.email || "" }}
                    </div>
                </div>
                <div class="my-3">
                    <Button noMargin @click="$router.push(`/employees/edit/${assetLog.user.id}`)"
                        >View Employee</Button
                    >
                </div>
            </div>
        </div>
        <div class="date-info">
            {{ formatIndianDate(assetLog.createdAt) }}
        </div>
    </div>
</template>

<script>
import Button from "../../components/UI/Button.vue"
import { formatIndianDate } from "../../utils/common"

export default {
    data() {
        return {
            viewMore: false,
        }
    },
    components: { Button },
    props: {
        assetLog: {
            type: Object,
            value: null,
        },
    },
    methods: {
        formatIndianDate,
    },
}
</script>

<style lang="scss" scoped>
.asset-log-container {
    margin: 10px 0;
    padding: 15px;

    background-color: #ddd;
    border-left: 5px solid #555;
}
.extra-details {
    margin: 8px 0;
    padding: 12px;
    background-color: #ccc;
}
.message {
    font-size: 1.1em;
}
.date-info {
    color: #555;
    margin-top: 5px;
}
.extra-detail-title {
    // color: white;
    margin-bottom: 10px;
}
</style>
