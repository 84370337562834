<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="Asset Log Types"
            :viewColumns="columns"
            :permission="PERMISSION_CODES.VIEW_ASSET_LOGS"
            fetchUrl="/asset-log-types"
            createUrl="/asset-log-types"
            updateRootUrl="/asset-log-types"
            :reloadOnSuccess="true"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import { mapState } from "vuex"
import CrudIndex from "../../components/crud/index.vue"
import { PERMISSION_CODES } from "../../store/permission"
import { createField, createColumn } from "../../utils/common"
import { http } from "../../utils/http"

export default {
    data() {
        return {
            createFields: [createField("Name*", "name", "text", true)],
            columns: [createColumn("Name", "name", "string")],
            loading: false,
            initialized: false,
            PERMISSION_CODES,
        }
    },
    components: { CrudIndex },
    mounted() {},
    computed: {
        ...mapState("misc", ["companies"]),
    },
    watch: {},
    methods: {},
}
</script>

<style></style>
