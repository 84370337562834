<template>
    <div class="bg-gray-100 p-4 rounded-lg shadow-md">
        <h2 class="text-xl font-semibold mb-2">{{ task.name }}</h2>
        <p>
            <strong>Status:</strong>
            <span :class="[statusClass, 'inline-block px-2 py-1 rounded']">{{ taskStatus }}</span>
        </p>
        <p><strong>Assigned Users:</strong> {{ assignedUsers }}</p>
        <p><strong>Start Date:</strong> {{ formattedStartDate }}</p>
        <p><strong>End Date:</strong> {{ formattedEndDate }}</p>

        <!-- Render subtasks -->
        <div v-if="subtasks.length" class="mt-4">
            <h3 class="text-lg font-semibold mb-2">Subtasks</h3>
            <div
                v-for="(subtask, index) in subtasks"
                :key="index"
                class="bg-white p-4 rounded-lg shadow-md mb-2"
            >
                <h4 class="text-lg font-semibold mb-2">{{ subtask.name }}</h4>
                <p>
                    <strong>Status:</strong>
                    <span
                        :class="[subtaskStatusClass(subtask), 'inline-block px-2 py-1 rounded']"
                        >{{ subtaskStatus(subtask) }}</span
                    >
                </p>
                <p><strong>Assigned Users:</strong> {{ assignedUsersSubtask(subtask) }}</p>
                <p><strong>Start Date:</strong> {{ formattedStartDateSubtask(subtask) }}</p>
                <p><strong>End Date:</strong> {{ formattedEndDateSubtask(subtask) }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { http } from "@/utils/http"
import { formatIndianDate } from "../../../utils/common"

export default {
    data() {
        return {
            task: {},
            subtasks: [], // Array to store subtask data
        }
    },
    computed: {
        assignedUsers() {
            return this.task.Reporter
                ? this.task.Reporter.map((user) => user.firstName).join(", ")
                : ""
        },
        taskStatus() {
            return this.task.Status ? this.task.Status.name : ""
        },
        statusClass() {
            return this.task.Status
                ? `bg-${this.task.Status.color}-200 text-${this.task.Status.color}-800`
                : ""
        },
        formattedStartDate() {
            return this.task.startDate ? formatIndianDate(this.task.startDate) : ""
        },
        formattedEndDate() {
            return this.task.endDate ? formatIndianDate(this.task.endDate) : ""
        },
    },
    methods: {
        async fetchSubtasks(taskIds) {
            for (const taskId of taskIds) {
                try {
                    const response = await http.get(`/project-tasks/${taskId.trim()}`)
                    const subtask = response.data.result
                    this.subtasks.push(subtask)
                } catch (error) {
                    console.error("Error fetching subtask data:", error)
                }
            }
        },
        subtaskStatus(subtask) {
            return subtask.Status ? subtask.Status.name : ""
        },
        subtaskStatusClass(subtask) {
            return subtask.Status
                ? `bg-${subtask.Status.color}-200 text-${subtask.Status.color}-800`
                : ""
        },
        assignedUsersSubtask(subtask) {
            return subtask.Reporter ? subtask.Reporter.map((user) => user.firstName).join(", ") : ""
        },
        formattedStartDateSubtask(subtask) {
            return subtask.startDate ? formatIndianDate(subtask.startDate) : ""
        },
        formattedEndDateSubtask(subtask) {
            return subtask.endDate ? formatIndianDate(subtask.endDate) : ""
        },
    },
    async created() {
        const id = this.$route.params.id
        try {
            const response = await http.get(`/project-tasks/${id}`)
            this.task = response.data.result
            console.log(this.task)

            // Check if taskId is present
            if (this.task.taskId) {
                const taskIds = this.task.taskId.split(",") // Split comma-separated task IDs
                await this.fetchSubtasks(taskIds)
            }
        } catch (error) {
            console.error("Error fetching data:", error)
        }
    },
}
</script>
