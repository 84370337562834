<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="Asset Allocations"
            :permission="PERMISSION_CODES.VIEW_ASSET_ALLOCATIONS"
            :viewColumns="columns"
            fetchUrl="/asset-allocations"
            createUrl="/asset-allocations"
            :allowEditing="false"
            :reloadOnSuccess="true"
            :createFields="createFields"
            v-slot="slotProps"
        >
            <Button
                :noMargin="true"
                style="min-width: 80px; background: #55f; font-size: 0.8em; padding: 10px 0px"
                @click="returnAllocation(slotProps.itemId)"
                class="crud-btn"
            >
                Return <i class="fas fa-refresh" />
            </Button>
        </CrudIndex>
    </div>
</template>

<script>
import { mapState } from "vuex"
import CrudIndex from "../../components/crud/index.vue"
import SelectDropdown from "../../components/crud/SelectDropdown.vue"
import Button from "../../components/UI/Button.vue"
import SectionTitle from "../../components/UI/SectionTitle.vue"
import { PERMISSION_CODES } from "../../store/permission"
import { createField, createColumn } from "../../utils/common"
import { http } from "../../utils/http"

export default {
    data() {
        return {
            createFields: [createField("Tag*", "tag", "text", true)],
            columns: [
                // createColumn("Tag", "tag", "string"),
                createColumn("Asset", "assetName", "string"),
                createColumn("Employee Allocated", "employeeName", "string"),
                createColumn("Allocated Date", "allocatedDateFormatted", "string"),
                createColumn("Status", "statusName", "string"),
                createColumn("Returned Date", "returnedDateFormatted", "string"),
            ],
            PERMISSION_CODES,
            loading: true,
            returning: false,
            initialized: false,
        }
    },
    components: { SelectDropdown, SectionTitle, CrudIndex, Button },
    mounted() {
        this.init()
    },
    computed: {
        ...mapState("misc", ["companies"]),
    },
    watch: {},
    methods: {
        async returnAllocation(assetAllocationId) {
            if (this.returning) return
            this.returning = true
            try {
                const res = await http.patch(`/asset-allocations/${assetAllocationId}/return`)
                this.$toast.success("Asset returned successfully!")
                window.location.reload()
            } catch (err) {
                console.log("error in returning asset", err)
                this.$toast.error(errMsg(err))
            }
            this.returning = false
        },
        async init() {
            if (this.initialized) return
            try {
                const { assets, employees } = await this.fetchAssetAllocationMasters()

                this.createFields = [
                    ...this.createFields,
                    createField(
                        "Asset Item*",
                        "assetId",
                        "select",
                        true,
                        "",
                        assets.map((item) => ({
                            id: item.id,
                            title: item.name,
                        }))
                    ),
                    createField(
                        "Employee*",
                        "employeeId",
                        "select",
                        true,
                        "",
                        employees.map((item) => ({
                            id: item.id,
                            title: `${item.firstName || ""} ${item.lastName || ""}`,
                        }))
                    ),
                    createField("Date of allocation*", "allocatedDate", "date", true, new Date().toISOString().slice(0, 10)),
                ]
                this.loading = false
                this.initialized = false
            } catch (err) {
                console.log("error in initializing assets", err)
            }
        },
        async fetchAssetAllocationMasters() {
            const res = await http.get("/asset-allocations/masters")
            return res.data.result
        },
    },
}
</script>

<style></style>
