function formatFileSize(bytes) {
    if (bytes < 1024) {
        return bytes + " bytes"
    } else if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(2) + " KB"
    } else if (bytes < 1024 * 1024 * 1024) {
        return (bytes / (1024 * 1024)).toFixed(2) + " MB"
    } else {
        return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB"
    }
}

function getFileTypeFromExtension(extension) {
    const extensionMap = {
        jpg: "JPEG Image",
        jpeg: "JPEG Image",
        png: "PNG Image",
        gif: "GIF Image",
        svg: "SVG Image",
        pdf: "PDF Document",
        doc: "Microsoft Word Document",
        docx: "Microsoft Word Document",
        xls: "Microsoft Excel Spreadsheet",
        xlsx: "Microsoft Excel Spreadsheet",
        ppt: "Microsoft PowerPoint Presentation",
        pptx: "Microsoft PowerPoint Presentation",
        // Add more extensions and types as needed
    }

    // Convert the extension to lowercase to handle case-insensitive comparison
    const lowercaseExtension = extension.toLowerCase()

    // Check if the extension exists in the map
    if (extensionMap.hasOwnProperty(lowercaseExtension)) {
        return extensionMap[lowercaseExtension]
    } else {
        return "Unknown File Type"
    }
}

export { formatFileSize, getFileTypeFromExtension }
