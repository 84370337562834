<template>
    <PageCard style="padding-top: 5px">
        <div class="text-center">
            <div class="my-3">
                <SectionTitle>{{ title }} Assets:</SectionTitle>
            </div>
            <hr />
        </div>
        <div class="my-3">
            <div class="amount-value">
                {{ assetCount }}
            </div>
            <div class="amount-label">Assets</div>
        </div>
        <div class="my-3">
            <div class="amount-value">Rs. {{ assetAmount }}</div>
            <div class="amount-label">Total amount</div>
        </div>
        <div class="text-center">
            <router-link :to="link"><Button>Details</Button></router-link>
        </div>
    </PageCard>
</template>

<script>
import PageCard from "../layout/PageCard.vue"
import Button from "../UI/Button.vue"
import SectionTitle from "../UI/SectionTitle.vue"

export default {
    components: { PageCard, Button, SectionTitle },
    props: {
        title: {
            type: String,
            default: "",
        },
        assetCount: {
            type: Number,
            default: 0,
        },
        assetAmount: {
            type: Number,
            default: 0,
        },
        link: {
            type: String,
            default: "/asset-allocations",
        },
    },
}
</script>

<style lang="scss" scoped>
.amount-value {
    color: #444;
    font-size: 1.3em;
}
.amount-label {
    color: #444;
    font-size: 0.8em;
}
</style>
