<template>
    <div class="flex justify-center items-center min-h-screen bg-gray-100">
        <div id="chart_div" class="w-full max-w-4xl bg-white shadow-lg rounded-lg p-6"></div>
    </div>
</template>

<script>
import { http } from "@/utils/http"

export default {
    data() {
        return {
            tasksData: [],
        }
    },
    mounted() {
        http.get("/project-tasks")
            .then((response) => {
                if (response.data) {
                    this.tasksData = response.data.result
                    // Load Google Charts library
                    google.charts.load("current", {
                        packages: ["gantt"],
                    })
                    // Set callback function to execute after Google Charts library is loaded
                    google.charts.setOnLoadCallback(this.drawChart)
                } else {
                    throw new Error("Response data is empty or not in JSON format")
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error)
            })
    },
    methods: {
        drawChart() {
            // Create data for the Gantt chart
            const data = new google.visualization.DataTable()
            data.addColumn("string", "Task ID")
            data.addColumn("string", "Task Name")
            data.addColumn("string", "Resource")
            data.addColumn("date", "Start Date")
            data.addColumn("date", "End Date")
            data.addColumn("number", "Duration")
            data.addColumn("number", "Percent Complete")
            data.addColumn("string", "Dependencies")

            // Iterate through tasks data and add them to the DataTable
            this.tasksData.forEach((task) => {
                const assigneeUser = this.getAssigneeUserName(task) // Get assigned user name

                let statusName = task.Status && task.Status.name
                data.addRow([
                    task.slug,
                    task.name,
                    assigneeUser,
                    new Date(task.startDate),
                    new Date(task.endDate),
                    null, // Duration (calculated automatically by Google Charts)
                    statusName == "Completed"
                        ? 100
                        : statusName == "In Progress"
                        ? 90
                        : statusName == "Pending"
                        ? 50
                        : 0, // Percent complete
                    null,
                ])
            })

            // Set options for the Gantt chart
            const options = {
                height: 400,
                gantt: {
                    trackHeight: 30,
                },
            }

            // Create a Gantt chart instance and render it
            const chart = new google.visualization.Gantt(document.getElementById("chart_div"))
            chart.draw(data, options)
        },
        getAssigneeUserName(task) {
            // Get assignee user name from task data (replace this with your actual logic)
            // For now, let's assume the assigneeUserIds is a string of comma-separated user IDs
            // We'll split the string and fetch the names of each user
            const assigneeUserIds = task.assigneeUserIds.split(",")
            let assigneeUserName = ""
            assigneeUserIds.forEach((userId) => {
                // Fetch the user details based on userId and append their name to the assigneeUserName string
                // You can implement this part based on how you fetch user details in your system
                assigneeUserName += `User${userId}, `
            })
            // Remove the trailing comma and space
            assigneeUserName = assigneeUserName.slice(0, -2)
            return assigneeUserName
        },
    },
}
</script>
