<template>
    <div>
        <div class="head-bg"><a href="/">Samyak Tradex</a></div>
        <div class="main-samyak">
            <h1>Privacy Policy</h1>
            <p>Effective Date: 1st March 2024</p>
            <p>
                Thank you for choosing Samyak Tradex for your Human Resource Management needs. This
                Privacy Policy outlines how we collect, use, disclose, and protect your personal
                information through our HRMS platform.
            </p>

            <h2>1. Information We Collect</h2>
            <p>
                We collect the following types of personal information from users of our HRMS
                platform:
            </p>
            <ul>
                <li>
                    Contact Information: such as name, email address, phone number, and postal
                    address.
                </li>
                <li>
                    Employment Information: such as job title, department, and employment history.
                </li>
                <li>
                    Identification Information: such as employee ID, Social Security Number (SSN),
                    or any government-issued identification numbers.
                </li>
                <li>Financial Information: such as bank account details for payroll processing.</li>
                <li>
                    Personal Preferences: such as language preference and communication preferences.
                </li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <p>We may use the information collected for the following purposes:</p>
            <ul>
                <li>
                    Employee Management: to manage employee profiles, performance evaluations, and
                    training records.
                </li>
                <li>
                    Payroll Processing: to process salary payments, tax deductions, and benefits
                    administration.
                </li>
                <li>
                    Communication: to communicate with employees regarding company policies,
                    announcements, and updates.
                </li>
                <li>Compliance: to comply with legal obligations and regulatory requirements.</li>
                <li>
                    Improving HRMS: to enhance and improve our HRMS platform for better user
                    experience and functionality.
                </li>
            </ul>

            <h2>3. Information Sharing and Disclosure</h2>
            <p>
                We may share your personal information with third parties in the following
                circumstances:
            </p>
            <ul>
                <li>
                    Service Providers: We may engage third-party service providers to assist us in
                    managing and operating our HRMS platform.
                </li>
                <li>
                    Legal Compliance: We may disclose personal information when required by law or
                    in response to legal process.
                </li>
                <li>
                    Business Transfers: In the event of a merger, acquisition, or sale of all or a
                    portion of our assets, personal information may be transferred as part of the
                    transaction.
                </li>
            </ul>

            <h2>4. Data Security</h2>
            <p>
                We implement appropriate security measures to protect the confidentiality,
                integrity, and availability of your personal information. These measures include
                encryption, access controls, and regular security assessments.
            </p>
            <p>We implement industry-standard security measures to protect your location data.

</p>

            <h2>5. Your Rights and Choices</h2>
            <p>
                You have the right to access, update, and correct your personal information stored
                in our HRMS platform. You may also request the deletion of your information, subject
                to legal requirements.
            </p>
<h2>6. Location Data </h2>
<p>Our app collects and uses location data to provide location-based services and enhance your experience. We may collect precise or approximate location data based on your device settings and permissions.

</p>
<h2>7. 
Data Retention:</h2><p>
We retain location data only as long as necessary for the purposes outlined above.</p>
           
<h2>8. How We Use Location Data:</h2>
<p>
To provide personalized content and services based on your location.<br>
To improve app functionality and user experience.<br>
For analytical purposes to understand user behavior and preferences.</p>
<h2>Sharing of Location Data:</h2><p>
We do not share your location data with third parties except:
<br>
With service providers who assist us in delivering and improving our services.<br>
As required by law or to protect our rights.</p>

<h2>9. Updates to this Privacy Policy</h2>
            <p>
                We may update this Privacy Policy from time to time to reflect changes in our
                practices or legal requirements. We will notify you of any material changes by
                posting the updated policy on our website.
            </p>
            <h2>Your Choices:</h2>
<p>
You can enable or disable location services in your device settings.</p>

            <h2>10. Contact Us</h2>
            <p>
                If you have any questions or concerns about our Privacy Policy or the handling of
                your personal information, please contact us at
                <a href="mailto:hr@samyaktradex.com">hr@samyaktradex.com</a>.
            </p>
        </div>
        <div class="footer-bg">&copy; Samyak Tradex {{ new Date().getFullYear() }}</div>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.head-bg {
    padding: 15px;
    background-color: brown;
    color: white;
    margin-bottom: 30px;
    font-size: 25px;
}
.footer-bg {
    padding: 30px 15px;
    background-color: #444;
    color: white;
    text-align: center;
}
.main-samyak {
    padding: 15px;
}
p {
    margin: 15px 0;
}
h1 {
    font-weight: bold;
    font-size: 22px;
}
h2 {
    font-weight: bold;
    font-size: 18px;
    margin-top: 15px;
}
li {
    list-style-type: disc;
    list-style-position: inside;
}
</style>
