import { http } from "@/utils/http"

// subtask related data should be stored here...
import { FILTER_TYPES } from "@/utils/common"

const state = {
    token: "",
    user: null,
    companyInitialized: false,
    // active project related
    activeCompany: null,
    activeCompanyProjects: [],
    activeProject: null,
    activeProjectTasks: [],
    activeProjectMoms: [],
    fetchingTasks: false,

    // other non active project related
    allAssignees: [],
    allDepartments: [],
    allUsers: [],
    allProjects: [],
    projectStatuses: [],
    projectPriorities: [],
    projectLabels: [],
    loading: false,
    FILTER_TYPES,
    filterValues: {},

    // filters
}

const getters = {}

class FilterOption {
    constructor(_id, title) {
        this.id = _id
        this.title = title
    }
}
const mutations = {
    setData: (state, data) => {
        for (let key in data) {
            state[key] = data[key]
        }
    },

    initFilters: (state) => {
        // projectStatuses: result.taskstatuses,
        // projectPriorities: result.projectPriorities,
        // projectLabels: result.projectLabels,
        // companies: result.companies,
        // allAssignees: result.assignees,
        let newFilterValues = {}
        console.log("filter values initsss")
        for (let key in FILTER_TYPES) {
            let filterType = FILTER_TYPES[key]
            let filterTypeValue = {
                type: filterType,
                options: [],
                selectedOptions: filterType.fieldType == "select" ? [] : "",
            }
            switch (filterType.type) {
                case FILTER_TYPES.PROJECT.type: {
                    filterTypeValue.options = state.allProjects.map(
                        (item) => new FilterOption(item.id, item.name)
                    )
                    if (state.activeProject) {
                        filterTypeValue.selectedOptions = [state.activeProject.id]
                    }
                    break
                }
                case FILTER_TYPES.STATUS.type: {
                    filterTypeValue.options = state.projectStatuses.map(
                        (item) => new FilterOption(item.id, item.name)
                    )
                    break
                }
                case FILTER_TYPES.PRIORITY.type: {
                    filterTypeValue.options = state.projectPriorities.map(
                        (item) => new FilterOption(item.id, item.name)
                    )
                    break
                }
                case FILTER_TYPES.DEPARTMENT.type: {
                    filterTypeValue.options = state.allDepartments.map(
                        (item) => new FilterOption(item.id, item.name)
                    )
                    break
                }
                case FILTER_TYPES.REPORTER.type: {
                    filterTypeValue.options = state.allUsers.map(
                        (item) => new FilterOption(item.id, `${item.firstName} ${item.lastName}`)
                    )
                    break
                }
                case FILTER_TYPES.ASSIGNEE.type: {
                    filterTypeValue.options = state.allUsers.map(
                        (item) => new FilterOption(item.id, `${item.firstName} ${item.lastName}`)
                    )
                    break
                }
                // case FILTER_TYPES.MOM.type: {
                //     filterTypeValue.options = state.activeProjectMoms.map(
                //         (item) => new FilterOption(item.id, `${item.name}`)
                //     )
                //     break
                // }
            }
            newFilterValues[filterType.type] = filterTypeValue
        }
        state["filterValues"] = newFilterValues
    },
}

const actions = {
    clearActiveCompany: async ({ commit }) => {
        commit("setData", {
            activeCompanyProjects: [],
            activeCompany: null,
        })
    },
    clearActiveProject: async ({ commit }) => {
        commit("setData", {
            activeProjectTasks: [],
            activeProject: null,
        })
    },
    async updateFilters({ state, commit, dispatch }, { type, value }) {
        const filterValueCopy = {
            ...state.filterValues,
            [type.type]: {
                ...state.filterValues[type.type],
                selectedOptions: value,
            },
        }
        commit("setData", {
            filterValues: filterValueCopy,
        })
        // refetch subtasks
        await dispatch("fetchTasks")
    },
    deleteTask: async ({ state, commit, dispatch }, taskId) => {
        try {
            const res = await http.delete(`/project-tasks/${taskId}`)
            commit("setData", {
                activeProjectTasks: state.activeProjectTasks.filter((item) => item.id != taskId),
            })
        } catch (err) {
            console.log("error in deleting project tasks", err)
        }
    },
    fetchTasks: async ({ state, commit, dispatch }) => {
        try {
            commit("setData", { fetchingTasks: true })
            const res = await http.post("/project-tasks/filter", {
                ...state.filterValues,
            })
            commit("setData", { activeProjectTasks: res.data.result })
        } catch (err) {
            console.log("error in loading active project subtasks")
        }
        commit("setData", { fetchingTasks: false })
    },
    initTasks: async ({ commit, dispatch }, taskInitData = {}) => {
        let projectSlug
        let companySlug

        if (taskInitData) {
            projectSlug = taskInitData.projectSlug
            companySlug = taskInitData.companySlug
        }
        commit("setData", {
            loading: true,
        })
        console.log("project slug is", projectSlug)

        commit("setData", {
            activeProjectTasks: [],
            activeProject: null,
        })

        let response = await http.get(
            `/project-tasks/init?projectSlug=${projectSlug || ""}&companySlug=${companySlug || ""}`
        )
        const result = response.data

        if (projectSlug || companySlug) {
            console.log("init subtask project is running...")

            //  (response.data.success === true)
            if (projectSlug) {
                commit("setData", {
                    activeProjectMoms: result.moms,
                    activeProjectTasks: result.tasks,
                    activeProject: result.activeProject,
                })

                let projectSelected = []
                if (!window.location.href.includes("search_mode")) {
                    projectSelected = [result.activeProject.id]
                }
                dispatch("updateFilters", {
                    type: FILTER_TYPES.PROJECT,
                    value: projectSelected,
                })
            }

            if (result && result.activeCompany) {
                commit("setData", {
                    activeCompany: result.activeCompany,
                })
            }

            if (result && result.projects) {
                commit("setData", {
                    activeCompanyProjects: result.projects,
                })
            }
        }

        if (!projectSlug && !companySlug) {
            commit("setData", {
                projectStatuses: result.projectStatuses,
                projectPriorities: result.projectPriorities,
                projectLabels: result.projectLabels,
                companies: result.companies,
                allAssignees: result.assignees,
                allDepartments: result.departments,
                allUsers: result.allUsers,
                allProjects: result.allProjects,
            })

            commit("setData", {
                companyInitialized: true,
            })
        }
        commit("setData", {
            loading: false,
        })

        // console.log("active project before calling ticket system", state.activeProject)
        commit("initFilters")

        return
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
