<template>
    <div>
        <PageCard title="Leave Requests" class="mt-3">
            <LeaveRequestList :activeTabIndex="0" title="Pending" />
        </PageCard>
    </div>
</template>

<script>
import PageCard from "../../../components/layout/PageCard.vue"
import LeaveRequestList from "./leave-request-list.vue"

export default {
    components: { LeaveRequestList, PageCard },
}
</script>

<style></style>
