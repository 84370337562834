<template>
    <div class="my-3 bg-white p-3 shadow-lg rounded-lg" style="min-height: 300px">
        <slot />
    </div>
</template>

<script>
export default {}
</script>

<style></style>
