var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('PageCard',{attrs:{"title":"Permission Editor"}},[(_vm.allowedUserRoles && _vm.allowedUserRoles.length)?_c('div',[_c('div',{staticClass:"my-3"},[_vm._v("Select Role")]),_c('SelectDropdown',{attrs:{"id":`form-subject`,"options":_vm.allowedUserRoles.map((item) => ({
              id: item.id,
              title: item.name,
            })),"value":_vm.selectedUserRoleId},on:{"change":function($event){_vm.selectedUserRoleId = $event}}})],1):_vm._e(),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('div',{staticClass:"mt-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectAllPermissions),expression:"selectAllPermissions"}],staticClass:"mr-1",attrs:{"type":"checkbox","aria-label":"selectAll"},domProps:{"checked":Array.isArray(_vm.selectAllPermissions)?_vm._i(_vm.selectAllPermissions,null)>-1:(_vm.selectAllPermissions)},on:{"change":[function($event){var $$a=_vm.selectAllPermissions,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectAllPermissions=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectAllPermissions=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectAllPermissions=$$c}},_vm.toggleSelectAll]}}),_c('label',{staticClass:"mr-3",attrs:{"for":"selectAll"}},[_vm._v("Select All")])]),_c('Button',{on:{"click":_vm.updatePermissions}},[_vm._v(_vm._s(_vm.saving ? "Please wait..." : "Update Permissions"))])],1)]),_c('div',{staticClass:"my-3"},[_c('div',{staticClass:"row gy-3"},_vm._l((_vm.permissionsGrouped),function(permissionsGroup){return _c('div',{key:permissionsGroup.id,staticClass:"col-md-6"},[_c('PageCard',{attrs:{"hr":"","title":permissionsGroup.title}},[_c('div',_vm._l((permissionsGroup.permissions),function(permission){return _c('label',{key:permission.id,class:[
                  'd-flex justify-content-between my-3',
                  {
                    'selected-checkbox-label': _vm.selectedPermissionIds.includes(
                      permission.id
                    ),
                  },
                ]},[_c('div',[_vm._v(" "+_vm._s(permission.name)+" ")]),_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectedPermissionIds.includes(permission.id)},on:{"click":function($event){return _vm.handlePermissionAdd(permission.id)}}})])}),0)])],1)}),0),_c('div',{staticClass:"text-end"},[_c('Button',{on:{"click":_vm.updatePermissions}},[_vm._v(_vm._s(_vm.saving ? "Please wait..." : "Update Permissions"))])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }