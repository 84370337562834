<template>
    <div>
        <PageCard title="Overtime Requests" class="mt-3">
            <OvertimeRequestList :activeTabIndex="0" title="Pending" />
        </PageCard>
    </div>
</template>

<script>
import PageCard from "../../../components/layout/PageCard.vue"
import OvertimeRequestList from "./overtime-request-list.vue"

export default {
    components: { PageCard, OvertimeRequestList },
}
</script>

<style></style>
