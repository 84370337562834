<template>
    <div>
        <div class="my-3">
            <SectionTitle>File Manager</SectionTitle>
        </div>
        <div class="main-file-manager">
            <div class="main-file-manager-inner">
                <div class="card p-3">
                    <SectionTitle>Filters</SectionTitle>
                    <div class="row">
                        <div class="col-md-4">
                            <SelectDropdown
                                :id="`form-employeeId`"
                                :options="
                                    employees.map((item) => ({
                                        id: item.id,
                                        title: item.fullName,
                                    }))
                                "
                                :multiple="false"
                                :field="employeeId"
                                :value="employeeId"
                                @change="employeeId = $event"
                                title="Select Employee"
                            />
                        </div>
                        <div class="col-md-4">
                            <CustomInput
                                placeholder="Enter file/folder name"
                                v-model="searchText"
                                label="Search files/folders..."
                                type="text"
                            />
                        </div>
                        <div class="col-md-4">
                            <div class="h-100 d-flex items-end" style="gap: 10px">
                                <Button noMargin style="background: crimson" @click="clearFilters"
                                    >Clear <i class="fas fa-times"></i
                                ></Button>
                                <Button noMargin @click="searchFilesFolders">Search <i class="fas fa-search"></i></Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>
                            <MultiBtnSelection
                                :value="activeFileSelectionType"
                                @change="handleFileSelectionTypeChange"
                                :types="fileSelectionTypes"
                            />
                        </div>
                        <div>
                            <MultiBtnSelection
                                :value="activeFileLayoutType"
                                @change="activeFileLayoutType = $event"
                                :types="fileLayoutTypes"
                            />
                        </div>
                    </div>
                </div>

                <div class="my-3"></div>

                <div>
                    <Button @click="openFolderCreate"> New Folder <i class="fas fa-add"></i> </Button>
                    <span class="mx-1"></span>
                    <Button @click="openFileCreate">
                        Upload File <span class="mx-1"></span>
                        <i class="fas fa-upload"></i>
                    </Button>
                </div>

                <CustomModal
                    v-if="showCreateModal"
                    :title="`Create ${file ? 'File' : 'Folder'}`"
                    :show="showCreateModal"
                    @close="showCreateModal = false"
                >
                    <div class="my-3">
                        <SelectDropdown
                            :id="`form-employeeId2`"
                            :options="employees.map((item) => ({ id: item.id, title: item.fullName }))"
                            :multiple="false"
                            :field="employeeIdForCreation"
                            :value="employeeIdForCreation"
                            @change="employeeIdForCreation = $event"
                            title="Select Employee"
                        />
                        <div v-if="fileCreateMode">
                            <CustomInput placeholder="Enter name" v-model="fileName" label="File Name:" type="text" />
                            <CustomInput placeholder="" v-model="selectedFile" label="Select file:" type="file" />
                        </div>
                        <div v-else>
                            <CustomInput placeholder="Folder name" v-model="folderName" label="Name:" type="text" />
                        </div>
                        <div>
                            <Button @click="handleFileFolderCreate">{{
                                processing ? "Please wait..." : fileCreateMode ? "Upload File" : "Create Folder"
                            }}</Button>
                        </div>
                    </div>
                </CustomModal>

                <Button @click="goBackFolder" v-if="openedFolders.length > 0"><i class="fas fa-chevron-left"></i> Back </Button>
                <div v-if="!searching" class="row">
                    <div
                        :class="[{ 'col-md-4': showFileInfo }, { 'col-md-3': !showFileInfo }, { 'col-md-12': listMode }]"
                        v-for="folder of filteredFolders"
                        :key="folder.id"
                    >
                        <FileFolderItem
                            :listMode="listMode"
                            @openInfo="handleShowFileInfo"
                            @openFolder="handleOpenFolder(folder)"
                            :folder="folder"
                        />
                    </div>
                    <div
                        :class="[{ 'col-md-4': showFileInfo }, { 'col-md-3': !showFileInfo }, { 'col-md-12': listMode }]"
                        v-for="file of filteredFiles"
                        :key="file.id"
                    >
                        <FileFolderItem :listMode="listMode" @openInfo="handleShowFileInfo" :file="file" />
                    </div>
                </div>
                <div v-else>
                    <div>Loading...</div>
                </div>
                <div v-if="!searching && !filteredFolders.length && !filteredFiles.length">No Results Found</div>
            </div>
            <div class="main-file-manager-details" v-if="showFileInfo && infoData">
                <FileFolderInfo :infoData="infoData" @close="showFileInfo = false" />
            </div>
        </div>
    </div>
</template>

<script>
import CustomInput from "../../components/crud/custom-input.vue"
import SelectDropdown from "../../components/crud/SelectDropdown.vue"
import FileFolderInfo from "../../components/file-manager/FileFolderInfo.vue"
import FileFolderItem from "../../components/file-manager/FileFolderItem.vue"
import MultiBtnSelection from "../../components/file-manager/MultiBtnSelection.vue"
import CustomModal from "../../components/layout/CustomModal.vue"
import Button from "../../components/UI/Button.vue"
import SectionTitle from "../../components/UI/SectionTitle.vue"
import { http } from "../../utils/http"

const FILE_SELECTION_TYPES = {
    ALL: "ALL",
    FILES: "FILES",
    FOLDERS: "FOLDERS",
}
const FILE_LAYOUT_TYPES = {
    GRID: "GRID",
    LIST: "LIST",
}

export default {
    components: {
        FileFolderInfo,
        CustomModal,
        SectionTitle,
        CustomInput,
        Button,
        FileFolderItem,
        SelectDropdown,
        MultiBtnSelection,
    },
    data() {
        return {
            searchText: "",
            infoData: null,
            employeeId: "",
            employeeIdForCreation: "",
            folders: [],
            files: [],
            showFileInfo: false,
            employees: [],
            searching: false,
            showCreateModal: false,
            selectedFile: null,
            folderName: "",
            fileName: "",
            fileCreateMode: false,
            openedFolders: [],
            processing: false,
            FILE_SELECTION_TYPES,
            FILE_LAYOUT_TYPES,
            fileLayoutTypes: [
                {
                    title: "Grid",
                    value: FILE_LAYOUT_TYPES.GRID,
                },
                {
                    title: "List",
                    value: FILE_LAYOUT_TYPES.LIST,
                },
            ],
            fileSelectionTypes: [
                {
                    title: "All",
                    value: FILE_SELECTION_TYPES.ALL,
                },
                {
                    title: "Files",
                    value: FILE_SELECTION_TYPES.FILES,
                },
                {
                    title: "Folders",
                    value: FILE_SELECTION_TYPES.FOLDERS,
                },
            ],
            activeFileSelectionType: FILE_SELECTION_TYPES.ALL,
            activeFileLayoutType: FILE_LAYOUT_TYPES.GRID,
        }
    },
    mounted() {
        this.getEmployeesForSelection()
        this.searchFilesFolders()
    },
    computed: {
        listMode() {
            return this.activeFileLayoutType == FILE_LAYOUT_TYPES.LIST
        },
        openedFolderId() {
            const res = this.openedFolders && this.openedFolders.length ? this.openedFolders[this.openedFolders.length - 1] : {}
            return res.id || ""
        },
        filteredFolders() {
            switch (this.activeFileSelectionType) {
                case FILE_SELECTION_TYPES.ALL:
                case FILE_SELECTION_TYPES.FOLDERS:
                    return this.folders
                case FILE_SELECTION_TYPES.FILES:
                    return []
            }
            return []
        },
        filteredFiles() {
            switch (this.activeFileSelectionType) {
                case FILE_SELECTION_TYPES.ALL:
                case FILE_SELECTION_TYPES.FILES:
                    return this.files
                case FILE_SELECTION_TYPES.FOLDERS:
                    return []
            }
            return []
        },
    },
    methods: {
        clearFilters() {
            this.searchText = ""
            this.searchFilesFolders()
        },
        handleFileSelectionTypeChange(val) {
            this.activeFileSelectionType = val
        },
        handleShowFileInfo(infoData) {
            console.log("handle show file info")
            this.showFileInfo = true
            this.infoData = infoData
        },
        goBackFolder() {
            this.openedFolders = this.openedFolders.slice(0, this.openedFolders.length - 1)
            this.searchFilesFolders()
        },
        handleOpenFolder(folderData) {
            console.log("handle open folder")
            this.openedFolders.push(folderData)
            this.searchFilesFolders()
            this.showFileInfo = false
        },
        handleFileFolderCreate() {
            if (this.fileCreateMode) {
                this.uploadFile()
            } else {
                this.createFolder()
            }
        },
        async getEmployeesForSelection() {
            try {
                const res = await http.get(`/employees?limited_fields=true`)
                this.employees = res.data.result
            } catch (err) {
                console.log("error in getting employees", err)
            }
        },
        async searchFilesFolders() {
            try {
                this.searching = true
                const res = await http.get(
                    `/file-management/search?search=${this.searchText}&employee_id=${this.employeeId}&parentFolderId=${
                        this.openedFolderId || ""
                    }`
                )
                this.folders = res.data.result.folders
                this.files = res.data.result.files
            } catch (err) {
                console.log("error in getting files folders", err)
            }
            this.searching = false
        },
        openFolderCreate() {
            this.showCreateModal = true
            this.fileCreateMode = false
            this.showFileInfo = false
        },
        openFileCreate() {
            this.showCreateModal = true
            this.fileCreateMode = true
            this.showFileInfo = false
        },
        async createFolder() {
            try {
                if (!this.folderName || !this.employeeIdForCreation) {
                    return this.$toast.error("Fillup all fields")
                }
                this.processing = true
                await http.post(`/file-management/folders`, {
                    name: this.folderName,
                    userId: this.employeeIdForCreation,
                    parentFolderId: this.openedFolderId,
                })
                this.$toast.success(`Folder created successfully`)
                this.showCreateModal = false
                this.folderName = ""
                window.location.reload()
            } catch (err) {
                console.log("error in updating file/folder", err)
                this.$toast.error("Could not create!")
            }
            this.processing = false
        },
        async uploadFile() {
            try {
                if (!this.fileName || !this.selectedFile || !this.employeeIdForCreation) {
                    return this.$toast.error("Please fillup all the fields")
                }
                this.processing = true
                const formData = new FormData()

                formData.append("name", this.fileName)
                formData.append("file", this.selectedFile)
                formData.append("userId", this.employeeIdForCreation)
                formData.append("folderId", this.openedFolderId)

                await http.post(`/file-management/files`, formData)
                this.$toast.success(`File uploaded successfully`)
                this.showCreateModal = false
                this.folderName = ""
                window.location.reload()
            } catch (err) {
                console.log("error in updating file/folder", err)
                this.$toast.error("Could not create!")
            }
            this.processing = false
        },
    },
}
</script>

<style lang="scss" scoped>
.main-file-manager {
    display: flex;
}
.main-file-manager-inner {
    flex: 5;
}
.main-file-manager-details {
    flex: 2;
    padding: 0 20px;
}
</style>
