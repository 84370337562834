<template>
    <div>
        <p>Select Company</p>
        <SelectDropdown
            :id="`form-subject`"
            v-if="companies.length"
            :options="companies.map((item) => ({ id: item.id, title: item.name }))"
            :value="selectedCompanyId"
            @change="selectedCompanyId = $event"
            style="max-width: 100%"
        />
        <canvas id="myPieChart" width="400" height="400"></canvas>
    </div>
</template>

<script>
import SelectDropdown from "../../components/crud/SelectDropdown.vue"
import { http } from "../../utils/http"
import { mapState } from "vuex"

export default {
    data() {
        return {
            pieChart: null,
            selectedCompanyId: null,
            companyInitialized: false,
        }
    },
    mounted() {},
    components: { SelectDropdown },
    computed: {
        ...mapState("misc", ["companies"]),
    },
    watch: {
        selectedCompanyId(e) {
            this.fetchCompanyStatAndUpdateChart()
        },
        companies(e) {
            if (e && e.length && !this.companyInitialized) {
                this.companyInitialized = true
                this.selectedCompanyId = this.companies.length ? this.companies[0].id : null
            }
        },
    },
    methods: {
        async fetchCompanyStatAndUpdateChart() {
            const res = await http.get(`/misc/company-stats?company_id=${this.selectedCompanyId}`)
            const companyStats = res.data.result
            if (this.pieChart) {
                this.pieChart.destroy()
            }
            this.createCharts({
                el: "myPieChart",
                title: "Today's Attendance",
                labels: ["Present", "Absent"],
                datasets: [companyStats.totalPresent, companyStats.totalAbsent],
                backgroundColor: ["#D77977", "#fff"],
                borderColor: "#612C2B",
            })
        },
        createCharts({ el, title, labels, datasets, backgroundColor, borderColor }) {
            console.log("create chart")
            const ctx = document.getElementById(el).getContext("2d")
            Chart.register(ChartDataLabels)

            this.pieChart = new Chart(ctx, {
                type: "pie",
                data: {
                    labels,
                    datasets: [
                        {
                            label: "Attendance Status",
                            data: datasets,
                            backgroundColor, // You can set the color of the bars here
                            borderColor, // You can set the border color of the bars here
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    plugins: {
                        title: {
                            display: true,
                            text: title,
                            position: "top",
                        },
                        legend: {
                            display: true,
                            position: "bottom",
                        },
                    },
                },
            })
        },
    },
}
</script>
