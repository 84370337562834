<template>
    <div>
        <div class="my-3">
            <div class="my-3">
                <div class="d-flex my-3" style="gap: 10px">
                    <div style="width: 250px">
                        <p class="my-2">Select Year</p>
                        <select v-model="year" class="form-select form-select-sm">
                            <option v-for="yearVal of years" :key="yearVal" :value="yearVal">
                                {{ yearVal }}
                            </option>
                        </select>
                    </div>
                    <div style="width: 250px">
                        <p class="my-2">Select Month</p>
                        <select v-model="month" class="form-select form-select-sm">
                            <option value="1">Jan</option>
                            <option value="2">Feb</option>
                            <option value="3">Mar</option>
                            <option value="4">Apr</option>
                            <option value="5">May</option>
                            <option value="6">Jun</option>
                            <option value="7">Jul</option>
                            <option value="8">Aug</option>
                            <option value="9">Sept</option>
                            <option value="10">Oct</option>
                            <option value="11">Nov</option>
                            <option value="12">Dec</option>
                        </select>
                    </div>
                    <CompanyDropdown :companyId="companyId" @change="companyId = $event" />
                </div>

                <Button @click="fetchAttendanceSummaryReport(false)" :noMargin="true"
                    ><i class="fas fa-search"></i> {{ loading ? "Searching..." : "Search" }}</Button
                >
                <Button class="mx-2" @click="fetchAttendanceSummaryReport(true)" :noMargin="true"
                    ><i class="fas fa-download"></i> Export Data</Button
                >
            </div>
            <CustomTable :scrollable="true" v-if="!loading && result && result.length">
                <template #head>
                    <tr>
                        <th>Sr. No.</th>
                        <th>Company</th>
                        <th>Name of Employee</th>
                        <th>Designation</th>
                        <th>Working Days</th>
                        <th>Total days Present</th>
                        <th>Total days Absent</th>
                        <th>Total leave Days</th>
                        <th>Total Holidays</th>
                        <th>Overtime Hours (if applicable)</th>
                        <th>Late Coming</th>
                        <th>Early Going</th>
                    </tr>
                </template>
                <template #body>
                    <tr v-for="item of result" :key="item.id">
                        <td>{{ item["Sr No"] }}</td>
                        <td>{{ item["Company"] }}</td>
                        <td>{{ item["NAME OF EMPLOYEE"] }}</td>
                        <td>{{ item["Designation"] }}</td>
                        <td>{{ item["Working Days"] }}</td>
                        <td>{{ item["Total days Present"] }}</td>
                        <td>{{ item["Total days Absent"] }}</td>
                        <td>{{ item["Total leave Days"] }}</td>
                        <td>{{ item["Total Holidays"] }}</td>
                        <td>{{ item["Overtime Hours (if applicable)"] }}</td>
                        <td>{{ item["Late Coming"] }}</td>
                        <td>{{ item["Early Going"] }}</td>
                    </tr>
                </template>
            </CustomTable>
            <div v-else>
                <div v-if="loading">Loading...</div>
                <p v-else>No results found!</p>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyDropdown from "../../../components/dropdowns/CompanyDropdown.vue"
import CustomTable from "../../../components/other/CustomTable.vue"
import Button from "../../../components/UI/Button.vue"
import { baseURL, http } from "../../../utils/http"

export default {
    components: {
        CustomTable,
        Button,
        CompanyDropdown,
    },
    data() {
        return {
            years: [],
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            result: [],
            loading: false,
            companyId: "",
        }
    },
    mounted() {
        const currentYear = new Date().getFullYear()
        this.years = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3, currentYear - 4]
        // this.fetchAttendanceSummaryReport(false)
    },
    methods: {
        async fetchAttendanceSummaryReport(exportMode = false) {
            if (!this.year || !this.month) {
                return this.$toast.error("Please enter year/month!")
            }
            this.loading = true
            try {
                const url = `/reports/attendance-summary?company_id=${this.companyId}&date=${this.year}-${this.month}-01&export_mode=${
                    exportMode ? "true" : "false"
                }`
                if (exportMode) {
                    window.open(baseURL + url, "_blank")
                } else {
                    const res = await http.get(url)
                    this.result = res.data.result
                }
            } catch (err) {
                console.log("error in fetching asset allocations", err)
                this.$toast.error("Could not load results")
                this.result = []
            }
            this.loading = false
        },
    },
}
</script>

<style></style>
