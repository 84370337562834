<template>
    <PageCard sm>
        <div class="mb-2 d-flex items-center justify-content-between" style="gap: 10px">
            <SectionTitle class="">Attendance Comparison</SectionTitle>
            <div class="d-flex items-center" style="gap: 10px">
                <label class="d-flex" style="gap: 5px"
                    ><input v-model="comparisonType" name="comparison_type" value="daily" type="radio" />Daily</label
                >
                <label class="d-flex" style="gap: 5px"
                    ><input v-model="comparisonType" name="comparison_type" value="monthly" type="radio" />Monthly</label
                >
            </div>
        </div>
        <Spinner v-if="!showChart" />
        <apexchart v-if="showChart" type="line" height="300" :options="chartOptions" :series="series"></apexchart>
    </PageCard>
</template>

<script>
import { mapActions, mapState } from "vuex"
import PageCard from "../layout/PageCard.vue"
import SectionTitle from "../UI/SectionTitle.vue"
import Spinner from "../UI/Spinner.vue"

export default {
    components: { PageCard, SectionTitle, Spinner },
    data() {
        return {
            comparisonType: "daily",
            showChart: false,
            series: [
                {
                    name: "Monthly Attendance",
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    type: "line",
                    height: 300,
                    zoom: {
                        enabled: false,
                    },
                },
                stroke: {
                    width: 1, // Adjust the width value to make the line thinner
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                    width: 2, // Adjust the width value to make the line thinner
                },

                // title: {
                //     text: "Attendance Comparison Chart",
                //     align: "left",
                // },
                // subtitle: {
                //     text: "Price Movements",
                //     align: "left",
                // },
                xaxis: {
                    // type: "datetime",
                    categories: [],
                    labels: {
                        style: {
                            fontSize: "10px", // Adjust the font size as needed
                        },
                    },
                },
                yaxis: {
                    min: 0,
                    max: 100,
                    tickAmount: 5,
                    labels: {
                        formatter: function (value) {
                            return value + "%"
                        },
                    },
                },
                legend: {
                    horizontalAlign: "left",
                },
            },
        }
    },
    computed: {
        ...mapState("misc", ["attendanceComparisonData"]),
    },
    mounted() {
        this.loadChart()
    },
    methods: {
        ...mapActions("misc", ["fetchDashboardStatsAttendance"]),
        loadChart() {
            const data = this.attendanceComparisonData

            if (data) {
                this.series[0].data = data.map((item) => item.percentage)
                this.chartOptions.xaxis.categories = data.map((item) => item.title)
                this.showChart = true
            }
        },
    },
    watch: {
        async comparisonType(e) {
            this.showChart = false
            await this.fetchDashboardStatsAttendance({
                attendanceComparisonMonthType: this.comparisonType,
            })
            this.loadChart()
        },
        attendanceComparisonData(e) {
            if (e) {
                this.loadChart()
            }
        },
    },
}
</script>

<style></style>
