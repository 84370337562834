<template>
  <div class="text-black font-bold">
    Edit User Role

    <Card>
      <form class="my-3" @submit.prevent="updateUserRole">
        <div class="mb-2">Name</div>
        <div>
          <input v-model="role.name" type="text" class="form-control" />
        </div>

        <Button
          class="btn btn-success bg-green-500 my-3 mt-4 border-none"
          style="min-width: 200px"
          type="submit"
        >
          {{ updatingUserRole ? "Please wait..." : "Update" }}
        </Button>
      </form>
    </Card>
  </div>
</template>

<script>
  import { http, serverURL } from "../../utils/http";
  import Card from "../../components/UI/Card.vue";

  export default {
    components: { Card },
    data() {
      return {
        role: [],
        serverURL,
        name: "",

        updatingUserRole: false,
      };
    },

    async mounted() {
      try {
        const res = await http.get("/user-roles/" + this.$route.params.id);

        this.role = res.data.result;
      } catch (err) {
        console.log("error in loading User role", err);
      }
    },
    methods: {
      async updateUserRole() {
        this.updatingUserRole = true;
        if (this.role.name == "") {
          this.$toast.error("Name is required");
        } else {
          try {
            await http.patch("/user-roles/" + this.$route.params.id, {
              name: this.role.name,
            });

            this.$toast.success("Role Updated Successfully");
            window.location.href = "/user-roles";
          } catch (err) {
            console.log("error in Updating role", err);
            this.$toast.error("Some error occured");
          }
        }
        this.updatingUserRole = false;
      },
    },
  };
</script>

<style></style>
