<template>
    <div class="form-group">
        <div class="my-2" v-if="label">
            <label :for="inputId"
                >{{ label }}<span v-if="required" style="color: red">*</span></label
            >
        </div>
        <input
            v-if="type == 'file'"
            :min="min"
            :id="inputId"
            :required="required"
            :placeholder="placeholder"
            :type="type"
            :disabled="disabled"
            accept="image/*,application/pdf"
            class="form-control form-control-sm"
            @input="$emit('input', $event.target.files[0])"
        />
        <textarea
            v-else-if="type == 'textarea'"
            :min="min"
            :id="inputId"
            :disabled="disabled"
            :placeholder="placeholder"
            :value="value"
            :required="required"
            class="form-control form-control-sm"
            @input="$emit('input', $event.target.value)"
        />
        <input
            v-else
            :min="min"
            :id="inputId"
            step="any"
            :required="required"
            :disabled="disabled"
            :placeholder="placeholder"
            :type="type"
            :value="value"
            class="form-control form-control-sm"
            @input="$emit('input', $event.target.value)"
        />
    </div>
</template>

<script>
export default {
    props: {
        min: { type: String, default: "" },
        label: String,
        value: [String, Number],
        inputId: String,
        type: { type: String, default: "text" },
        disabled: { type: Boolean, default: false },
        placeholder: { type: String, default: "" },
        required: { type: Boolean, default: false },
    },
    model: {
        prop: "value",
        event: "input",
    },
}
</script>
