<template>
    <PageCard title="Employees">
        <div class="d-flex justify-content-between align-items-start">
            <AllowPermission :permission="PERMISSION_CODES.CREATE_EMPLOYEES">
                <Button noMargin @click="navigateToCreateEmployee">
                    <i class="fas fa-plus"></i>
                    Create Employee</Button
                >
            </AllowPermission>
            <AllowPermission :permission="PERMISSION_CODES.EDIT_EMPLOYEES">
                <div class="text-end mb-3">
                    <Button noMargin @click="updateEmployeeCTC">
                        <i class="fas fa-refresh"></i>
                        {{ updatingEmployeeCTC ? "Please wait..." : "Sync Current Employee CTCs" }}
                    </Button>
                    <div class="text-secondary">Only for Employees connected to company will sync!</div>
                </div>
            </AllowPermission>
        </div>

        <div class="d-flex" style="gap: 15px; margin-bottom: 15px">
            <div style="">
                <p class="my-2">Select Company</p>
                <SelectDropdown
                    :id="`form-subject`"
                    style="max-width: 190px"
                    v-if="companies.length"
                    :options="companies.map((item) => ({ id: item.id, title: item.name }))"
                    :value="selectedCompanyId"
                    @change="selectedCompanyId = $event"
                />
            </div>
            <div style="">
                <p class="my-2">Select Role</p>
                <SelectDropdown
                    :id="`form-user-role`"
                    style="max-width: 190px"
                    v-if="userRoles.length"
                    :options="userRoles.filter((item) => !item.name.includes('admin')).map((item) => ({ id: item.id, title: item.name }))"
                    :value="selectedUserRoleId"
                    @change="selectedUserRoleId = $event"
                />
            </div>
        </div>

        <div>
            <SectionTitle>Employees</SectionTitle>
        </div>
        <div class="my-3">
            <CustomInput v-model="searchQuery" placeholder="Search employee" />
        </div>

        <AllowPermission :permission="PERMISSION_CODES.VIEW_EMPLOYEES">
            <div>
                <div v-if="loading" class="text-center my-3">
                    <Spinner />
                </div>
                <div v-else-if="employees.length > 0">
                    <CustomTable>
                        <template #head>
                            <tr>
                                <th>Full Name</th>
                                <th>CTC</th>
                                <th>Email</th>
                                <th>DOC Updated</th>
                                <th>Actions</th>
                            </tr>
                        </template>
                        <template #body>
                            <tr v-for="employee of employees" :key="employee.id">
                                <td>{{ employee.fullName }}</td>
                                <td>{{ employee.ctc }}</td>
                                <td>{{ employee.email }}</td>
                                <td>{{ employee.docCompleted }}</td>
                                <td>
                                    <div class="d-flex" style="gap: 15px">
                                        <AllowPermission :permission="PERMISSION_CODES.EDIT_EMPLOYEES">
                                            <a
                                                class="btn-edit"
                                                :href="
                                                    navigatePage(
                                                        {
                                                            name: `employee-edit`,
                                                            params: { id: employee.id },
                                                        },
                                                        true
                                                    )
                                                "
                                            >
                                                Edit
                                            </a>
                                        </AllowPermission>
                                        <AllowPermission :permission="PERMISSION_CODES.DELETE_EMPLOYEES">
                                            <div class="btn-delete" href="javascript:void(0)" @click="deleteDataItem(employee.id)">
                                                Delete
                                            </div>
                                        </AllowPermission>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </CustomTable>
                    <Paginator @change="handlePageChange" :page="page" :totalPages="totalPages" />
                </div>
                <div class="my-3" v-else>No Employees Found</div>
            </div>
        </AllowPermission>

        <!-- <CrudIndex
            v-if="!loading && showResults"
            title="Employee"
            :viewColumns="columns"
            :permission="PERMISSION_CODES.VIEW_EMPLOYEES"
            :fetchUrl="`/employees?company_id=${selectedCompanyId || ''}&role_id=${selectedUserRoleId || ''}`"
            updateRootUrl="/employees"
            editRouteName="employee-edit"
            deleteRootUrl="/users"
            :createFields="createFields"
        /> -->
    </PageCard>
</template>

<script>
import { http } from "@/utils/http"
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"
import Button from "../../components/UI/Button.vue"
import { errMsg } from "../../utils/common"
import SelectDropdown from "../../components/crud/SelectDropdown.vue"
import { mapState } from "vuex"
import { PERMISSION_CODES } from "../../store/permission"
import AllowPermission from "../../components/layout/AllowPermission.vue"
import PageCard from "../../components/layout/PageCard.vue"
import SectionTitle from "../../components/UI/SectionTitle.vue"
import CustomInput from "../../components/crud/custom-input.vue"
import CustomTable from "../../components/other/CustomTable.vue"
import Spinner from "../../components/UI/Spinner.vue"
import Paginator from "../../components/other/Paginator.vue"

export default {
    data() {
        return {
            PERMISSION_CODES,
            showResults: true,
            loading: false,
            videos: [],
            limit: 10,
            page: 1,
            totalPages: 0,
            createFields: [],
            selectedCompanyId: null,
            selectedUserRoleId: null,
            updatingEmployeeCTC: false,
            employees: [],
            searchQuery: "",
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Full Name", "fullName", "string"),
                createColumn("CTC", "ctc", "number"),
                // createColumn("Phone", "phone", "string"),
                createColumn("Email", "email", "string"),
                createColumn("Doc Updated", "docCompleted", "string"),
            ],
        }
    },

    computed: { ...mapState("misc", ["companies", "userRoles"]) },
    props: { videoId: { type: String, default: "" } },
    components: {
        Paginator,
        Spinner,
        CustomTable,
        CustomInput,
        SectionTitle,
        PageCard,
        AllowPermission,
        CrudIndex,
        Button,
        SelectDropdown,
    },
    async mounted() {
        this.fetchEmployees()
    },
    methods: {
        async handlePageChange(newPage) {
            if (this.page != newPage) {
                this.page = newPage
                this.fetchEmployees()
            }
        },
        navigatePage(routeObj, onlyUrl = false) {
            const url = this.$router.resolve(routeObj).href
            if (onlyUrl) return url
            window.location.href = url
        },
        async deleteDataItem(id) {
            try {
                if (confirm("Do you really want to delete?")) {
                    await http.delete(`delete/${id}`)
                    this.$toast.success(this.title + " Deleted Successfully!")
                    this.employees = this.employees.filter((item) => item.id !== id)
                    window.location.reload()
                }
            } catch (err) {
                console.log("error in deleting " + this.title + " item", err)
                this.$toast.error("Some error occured")
            }
        },
        async fetchEmployees() {
            try {
                this.loading = true
                const res = await http.get(
                    `/employees?company_id=${this.selectedCompanyId || ""}&role_id=${this.selectedUserRoleId || ""}&search=${
                        this.searchQuery
                    }&page=${this.page}&limit=${this.limit}`
                )
                this.employees = res.data.result
                this.totalPages = res.data.totalPages
            } catch (err) {
                console.log("error in fetching employees", err)
            }
            this.loading = false
        },
        navigateToCreateEmployee() {
            this.$router.push("/employees/create")
        },
        async updateEmployeeCTC() {
            this.updatingEmployeeCTC = true
            try {
                const res = await http.patch("/payroll-payments/employee-cts")
                this.$toast.success("Updated employee cts.")
                window.location.reload()
            } catch (err) {
                console.log("err", err)
                this.$toast.error(errMsg(err, "Error occured"))
            }
            this.updatingEmployeeCTC = false
        },
    },
    watch: {
        searchQuery(e) {
            clearTimeout(this.searchTimeout)
            this.searchTimeout = setTimeout(() => {
                this.page = 1
                this.fetchEmployees()
            }, 800)
        },
        selectedCompanyId(e) {
            this.showResults = false
            setTimeout(() => {
                this.showResults = true
            }, 100)
        },
        selectedUserRoleId(e) {
            this.showResults = false
            setTimeout(() => {
                this.showResults = true
            }, 100)
        },
    },
}
</script>

<style></style>
