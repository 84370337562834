<template>
    <div>
        <div v-if="editEmployeeData">
            <strong>Current CTC: {{ editEmployeeData.ctc || 0 }}</strong>
        </div>
        <SectionTitle v-if="incrementMode"> Increment CTC by {{ incrementPercent }}% </SectionTitle>
        <div>
            New CTC (approx): <strong>{{ newCtc || 0 }}</strong>
        </div>

        <div v-if="companyId"><strong>Default Values for Employees</strong></div>
        <div v-else>
            <Button v-if="incrementMode" class="me-2" @click="reloadLatest">Cancel</Button>
            <Button @click="copyCompanyDefaults">Use Company Defaults </Button>
            <Button class="ms-2" @click="updatePayheadDefaults">{{
                processing ? "Please wait..." : "Update Payhead Values"
            }}</Button>
        </div>
        <div>
            <div class="my-3" v-if="loading">Loading...</div>
            <div v-else-if="payheadMasters && payheadValues && companyDefaultsInitialized">
                <div v-for="payhead of payheads" :key="payhead.id">
                    <PayheadValueEditInput
                        :payheads="payheads"
                        :payhead="payhead"
                        :companyPayheadValue="
                            companyPayheadValues && companyPayheadValues[payhead.id]
                        "
                        :payheadValue="payheadValues[payhead.id]"
                        :payheadMasters="payheadMasters"
                        @change="handlePayheadValueEdit"
                    />
                </div>
            </div>
            <Button @click="updatePayheadDefaults"
                ><i class="fas fa-money-bill"></i>
                {{
                    processing
                        ? "Please wait..."
                        : incrementMode
                        ? "Confirm CTC Increment"
                        : "Update Payhead Values"
                }}
            </Button>
        </div>
    </div>
</template>

<script>
import { round2 } from "../../utils/common"
import { http } from "../../utils/http"
import Button from "../UI/Button.vue"
import SectionTitle from "../UI/SectionTitle.vue"
import PayheadValueEditInput from "./PayheadValueEditInput.vue"

export default {
    props: {
        // either company or employee id required
        employeeId: { type: String, default: "" },
        companyId: { type: String, default: "" },
        editEmployeeData: { type: Object, default: null },
    },
    data() {
        return {
            newCtc: 0,
            incrementMode: false,
            incrementPercent: 0,
            payheads: [],
            loading: false,
            payheadMasters: null,
            processing: false,
            editValues: {},
            companyDefaultsInitialized: false,
            payheadValues: null,
            // if employee then its used to copy from company values
            companyPayheadValues: null,
            companyPayheadValuesOnlyEnabled: null,
        }
    },
    components: { SectionTitle, Button, PayheadValueEditInput },
    async mounted() {
        this.loading = true
        try {
            await this.fetchPayheads()
            await this.fetchPayheadMasters()
            await this.fetchPayheadValues()
            const incrPerc = this.$route.query.increment_percent
            this.incrementMode = !!incrPerc
            this.incrementPercent = Number(incrPerc || 0)

            if (this.companyPayheadValues) {
                this.copyCompanyDefaults(false)
                this.updateNewCtc()
            }

            // logic for incrementing salary by given percent based on old ctc

            let newBasicSalary

            let empPayheadValues
            let currentBasicSalary

            setTimeout(() => {
                empPayheadValues = this.getEmpPayheadValues()
                console.log("empPayheadValues for increment", empPayheadValues)
                // increment salary but don't save it until confirmed

                console.log("get emp payhead values", this.payheadValues)
                if (this.companyPayheadValues) {
                    if (this.incrementPercent) {
                        currentBasicSalary = empPayheadValues.find(
                            (item) => item.payhead.isBasicSalary
                        )
                        if (currentBasicSalary) {
                            const currentCtc = this.editEmployeeData.ctc || 0
                            const additionalBasicSalaryExpected =
                                (currentCtc * this.incrementPercent * 0.01) / 12
                            console.log(
                                "additionalBasicSalaryExpected for increment",
                                additionalBasicSalaryExpected,
                                "currentCtc",
                                currentCtc
                            )
                            newBasicSalary = round2(
                                currentBasicSalary.value + additionalBasicSalaryExpected
                            )
                            console.log("currentBasicSalary for increment", currentBasicSalary)
                        }
                    }
                }

                setTimeout(() => {
                    // for input updates after initializing
                    if (currentBasicSalary && newBasicSalary) {
                        console.log(
                            "the current basic salary and new salary is",
                            currentBasicSalary,
                            newBasicSalary
                        )
                        this.payheadValues[currentBasicSalary.payhead.id].value = newBasicSalary

                        this.companyDefaultsInitialized = true
                        this.updateNewCtc()
                    } else {
                        this.companyDefaultsInitialized = true
                    }
                }, 100)
            }, 100)
        } catch (err) {
            console.log("error in fetching payheads", err)
        }
        this.loading = false
    },
    methods: {
        reloadLatest() {
            window.location.href = this.$route.path + "?active_tab=PAYROLL"
        },
        handlePayheadValueEdit($event) {
            this.editValues[$event.payhead.id] = $event.data
            // console.log("handle payhead value edit", $event.data)
            this.payheadValues[$event.payhead.id].value = $event.data.value
            this.payheadValues[$event.payhead.id].percentage = $event.data.percentage
            this.updateNewCtc()
        },
        async fetchPayheadMasters() {
            const res = await http.get("/payheads/masters")
            this.payheadMasters = res.data.result
        },
        async fetchPayheads() {
            const res = await http.get("/payheads")
            this.payheads = res.data.result
        },
        async fetchPayheadValues() {
            const res = await http.get(
                `/payhead-values?employeeId=${this.employeeId}&companyId=${this.companyId}`
            )
            const payheadValueRes = res.data.result
            this.payheadValues = payheadValueRes
            this.companyPayheadValues = res.data.companyPayheadValues || null

            if (this.companyPayheadValues) {
                let temp = { ...this.companyPayheadValues }
                let final = {}
                // remove non enabled values
                for (let key in temp) {
                    if (temp[key].enabled) {
                        final[key] = temp[key]
                    }
                }
                // console.log("this.companyPayheadValuesOnlyEnabled", final)
                this.companyPayheadValuesOnlyEnabled = final
            }
        },
        async updatePayheadDefaults() {
            if (this.processing) return
            try {
                const storeCtcSnapshot = !!this.incrementPercent
                const oldCtc = this.editEmployeeData && this.editEmployeeData.ctc
                if (storeCtcSnapshot && oldCtc > this.newCtc) {
                    return this.$toast.error("New CTC must be more than old CTC!")
                }
                const updateData = Object.values(this.editValues).map((item) => ({
                    ...item,
                    employeeId: this.employeeId || null,
                    companyId: this.companyId || null,
                }))

                for (let updateItem of updateData) {
                    if (updateItem.value && updateItem.value < 0) {
                        return this.$toast.error("Value cannot be negative")
                    }
                    if (
                        updateItem.percentage &&
                        (updateItem.percentage < 0 || updateItem.percentage > 100)
                    ) {
                        return this.$toast.error("Percentage must be between 0 to 100")
                    }
                    // if (updateItem.enabled && !updateItem.calculationTypeId) {
                    //     return this.$toast.error(`Calculation type is required for enabled fields`)
                    // }
                }
                this.processing = true

                const res = await http.post("/payhead-values/create-or-update", {
                    employeeId: this.employeeId,
                    updateData,
                    storeCtcSnapshot,
                    oldCtc,
                    newCtc: this.newCtc,
                })
                this.$toast.success("Payhead values updated")
                window.location.href = this.$route.path + "?active_tab=PAYROLL"
            } catch (err) {
                console.log("error in updating payhead defaults", err)
            }
            this.processing = false
        },
        copyCompanyDefaults(overwrite = true) {
            const copiedPayheadValues = {}
            const loopArray = overwrite ? this.companyPayheadValues : this.payheadValues
            if (overwrite) {
                this.$toast.info("Values Copied! Please save to overwrite!")
            }
            for (let key in loopArray) {
                if (overwrite) {
                    copiedPayheadValues[key] = {
                        ...this.companyPayheadValues[key],
                        companyId: null,
                        employeeId: this.employeeId,
                    }
                } else {
                    // remove falsy values and add others
                    for (let key2 in this.payheadValues[key]) {
                        if (
                            !this.payheadValues[key][key2] &&
                            this.payheadValues[key][key2] !== false &&
                            this.payheadValues[key][key2] !== 0
                        ) {
                            delete this.payheadValues[key][key2]
                        }
                    }
                    copiedPayheadValues[key] = {
                        ...this.companyPayheadValues[key],
                        ...this.payheadValues[key],
                        enabled: this.companyPayheadValues[key]
                            ? this.companyPayheadValues[key].enabled &&
                              this.payheadValues[key].enabled
                            : this.payheadValues[key].enabled,
                        companyId: null,
                        employeeId: this.employeeId,
                    }
                }
            }

            this.payheadValues = null
            setTimeout(() => {
                this.payheadValues = copiedPayheadValues
            })
        },
        updateNewCtc() {
            this.newCtc = this.getNewCtcValue()
        },
        getEmpPayheadValues() {
            if (!this.payheadValues) return []
            const empPayheadValues = Object.values(this.payheadValues).map((empPayheadVal) => {
                return {
                    ...empPayheadVal,
                    payhead: this.payheads.find((item) => empPayheadVal.payheadId == item.id),
                }
            })
            return empPayheadValues
        },
        getNewCtcValue() {
            // update ctc calculation....
            if (!this.payheadValues || !this.payheadMasters) return 0
            const empPayheadValues = this.getEmpPayheadValues()
            const basicSalary = empPayheadValues.find((item) => item.payhead.isBasicSalary)
            // dont' consider overtime

            console.log("empPayheadValues", empPayheadValues)

            const earningList = empPayheadValues.filter(
                (item) =>
                    item.enabled &&
                    item.payhead.payroll_payhead_type &&
                    item.payhead.payroll_payhead_type &&
                    !item.payhead.payroll_payhead_type.isDecrement &&
                    !item.payhead.isBasicSalary &&
                    !item.payhead.isOvertimePayhead
                // &&
                // item.payhead.name.toLowerCase() !== "reimbursements"
            )

            const deductionList = empPayheadValues.filter(
                (item) =>
                    item.enabled &&
                    item.payhead.payroll_payhead_type.isDecrement &&
                    !item.payhead.isBasicSalary &&
                    !item.payhead.isLeavePayhead
            )

            const earningsForCtc = earningList.reduce((val, item) => {
                if (item.percentage && item.calculateFromPayheadId) {
                    const pheadVal =
                        empPayheadValues.find(
                            (phv) => phv.payhead.id == item.calculateFromPayheadId
                        )?.value || 0
                    const amt = pheadVal * item.percentage * 0.01
                    return val + amt
                }
                return val + item.value
            }, 0)

            // don't consider leave
            const deductionsForCtc = deductionList.reduce((val, item) => val + item.value, 0)

            console.log("earning list", earningList)
            console.log("deductions list", deductionList)
            console.log("earnings", earningsForCtc)
            console.log("deductionsForCtc", deductionsForCtc)
            return round2((basicSalary.value + earningsForCtc - deductionsForCtc) * 12)
        },
    },
}
</script>

<style lang="scss" scoped></style>
