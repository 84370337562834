<template>
    <div>
        <div class="my-3">
            <div class="my-3">
                <div class="d-flex my-3" style="gap: 10px">
                    <div style="width: 200px">
                        <p class="my-2">Select Bank</p>
                        <select v-model="bankMasterId" class="form-select form-select-sm">
                            <option value="">Select bank</option>
                            <option v-for="bankMaster of bankMasters" :key="bankMaster.id" :value="bankMaster.id">
                                {{ bankMaster.name }}
                            </option>
                        </select>
                    </div>
                    <div style="width: 200px">
                        <p class="my-2">Select Year</p>
                        <select v-model="year" class="form-select form-select-sm">
                            <option v-for="yearVal of years" :key="yearVal" :value="yearVal">
                                {{ yearVal }}
                            </option>
                        </select>
                    </div>
                    <div style="width: 200px">
                        <p class="my-2">Select Month</p>
                        <select v-model="month" class="form-select form-select-sm">
                            <option value="1">Jan</option>
                            <option value="2">Feb</option>
                            <option value="3">Mar</option>
                            <option value="4">Apr</option>
                            <option value="5">May</option>
                            <option value="6">Jun</option>
                            <option value="7">Jul</option>
                            <option value="8">Aug</option>
                            <option value="9">Sept</option>
                            <option value="10">Oct</option>
                            <option value="11">Nov</option>
                            <option value="12">Dec</option>
                        </select>
                    </div>
                    <CompanyDropdown :companyId="companyId" @change="companyId = $event" />
                </div>

                <Button @click="fetchStllpTxtReport(false)" :noMargin="true"
                    ><i class="fas fa-search"></i> {{ loading ? "Searching..." : "Search" }}</Button
                >
                <Button class="mx-2" @click="fetchStllpTxtReport(true)" :noMargin="true"
                    ><i class="fas fa-download"></i> Export Excel</Button
                >
                <Button class="mx-2" @click="fetchStllpTxtReport(true, true)" :noMargin="true"
                    ><i class="fas fa-download"></i> Export TXT</Button
                >
            </div>
            <CustomTable :scrollable="true" v-if="!loading && result && result.length">
                <template #head>
                    <tr>
                        <th v-for="columnName of columnNames" :key="columnName">
                            {{ columnName }}
                        </th>
                    </tr>
                </template>
                <template #body>
                    <tr v-for="item of result" :key="item.id">
                        <td v-for="columnName of columnNames" :key="columnName">
                            {{ item[columnName] }}
                        </td>
                    </tr>
                </template>
            </CustomTable>
            <div v-else>
                <div v-if="loading">Loading...</div>
                <p v-else>No results found!</p>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyDropdown from "../../../components/dropdowns/CompanyDropdown.vue"
import CustomTable from "../../../components/other/CustomTable.vue"
import Button from "../../../components/UI/Button.vue"
import { baseURL, http } from "../../../utils/http"

export default {
    components: {
        CustomTable,
        Button,
        CompanyDropdown,
    },
    data() {
        return {
            years: [],
            maxDate: new Date().toISOString().slice(0, 10),
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            result: [],
            bankMasters: [],
            columnNames: [],
            bankMasterId: "",
            loading: false,
            date: new Date().toISOString().slice(0, 10),
            companyId: "",
        }
    },
    async mounted() {
        const currentYear = new Date().getFullYear()
        this.years = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3, currentYear - 4]
        await this.fetchBankMasters()
    },
    methods: {
        async fetchBankMasters() {
            try {
                const res = await http.get("/bank-masters")
                this.bankMasters = res.data.result
            } catch (err) {
                console.log("error in fetching bank masters", err)
                this.$toast.error("Could not load results")
            }
        },
        async fetchStllpTxtReport(exportMode = false, txtMode = false) {
            if (!this.year || !this.month) {
                return this.$toast.error("Please enter year/month!")
            }
            if (!this.bankMasterId) {
                return this.$toast.error("Please select bank!")
            }
            this.loading = true
            try {
                const url = `/reports/stllp-txt?company_id=${this.companyId}&bank_master_id=${this.bankMasterId}&date=${this.year}-${
                    this.month
                }-01&export_mode=${exportMode ? "true" : "false"}&txt_mode=${txtMode ? "true" : "false"}`
                if (exportMode) {
                    window.open(baseURL + url, "_blank")
                } else {
                    const res = await http.get(url)
                    this.result = res.data.result
                    this.columnNames = res.data.columnNames
                }
            } catch (err) {
                console.log("error in fetching stllp report", err)
                this.$toast.error("Could not load results")
                this.result = []
            }
            this.loading = false
        },
    },
}
</script>

<style></style>
