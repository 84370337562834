<template>
    <div>
        <div class="my-3">
            <SectionTitle>Assets</SectionTitle>
            <div class="my-3" style="width: 300px">
                <SelectDropdown
                    title=""
                    :id="`form-asset-allocation-dropdown`"
                    :options="filterOptions"
                    :value="allocatedType"
                    @change="handleAllocatedTypeChange"
                />
            </div>
        </div>
        <CrudIndex
            v-if="!loading"
            title="Asset Master"
            :viewColumns="columns"
            :permission="PERMISSION_CODES.VIEW_ASSETS"
            :fetchUrl="`/assets?type=${allocatedType}`"
            createUrl="/assets"
            updateRootUrl="/assets"
            deleteRootUrl="/assets"
            :reloadOnSuccess="true"
            :createFields="createFields"
            v-slot="slotProps"
        >
            <Button style="background: #55f" @click="viewLogs(slotProps.itemId)" class="crud-btn">
                Logs <i class="fas fa-external-link" />
            </Button>
        </CrudIndex>
    </div>
</template>

<script>
import { mapState } from "vuex"
import CrudIndex from "../../components/crud/index.vue"
import SelectDropdown from "../../components/crud/SelectDropdown.vue"
import SectionTitle from "../../components/UI/SectionTitle.vue"
import { PERMISSION_CODES } from "../../store/permission"
import { createField, createColumn, FILTER_TYPES } from "../../utils/common"
import { http } from "../../utils/http"
import { ASSET_FILTER_TYPES } from "../../utils/constants"

export default {
    data() {
        return {
            createFields: [createField("Name*", "name", "text", true), createField("Description", "description", "text", false)],
            columns: [
                createColumn("Name", "name", "string"),
                createColumn("Serial No", "serialNo", "string"),
                // createColumn("Multi Allocation", "allowMultipleUserAllocation", "string"),
                createColumn("Is Active", "isActive", "string"),
                createColumn("Price", "price", "string"),
            ],
            loading: true,
            initialized: false,
            PERMISSION_CODES,
            filterOptions: [
                {
                    id: ASSET_FILTER_TYPES.ALL,
                    title: "All Assets",
                },
                {
                    id: ASSET_FILTER_TYPES.ALLOCATED,
                    title: "Allocated",
                },
                {
                    id: ASSET_FILTER_TYPES.NOT_ALLOCATED,
                    title: "Not Allocated",
                },
            ],
            allocatedType: ASSET_FILTER_TYPES.ALL,
        }
    },
    components: { SectionTitle, SelectDropdown, CrudIndex },
    mounted() {
        this.init()
    },
    computed: {
        ...mapState("misc", ["companies"]),
    },
    watch: {
        companies(e) {
            this.init()
        },
        $route(e) {},
    },
    methods: {
        viewLogs(id) {
            window.open(`assets/${id}/logs`)
        },
        handleAllocatedTypeChange(e) {
            this.allocatedType = e
            this.loading = true
            this.$router.push(`/assets?type=${e}`)
            setTimeout(() => {
                this.loading = false
            })
        },
        async init() {
            if (this.initialized) return
            if (!this.companies.length) return
            try {
                this.allocatedType = this.$route.query.type || ASSET_FILTER_TYPES.ALL
                console.log("the companies are", this.companies)
                const categories = await this.fetchAssetCategoryMaster()
                this.createFields = [
                    ...this.createFields,
                    createField("Serial No*", "serialNo", "text", true),
                    createField(
                        "Asset Category*",
                        "assetCategoryId",
                        "select",
                        true,
                        "",
                        categories.map((item) => ({
                            id: item.id,
                            title: item.name,
                        }))
                    ),
                    createField(
                        "Company*",
                        "companyId",
                        "select",
                        true,
                        "",
                        this.companies.map((item) => ({
                            id: item.id,
                            title: item.name,
                        }))
                    ),
                    createField("Allow Multiple User Allocation", "allowMultipleUserAllocation", "switch", false, false),
                    createField("Is Active", "isActive", "switch", false, true),
                    // createField("Quantity*", "qty", "number", true),
                    createField("Price*", "price", "number", true),
                ]
                this.loading = false
                this.initialized = false
            } catch (err) {
                console.log("error in initializing assets", err)
            }
        },
        async fetchAssetCategoryMaster() {
            const res = await http.get("/asset-categories")
            return res.data.result
        },
    },
}
</script>

<style></style>
