<template>
    <div class="file-folder-info-container" v-if="infoData">
        <div class="file-folder-info-header">
            <div class="file-folder-info-title">{{ infoData.name }}</div>
            <div @click="$emit('close')" class="file-folder-info-close">&times;</div>
        </div>
        <div>
            <div>
                <strong><i class="fas fa-info-circle"></i> File Details</strong>
            </div>
            <div class="detail-container">
                <div class="detail-item" v-for="detailItem of detailItems" :key="detailItem.id">
                    <div>{{ detailItem.name }}</div>
                    <div>{{ detailItem.value }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { formatFileSize, getFileTypeFromExtension } from "../../utils/file-utils"

export default {
    emits: ["close"],
    data() {
        return {
            detailItems: [],
        }
    },
    mounted() {
        if (this.infoData) {
            this.detailItems = this.detailItems.concat(
                {
                    name: "Type",
                    value: this.infoData.mimeType
                        ? getFileTypeFromExtension(this.infoData.mimeType.split("/")[1])
                        : "Folder",
                },
                {
                    name: "Size",
                    value: this.infoData.size ? formatFileSize(this.infoData.size) : "NA",
                },
                {
                    name: "Location",
                    value: this.infoData.folderId ? "Folder" : "Root",
                },
                {
                    name: "Owner",
                    value: "me",
                },
                {
                    name: "Modified",
                    value: this.infoData.updatedAt,
                },
                // {
                //     name: "Opened",
                //     value: this.infoData.updatedAt,
                // },
                {
                    name: "Created",
                    value: this.infoData.createdAt,
                }
            )
        }
    },
    props: {
        infoData: {
            type: Object,
            default: null,
        },
    },
}
</script>

<style lang="scss" scoped>
.file-folder-info-container {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    position: relative;
    z-index: 1000;
}
.file-folder-info-title {
    padding: 0;
    font-size: 15px;
    margin: 15px 0;
}
.file-folder-info-close {
    font-size: 21px;
    cursor: pointer;
}
.file-folder-info-header {
    display: flex;
    border-bottom: 1px solid #aaa;
    justify-content: space-between;
    margin-bottom: 10px;
}
.detail-item {
    margin: 20px 0;
}
.detail-container {
    overflow-y: scroll;
    height: 60vh;
}
</style>
