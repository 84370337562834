<template>
    <div>
        <h2 class="">
            <strong>Import Attendance (Only valid csv files)</strong>
        </h2>
        <p class="text-secondary my-3">
            Note: Employee code to import, the status (A, WO, L, etc), and department must be
            present in database. Month specified in CSV file is not considered. Whatever month is
            selected in this import form will be considered. Dates can be 01-Aug or 08/01/2024
            (mm/dd/yyyy) etc. If existing attendance record is present in that date, it will be
            overwritten! Download the sample for more information.
        </p>
        <div style="width: 250px">
            <CustomInput type="number" v-model="year" label="Year" :required="required" />
            <p class="my-2">Select Month</p>
            <select v-model="month" class="form-select form-select-sm">
                <option value="1">Jan</option>
                <option value="2">Feb</option>
                <option value="3">Mar</option>
                <option value="4">Apr</option>
                <option value="5">May</option>
                <option value="6">Jun</option>
                <option value="7">Jul</option>
                <option value="8">Aug</option>
                <option value="9">Sept</option>
                <option value="10">Oct</option>
                <option value="11">Nov</option>
                <option value="12">Dec</option>
            </select>
        </div>

        <ImportErrorModal
            :show="showImportResult"
            :importStatus="importStatus"
            :importSuccess="importSuccess"
            :importErrors="importErrors"
            :processing="processing"
            @close="handleImportModalClose"
        >
            <a href="/employees">
                <Button>View Employees</Button>
            </a>
        </ImportErrorModal>

        <div class="my-2 mb-3">
            <a
                style="color: dodgerblue"
                :href="getAssetUrlPath('/uploads/excel/Import attendance format.csv')"
                target="_blank"
                download="Import attendance format"
            >
                <i class="fas fa-download"></i> Download Template
            </a>
        </div>

        <form style="max-width: 300px">
            <div>
                <input
                    @click="$event.target.value = null"
                    accept="text/csv"
                    type="file"
                    class="form-control form-control-sm"
                    @change="handleSelectCsvFile"
                />
            </div>
        </form>

        <CustomModal
            v-if="showWarningModal"
            :title="`Warning`"
            :show="showWarningModal"
            @close="showWarningModal = false"
        >
            <div class="my-3">
                <div>
                    Matching attendance records found in
                    {{ getMonthName(month) }}, {{ year }}. The data will be overwritten. Are you
                    sure?
                </div>
                <div>
                    <Button @click="handleImport">{{
                        processing ? "Please wait..." : "Confirm Import Data"
                    }}</Button>
                </div>
            </div>
        </CustomModal>

        <Button @click="verifyImport">{{
            processing ? "Please wait..." : "Import Attendance"
        }}</Button>
    </div>
</template>

<script>
import Button from "../../components/UI/Button.vue"
import { http } from "@/utils/http"
import { errMsg, getAssetUrlPath, getMonthName } from "../../utils/common"
import ImportErrorModal from "../../components/other/ImportErrorModal.vue"
import CustomInput from "../../components/crud/custom-input.vue"
import CustomModal from "../../components/layout/CustomModal.vue"

export default {
    components: { CustomModal, Button, ImportErrorModal, CustomInput },
    data() {
        return {
            csvFile: null,
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            processing: false,
            showImportResult: false,
            importStatus: "Pending",
            importErrors: null,
            importSuccess: false,
            showWarningModal: false,
        }
    },
    methods: {
        getAssetUrlPath,
        getMonthName,
        handleImportModalClose() {
            if (this.importSuccess) {
                window.location.reload()
            } else {
                this.showImportResult = false
            }
        },
        async verifyImport() {
            if (!this.csvFile) {
                return this.$toast.error("Please select an Excel or CSV File!")
            }
            if (!this.year || !this.month) {
                return this.$toast.error("Please select year or month!")
            }
            try {
                this.processing = true
                const res = await http.get(
                    `/attendance-records/count?year=${this.year}&month=${this.month}`
                )
                if (res.data.result > 0) {
                    this.showWarningModal = true
                } else {
                    this.processing = false
                    await this.handleImport()
                }
            } catch (err) {
                console.log("error in processing", err)
            }
            this.processing = false
        },
        async handleImport() {
            const formData = new FormData()
            if (this.processing) return

            formData.append("year", this.year)
            formData.append("month", this.month)
            formData.append("csvFile", this.csvFile)

            this.showWarningModal = false
            this.processing = true
            this.importSuccess = false
            this.showImportResult = true

            try {
                this.importStatus = "Importing..."
                await http.post("/attendance-records/import", formData)
                this.$toast.success("Imported Successfully!")
                this.importStatus = "Success"
                this.importSuccess = true
                // window.location.href = "/inventory-manager"
            } catch (err) {
                console.log("error in importing records", err)
                this.importErrors = err.response.data.result
                this.$toast.error(errMsg(err, "Could not import stock!"))
                this.importStatus = "Failed"
            }
            this.processing = false
        },
        handleSelectCsvFile(e) {
            this.csvFile = e.target.files[0]
        },
    },
}
</script>

<style></style>
