<template>
    <div class="mt-3">
        <AllowPermission :permission="PERMISSION_CODES.CREATE_PROJECT_TASKS">
            <h2 class="mb-3" style="font-size: 18px">Create New Task</h2>
        </AllowPermission>
        <Button @click="$router.push('/project-tasks')"><i class="fas fa-chevron-left"></i> Back to List</Button>
        <CrudIndex
            v-if="!loading"
            @created="$router.push('/project-tasks')"
            title="Task"
            :columnCount="1"
            :onlyCreate="true"
            :permission="PERMISSION_CODES.VIEW_PROJECT_TASKS"
            :viewColumns="columns"
            fetchUrl="/project-tasks"
            createUrl="/project-tasks"
            updateRootUrl="/project-tasks"
            deleteRootUrl="/project-tasks"
            :createFields="createFields"
            :editingFormatter="editingFormatter"
            @formDataUpdated="handleFormDataUpdate"
        />
        <div v-else>Please wait...</div>
    </div>
</template>

<script>
import CrudIndex from "@/components/crud/index"
import { createField, createColumn } from "@/components/crud/utils"
import { mapState, mapActions } from "vuex"
import { http } from "@/utils/http"
import Button from "@/components/UI/Button.vue"
import { PERMISSION_CODES } from "../../../store/permission"
import AllowPermission from "../../../components/layout/AllowPermission.vue"

export default {
    components: { AllowPermission, CrudIndex, Button },
    computed: {
        ...mapState("auth", ["user", "isAdmin"]),
        // ...mapState("project", ["projects", "activeCompany", "activeProject"]),
    },
    data() {
        return {
            PERMISSION_CODES,
            customers: {},
            products: {},
            currentCompanyId: "",
            orders: {},
            loading: false,
            columns: [createColumn("Id", "id", "string"), createColumn("Name", "name", "string")],
            createFields: [],
            taskCreateOptions: [],
            showPendingUsersSelection: false,
            minEndDate: "",
            maxEndDate: "",
            minInterimDate: "",
            maxInterimDate: "",
            endDateFilled: false,
            projectsToSelect: [],
            tasksToSelect: [],
            defaultSelectedCompany: "",
            defaultSelectedProject: "",
            defaultSelectedTask: "",
            // defaultSelectedTaskTypeId: 2,
            // showTaskSelectInput: false,
            // showTaskTextInput: false,
            // tasksToSelectType: [
            //     {
            //         id: 1,
            //         title: "New Parent Task",
            //     },
            //     {
            //         id: 2,
            //         title: "Existing Parent Task",
            //     },
            // ],
        }
    },
    async mounted() {
        console.log("fetch task mounted 1")
        this.loading = true
        this.taskCreateOptions = await this.fetchTaskCreateOptions()

        const defaultCompanyId = this.$route.query.company_id
        const defaultProjectId = this.$route.query.project_id
        const defaultTaskId = this.$route.query.task_id

        if (defaultCompanyId) {
            this.defaultSelectedCompany = defaultCompanyId
            this.updateProjectsToSelect(defaultCompanyId)
        }
        if (defaultProjectId) {
            this.defaultSelectedProject = defaultProjectId
            await this.fetchTasks(defaultProjectId)
        }
        if (defaultTaskId) {
            this.defaultSelectedTask = defaultTaskId
        }

        // if (this.activeProject) {
        //     this.defaultSelectedProject = this.activeProject && this.activeProject.id
        // }
        // if (this.activeCompany) {
        //     this.defaultSelectedCompany = this.activeCompany && this.activeCompany.id

        //     this.projectsToSelect = this.taskCreateOptions.projects.filter(
        //         (item) => item.companyId == this.activeCompany.id
        //     )
        // }
        this.recreateFields()
        setTimeout(() => {
            this.loading = false
        }, 100)
        console.log("fetch task mounted 2")
    },
    methods: {
        ...mapActions("auth", ["checkRole"]),
        delay(seconds = 1) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(true)
                }, seconds * 1000)
            })
        },
        recreateFields(showMeasurement = true) {
            let creatorField
            if (this.isAdmin) {
                creatorField = createField(
                    "Creator*",
                    "reporterId",
                    "select",
                    true,
                    "",
                    this.taskCreateOptions.allUsers.map((item) => ({
                        id: item.id,
                        title: item.firstName,
                    }))
                )
            } else {
                creatorField = createField("Creator*", "reporterId", "hidden", true, this.user.id)
            }

            let createFields = [
                createField(
                    "Company*",
                    "companyId",
                    "select",
                    true,
                    this.defaultSelectedCompany,
                    this.taskCreateOptions.companies.map((item) => ({
                        id: item.id,
                        title: item.name,
                    }))
                ),
                createField(
                    "Project*",
                    "projectId",
                    "select",
                    true,
                    this.defaultSelectedProject,
                    this.projectsToSelect.map((item) => ({
                        id: item.id,
                        title: item.name,
                    }))
                ),
                // createField(
                //     "New Parent Task / Existing Parent Task*",
                //     "taskType",
                //     "select",
                //     true,
                //     this.defaultSelectedTaskTypeId,
                //     this.tasksToSelectType
                // ),
            ]

            if (showMeasurement) {
                createFields.push(createField("Measurement Value", "measurementValue", "number", false))
            }

            // if (this.showTaskSelectInput) {
            createFields.push(
                createField(
                    "Parent Task (Select if creating subtask)",
                    "taskId",
                    "select",
                    false,
                    this.defaultSelectedTask,
                    this.tasksToSelect.map((item) => ({
                        id: item.id,
                        title: item.name,
                    }))
                )
            )
            // }
            // if (this.showTaskTextInput) {
            //     createFields.push(createField("Parent Task Name*", "taskName", "text", true))
            // }

            createFields = [
                ...createFields,
                createField(
                    "Status*",
                    "statusId",
                    "select",
                    true,
                    "",
                    this.taskCreateOptions.statuses.map((item) => ({
                        id: item.id,
                        title: item.name,
                    }))
                ),
                createField(
                    "Task Pending Due to Users*",
                    "pendingDueToUserIds",
                    this.showPendingUsersSelection ? "multiselect" : "hidden",
                    false,
                    "",
                    this.taskCreateOptions.allUsers.map((item) => ({
                        id: item.id,
                        title: item.firstName,
                    }))
                ),
                createField("Task Title*", "name", "text", true),

                creatorField,
                createField(
                    "Assignees*",
                    "assigneeUserIds",
                    "multiselect",
                    true,
                    "",
                    this.taskCreateOptions.allUsers
                        .filter((item) => this.currentCompanyId && item.companyId == this.currentCompanyId)
                        .map((item) => ({
                            id: item.id,
                            title: item.firstName,
                        }))
                ),
                createField(
                    "Report To*",
                    "reportToIds",
                    "multiselect",
                    true,
                    "",
                    this.taskCreateOptions.allUsers.map((item) => ({
                        id: item.id,
                        title: item.firstName,
                    }))
                ),

                createField("Start Date*", "startDate", "date", true, new Date().toISOString().slice(0, 10)),
                createField(
                    "Target Date (must be after start date)*",
                    "endDate",
                    `date|${this.minEndDate}|${this.maxEndDate}|disabled:false`,
                    true,
                    ""
                ),
                // createField(
                //     "Interim Date (between start and target date)",
                //     "interimDate",
                //     `date|${this.minInterimDate}|${this.maxInterimDate}|disabled:${
                //         this.endDateFilled ? "false" : "true"
                //     }`,
                //     false,
                //     null
                // ),
                createField(
                    "Departments*",
                    "departmentIds",
                    "multiselect",
                    true,
                    "",
                    this.taskCreateOptions.departments.map((item) => ({
                        id: item.id,
                        title: item.name,
                    }))
                ),
                createField(
                    "Priority*",
                    "priorityId",
                    "select",
                    true,
                    "",
                    this.taskCreateOptions.priorities.map((item) => ({
                        id: item.id,
                        title: item.name,
                    }))
                ),
                createField("Attachment 1", "attachment1", "file", false),
                createField("Attachment 2", "attachment2", "file", false),
                createField("Attachment 3", "attachment3", "file", false),
                createField("Description", "description", "textarea", false),
            ]

            // update create fields
            this.createFields = createFields
        },
        async fetchTasks(selectedId = "", val = null) {
            try {
                const res = await http.get(`/project-tasks?projectId=${selectedId || (val && val.projectId)}`)

                // await this.delay(1)
                this.tasksToSelect = res.data.result || []
            } catch (err) {
                console.log("error in loading tasks", err)
                this.$toast.error("Error in loading tasks")
            }
        },
        updateProjectsToSelect(companyId) {
            this.projectsToSelect = this.taskCreateOptions.projects.filter(
                (item) => item.companyIds && item.companyIds.split(",").includes(companyId)
            )
        },
        async handleFormDataUpdate(val) {
            console.log("handle form data update", val)

            if (val) {
                // if (val.taskType) {
                //     this.showTaskSelectInput = false
                //     this.showTaskTextInput = false
                //     if (val.taskType == 1) {
                //         this.showTaskTextInput = true
                //     } else {
                //         // fetch task select inputs
                //         this.showTaskSelectInput = true
                //     }
                //     this.recreateFields()
                // }

                if (val.projectId) {
                    await this.fetchTasks("", val)
                    this.recreateFields()
                }

                if (val.statusId) {
                    const pendingStatus = this.taskCreateOptions.statuses.find((item) => item.slug === "pending")
                    const statusId = val.statusId
                    if (pendingStatus && pendingStatus.id === statusId) {
                        this.showPendingUsersSelection = true
                    } else {
                        this.showPendingUsersSelection = false
                    }

                    this.recreateFields()
                    // minInterimDate
                    // maxInterimDate
                }

                if (val.startDate || val.endDate) {
                    if (val.startDate) {
                        this.minEndDate = val.startDate
                        this.minInterimDate = val.startDate
                    }
                    if (val.endDate) {
                        this.maxInterimDate = val.endDate
                        this.endDateFilled = true
                    }
                    this.recreateFields()
                }

                if (!this.currentCompanyId || val.companyId != this.currentCompanyId) {
                    this.currentCompanyId = val.companyId
                    this.updateProjectsToSelect(val.companyId)
                    this.recreateFields()
                }

                if (val.taskId) {
                    this.recreateFields(false)
                }

                // minEndDate
                // maxEndDate
                // minInterimDate
                // maxInterimDate
            }
        },
        editingFormatter(item) {
            console.log("editing formatter running", item)
            return {
                ...item,
            }
        },
        async fetchTaskCreateOptions() {
            let result = {}
            try {
                const res = await http.get("/projects/masters?all_projects=true")
                result = res.data
            } catch (err) {
                console.log("error in loading task create options", err)
            }
            // await this.delay(3)
            this.initialized = true
            return result
        },
    },
}
</script>

<style lang="scss" scoped>
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.issue-toggle-btn {
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    background-color: #f8f8f8;
    &:hover {
        background-color: #eee;
    }
}
</style>
