<template>
    <div>
        <div class="row">
            <div class="col-md-9">
                <div v-if="showMap" style="height: 75vh" id="map"></div>
                <div v-else style="height: 75vh" id="map-demo">Search Records To View Map</div>
            </div>
            <div class="col-md-3">
                <Button @click="handleLoadData">{{ loading ? "Please wait..." : "Load Records" }}</Button>
                <SelectDropdown
                    title="Check In / Out"
                    :id="`form-check-in-out`"
                    :options="checkInOuts"
                    :value="selectedCheckInOut"
                    @change="selectedCheckInOut = $event"
                />
                <SelectDropdown
                    title="Company"
                    :id="`form-company`"
                    :options="companies.map((item) => ({ id: item.id, title: item.name }))"
                    :value="selectedCompany"
                    @change="selectedCompany = $event"
                />
                <SelectDropdown
                    title="Work Locations"
                    :id="`form-work-location`"
                    v-if="companyWorkLocations.length"
                    :multiple="true"
                    :options="companyWorkLocations.map((item) => ({ id: item.id, title: item.name }))"
                    :value="selectedCompanyLocations"
                    @change="selectedCompanyLocations = $event"
                />
                <div class="mb-2">
                    <p class="my-2">Date:</p>
                    <input type="date" class="form-control form-control-sm" v-model="date" />
                </div>
                <SelectDropdown
                    title="Employees"
                    :id="`form-employee`"
                    v-if="employees.length"
                    :options="
                        employees.map((item) => ({
                            id: item.id,
                            title: `${item.firstName} ${item.lastName}`,
                        }))
                    "
                    :value="selectedEmployees"
                    :multiple="true"
                    @change="selectedEmployees = $event"
                />
            </div>
        </div>
    </div>
</template>

<script>
import SelectDropdown from "../../../components/crud/SelectDropdown.vue"
import Button from "../../../components/UI/Button.vue"
import { http, serverURL } from "../../../utils/http"
import { convertUTCToIST, convertToAmPm, convertTimeToTimezone } from "../../../utils/common"

var userPngImg = require("@/assets/user.png")
var userWorkImg = require("@/assets/work_location.png")

export default {
    data() {
        return {
            date: new Date().toISOString().slice(0, 10),
            employees: [],
            showMap: false,
            companies: [],
            selectedCompany: null,
            selectedEmployees: [],
            companyWorkLocations: [],
            selectedCompanyLocations: [],
            attendanceRecords: [],
            loading: false,
            checkInOuts: [
                {
                    id: 1,
                    title: "Check In",
                },
                {
                    id: 2,
                    title: "Check Out",
                },
            ],
            selectedCheckInOut: 1,
        }
    },
    components: { SelectDropdown, Button },
    mounted() {
        // const loader = new Loader({
        //     apiKey: process.env.VUE_APP_MAP_KEY,
        //     version: "weekly",
        // })
        // loader.load().then(async () => {})

        this.getCompanies()
        setTimeout(() => {
            this.initMap()
        }, 1000)
    },
    methods: {
        async handleLoadData() {
            if (!this.selectedCompany || !this.selectedEmployees || !this.selectedEmployees.length) {
                return this.$toast.error("Please select company and employees!")
            }
            this.showMap = false
            this.loading = true
            try {
                const url = `/attendance-records/map?date=${
                    this.date
                }&allow_role_condition=false&employee_ids=${this.selectedEmployees.join(",")}`
                const res = await http.get(url)

                const isCheckIn = this.selectedCheckInOut == 1 ? true : false

                // ensure that values are present for lat/lng
                this.attendanceRecords = res.data.result.filter((item) =>
                    isCheckIn ? item.timeInLatitude && item.timeInLongitude : item.timeOutLatitude && item.timeOutLongitude
                )
                if (this.attendanceRecords.length <= 0) {
                    this.$toast.info("No records found!")
                } else {
                    this.showMap = true
                    this.initMap()
                    this.$toast.success("Records loaded!")
                }
            } catch (err) {
                console.log("error in loading ", err)
            }
            this.loading = false
        },
        async getCompanies() {
            const res = await http.get("/companies")
            this.companies = res.data.result
        },
        async fetchCompanyLocations() {
            const res = await http.get(`/companies/${this.selectedCompany}`)
            this.companyWorkLocations = []
            setTimeout(() => {
                this.companyWorkLocations = res.data.result.workLocations || []
                this.companyWorkLocations = this.companyWorkLocations.filter((item) => item.latitude && item.longitude)
                this.selectedCompanyLocations = this.companyWorkLocations.map((item) => item.id)
            })
        },
        async fetchEmployees() {
            const res = await http.get(`/employees?company_id=${this.selectedCompany}`)
            this.employees = []
            setTimeout(() => {
                this.employees = res.data.result
                this.selectedEmployees = this.employees.map((item) => item.id)
            })
        },
        initMap() {
            setTimeout(async () => {
                const { Map } = await google.maps.importLibrary("maps")
                const { AdvancedMarkerElement } = await google.maps.importLibrary("marker")
                const isCheckIn = this.selectedCheckInOut == 1 ? true : false

                // Array of locations with latitude and longitude

                var locations = this.attendanceRecords.map((item) => ({
                    lat: isCheckIn ? item.timeInLatitude : item.timeOutLatitude,
                    lng: isCheckIn ? item.timeInLongitude : item.timeOutLongitude,
                    profileImage: item.employee && item.employee.profileImage,
                    label: item.employee && `${item.employee.firstName} ${item.employee.lastName}`,
                    time: convertTimeToTimezone(isCheckIn ? item.timeIn : item.timeOut),
                }))

                const companyWorkLocations = this.companyWorkLocations
                    .filter((item) => this.selectedCompanyLocations.includes(item.id))
                    .map((item, idx) => ({
                        lat: Number(item.latitude),
                        lng: Number(item.longitude),
                        isWork: true,
                        label: `${idx + 1}: ${item.name} (Company)`,
                    }))

                const map = new Map(document.getElementById("map"), {
                    center: locations[0],
                    zoom: 19,
                    mapId: "4504f8b37365c3d0",
                })

                locations = [...locations, ...companyWorkLocations]
                console.log("total work locations", locations)

                locations.forEach(function (location) {
                    const priceTag = document.createElement("div")

                    priceTag.className = "price-tag"
                    if (location.isWork) {
                        priceTag.className = "price-tag work"
                    }

                    const priceTagText = document.createElement("p")
                    priceTagText.textContent = location.label
                    priceTagText.className = "price-tag-text"

                    let imgSrc = ""
                    if (location.profileImage) {
                        imgSrc = serverURL + "/" + location.profileImage
                    } else {
                        if (location.isWork) {
                            imgSrc = userWorkImg
                        } else {
                            imgSrc = userPngImg
                        }
                    }

                    const img = document.createElement("img")
                    img.src = imgSrc
                    img.className = "price-tag-img"

                    priceTag.appendChild(img)

                    const tagPrefix = document.createElement("p")
                    tagPrefix.textContent = location.isWork
                        ? location.label.toUpperCase().slice(3)[0] || ""
                        : location.label.toUpperCase()[0] || ""
                    tagPrefix.className = "tag-prefix"

                    priceTag.appendChild(tagPrefix)
                    priceTag.appendChild(priceTagText)

                    const timeTagText = document.createElement("p")
                    if (location.time) {
                        timeTagText.textContent = location.time
                        timeTagText.className = "price-tag-time"
                        priceTag.appendChild(timeTagText)
                    }

                    const marker = new AdvancedMarkerElement({
                        map,
                        position: location,
                        content: priceTag,
                    })

                    marker.addListener("click", () => {
                        console.log("Marker clicked for location:", location)
                        // Display the location details here
                        // For example, you can toggle the display of the priceTagText
                        priceTagText.style.display = priceTagText.style.display === "block" ? "none" : "block"
                        timeTagText.style.display = timeTagText.style.display === "block" ? "none" : "block"
                        img.style.display = img.style.display === "block" ? "none" : "block"
                        tagPrefix.style.display = priceTagText.style.display === "block" ? "none" : "block"
                        priceTag.style.opacity = priceTagText.style.display === "block" ? "1" : "0.8"
                    })
                })
            })
        },
    },
    watch: {
        selectedCompany(e) {
            this.selectedEmployees = []
            this.fetchEmployees()
            this.fetchCompanyLocations()
        },
    },
}
</script>

<style>
/* HTML marker styles */
.price-tag {
    background-color: #4285f4;
    border-radius: 28px;
    color: #ffffff;
    font-size: 14px;
    padding: 10px 15px;
    position: relative;
    opacity: 0.8;
    transform: translateY(-10px);
    display: flex;
    align-items: center;
    flex-direction: column;
}
.price-tag.work {
    background-color: #334053;
}

.price-tag::after,
.price-tag.work::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #4285f4;
}
.price-tag.work::after {
    border-top-color: #334053;
}

.tag-prefix {
    cursor: pointer;
}
.price-tag-text {
    display: none;
    margin: 5px 0;
    font-size: 11px;
}
.price-tag-time {
    margin: 0px 0;
    display: none;
    font-size: 11px;
}
.price-tag-img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    display: none;
}
#map-demo {
    background-color: #ddd;
    display: grid;
    place-content: center;
    color: #666;
    font-size: 1.2em;
}
</style>
