<template>
    <div>
        <CrudIndex
            title="Attendance Statuses"
            :viewColumns="columns"
            fetchUrl="/attendance-statuses"
            createUrl="/attendance-statuses"
            updateRootUrl="/attendance-statuses"
            :createFields="createFields"
            :permission="PERMISSION_CODES.VIEW_ATTENDANCE_STATUSES"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { PERMISSION_CODES } from "../../store/permission"
import { createField, createColumn } from "../../utils/common"

export default {
    data() {
        return {
            createFields: [
                createField("Name*", "name", "text", true),
                createField("Code*", "code", "text", true),
            ],
            columns: [
                createColumn("Name", "name", "string"),
                createColumn("Code", "code", "string"),
            ],
            PERMISSION_CODES,
        }
    },
    components: { CrudIndex },
}
</script>

<style></style>
