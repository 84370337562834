<template>
    <AllowPermission :permission="`VIEW_${permissionCategory}`">
        <div>
            <AllowPermission :permission="`CREATE_${permissionCategory}`">
                <div class="text-black font-bold mb-3" v-if="createUrl && !onlyEdit && !onlyCreate">
                    Create {{ title }}
                    <span @click="enableCreateForm" class="p-1 px-2 mx-3 rounded text-green-500 cursor-pointer">
                        <i class="fas fa-minus" v-if="showForm && !editMode" />
                        <i class="fas fa-add" v-else />
                    </span>
                </div>
            </AllowPermission>

            <div v-if="showForm || onlyCreate">
                <Form
                    :fields="createFields"
                    :onlyCreate="onlyCreate"
                    :title="title"
                    :submitUrl="createUrl"
                    :updateRootUrl="updateRootUrl"
                    @success="handleCreateSuccess"
                    @formDataUpdated="$emit('formDataUpdated', $event)"
                    @cancel="disableEditing(true)"
                    :editMode="editMode"
                    :editData="editData"
                />
            </div>
            <div v-if="loading && !onlyCreate" class="d-flex justify-content-center my-5">
                <div class="spinner-border text-primary"></div>
            </div>
            <div style="font-weight: normal" v-if="!onlyEdit && !onlyCreate && !loading && !dataItems.length">No Data Found</div>
            <div v-else class="datatable-wrapper">
                <datatable
                    :title="`${title} data`"
                    :columns="columns"
                    :rows="dataItemFilter(dataItems)"
                    :exactSearch="false"
                    v-if="!onlyEdit && !onlyCreate && dataItems.length"
                >
                    <th slot="thead-tr">Actions</th>
                    <template slot="tbody-tr" slot-scope="props">
                        <td>
                            <div>
                                <div class="d-flex" style="gap: 5px">
                                    <slot :itemId="props.row.id" />
                                    <AllowPermission :permission="`EDIT_${permissionCategory}`">
                                        <div
                                            class="btn-edit"
                                            href="javascript:void(0)"
                                            v-if="editRouteName"
                                            @click="
                                                navigatePage({
                                                    name: editRouteName,
                                                    params: { id: props.row.id },
                                                })
                                            "
                                        >
                                            {{ editBtnName }}
                                        </div>
                                        <div
                                            class="btn-edit"
                                            href="javascript:void(0)"
                                            v-else-if="allowEditing"
                                            @click="enableEditing(dataItems.find((item) => item.id == props.row.id))"
                                        >
                                            {{ editBtnName }}
                                        </div>
                                    </AllowPermission>
                                    <AllowPermission :permission="`DELETE_${permissionCategory}`">
                                        <div
                                            class="btn-delete"
                                            href="javascript:void(0)"
                                            v-if="deleteRootUrl"
                                            @click="deleteDataItem(props.row.id)"
                                        >
                                            Delete
                                        </div>
                                    </AllowPermission>
                                </div>
                            </div>
                        </td>
                    </template>
                </datatable>
            </div>
        </div>
    </AllowPermission>
</template>

<script>
import "material-design-icons-iconfont"
import Card from "../../components/UI/Card.vue"
import Button from "../../components/UI/Button.vue"
import Form from "../../components/crud/form.vue"
import { http } from "../../utils/http"
import "vue-good-table/dist/vue-good-table.css"
// import { VueGoodTable } from 'vue-good-table';
import DataTable from "vue-materialize-datatable"
import AllowPermission from "../layout/AllowPermission.vue"

export default {
    emits: ["created", "formDataUpdated"],
    components: { Form, Card, Button, datatable: DataTable, AllowPermission },
    data() {
        return {
            dataItems: [],
            name: "",
            showForm: false,
            initialized: false,
            processing: false,
            columns: [],
            loading: false,
            editMode: false,
            editData: null,
        }
    },
    props: {
        // below two fields are required to apply permissions
        editBtnName: {
            type: String,
            default: "Edit",
        },
        permission: {
            type: String,
            default: "",
        },
        applyPermissions: {
            type: Boolean,
            default: true,
        },

        onlyCreate: {
            type: Boolean,
            default: false,
        },
        allowEditing: {
            type: Boolean,
            default: true,
        },
        backRoute: {
            type: String,
            default: "",
        },
        editingFormatter: {
            type: Function,
            default: (data) => {
                return data
            },
        },
        dataItemFilter: {
            type: Function,
            default: (data) => {
                return data
            },
        },
        allowGoBack: {
            type: Boolean,
            default: false,
        },
        onlyEdit: {
            type: Boolean,
            default: false,
        },
        onlyEditData: {
            type: Object,
            default: () => null,
        },
        editRouteName: { type: String, default: "" },
        createFields: {
            type: Array,
            default: () => [],
        },
        title: {
            type: String,
            default: "",
        },
        fetchUrl: {
            type: String,
            default: "",
        },
        createUrl: {
            type: String,
            defualt: "",
        },
        updateRootUrl: {
            type: String,
            defualt: "",
        },
        deleteRootUrl: {
            type: String,
            default: "",
        },
        reloadOnSuccess: {
            type: Boolean,
            default: false,
        },
        viewColumns: {
            type: Array,
            default: () => [
                // {
                //     label: "Id",
                //     field: "id",
                //     type: "number",
                // },
                // {
                //     label: "Name",
                //     field: "name",
                // },
            ],
        },
    },
    computed: {
        permissionCategory() {
            // get the rest of the permissions
            const prmCat = this.permission ? this.permission.split("_").slice(1).join("_") : ""
            return prmCat
        },
    },
    mounted() {
        console.log("teh cssurd index is mounted", this.onlyEdit, this.viewColumns, this.fetchUrl)
        this.columns = [{ label: "#", field: "index", type: "number" }, ...this.viewColumns]
        if (this.onlyEdit) {
            this.enableEditing(this.onlyEditData)
        } else {
            this.fetchData()
        }
    },
    methods: {
        navigatePage(routeObj) {
            window.location.href = this.$router.resolve(routeObj).href
        },
        async enableCreateForm() {
            this.editData = null

            // for new state
            const showFormOriginal = this.showForm
            this.showForm = false

            setTimeout(() => {
                this.showForm = showFormOriginal
                if (this.showForm && !this.editMode) {
                    this.showForm = false
                } else {
                    this.showForm = true
                }
                this.editMode = false
            }, 0)
        },
        async enableEditing(editItem) {
            this.disableEditing()

            setTimeout(() => {
                this.editMode = true
                this.editData = this.editingFormatter(editItem)
                this.showForm = true
            }, 0)
            window.scroll({ top: 0, behavior: "smooth" })
        },
        async disableEditing(cancelMode) {
            window.scroll({ top: 0, behavior: "smooth" })
            if (this.onlyEdit) {
                if (this.allowGoBack && cancelMode) {
                    this.$router.go(-1)
                }
            } else {
                this.editMode = false
                this.editData = null
                this.showForm = false
            }
        },
        async handleCreateSuccess(result) {
            if (this.onlyEdit) {
                if (this.allowGoBack) {
                    if (this.backRoute) {
                        return this.$router.push(this.backRoute)
                    }
                    this.$router.go(-1)
                }
                return
            }
            if (this.editMode) {
                this.dataItems = this.dataItems.map((item) => {
                    if (item.id == result.id) {
                        return result
                    }
                    return item
                })
            } else {
                this.dataItems.unshift(result)
            }

            this.$emit("created", result)
            if (this.reloadOnSuccess) {
                window.location.reload()
            }
            this.disableEditing()
        },
        async fetchData() {
            this.loading = true
            try {
                const res = await http.get(this.fetchUrl)
                this.dataItems = res.data.result.map((item, index) => ({
                    ...item,
                    index: index + 1,
                }))
                console.log(this.dataItems)
            } catch (err) {
                console.log("error in loading data", err)
            }
            this.initialized = true
            this.loading = false
        },
        async deleteDataItem(id) {
            try {
                if (confirm("Do you really want to delete?")) {
                    await http.delete(`${this.deleteRootUrl}/${id}`)
                    this.$toast.success(this.title + " Deleted Successfully!")
                    this.dataItems = this.dataItems.filter((item) => item.id !== id)
                    this.disableEditing()
                    if (this.reloadOnSuccess) {
                        window.location.reload()
                    }
                }
            } catch (err) {
                console.log("error in deleting " + this.title + " item", err)
                this.$toast.error("Some error occured")
            }
        },
    },
}
</script>

<style lang="scss">
.btn-edit,
.btn-delete,
.crud-btn {
    padding: 5px 15px;
    background: red;
    cursor: pointer;
    display: block;
    color: white;
    border-radius: 5px;
    text-align: center;
    font-size: 0.9em;
}

.btn-edit {
    background: #555;
}
.btn-edit:hover {
    color: white;
    background: #777;
}

.material-table {
    #search-input-container {
        padding: 0;
        label {
            width: 100%;
        }
        input {
            padding: 0 20px;
            border: 1px solid #ddd !important;
        }
    }
}
.datatable-wrapper {
    width: 100%;
    overflow-x: scroll;
    border: 1px solid #ddd;
}
.material-table {
    border: none;
}
</style>
