<template>
    <div>
        <SectionTitle>Notice Period</SectionTitle>
        <div class="my-3" v-if="loading">Loading...</div>
        <div class="my-3" v-else-if="!employee.designation">
            Designation not assigned to employee!
        </div>
        <div class="my-3" v-if="employee && employee.designation">
            <div v-if="employee.noticePeriodStartDate && employee.noticePeriodEndDate">
                <div>Start Date: {{ employee.noticePeriodStartDate }}</div>
                <div>End Date: {{ employee.noticePeriodEndDate }}</div>
                <div class="my-2">
                    <OldImageView linkMode :src="employee.noticePeriodResignLetterUrl" />
                </div>
                <div class="my-2" v-if="employee.noticePeriodNotes">
                    <p>Notes:</p>
                    <p>{{ employee.noticePeriodNotes }}</p>
                </div>
                <Button @click="cancel" style="background: red">{{
                    processing ? "Please wait..." : "Cancel Notice Period"
                }}</Button>
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-md-4">
                        <custom-input
                            type="number"
                            :min="0"
                            v-model="noticePeriodDays"
                            required
                            label="Notice Period Days"
                        />
                    </div>
                    <div class="col-md-4">
                        <custom-input
                            type="date"
                            v-model="startDate"
                            label="Start Date"
                            required
                            :min="minDate"
                        />
                    </div>
                    <div class="col-md-4">
                        <custom-input
                            type="date"
                            disabled
                            v-model="endDate"
                            label="End Date"
                            required
                            :min="minDate"
                        />
                    </div>
                    <div class="col-md-4">
                        <custom-input type="textarea" v-model="noticePeriodNotes" label="Notes" />
                    </div>
                    <div class="col-md-4">
                        <custom-input
                            type="file"
                            v-model="noticePeriodResignLetterUrl"
                            label="Upload Resign Letter"
                        />
                    </div>
                </div>
                <Button @click="submit">{{ processing ? "Please wait..." : "Submit" }}</Button>
            </div>
            <hr />
            <div class="my-3">
                <SectionTitle>Employee Termination</SectionTitle>

                <div class="row">
                    <div class="col-md-6">
                        <custom-input
                            v-model="terminationOfferedByPersonName"
                            type="text"
                            required
                            label="Termination Offered By Person Name"
                        />
                    </div>
                    <div class="col-md-6">
                        <custom-input
                            v-model="terminationOfferedByPersonPosition"
                            type="text"
                            required
                            label="Person's Position"
                        />
                    </div>
                </div>

                <div v-if="employee.terminationLetterLastGeneratedDate">
                    Last generated on
                    {{ formatIndianDate(employee.terminationLetterLastGeneratedDate) }}
                </div>
                <div class="my-3">
                    <Button noMargin @click="generateTerminationLetter">
                        <span v-if="terminating"> Please wait... </span>
                        <span v-else-if="!employee.terminationLetterPdf">
                            <i class="fas fa-check"></i>
                            Generate Termination Letter
                        </span>
                        <span v-else>
                            <i class="fas fa-refresh"></i> Regenerate Termination Letter
                        </span>
                    </Button>
                    <Button
                        v-if="employee.terminationLetterPdf"
                        @click="downloadTerminationLetter(employee.terminationLetterPdf)"
                        noMargin
                        type="button"
                        class="mx-2"
                    >
                        <i class="fas fa-download"></i> Download Termination Letter
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { daysToMs, formatIndianDate, getAssetUrlPath } from "../../utils/common"
import { http } from "../../utils/http"
import CustomInput from "../crud/custom-input.vue"
import Button from "../UI/Button.vue"
import OldImageView from "../UI/OldImageView.vue"
import SectionTitle from "../UI/SectionTitle.vue"

export default {
    components: { CustomInput, SectionTitle, Button, OldImageView },
    data() {
        return {
            noticePeriodResignLetterUrl: null,
            noticePeriodDays: 0,
            loading: false,
            employee: "",
            startDate: "",
            endDate: "",
            processing: false,
            noticePeriodNotes: "",
            minDate: new Date().toISOString().slice(0, 10),
            terminating: false,
            terminationOfferedByPersonName: "",
            terminationOfferedByPersonPosition: "",
        }
    },
    props: {
        empId: { type: [String, Number] },
    },
    async mounted() {
        // noticePeriodStartDate
        // noticePeriodEndDate
        this.fetchUser()
    },
    methods: {
        formatIndianDate,
        downloadTerminationLetter(path) {
            window.open(getAssetUrlPath(path), "_blank")
        },
        async generateTerminationLetter() {
            if (!this.terminationOfferedByPersonName || !this.terminationOfferedByPersonPosition) {
                this.$toast.error("Please fillup the fields required for termination letter!")
                return
            }
            this.terminating = true
            try {
                const res = await http.post(
                    `/employees/${this.employee.id}/generate-termination-letter/`,
                    {
                        terminationOfferedByPersonName: this.terminationOfferedByPersonName,
                        terminationOfferedByPersonPosition: this.terminationOfferedByPersonPosition,
                    }
                )
                this.$toast.success("Termination letter generated!")
                window.location.href = `${this.$route.path}?active_tab=NOTICE_PERIOD`
            } catch (err) {
                console.log("error in generating termination letter", err)
                this.$toast.error("Error in generating termination letter!")
            }
            this.terminating = false
        },
        async cancel() {
            if (this.processing) return
            try {
                if (!this.noticePeriodDays || Number(this.noticePeriodDays) < 0) {
                    this.$toast.error("Please enter a positive integer for notice period days!")
                    return
                }
                this.processing = true
                await http.post("/notice-periods/cancel", {
                    employeeId: this.employee.id,
                })
                window.location.href = this.$route.path + "?active_tab=NOTICE_PERIOD"
            } catch (err) {
                console.log("error in cancelling notice period", err)
            }
            this.processing = false
        },
        async submit() {
            if (!this.startDate) {
                this.$toast.error("Please enter start date!")
                return
            }
            if (this.processing) return
            try {
                this.processing = true

                const formData = new FormData()
                formData.append("startDate", this.startDate)
                formData.append("endDate", this.endDate)
                formData.append("employeeId", this.employee.id)
                formData.append("noticePeriodNotes", this.noticePeriodNotes)
                formData.append("noticePeriodResignLetterUrl", this.noticePeriodResignLetterUrl)
                await http.post("/notice-periods/create", formData)
                window.location.href = this.$route.path + "?active_tab=NOTICE_PERIOD"
            } catch (err) {
                console.log("error in creating notice period", err)
            }
            this.processing = false
        },
        async fetchUser() {
            if (this.loading) return
            this.loading = true
            try {
                const res = await http.get(`/users/${this.empId}`)
                this.employee = res.data.result
                this.terminationOfferedByPersonName =
                    this.employee.terminationOfferedByPersonName || ""
                this.terminationOfferedByPersonPosition =
                    this.employee.terminationOfferedByPersonPosition || ""
            } catch (err) {
                console.log("error in fetching user", err)
            }
            this.loading = false
        },
        updateDates() {
            if (this.startDate) {
                const addDays = daysToMs(this.noticePeriodDays || 30)
                this.endDate = new Date(new Date(this.startDate).getTime() + addDays)
                    .toISOString()
                    .slice(0, 10)
            }
        },
    },
    watch: {
        employee(e) {
            if (e) {
                this.noticePeriodDays = this.employee.designation.noticePeriodDays
            }
        },
        startDate(e) {
            this.updateDates()
        },
        noticePeriodDays(e) {
            this.updateDates()
        },
    },
}
</script>

<style></style>
