import formatDuration from "format-duration"
import {
    createField as createFieldUtil,
    createFormData as createFormDataUtil,
    createColumn as createColumnUtil,
} from "../components/crud/utils"
import validator from "validator"
import { serverURL } from "./http"

const durify = (seconds) => formatDuration(seconds * 1000)
const round2 = (num) => Math.round((num || 0) * 100) / 100

function stripText(text, length) {
    return text.length > length ? `${text.slice(0, length)}...` : text
}
const errMsg = (err) => {
    return err.response && err.response.data.message ? err.response && err.response.data.message : "Some error occured!"
}

function createField(label, field = "", type = "text", required = false, value = "", options = []) {
    // for custom form field object for crud form
    return createFieldUtil(label, field, type, required, value, options)
}
function createColumn(label, field, type) {
    // for vue good table
    return createColumnUtil(label, field, type)
}
function createFormData(formValues) {
    return createFormDataUtil(formValues)
}

function onlyAlphabetic(val) {
    return !/[^a-zA-Z ]/.test(val)
}
function isMobileNumber(val) {
    return val.length == 10 && /[0-9 -()+]+$/.test(val)
}
function isValidEmail(val) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(
        val
    )
}

function validateEmail(email) {
    return validator.isEmail(email) && (email.includes(".com") || email.includes("."))
}

function validatePhone(phone) {
    return validator.isNumeric(phone) && phone.length === 10
}
function formatIsoDate(date = new Date()) {
    return new Date(date).toISOString().slice(0, 10)
}
function isoToIndianDate(isoDate) {
    if (!isoDate) return ""
    const [yyyy, mm, dd] = isoDate.split("-")
    return `${padStringWithZeros(dd, 2)}/${padStringWithZeros(mm, 2)}/${yyyy}`
}
function indianToIsoDate(indianDate) {
    if (!indianDate) return ""
    const [dd, mm, yyyy] = indianDate.split("/")
    return `${yyyy}-${padStringWithZeros(mm, 2)}-${padStringWithZeros(dd, 2)}`
}
function padStringWithZeros(inputString, totalLength) {
    // Convert the input to a string in case it's a number or other type
    let str = String(inputString)

    // Calculate the number of zeros needed
    let zerosNeeded = totalLength - str.length

    // If no zeros are needed or the string is already longer, return the original string
    if (zerosNeeded <= 0) {
        return str
    }

    // Create a string of zeros
    let zeros = "0".repeat(zerosNeeded)

    // Concatenate the zeros and the original string
    return zeros + str
}

function formatIndianDate(date = new Date()) {
    const [yyyy, mm, dd] = new Date(date).toISOString().slice(0, 10).split("-")
    return `${dd}/${mm}/${yyyy}`
}

function daysToMs(days) {
    return days * 24 * 60 * 60 * 1000
}

function getMonthName(num = 1) {
    // num 1 (jan) to 12 (dec)
    return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][
        num - 1
    ]
}

// Function to convert UTC time to IST
function convertUTCToIST(utcTimeString) {
    // Create a Date object from the UTC time string
    const utcDate = new Date(`1970-01-01T${utcTimeString}Z`)

    // Use the Intl.DateTimeFormat object to format the date in IST
    const istFormatter = new Intl.DateTimeFormat("en-IN", {
        timeZone: "Asia/Kolkata",
        hour12: false,
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
    })

    // Format the date in IST
    const istTime = istFormatter.format(utcDate)

    return istTime
}

function convertTimeToTimezone(utcTime, timezone = "") {
    // Default to IST if not set in environment
    // Parse the given UTC time
    const [hours, minutes, seconds] = utcTime.split(":").map(Number)

    // Create a new date object with today's date and the given UTC time
    const date = new Date()
    date.setHours(hours, minutes, seconds)

    // Convert the date to the specified timezone
    // use this function for removed timezone for now if its already stored in indian time in db
    const options = { hour12: true, hour: "2-digit", minute: "2-digit" }
    const formattedTime = date.toLocaleString("en-US", options)

    return formattedTime
}

function convertAmPmToTimestamp(formattedTime) {
    // format: hh:mm ampm

    if (!formattedTime || !formattedTime.length) {
        return
    }

    const [hhMM, amPm] = formattedTime.split(" ")
    let [hh, mm] = hhMM.split(":")

    if (amPm.toLowerCase() == "pm" && hh < 12) {
        hh = hh + 12
    }

    // create timestamp with a random date
    // used for sorting
    return new Date(1999, 1, 1, hh, mm).getTime()
}
function convertToAmPm(time24) {
    // Split the input time string into hours and minutes
    var [hours, minutes] = time24.split(":").map(Number)

    // Determine AM or PM
    var period = hours >= 12 ? "PM" : "AM"

    // Convert to 12-hour format using modulus operator
    hours = hours % 12 || 12

    // Add leading zero to minutes if needed
    minutes = minutes < 10 ? "0" + minutes : minutes

    // Create the formatted time string
    var amPmTime = hours + ":" + minutes + " " + period

    return amPmTime
}

function getPasswordError(password) {
    if (password.includes(" ")) return "Password cannot have spaces!"

    const hasMin1Num = password && /\d/.test(password)
    const hasMin1SpecialChar = password && /[^a-zA-Z0-9 ]/.test(password)
    const hasMin8Chars = password && password.length > 8

    if (!(hasMin1Num && hasMin1SpecialChar && hasMin8Chars)) {
        return "Password length must be 8 characters with minimum 1 number and 1 special character"
    }
    return ""
}

function getAssetUrlPath(path) {
    let prefix = "/"
    if (path.startsWith("/")) {
        prefix = ""
    }
    return `${serverURL}${prefix}${path}`
}

const FILTER_TYPES = {
    PROJECT: { type: "project", fieldType: "select", title: "Project", multiple: true },
    STATUS: { type: "status", fieldType: "select", title: "Status", multiple: true },
    PRIORITY: { type: "priority", fieldType: "select", title: "Priority", multiple: true },
    DEPARTMENT: { type: "department", fieldType: "select", title: "Department", multiple: true },
    REPORTER: { type: "reporter", fieldType: "select", title: "Creator", multiple: true },
    // MOM: { type: "mom", fieldType: "select", title: "MOM", multiple: true },
    ASSIGNEE: { type: "assignee", fieldType: "select", title: "Assignee", multiple: true },
    START_DATE: { type: "startDate", fieldType: "date", title: "Start Date" },
    END_DATE: { type: "endDate", fieldType: "date", title: "Target Date" },
}
function hasIssuePermission(userId, assigneeUserIds, reporterId) {
    // console.log("has issue permission result", assigneeUserIds, userId, reporterId)

    console.log("check assignee user ids are", assigneeUserIds)

    let isUserAssignedForIssue = false
    if (userId == reporterId) {
        isUserAssignedForIssue = true
    } else {
        assigneeUserIds.map((item) => {
            if ((item._id && item._id == userId) || item == userId) {
                isUserAssignedForIssue = true
            }
        })
    }
    return isUserAssignedForIssue
}
function humanReadableDate(date) {
    const days = [
        "1st",
        "2nd",
        "3rd",
        "4th",
        "5th",
        "6th",
        "7th",
        "8th",
        "9th",
        "10th",
        "11th",
        "12th",
        "13th",
        "14th",
        "15th",
        "16th",
        "17th",
        "18th",
        "19th",
        "20th",
        "21st",
        "22nd",
        "23rd",
        "24th",
        "25th",
        "26th",
        "27th",
        "28th",
        "29th",
        "30th",
        "31st",
    ]

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ]

    const day = days[date.getDate() - 1]
    const month = months[date.getMonth()]
    const year = date.getFullYear()

    return `${day} ${month} ${year}`
}

export {
    getAssetUrlPath,
    createField,
    validateEmail,
    validatePhone,
    createColumn,
    createFormData,
    durify,
    isMobileNumber,
    isValidEmail,
    formatIsoDate,
    daysToMs,
    formatIndianDate,
    errMsg,
    round2,
    getMonthName,
    convertToAmPm,
    convertUTCToIST,
    getPasswordError,
    FILTER_TYPES,
    hasIssuePermission,
    convertTimeToTimezone,
    stripText,
    humanReadableDate,
    onlyAlphabetic,
    isoToIndianDate,
    indianToIsoDate,
    convertAmPmToTimestamp,
}
