<template>
    <div>
        <router-link to="/bank-masters" class="mb-3">
            <Button class="me-2"> <i class="fas fa-chevron-left"></i> Back to Bank Masters </Button>
        </router-link>
        <div class="my-3">
            <strong>{{ editMode ? "Edit" : "Create" }} Bank Master</strong>
        </div>
        <div></div>
        <div>
            <CustomInput
                style="max-width: 300px"
                placeholder="Enter name"
                v-model="name"
                label="Bank Name:"
                type="text"
            />
            <div class="my-3">Column Orders:</div>
            <div class="my-3 d-flex flex-wrap" style="gap: 10px">
                <div
                    style="min-width: 200px"
                    v-for="stllpColumn of stllpColumns"
                    :key="stllpColumn.id"
                >
                    <div class="card h-100 p-3">
                        <div class="mb-2">
                            {{ stllpColumn.name }}
                        </div>
                        <div class="d-flex" style="gap: 10px">
                            <button @click="moveLeft(stllpColumn)" class="move-nav-btn">
                                <i class="fas fa-chevron-left"></i>
                            </button>
                            <div>{{ stllpColumn.orderNum }}</div>
                            <button @click="moveRight(stllpColumn)" class="move-nav-btn">
                                <i class="fas fa-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Button @click="$router.push('/bank-masters')">Cancel</Button>
            <Button class="mx-2" @click="handleCreateUpdate">{{
                processing ? "Please wait..." : editMode ? "Update" : "Create"
            }}</Button>
        </div>
    </div>
</template>

<script>
import Button from "../../components/UI/Button.vue"
import CrudIndex from "../../components/crud/index.vue"
import CustomInput from "../../components/crud/custom-input.vue"
import { http } from "../../utils/http"

export default {
    data() {
        return {
            name: "",
            editMode: false,
            editId: "",
            processing: false,
            stllpColumns: [],
        }
    },
    components: { Button, CrudIndex, CustomInput },
    async mounted() {
        if (this.$route.params.id) {
            this.editMode = true
            this.editId = this.$route.params.id
            await this.fetchBankMaster()
        }
        await this.fetchBankMasterStllpColumns()
    },
    methods: {
        moveLeft(stllpColumn) {
            this.stllpColumns = this.stllpColumns.map((item) => {
                if (stllpColumn.id == item.id) {
                    item.orderNum = item.orderNum - 1
                    return item
                }
                // update existing
                if (item.orderNum == stllpColumn.orderNum - 1 && stllpColumn.id !== item.id) {
                    item.orderNum = item.orderNum + 1
                    return item
                }
                return item
            })
            this.refreshIndexes()
        },
        moveRight(stllpColumn) {
            console.log("more right", stllpColumn)
            this.stllpColumns = this.stllpColumns.map((item) => {
                console.log("item", item)
                if (stllpColumn.id == item.id) {
                    console.log("found match 1 BF", item.orderNum)
                    item.orderNum = item.orderNum + 1
                    console.log("found match 1 AF", item.orderNum)
                    return item
                }
                // update existing
                if (item.orderNum == stllpColumn.orderNum && stllpColumn.id !== item.id) {
                    console.log("found match 2 BF", item.orderNum)
                    item.orderNum = item.orderNum - 1
                    console.log("found match 2 AF", item.orderNum)
                    return item
                }
                return item
            })
            this.refreshIndexes()
        },
        refreshIndexes() {
            this.stllpColumns = this.stllpColumns
                .sort((a, b) => (a.orderNum < b.orderNum ? -1 : 1))
                .map((item, index) => {
                    item.orderNum = index + 1
                    return item
                })
        },

        async fetchBankMasterStllpColumns() {
            try {
                const res = await http.get(
                    `/bank-master-stllp-column-orders?bank_master_id=${this.editId || ""}`
                )
                this.stllpColumns = res.data.result
            } catch (err) {
                console.log("error in fetching bank master stllp columns", err)
            }
        },
        async fetchBankMaster() {
            try {
                const res = await http.get(`/bank-masters/${this.editId}`)
                const { name } = res.data.result
                this.name = name
            } catch (err) {
                console.log("error in fetching bank master", err)
            }
        },
        async handleCreateUpdate() {
            if (this.processing) this.processing = true
            if (!this.name) {
                return this.$toast.error("Bank Name is required")
            }
            try {
                if (this.editMode) {
                    await http.patch(`/bank-masters/${this.editId}`, {
                        name: this.name,
                        stllpColumns: this.stllpColumns,
                    })
                } else {
                    await http.post(`/bank-masters`, {
                        name: this.name,
                        stllpColumns: this.stllpColumns,
                    })
                }
                this.$toast.success(`${this.editMode ? "Updated" : "Created"} bank master`)
                this.$router.push("/bank-masters")
            } catch (err) {
                console.log("error in updating/creating bank master", err)
                this.$toast.error(`Could not ${this.editMode ? "update" : "create"}`)
            }
            this.processing = false
        },
    },
}
</script>

<style lang="scss" scoped>
.move-nav-btn {
    border: 1px solid #aaa;
    width: 25px;
    background: #eee;
}
</style>
