<template>
    <div>
        <IndexTemplate />
    </div>
</template>

<script>
import IndexTemplate from "./indexTemplate.vue"
export default {
    data() {
        return {}
    },
    components: { IndexTemplate },
}
</script>

<style></style>
