<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="Super Admins"
            :permission="PERMISSION_CODES.VIEW_USERS"
            :viewColumns="columns"
            :fetchUrl="`/users/only-user-roles?role=admin`"
            createUrl="/users?mode=superadmin"
            updateRootUrl="/users?mode=superadmin"
            deleteRootUrl="/users"
            :createFields="createFields"
            :reloadOnSuccess="true"
        />
    </div>
</template>

<script>
import { http } from "@/utils/http"
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"
import { PERMISSION_CODES } from "../../store/permission"

export default {
    data() {
        return {
            PERMISSION_CODES,
            loading: true,
            videos: [],
            createFields: [],
            userRoles: [],
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Username", "username", "string"),
                createColumn("Email", "email", "string"),
                createColumn("Role", "role", "string"),
                // createColumn("Status", "status", "string"),
            ],
        }
    },
    props: { videoId: { type: String, default: "" } },
    components: { CrudIndex },
    async mounted() {
        this.loading = false
        await this.fetchRoles()
        this.recreateFields()
    },
    methods: {
        async fetchRoles() {
            try {
                const res = await http.get("/user-roles")
                this.userRoles = res.data.result.filter((item) =>
                    item.name.toLowerCase().includes("employee")
                )
            } catch (err) {}
        },
        recreateFields() {
            this.createFields = [
                // createField("First name*", "firstName", "text", true),
                // createField("Last name*", "lastName", "text", true),
                createField("Username*", "username", "text", true),
                createField("Email*", "email", "text", true),
            ]

            this.createFields = [
                ...this.createFields,
                // createField(
                //     "Select Role*",
                //     "roleId",
                //     "select",
                //     true,
                //     "",
                //     this.userRoles.map((item) => ({
                //         id: item.id,
                //         title: `${item.name || ""}`,
                //     }))
                // ),
                createField("Password(Leave empty for old)", "password", "text", false),
            ]
        },
    },
}
</script>

<style></style>
