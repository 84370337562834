<template>
    <div>
        <CrudIndex
            v-if="!loading"
            :permission="PERMISSION_CODES.VIEW_PAYHEADS"
            title="Payheads"
            :viewColumns="columns"
            fetchUrl="/payheads"
            createUrl="/payheads"
            updateRootUrl="/payheads"
            :createFields="createFields"
            :reloadOnSuccess="true"
            :editingFormatter="editingFormatter"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { createField, createColumn } from "../../utils/common"
import { http } from "@/utils/http"
import { PERMISSION_CODES } from "../../store/permission"

export default {
    data() {
        return {
            PERMISSION_CODES,
            payheadMasters: {},
            loading: true,
            createFields: [
                createField("Name*", "name", "text", true),
                createField("Form Title", "placeholderTitle", "text", false),
                createField("Default Flat Amount", "defaultAmount", "number", false),
                createField(
                    "Or Default Percentage (of basic salary)",
                    "defaultPercentage",
                    "number",
                    false
                ),
                createField("Only Apply Above Amount", "onlyApplyAboveAmount", "number", false),
            ],
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Name", "name", "string"),
                createColumn("Payhead Type", "payroll_payhead_type.name", "string"),
                createColumn("Expense Type", "payroll_expense_type.name", "string"),
            ],
        }
    },
    components: { CrudIndex },
    async mounted() {
        await this.fetchPayheadCreationMasters()
        this.createFields = this.createFields.concat([
            createField(
                "Payhead Type*",
                "payheadTypeId",
                "select",
                true,
                "",
                this.payheadMasters.payheadTypes.map((item) => ({
                    id: item.id,
                    title: item.name,
                }))
            ),
            createField(
                "Expense Type*",
                "expenseTypeId",
                "select",
                true,
                "",
                this.payheadMasters.expenseTypes.map((item) => ({
                    id: item.id,
                    title: item.name,
                }))
            ),

            createField("Only Allow Flat Rate", "onlyAllowFlatRate", "switch", false, false),
            createField("Is Basic Salary", "isBasicSalary", "switch", false, false),
            createField("Is Leave Payhead", "isLeavePayhead", "switch", false, false),
            createField("Is Overtime Payhead", "isOvertimePayhead", "switch", false, false),
            createField(
                "Disable Company and Employee Edits",
                "disableCompanyAndEmployeeEdits",
                "switch",
                false,
                false
            ),
        ])
        this.loading = false
    },
    methods: {
        editingFormatter(item) {
            return item
            // return {
            //     ...item,
            //     questionId: item.questionId.id,
            //     quizQuestionMasterId: item.quizQuestionMasterId.id,
            // }
        },
        async fetchPayheadCreationMasters() {
            try {
                const res = await http.get("/payheads/masters")
                this.payheadMasters = res.data.result
            } catch (err) {
                console.log("error in fetching communication groups", err)
            }
        },
    },
}
</script>

<style></style>
