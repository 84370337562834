<template>
    <PageCard title="Create New Payroll">
        <div>
            <div v-if="!initialized">
                <form @submit.prevent="fetchEmployeesAndSalaryDetails" style="max-width: 600px">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="mt-2">
                                <p class="my-2">Year*</p>
                                <div>
                                    <SelectDropdown
                                        v-if="yearOptions.length"
                                        :id="`form-year`"
                                        :options="yearOptions"
                                        :value="selectedYear"
                                        @change="selectedYear = $event"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="mt-2">
                                <p class="my-2">Month*</p>
                                <div>
                                    <SelectDropdown
                                        v-if="monthOptions.length"
                                        :id="`form-month`"
                                        :options="monthOptions"
                                        :value="selectedMonth"
                                        @change="selectedMonth = $event"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2">
                        <p class="my-2">Company*</p>
                        <div>
                            <SelectDropdown
                                :id="`form-company`"
                                v-if="companies.length"
                                :options="
                                    companies.map((item) => ({ id: item.id, title: item.name }))
                                "
                                :value="selectedCompany"
                                @change="selectedCompany = $event"
                            />
                        </div>
                    </div>
                    <div class="mt-2">
                        <p class="my-2">Departments:<span v-if="!selectAllDepartments">*</span></p>
                        <div>
                            <SelectDropdown
                                :id="`form-departments`"
                                v-if="departments.length"
                                :multiple="true"
                                :options="
                                    departments.map((item) => ({ id: item.id, title: item.name }))
                                "
                                :value="selectedDepartments"
                                @change="selectedDepartments = $event"
                            />
                        </div>
                    </div>
                    <div class="mt-3">
                        <label>
                            <input v-model="selectAllDepartments" type="checkbox" /> Select All
                            (Even employees without departments)
                        </label>
                    </div>
                    <Button>{{ fetchingEmployees ? "Please wait..." : "Next" }}</Button>
                </form>
            </div>
            <div v-else>
                <div class="mt-2">
                    <div>Company: {{ selectedCompanyName }}</div>
                    <div>Year: {{ selectedYear }}</div>
                    <div>Month: {{ selectedMonthTitle }}</div>
                </div>
                <div>
                    <Button class="me-2" @click="initialized = false">
                        <i class="fas fa-chevron-left"></i> Change Company
                    </Button>
                    <Button @click="generatePaySlips">{{
                        generatingPaySlips ? "Please wait..." : "Generate Pay Slips"
                    }}</Button>
                </div>
                <router-link
                    target="_blank"
                    style="text-decoration: underline"
                    :to="`/companies/${selectedCompany}?active_tab=PAYROLL`"
                >
                    <i class="fas fa-edit"> </i> <strong>Edit Company Level Defaults</strong>
                </router-link>
                <p class="text-secondary">
                    Note: Reload the page if company level or employee level payroll settings were
                    changed after loading this page!
                </p>
                <CreatePayrollFormList
                    ref="payrollForm"
                    :employeePayrollData="employeePayrollData"
                />
                <Button @click="generatePaySlips">{{
                    generatingPaySlips ? "Please wait..." : "Generate Pay Slips"
                }}</Button>
            </div>
        </div>
    </PageCard>
</template>

<script>
import CustomInput from "../../components/crud/custom-input.vue"
import SelectDropdown from "../../components/crud/SelectDropdown.vue"
import PageCard from "../../components/layout/PageCard.vue"
import CreatePayrollFormList from "../../components/payroll/CreatePayrollFormList.vue"
import Button from "../../components/UI/Button.vue"
import { errMsg } from "../../utils/common"
import { http } from "../../utils/http"

export default {
    components: { PageCard, Button, CustomInput, SelectDropdown, CreatePayrollFormList },
    data() {
        return {
            initialized: false,
            startDate: null,
            endDate: null,
            companies: [],
            selectAllDepartments: false,
            departments: [],
            yearOptions: [],
            monthOptions: [
                { id: 1, title: "Jan" },
                { id: 2, title: "Feb" },
                { id: 3, title: "Mar" },
                { id: 4, title: "Apr" },
                { id: 5, title: "May" },
                { id: 6, title: "Jun" },
                { id: 7, title: "Jul" },
                { id: 8, title: "Aug" },
                { id: 9, title: "Sep" },
                { id: 10, title: "Oct" },
                { id: 11, title: "Nov" },
                { id: 12, title: "Dec" },
            ],
            selectedYear: new Date().getFullYear(),
            selectedMonth: new Date().getMonth() + 1,
            selectedCompany: null,
            selectedDepartments: null,
            fetchingEmployees: false,
            generatingPaySlips: false,
            payslipsGenerated: false,
            employeePayrollData: null,
        }
    },
    mounted() {
        this.initDates()
        this.fetchCompanies()
        this.fetchDepartments()
    },
    computed: {
        selectedCompanyName() {
            return this.companies.find((item) => item.id == this.selectedCompany)?.name
        },
        selectedMonthTitle() {
            return this.monthOptions.find((item) => item.id == this.selectedMonth)?.title
        },
    },
    methods: {
        updateDates() {
            this.startDate = new Date(this.selectedYear, this.selectedMonth - 1, 1)
            this.endDate = new Date(this.selectedYear, this.selectedMonth, 0)
        },
        initDates() {
            // init dates.
            const currentYear = new Date().getFullYear()
            this.yearOptions = [
                currentYear - 4,
                currentYear - 3,
                currentYear - 2,
                currentYear - 1,
                currentYear,
                currentYear + 1,
            ].map((item) => ({ id: item, title: item }))
            this.updateDates()
        },
        async fetchCompanies() {
            const res = await http.get("/companies?for_select=true")
            this.companies = res.data.result
        },
        async fetchDepartments() {
            const res = await http.get("/departments?for_select=true")
            this.departments = res.data.result
        },
        async fetchEmployeesAndSalaryDetails() {
            if (
                !this.selectedYear ||
                !this.selectedMonth ||
                !this.selectedCompany ||
                (!this.selectAllDepartments && !this.selectedDepartments)
            ) {
                return this.$toast.error("Please fill mandatory fields!")
            }
            this.fetchingEmployees = true
            try {
                const res = await http.get(
                    `/payroll-records/creation-data?company=${this.selectedCompany}&year=${
                        this.selectedYear
                    }&month=${Number(this.selectedMonth)}&department=${
                        this.selectedDepartments
                    }&select_all_departments=${this.selectAllDepartments ? 1 : 0}`
                )
                this.employeePayrollData = res.data.result
                this.initialized = true
            } catch (err) {
                console.log("error in fetching employee details", err)
                this.$toast.error(errMsg(err, "Could not fetch data!"))
            }
            this.fetchingEmployees = false
        },

        async generatePaySlips() {
            const payrollPaymentSlips = this.$refs.payrollForm.getData()
            console.log("payrollPaymentSlips", payrollPaymentSlips)
            if (!payrollPaymentSlips.length) {
                return this.$toast.error("No data to generate payroll payment slip!")
            }
            this.generatingPaySlips = true
            try {
                const res = await http.post("/payroll-records", {
                    payrollData: {
                        companyId: this.selectedCompany,
                        startDate: this.startDate,
                        endDate: this.endDate,
                        month: this.selectedMonth,
                        year: this.selectedYear,
                    },
                    payrollPaymentSlips,
                })
                this.$toast.success("Payroll generated!")
                this.payslipsGenerated = true
                this.$router.push("/payroll-history")
            } catch (err) {
                console.log("error in fetching employee details", err)
                this.$toast.error(errMsg(err, "Could not create payroll!"))
            }
            this.generatingPaySlips = false
        },
    },
    watch: {
        selectedYear(e) {
            this.updateDates()
        },
        selectedMonth(e) {
            this.updateDates()
        },
    },
}
</script>

<style></style>
