<template>
    <div>
        <AllowPermission :permission="PERMISSION_CODES.CREATE_WORK_LOCATIONS">
            <Button @click="navigateToCreateEmployee"
                ><i class="fas fa-plus"></i> Create Work Location
            </Button>
        </AllowPermission>
        <CrudIndex
            v-if="!loading"
            title="Work Locations"
            :permission="PERMISSION_CODES.VIEW_WORK_LOCATIONS"
            :viewColumns="columns"
            updateRootUrl="/work-locations"
            :fetchUrl="`/work-locations`"
            editRouteName="work-location-edit"
            deleteRootUrl="/work-locations"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import { http } from "@/utils/http"
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"
import Button from "../../components/UI/Button.vue"
import { PERMISSION_CODES } from "../../store/permission"
import AllowPermission from "../../components/layout/AllowPermission.vue"

export default {
    data() {
        return {
            PERMISSION_CODES,
            loading: true,
            workLocationTypes: [],
            createFields: [],
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Name", "name", "string"),
                createColumn("Country", "country_data.name", "string"),
                createColumn("City", "city_data.name", "string"),
                // createColumn("State", "state_data.name", "string"),
                createColumn("Zip Code", "zipCode", "string"),
                // createColumn("Latitude", "latitude", "string"),
                // createColumn("Longitude", "longitude", "string"),
                // createColumn("Status", "status", "string"),
            ],
        }
    },
    components: { AllowPermission, CrudIndex, Button },
    async mounted() {
        await this.fetchWorkLocationTypes()

        this.createFields = this.createFields.concat([
            createField("Name*", "name", "text", true),
            createField("Address*", "address", "text", true),
            createField("City*", "city", "text", true),
            createField("State*", "state", "text", true),
            createField("Zip Code*", "zipCode", "text", true),
            createField("Latitude", "latitude", "text", false),
            createField("Longitude", "longitude", "text", false),
            createField(
                "Work Location Type*",
                "workLocationTypeId",
                "select",
                true,
                "",
                this.workLocationTypes.map((item) => ({
                    id: item.id,
                    title: item.name,
                }))
            ),
            // createField("Status", "status", "switch", false, true),
        ])
        this.loading = false
    },
    methods: {
        async fetchWorkLocationTypes() {
            const res = await http.get("/work-location-types")
            this.workLocationTypes = res.data.result
        },
        navigateToCreateEmployee() {
            this.$router.push("/work-locations/create")
        },
    },
}
</script>

<style></style>
