<template>
    <div>
        <div class="relative">
            <select
                v-model="selectedProject"
                @change="getTasksByProject"
                class="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
                <option value="">Select Project</option>
                <option
                    v-for="project in projects"
                    :key="project.id"
                    :value="project.id"
                    class="text-gray-700"
                >
                    {{ project.Project && project.Project.name }}
                </option>
            </select>
            <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
                <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                >
                    <path d="M10 12l-6-6 1.414-1.414L10 9.172l4.586-4.586L16 6z" />
                </svg>
            </div>
        </div>
        <div class="chart-container">
            <canvas id="taskProgressChart"></canvas>
        </div>
    </div>
</template>

<script>
import { http } from "@/utils/http"
import Chart from "chart.js/auto"

export default {
    data() {
        return {
            projects: [],
            selectedProject: null,
            tasks: [],
            chart: null, // Add a property to hold the chart instance
        }
    },
    mounted() {
        this.getProjects()
    },
    methods: {
        async getProjects() {
            try {
                const response = await http.get("/project-tasks")
                this.projects = response.data.result
                this.selectedProject = this.projects.length > 0 ? "" : null
                this.getTasksByProject()
            } catch (error) {
                console.error("Error fetching projects:", error)
            }
        },
        async getTasksByProject() {
            try {
                const response = await http.get(`/project-tasks/${this.selectedProject}`)
                let subtasksForSelectedProject = response.data.result
                // Check if subtasksForSelectedProject is an object and not an array
                if (!Array.isArray(subtasksForSelectedProject)) {
                    // Wrap the object in an array
                    subtasksForSelectedProject = [subtasksForSelectedProject]
                }
                this.tasks = subtasksForSelectedProject
                this.renderTaskProgressChart(subtasksForSelectedProject)
            } catch (error) {
                console.error("Error fetching project tasks:", error)
            }
        },
        calculateSubtaskCompletionStatus(subtask) {
            const statusName = subtask.Status && subtask.Status.name
            if (statusName === "Pending") {
                return 0.5 // Half complete
            } else if (statusName === "In Progress") {
                return 0.9 // 90% complete
            } else if (statusName === "Complete") {
                return 1 // Fully complete
            }
        },
        renderTaskProgressChart(subtasksForSelectedProject) {
            const ctx = document.getElementById("taskProgressChart").getContext("2d")
            if (this.chart) {
                this.chart.destroy() // Clear previous chart instance
            }

            const labels = subtasksForSelectedProject.map((subtask) => subtask.name)
            const data = subtasksForSelectedProject.map((subtask) =>
                this.calculateSubtaskCompletionStatus(subtask)
            )
            const backgroundColors = subtasksForSelectedProject.map((subtask) =>
                subtask.Status ? subtask.Status.color : "#333"
            ) // Use status color for bar color

            this.chart = new Chart(ctx, {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: "Subtask Status",
                            data: data,
                            backgroundColor: backgroundColors,
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    plugins: {
                        // Add any additional configuration for the chart plugins
                    },
                    scales: {
                        y: {
                            beginAtZero: true,
                            suggestedMax: 1, // Max completion status is 1
                        },
                        x: {
                            barPercentage: 0.3, // Adjust the width of the bars
                            categoryPercentage: 0.4, // Adjust the spacing between bars
                        },
                    },
                    layout: {
                        padding: {
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        },
                    },
                },
            })
        },
    },
}
</script>

<style scoped>
.chart-container {
    width: 100%;
    height: 400px;
    margin: 0 auto;
}

#taskProgressChart {
    width: 100% !important;
    height: 100% !important;
}
</style>
