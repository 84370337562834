<template>
    <div class="mb-3 shadow-sm task-filters" v-show="showTaskFilters">
        <div class="row" v-if="Object.values(filterValues).length">
            <div
                class="col-3 gy-2"
                v-for="filterValue of Object.values(filterValues)"
                :key="filterValue.type.type"
            >
                <div>{{ filterValue.type.title }}</div>
                <SelectDropdown
                    v-if="filterValue.type.fieldType == 'select'"
                    :id="`project-filter-${filterValue.type.type}`"
                    :options="filterValue.options"
                    :multiple="filterValue.type.multiple"
                    :field="filterValue.type.type"
                    :value="filterValue.selectedOptions"
                    @change="updateFormData(filterValue.type, $event)"
                />
                <div v-if="filterValue.type.fieldType == 'date'">
                    <div class="mt-1">
                        <input
                            type="date"
                            class="pt-2 form-control form-control-sm"
                            :value="filterValue.selectedOptions"
                            @input="updateFormData(filterValue.type, $event.target.value)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-else>Loading Filters...</div>
        <div class="my-3">
            <Button @click="fetchData">{{ fetchingTasks ? "Please wait..." : "Load Data" }}</Button>
        </div>
    </div>
</template>

<script>
import { FILTER_TYPES } from "../../utils/common"
import { mapState, mapActions } from "vuex"
import SelectDropdown from "../crud/SelectDropdown.vue"
import Button from "../UI/Button.vue"

export default {
    data() {
        return { FILTER_TYPES, showTaskFilters: false, showTimeout: null }
    },
    components: { Button, SelectDropdown },
    computed: {
        ...mapState("project", ["filterValues", "allProjects", "activeProject", "fetchingTasks"]),
    },
    mounted() {
        this.initTasks()
        this.setShowFilter(true)
    },
    methods: {
        ...mapActions("project", ["initTasks", "fetchTasks", "updateFilters"]),
        fetchData() {
            this.fetchTasks()
        },
        setShowFilter(val) {
            clearTimeout(this.showTimeout)
            this.showTimeout = setTimeout(() => {
                // show after some delay to avoid glitch select ui for a moment
                this.showTaskFilters = val
            }, 1000)
        },
        updateFormData(filterType, value) {
            console.log("update form data running", filterType, value)
            this.updateFilters({
                type: filterType,
                value,
            })
        },
    },
    watch: {
        filterValues(e) {
            if (e && e.length) {
                this.setShowFilter(true)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.task-filters {
    padding: 15px;
    border-radius: 15px;
    // background-color: #f7f7f7;
    border: 2px solid #eee;
}
</style>
