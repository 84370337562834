<template>
    <div class="d-flex flex-wrap my-3" style="gap: 15px">
        <div class="color-wrapper" v-for="label of labels" :key="label.color">
            <div class="color-box" :style="`background: ${label.color}`"></div>
            {{ label.title }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        labels: {
            type: Array,
            default: [],
        },
    },
}
</script>

<style lang="scss" scoped>
.color-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
}
.color-box {
    width: 10px;
    height: 10px;
}
</style>
