<template>
    <div class="text-black font-bold">
        Edit User
        <Card>
            <form class="my-3" @submit.prevent="updateUser">
                <div class="row">
                    <div class="col-6">
                        <div class="my-2">First name*</div>
                        <div>
                            <input
                                required
                                disabled
                                v-model="formatUser(user).firstname"
                                type="text"
                                class="form-control"
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="my-2">Last name*</div>
                        <div>
                            <input
                                required
                                disabled
                                v-model="formatUser(user).lastname"
                                type="text"
                                class="form-control"
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="my-2">Email*</div>
                        <div>
                            <input
                                required
                                disabled
                                v-model="formatUser(user).email"
                                type="email"
                                class="form-control"
                            />
                        </div>
                    </div>
                    <!-- <div class="col-6">
                        <div class="my-2">Password</div>
                        <div>
                            <input
                                v-model="user.password"
                                type="password"
                                class="form-control"
                                disabled
                            />
                        </div>
                    </div> -->
                    <div class="col-6">
                        <div class="mb-2">Mobile</div>
                        <div>
                            <input
                                required
                                disabled
                                v-model="formatUser(user).callingnumber"
                                type="text"
                                class="form-control"
                            />
                        </div>
                    </div>
                    <!-- <div class="col-6">
                        <div class="mb-2">Address</div>
                        <div>
                            <input
                                required
                                v-model="user.address"
                                type="text"
                                class="form-control"
                            />
                        </div>
                    </div> -->
                    <!-- <div class="col-6">
                        <div class="my-2">Update Image</div>
                        <img v-if="user.profilePic" :src="serverURL + user.profilePic" />
                        <input
                            type="file"
                            class="form-control"
                            name="image"
                            @change="updateImage"
                            accept="image/*"
                        />
                    </div> -->
                    <div class="col-6">
                        <div class="mb-2">Role</div>
                        <SelectDropdown
                            :id="`form-role`"
                            v-if="roleLoaded"
                            :options="dropdownRoles"
                            :value="selectedRole"
                            @change="selectedRole = $event"
                        />
                    </div>
                    <div class="col-6">
                        <div class="mb-2">Status</div>
                        <div class="form-check form-switch">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                role="switch"
                                :id="`form-status`"
                                v-model="status"
                            />
                            <label class="form-check-label" :for="`form-status`"> Active </label>
                        </div>
                    </div>

                    <!-- <div class="col-6" style="min-height: 200px">
                        <div class="mb-2">Interested Subject</div>
                        <SelectDropdown
                            :id="`form-subject`"
                            :multiple="true"
                            :options="subjects"
                            :value="user.interestedSubjectIds"
                            @change="user.interestedSubjectIds = $event"
                        />
                    </div> -->
                </div>

                <Button
                    class="btn btn-success bg-green-500 my-3 mt-4 border-none"
                    style="min-width: 200px"
                    type="submit"
                >
                    {{ updatingUser ? "Please wait..." : "Update" }}
                </Button>
            </form>
        </Card>
    </div>
</template>

<script>
import { http, serverURL } from "../../utils/http"
import Card from "../../components/UI/Card.vue"
import SelectDropdown from "../../components/crud/SelectDropdown"

export default {
    components: { Card, SelectDropdown },
    data() {
        return {
            user: {},
            serverURL,
            status: false,
            name: "",
            interestedSubjectIds: [],
            roles: [],
            subjects: [],
            updatingUser: false,
            selectedRole: "",
            roleLoaded: false,
        }
    },
    async mounted() {
        try {
            const res = await http.get("/users/" + this.$route.params.id)
            const res2 = await http.get("/user-roles")
            const res3 = await http.get("/subjects")

            this.user = res.data.result
            this.roles = res2.data.result
            this.subjects = res3.data.result
            this.status = this.user.status
            this.selectedRole = this.user.roleId.toString()
            this.roleLoaded = true
        } catch (err) {
            console.log("error in loading user", err)
        }
    },
    methods: {
        updateImage(e) {
            this.user.profilePic = e.target.files[0]
        },
        async updateUser() {
            console.log("updating user", this.user)
            this.updatingUser = true

            try {
                const formData = new FormData()
                formData.append("firstName", this.user.firstName || "")
                formData.append("lastName", this.user.lastName || "")
                formData.append("email", this.user.email || "")
                formData.append("mobile", this.user.mobile || "")
                formData.append("address", this.user.address || "")

                formData.append("roleId", this.selectedRole || "")
                formData.append("status", this.status || false)
                formData.append("interestedSubjectIds", this.user.interestedSubjectIds || "")
                formData.append(
                    "interestedVideoDurationInMins",
                    this.user.interestedVideoDurationInMins || ""
                )
                formData.append("profilePic", this.user.profilePic || "")
                formData.append("password", this.user.password || "")

                await http.patch("/users/" + this.$route.params.id, formData)

                this.$toast.success("User updated Successfully")

                window.location.href = "/users"

                // window.location.href = "/users";
            } catch (err) {
                console.log("error in Updating User", err)
                this.$toast.error("Some error occured")
            }
            this.updatingUser = false
        },
        formatUser(user) {
            const result = { ...user, ...JSON.parse(user.apiUserDataJson || "{}") }
            console.log("format user result", result)
            return result
        },
    },
    computed: {
        dropdownRoles() {
            const rolesFormatted = this.roles.map((item) => ({
                id: item.id,
                id: item.id,
                title: item.name,
            }))
            console.log("roles formatted", rolesFormatted, this.user.roleId)
            return rolesFormatted
        },
    },
}
</script>

<style></style>
