import { http } from "@/utils/http"

const PERMISSION_CODES = {
    CREATE_EMPLOYEES: "CREATE_EMPLOYEES",
    VIEW_EMPLOYEES: "VIEW_EMPLOYEES",
    EDIT_EMPLOYEES: "EDIT_EMPLOYEES",
    DELETE_EMPLOYEES: "DELETE_EMPLOYEES",
    VIEW_NOTIFICATIONS: "VIEW_NOTIFICATIONS",
    CREATE_NOTICES: "CREATE_NOTICES",
    VIEW_NOTICES: "VIEW_NOTICES",
    EDIT_NOTICES: "EDIT_NOTICES",
    DELETE_NOTICES: "DELETE_NOTICES",
    CREATE_REPORTS: "CREATE_REPORTS",
    VIEW_REPORTS: "VIEW_REPORTS",
    EDIT_REPORTS: "EDIT_REPORTS",
    DELETE_REPORTS: "DELETE_REPORTS",
    VIEW_SETTINGS: "VIEW_SETTINGS",
    CREATE_COMPANIES: "CREATE_COMPANIES",
    VIEW_COMPANIES: "VIEW_COMPANIES",
    EDIT_COMPANIES: "EDIT_COMPANIES",
    DELETE_COMPANIES: "DELETE_COMPANIES",
    CREATE_DEPARTMENTS: "CREATE_DEPARTMENTS",
    VIEW_DEPARTMENTS: "VIEW_DEPARTMENTS",
    EDIT_DEPARTMENTS: "EDIT_DEPARTMENTS",
    DELETE_DEPARTMENTS: "DELETE_DEPARTMENTS",
    CREATE_DESIGNATIONS: "CREATE_DESIGNATIONS",
    VIEW_DESIGNATIONS: "VIEW_DESIGNATIONS",
    EDIT_DESIGNATIONS: "EDIT_DESIGNATIONS",
    DELETE_DESIGNATIONS: "DELETE_DESIGNATIONS",
    CREATE_WORK_LOCATIONS: "CREATE_WORK_LOCATIONS",
    VIEW_WORK_LOCATIONS: "VIEW_WORK_LOCATIONS",
    EDIT_WORK_LOCATIONS: "EDIT_WORK_LOCATIONS",
    DELETE_WORK_LOCATIONS: "DELETE_WORK_LOCATIONS",
    CREATE_WORK_LOCATION_TYPES: "CREATE_WORK_LOCATION_TYPES",
    VIEW_WORK_LOCATION_TYPES: "VIEW_WORK_LOCATION_TYPES",
    EDIT_WORK_LOCATION_TYPES: "EDIT_WORK_LOCATION_TYPES",
    DELETE_WORK_LOCATION_TYPES: "DELETE_WORK_LOCATION_TYPES",
    CREATE_EMPLOYMENT_STATUSES: "CREATE_EMPLOYMENT_STATUSES",
    VIEW_EMPLOYMENT_STATUSES: "VIEW_EMPLOYMENT_STATUSES",
    EDIT_EMPLOYMENT_STATUSES: "EDIT_EMPLOYMENT_STATUSES",
    DELETE_EMPLOYMENT_STATUSES: "DELETE_EMPLOYMENT_STATUSES",
    CREATE_MASTER_TEMPLATES: "CREATE_MASTER_TEMPLATES",
    VIEW_MASTER_TEMPLATES: "VIEW_MASTER_TEMPLATES",
    EDIT_MASTER_TEMPLATES: "EDIT_MASTER_TEMPLATES",
    DELETE_MASTER_TEMPLATES: "DELETE_MASTER_TEMPLATES",
    CREATE_BANK_MASTER: "CREATE_BANK_MASTER",
    VIEW_BANK_MASTER: "VIEW_BANK_MASTER",
    EDIT_BANK_MASTER: "EDIT_BANK_MASTER",
    DELETE_BANK_MASTER: "DELETE_BANK_MASTER",
    CREATE_CCTV_MASTER: "CREATE_CCTV_MASTER",
    VIEW_CCTV_MASTER: "VIEW_CCTV_MASTER",
    EDIT_CCTV_MASTER: "EDIT_CCTV_MASTER",
    DELETE_CCTV_MASTER: "DELETE_CCTV_MASTER",
    CREATE_PAYHEADS: "CREATE_PAYHEADS",
    VIEW_PAYHEADS: "VIEW_PAYHEADS",
    EDIT_PAYHEADS: "EDIT_PAYHEADS",
    DELETE_PAYHEADS: "DELETE_PAYHEADS",
    CREATE_PAYROLLS: "CREATE_PAYROLLS",
    VIEW_PAYROLLS: "VIEW_PAYROLLS",
    EDIT_PAYROLLS: "EDIT_PAYROLLS",
    DELETE_PAYROLLS: "DELETE_PAYROLLS",
    VIEW_PAYROLL_HISTORY: "VIEW_PAYROLL_HISTORY",
    VIEW_SALARY_UPDATE_HISTORY: "VIEW_SALARY_UPDATE_HISTORY",
    CREATE_ASSETS: "CREATE_ASSETS",
    VIEW_ASSETS: "VIEW_ASSETS",
    EDIT_ASSETS: "EDIT_ASSETS",
    DELETE_ASSETS: "DELETE_ASSETS",
    CREATE_ASSET_CATEGORIES: "CREATE_ASSET_CATEGORIES",
    VIEW_ASSET_CATEGORIES: "VIEW_ASSET_CATEGORIES",
    EDIT_ASSET_CATEGORIES: "EDIT_ASSET_CATEGORIES",
    DELETE_ASSET_CATEGORIES: "DELETE_ASSET_CATEGORIES",
    CREATE_ASSET_ALLOCATIONS: "CREATE_ASSET_ALLOCATIONS",
    VIEW_ASSET_ALLOCATIONS: "VIEW_ASSET_ALLOCATIONS",
    EDIT_ASSET_ALLOCATIONS: "EDIT_ASSET_ALLOCATIONS",
    DELETE_ASSET_ALLOCATIONS: "DELETE_ASSET_ALLOCATIONS",
    VIEW_ASSET_LOGS: "VIEW_ASSET_LOGS",
    CREATE_IMPORT_ATTENDANCE: "CREATE_IMPORT_ATTENDANCE",
    VIEW_IMPORT_ATTENDANCE: "VIEW_IMPORT_ATTENDANCE",
    EDIT_IMPORT_ATTENDANCE: "EDIT_IMPORT_ATTENDANCE",
    DELETE_IMPORT_ATTENDANCE: "DELETE_IMPORT_ATTENDANCE",
    CREATE_ATTENDANCE_RECORD: "CREATE_ATTENDANCE_RECORD",
    VIEW_ATTENDANCE_RECORD: "VIEW_ATTENDANCE_RECORD",
    EDIT_ATTENDANCE_RECORD: "EDIT_ATTENDANCE_RECORD",
    DELETE_ATTENDANCE_RECORD: "DELETE_ATTENDANCE_RECORD",
    CREATE_ATTENDANCE_STATUSES: "CREATE_ATTENDANCE_STATUSES",
    VIEW_ATTENDANCE_STATUSES: "VIEW_ATTENDANCE_STATUSES",
    EDIT_ATTENDANCE_STATUSES: "EDIT_ATTENDANCE_STATUSES",
    DELETE_ATTENDANCE_STATUSES: "DELETE_ATTENDANCE_STATUSES",
    CREATE_HOLIDAYS: "CREATE_HOLIDAYS",
    VIEW_HOLIDAYS: "VIEW_HOLIDAYS",
    EDIT_HOLIDAYS: "EDIT_HOLIDAYS",
    DELETE_HOLIDAYS: "DELETE_HOLIDAYS",
    CREATE_CHECK_INOUT_LOCATION_TRACKING: "CREATE_CHECK_INOUT_LOCATION_TRACKING",
    VIEW_CHECK_INOUT_LOCATION_TRACKING: "VIEW_CHECK_INOUT_LOCATION_TRACKING",
    EDIT_CHECK_INOUT_LOCATION_TRACKING: "EDIT_CHECK_INOUT_LOCATION_TRACKING",
    DELETE_CHECK_INOUT_LOCATION_TRACKING: "DELETE_CHECK_INOUT_LOCATION_TRACKING",
    CREATE_ATTENDANCE_SHIFTS: "CREATE_ATTENDANCE_SHIFTS",
    VIEW_ATTENDANCE_SHIFTS: "VIEW_ATTENDANCE_SHIFTS",
    EDIT_ATTENDANCE_SHIFTS: "EDIT_ATTENDANCE_SHIFTS",
    DELETE_ATTENDANCE_SHIFTS: "DELETE_ATTENDANCE_SHIFTS",
    CREATE_LEAVE_REQUESTS: "CREATE_LEAVE_REQUESTS",
    VIEW_LEAVE_REQUESTS: "VIEW_LEAVE_REQUESTS",
    EDIT_LEAVE_REQUESTS: "EDIT_LEAVE_REQUESTS",
    DELETE_LEAVE_REQUESTS: "DELETE_LEAVE_REQUESTS",
    CREATE_OVERTIME_REQUESTS: "CREATE_OVERTIME_REQUESTS",
    VIEW_OVERTIME_REQUESTS: "VIEW_OVERTIME_REQUESTS",
    EDIT_OVERTIME_REQUESTS: "EDIT_OVERTIME_REQUESTS",
    DELETE_OVERTIME_REQUESTS: "DELETE_OVERTIME_REQUESTS",
    CREATE_PROJECTS: "CREATE_PROJECTS",
    VIEW_PROJECTS: "VIEW_PROJECTS",
    EDIT_PROJECTS: "EDIT_PROJECTS",
    DELETE_PROJECTS: "DELETE_PROJECTS",
    CREATE_PROJECT_TASKS: "CREATE_PROJECT_TASKS",
    VIEW_PROJECT_TASKS: "VIEW_PROJECT_TASKS",
    EDIT_PROJECT_TASKS: "EDIT_PROJECT_TASKS",
    DELETE_PROJECT_TASKS: "DELETE_PROJECT_TASKS",
    CREATE_PROJECT_CATEGORIES: "CREATE_PROJECT_CATEGORIES",
    VIEW_PROJECT_CATEGORIES: "VIEW_PROJECT_CATEGORIES",
    EDIT_PROJECT_CATEGORIES: "EDIT_PROJECT_CATEGORIES",
    DELETE_PROJECT_CATEGORIES: "DELETE_PROJECT_CATEGORIES",
    CREATE_PROJECT_LABELS: "CREATE_PROJECT_LABELS",
    VIEW_PROJECT_LABELS: "VIEW_PROJECT_LABELS",
    EDIT_PROJECT_LABELS: "EDIT_PROJECT_LABELS",
    DELETE_PROJECT_LABELS: "DELETE_PROJECT_LABELS",
    VIEW_PROJECT_REPORTS: "VIEW_PROJECT_REPORTS",
    VIEW_PROJECT_COMPARISONS: "VIEW_PROJECT_COMPARISONS",
    VIEW_PROJECT_VISUALIZATIONS: "VIEW_PROJECT_VISUALIZATIONS",
    VIEW_PROJECT_PROJECT_GANTTCHART: "VIEW_PROJECT_PROJECT_GANTTCHART",
    VIEW_FILES: "VIEW_FILES",
    VIEW_COMMUNICATION_GROUPS: "VIEW_COMMUNICATION_GROUPS",
}

const state = {
    permissions: [],
    allowedPermissionIds: [],
    userRoles: [],
    permissionsGrouped: [],
}

const getters = {}

function checkHasPermission({ isAdmin, permissionId, userPermissionIds }) {
    // in actions its promise by default which causes issue
    if (isAdmin) {
        return true
    }
    const result = permissionId && userPermissionIds.includes(permissionId)
    return result
}

const mutations = {
    SET_DATA: (state, data) => {
        for (let key in data) {
            state[key] = data[key]
        }
    },
}

const actions = {
    setData(vuexContext, data) {
        vuexContext.commit("SET_DATA", data)
    },
    async initRoles({ state, dispatch }) {
        try {
            const res = await http.get(`/user-roles`)
            state.userRoles = res.data.result
        } catch (err) {
            console.log("error in loading roles", err)
        }
    },
    async initPermissions({ state, dispatch }, roleId) {
        try {
            const res = await http.get(`/permissions?role_id=${roleId}`)
            state.permissions = res.data.permissions
            state.allowedPermissionIds = res.data.result
            state.permissionsGrouped = res.data.permissionsGrouped
        } catch (err) {
            console.log("error in loading permissions", err)
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
export { PERMISSION_CODES, checkHasPermission }
