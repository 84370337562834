<template>
    <div>
        <AllowPermission :permission="PERMISSION_CODES.CREATE_HOLIDAYS">
            <Button @click="$router.push('/attendance-holidays/create')">
                <i class="fas fa-plus"></i>
                Create Hoilday
            </Button>
        </AllowPermission>
        <CrudIndex
            v-if="!loading"
            title="Attendance Holidays"
            :viewColumns="columns"
            fetchUrl="/attendance-holidays"
            :permission="PERMISSION_CODES.VIEW_HOLIDAYS"
            updateRootUrl="/attendance-holidays"
            deleteRootUrl="/attendance-holidays"
            editRouteName="attendance-holidays-edit"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import { http } from "@/utils/http"
import CrudIndex from "../../../components/crud/index.vue"
import { createField, createColumn } from "../../../utils/common"
import Button from "@/components/UI/Button.vue"
import { PERMISSION_CODES } from "../../../store/permission"
import AllowPermission from "../../../components/layout/AllowPermission.vue"

export default {
    components: { CrudIndex, Button, AllowPermission },

    data() {
        return {
            loading: true,
            holidayTypes: [],
            countries: [],
            states: [],
            createFields: [],
            PERMISSION_CODES,
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Name", "name", "string"),
                createColumn("Start Date", "startDateFormatted", "string"),
                createColumn("End Date", "endDateFormatted", "string"),
                createColumn("Country", "country.name", "string"),
                createColumn("Holiday Type", "holidayType.name", "string"),
                // createColumn("Status", "status", "string"),
            ],
        }
    },

    async mounted() {
        this.loading = true
        await this.fetchHolidayTypes()
        await this.fetchCountries()

        this.createFields = this.createFields.concat([
            createField("Name*", "name", "text", true),
            createField("Start Date*", "startDate", "date", true),
            createField("End Date*", "endDate", "date", true),
            createColumn("Description*", "description", "text", true),
            createField(
                "Country*",
                "countryId",
                "select",
                true,
                "",
                this.countries.map((item) => ({
                    id: item.id,
                    title: item.title,
                }))
            ),
            createField(
                "Holiday Type*",
                "holidayTypeId",
                "select",
                true,
                "",
                this.holidayTypes.map((item) => ({
                    id: item.id,
                    title: item.name,
                }))
            ),
            // createField("Status", "status", "switch", false, true),
        ])
        this.loading = false
    },
    methods: {
        async fetchHolidayTypes() {
            const res = await http.get("/attendance-holiday-types")
            this.holidayTypes = res.data.result
        },
        async fetchCountries() {
            let res = await http.get("/misc/countries")
            this.countries = res.data.result
            // res = await http.get("/misc/states")
            // this.states = res.data.result
        },
    },
}
</script>

<style></style>
