<template>
    <div>
        <div v-if="!showChart">Loading...</div>
        <apexchart v-if="showChart" type="donut" height="300" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import PageCard from "../layout/PageCard.vue"
import SectionTitle from "../UI/SectionTitle.vue"

export default {
    components: { PageCard, SectionTitle },
    data() {
        return {
            comparisonType: "daily",
            showChart: false,
            series: [],
            chartOptions: {
                chart: {
                    type: "donut",
                    height: 300,
                    zoom: {
                        enabled: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                // stroke: {
                //     curve: "straight",
                // },
                labels: null,
                colors: [],
                // title: {
                //     text: "Attendance Comparison Chart",
                //     align: "left",
                // },
                // subtitle: {
                //     text: "Price Movements",
                //     align: "left",
                // },
                legend: {
                    position: "bottom",
                },
            },
        }
    },
    computed: {
        ...mapState("misc", ["dashboardStats"]),
    },
    mounted() {
        this.loadChart()
    },
    methods: {
        ...mapActions("misc", [""]),
        loadChart() {
            let data = this.dashboardStats && this.dashboardStats.attendancePerformance

            if (data) {
                data = Object.values(data)
                console.log("data for pie chart is", data)
                this.series = data.map((item) => item.value)
                const allZero = this.series.every((value) => value === 0)
                this.series = allZero ? [1] : this.series

                const colors = data.map((item) => item.color)
                this.chartOptions.colors = colors
                console.log("series", this.series)
                console.log("colors", colors)
                // Static array of colors
                if (allZero) {
                    this.chartOptions.labels = ["No Data"]
                } else {
                    this.chartOptions.labels = data.map((item) => item.title)
                }
                this.showChart = true
            }
        },
    },
    watch: {
        dashboardStats(e) {
            if (e) {
                this.loadChart()
            }
        },
    },
}
</script>

<style></style>
