<template>
    <PageCard title="Attendance Records">
        <TabContainer
            :tabs="Object.values(ATTENDANCE_RECORD_TABS)"
            :selectedTab="attendanceRecordTab"
            @change="attendanceRecordTab = $event"
        />
        <div v-if="attendanceRecordTab.name == ATTENDANCE_RECORD_TABS.ATTENDANCE.name">
            <AttendanceDateController />
            <div class="search-container">
                <i class="fas fa-search" />
                <input v-model="searchText" type="text" class="search-input" placeholder="Search employee..." />
            </div>
            <p class="my-3"><strong>Employees</strong></p>
            <div v-if="initLoading || loadingAttendanceData">
                <Spinner />
            </div>

            <div v-if="!initLoading && !loadingAttendanceData && !filteredAttendanceRecordsForDate.length">No records found</div>
            <div v-else>
                <AttendanceRecordListItem
                    :attendanceRecord="attendanceRecord"
                    :key="attendanceRecord.id"
                    v-for="attendanceRecord of filteredAttendanceRecordsForDate"
                />
            </div>
        </div>
        <div v-if="attendanceRecordTab.name == ATTENDANCE_RECORD_TABS.MAPS.name">
            <AttendanceMap />
        </div>
    </PageCard>
</template>

<script>
import AttendanceDateController from "./attendance-date-controller.vue"
import AttendanceRecordListItem from "./attendance-record-list-item.vue"
import { mapActions, mapState } from "vuex"
import TabContainer from "../../../components/layout/TabContainer.vue"
import AttendanceMap from "./attendance-map.vue"
import PageCard from "../../../components/layout/PageCard.vue"
import Spinner from "../../../components/UI/Spinner.vue"
import { convertAmPmToTimestamp } from "../../../utils/common"

const ATTENDANCE_RECORD_TABS = {
    ATTENDANCE: { name: "ATTENDANCE", value: "Attendance Stats" },
    MAPS: { name: "MAPS", value: "Map View" },
}

export default {
    components: {
        AttendanceDateController,
        TabContainer,
        AttendanceRecordListItem,
        AttendanceMap,
        PageCard,
        Spinner,
    },
    data() {
        return {
            initLoading: true,
            searchText: "",
            ATTENDANCE_RECORD_TABS,
            attendanceRecordTab: ATTENDANCE_RECORD_TABS.ATTENDANCE,
        }
    },
    async mounted() {
        await this.fetchRecordsByDate()
        // await this.fetchAttendanceInfo()
        this.initLoading = false
    },
    computed: {
        ...mapState("misc", ["loadingAttendanceData", "attendanceRecordsForDate"]),
        filteredAttendanceRecordsForDate() {
            const filtered = this.attendanceRecordsForDate.filter(
                (item) => item.user && item.user.fullName && item.user.fullName.toLowerCase().includes(this.searchText.toLowerCase())
            )

            // sort the resutls based on ascending order of timein
            const sortedRecords = filtered.sort((a, b) => {
                if (a.timeIn && b.timeIn) {
                    return convertAmPmToTimestamp(a.timeIn) < convertAmPmToTimestamp(b.timeIn) ? -1 : 1
                } else {
                    return 0
                }
            })

            return sortedRecords
        },
    },
    methods: {
        ...mapActions("misc", ["fetchRecordsByDate", "fetchAttendanceInfo"]),
    },
}
</script>

<style lang="scss" scoped>
.page-title {
    font-size: 1.2em;
    font-weight: 500;
}
.search-container {
    border: 2px solid #ddd;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.search-input {
    outline: none;
    width: 100%;
}
</style>
