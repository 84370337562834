<template>
    <div>
        <CrudIndex
            title="Attendance Holiday Types"
            :viewColumns="columns"
            fetchUrl="/attendance-holiday-types"
            createUrl="/attendance-holiday-types"
            updateRootUrl="/attendance-holiday-types"
            deleteRootUrl="/attendance-holiday-types"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { createField, createColumn } from "../../utils/common"

export default {
    data() {
        return {
            createFields: [
                createField("Name*", "name", "text", true),
                // createField("Status", "status", "switch", false, true),
            ],
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Name", "name", "string"),
                // createColumn("Active", "status", "string"),
            ],
        }
    },
    components: { CrudIndex },
}
</script>

<style></style>
