<template>
  <div>
    <div
      v-if="showOptionsMenu"
      class="file-folder-item-backdrop"
      @click="showOptionsMenu = false"
    ></div>
    <div
      class="file-folder-container"
      v-if="file || folder"
      :class="[{ 'file-ui': !!file }, { 'folder-ui': !!folder }]"
    >
      <div class="row">
        <div :class="[{ 'col-md-12': !listMode }, { 'col-md-8': listMode }]">
          <div class="file-wrapper" @dblclick="handleFileClick(file || folder)">
            <i
              :class="[
                'file-icon fas',
                { 'fa-file': !!file },
                { 'fa-folder': !!folder },
                'fas fa-file',
              ]"
            ></i>
            <div>{{ fileFolderName }}</div>
          </div>
        </div>
        <div class="col-md-2 text-end" v-if="listMode">
          {{ formatIndianDate(file ? file.createdAt : folder.createdAt) }}
        </div>
        <div class="col-md-2 text-end pe-5" v-if="listMode">
          {{ file ? formatFileSize(file.size) : "" }}
        </div>
      </div>

      <div class="options-container">
        <div class="options-container-inner">
          <i
            @click="showOptionsMenu = !showOptionsMenu"
            class="fas fa-ellipsis-v options-icon"
            style="cursor: pointer; padding: 3px"
          ></i>

          <div
            class="options-menu"
            v-if="showOptionsMenu"
            @click="showOptionsMenu = false"
          >
            <div
              class="options-menu-item"
              v-if="file"
              @click="showShareModal = true"
            >
              <i class="fas fa-share me-2"></i> Share
            </div>
            <div class="options-menu-item" @click="showUpdateModal = true">
              <i class="fas fa-edit me-2"></i> Rename
            </div>
            <div class="options-menu-item" @click="openInfo">
              <i class="fas fa-info-circle me-2"></i> Info
            </div>
            <div class="options-menu-item" v-if="file" @click="downloadItem">
              <i class="fas fa-download me-2"></i> Download
            </div>
            <div class="options-menu-item" @click="deleteFileFolder">
              <i class="fas fa-trash me-2"></i> Delete
            </div>
          </div>
        </div>
      </div>
    </div>

    <CustomModal
      v-if="showUpdateModal"
      :title="`Update ${file ? 'File' : 'Folder'}`"
      :show="showUpdateModal"
      @close="showUpdateModal = false"
    >
      <div class="my-3">
        <div>
          <CustomInput
            placeholder="Enter name"
            v-model="newName"
            label="Name:"
            type="text"
          />
        </div>
        <div>
          <Button @click="updateFileFolder">{{
            processing
              ? "Please wait..."
              : folder
              ? "Update Folder"
              : "Update File"
          }}</Button>
        </div>
      </div>
    </CustomModal>

    <CustomModal
      v-if="showShareModal"
      :title="`Share ${file ? 'File' : 'Folder'}`"
      :show="showShareModal"
      @close="showShareModal = false"
    >
      <div class="my-3">
        <ShareFile
          @close="showShareModal = false"
          :isFolder="!!folder"
          :fileFolder="file || folder"
        />
      </div>
    </CustomModal>
  </div>
</template>

<script>
import { formatIndianDate, stripText } from "../../utils/common";
import { formatFileSize } from "../../utils/file-utils";
import { http, serverURL } from "../../utils/http";
import CustomInput from "../crud/custom-input.vue";
import CustomModal from "../layout/CustomModal.vue";
import Button from "../UI/Button.vue";
import ShareFile from "./ShareFile.vue";

export default {
  emits: ["openFolder", "openInfo"],
  props: {
    folder: {
      type: Object,
      default: null,
    },
    listMode: {
      type: Boolean,
      default: false,
    },
    file: {
      type: Object,
      default: null,
    },
  },
  components: { ShareFile, Button, CustomModal, CustomInput },
  data() {
    return {
      showShareModal: false,
      showUpdateModal: false,
      processing: false,
      newName: "",
      showOptionsMenu: false,
    };
  },
  computed: {
    fileFolderName() {
      const name =
        (this.file && this.file.name) || (this.folder && this.folder.name);
      return stripText(name, this.listMode ? 200 : 20);
    },
  },
  methods: {
    formatFileSize,
    formatIndianDate,
    handleFileClick(fileFolderData) {
      if (fileFolderData.src) {
        this.downloadItem();
      } else {
        this.$emit("openFolder");
      }
    },
    downloadItem() {
      const openUrl = serverURL + "/" + this.file.src;
      window.open(openUrl, "_blank");
      this.showOptionsMenu = false;
    },
    async deleteFileFolder() {
      try {
        if (!confirm("Are you sure?")) {
          return;
        }
        this.processing = true;
        await http.delete(
          `/file-management/${this.file ? "files" : "folders"}/${
            this.file ? this.file.id : this.folder.id
          }`
        );
        this.$toast.success(
          `${this.file ? "File" : "Folder"} deleted successfully`
        );
        this.showUpdateModal = false;
        window.location.reload();
      } catch (err) {
        console.log("error in deleting file/folder", err);
        this.$toast.error("Could not update!");
      }
      this.processing = false;
    },
    async updateFileFolder() {
      try {
        this.processing = true;
        await http.patch(
          `/file-management/${this.file ? "files" : "folders"}/${
            this.file ? this.file.id : this.folder.id
          }`,
          {
            name: this.newName,
          }
        );
        this.$toast.success(
          `${this.file ? "File" : "Folder"} updated successfully`
        );
        this.showUpdateModal = false;
        window.location.reload();
      } catch (err) {
        console.log("error in updating file/folder", err);
        this.$toast.error("Could not update!");
      }
      this.processing = false;
    },
    openInfo() {
      console.log("open info click");
      this.$emit("openInfo", this.file || this.folder);
      this.showOptionsMenu = false;
    },
  },
  mounted() {
    this.newName = this.file ? this.file.name : this.folder.name;
  },
};
</script>

<style lang="scss" scoped>
.file-folder-container {
  position: relative;
}
.options-container {
  position: absolute;
  top: 10px;
  right: 10px;
}
.options-container-inner {
  position: relative;
  top: 10px;
  right: 10px;
}
.options-menu {
  position: absolute;
  z-index: 100;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  width: 150px;
  top: 20px;
  right: 10px;
}
.options-menu-item {
  padding: 10px;
}
.options-menu-item:hover {
  background-color: #eee;
  cursor: pointer;
}
.file-ui,
.folder-ui {
  background: rgb(227, 239, 188);
  padding: 15px;
  margin-bottom: 14px;
  border-radius: 10px;
}
.folder-ui {
  background: rgb(229, 229, 229);
}
// .file-ui:hover,
// .folder-ui:hover {
//     cursor: pointer;
//     background: rgb(216, 216, 216);
// }
.file-ui:hover {
  background: rgb(205, 221, 115);
}
.file-icon {
  margin-bottom: 5px;
  font-size: 30px;
}
.file-wrapper {
  display: flex;
  gap: 10px;
}
.file-wrapper:hover {
  opacity: 0.8;
  cursor: pointer;
}
.edit-icon:hover {
  cursor: pointer;
  color: #aaa;
}
.file-folder-item-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background: rgba(255, 255, 255, 0.4);
}
</style>
