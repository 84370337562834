<template>
    <div>
        <AllowPermission :permission="PERMISSION_CODES.CREATE_COMPANIES">
            <Button @click="$router.push('/companies/create')">
                <i class="fas fa-plus"></i> Create Company
            </Button>
        </AllowPermission>
        <CrudIndex
            v-if="!loading"
            title="Companies"
            :viewColumns="columns"
            :permission="PERMISSION_CODES.VIEW_COMPANIES"
            updateRootUrl="/companies"
            :fetchUrl="`/companies`"
            deleteRootUrl="/companies"
            editRouteName="company-edit"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import { http } from "@/utils/http"
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"
import Button from "../../components/UI/Button.vue"
import { PERMISSION_CODES } from "../../store/permission"
import AllowPermission from "../../components/layout/AllowPermission.vue"

export default {
    data() {
        return {
            loading: true,
            PERMISSION_CODES,
            workLocationTypes: [],
            createFields: [],
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Name", "name", "string"),
                // createColumn("Address", "address", "string"),
                createColumn("Country", "country_data.name", "string"),
                createColumn("City", "city_data.name", "string"),
                createColumn("State", "state_data.name", "string"),
                // createColumn("Contact Email", "contactEmail", "string"),
                // createColumn("Contact Number", "contactNumber", "string"),
                // todo: work on single page where all details will be shown
                // createColumn("Status", "status", "string"),
            ],
        }
    },
    components: { AllowPermission, CrudIndex, Button },
    async mounted() {
        this.createFields = this.createFields.concat([
            createField("Name*", "name", "text", true),
            createField("City*", "city", "text", true),
            createField("State*", "state", "text", true),
            createField("Country*", "country", "text", true),
            createField("Address*", "address", "text", true),
            createField("Address Pincode*", "addressPincode", "text", true),
            createField("Contact Number*", "contactNumber", "text", true),
            createField("Contact Email*", "contactEmail", "text", true),
            createField("Contact Website*", "contactWebsite", "text", true),
            // createField("PF Applicable*", "pfApplicable", "switch", false, true),
            createField("Tan No*", "tanNo", "text", false),
            createField("Registration No*", "registrationNo", "text", false),
            // createField("Status", "status", "switch", false, true),
        ])
        this.loading = false
    },
    methods: {},
}
</script>

<style></style>
