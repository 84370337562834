<template>
    <div :class="['attendance-status-card primary', { loading }, { active }]" @click="$emit('click')" v-if="custom">
        <div class="status-symbol">{{ customStatusName[0] }}</div>
        <div>{{ customStatusName }}</div>
    </div>
    <div
        :class="[
            'attendance-status-card',
            { loading },
            { active },
            { danger: active && includesOneOfStatuses('absent', 'leave') },
            { success: active && !includesOneOfStatuses('absent', 'leave') },
        ]"
        @click="handleStatusUpdate"
        v-else-if="attendanceStatus"
    >
        <div class="status-symbol">{{ attendanceStatus.name[0] }}</div>
        <div>{{ attendanceStatus.name }}</div>
    </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
    emits: ["updated"],
    props: {
        attendanceStatus: {
            type: Object,
            default: () => null,
        },
        attendanceRecord: {
            type: Object,
            default: () => null,
        },
        active: {
            type: Boolean,
            default: () => false,
        },
        custom: {
            type: Boolean,
            default: () => false,
        },
        customStatusName: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        ...mapActions("misc", ["updateAttendanceRecord"]),
        includesOneOfStatuses(...statuses) {
            return statuses.some((item) => this.attendanceStatus.name.toLowerCase().includes(item))
        },
        async handleStatusUpdate() {
            if (this.loading || !this.attendanceRecord || !this.attendanceStatus) return
            this.loading = true
            try {
                await this.updateAttendanceRecord({
                    id: this.attendanceRecord.id,
                    attendanceStatusId: this.attendanceStatus.id,
                })
            } catch (err) {
                console.log("error in updating status", err)
            }
            this.$emit("updated")
            this.loading = false
        },
    },
}
</script>

<style lang="scss" scoped>
.attendance-status-card {
    border: 2px solid #ddd;
    padding: 10px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    gap: 10px;
    font-size: 0.9em;
    transition: 0.2s;

    &.loading {
        opacity: 0.7;
    }
    &.active .status-symbol {
        background: white;
        color: #333;
    }
    &.active {
        color: white;
    }
    &.success {
        background: rgb(10, 122, 10);
    }
    &.danger {
        background: rgb(174, 41, 41);
    }
    &.primary {
        background: rgb(95, 188, 255);
    }
}
.attendance-status-card {
    cursor: pointer;
}
.attendance-status-card:hover:not(.active) {
    background: #ddd;
}
.status-symbol {
    background-color: #666;
    color: white;
    text-align: center;
    padding: 3px;
    font-weight: bold;
    width: 40px;
    font-size: 0.9em;
    border-radius: 3px;
}
</style>
