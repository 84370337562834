<template>
    <PageCard sm>
        <Spinner v-if="!dashboardStats" />
        <div v-else>
            <div class="mb-2 d-flex items-center justify-content-between" style="gap: 10px">
                <SectionTitle class="">Performance Statistics</SectionTitle>
                <div class="d-flex items-center" style="gap: 10px">
                    <ColorLabelView
                        v-if="dashboardStats && dashboardStats.attendancePerformance"
                        :labels="Object.values(dashboardStats.attendancePerformance)"
                    />
                </div>
            </div>
            <div class="d-flex justify-content-end mb-3">
                <div class="d-flex items-center" style="gap: 10px">
                    <div><i class="arrow-btn fas fa-chevron-left" @click="decrementMonth()"></i></div>
                    <div>{{ getMonthName(monthIndex) }}</div>
                    <div><i class="arrow-btn fas fa-chevron-right" @click="incrementMonth()"></i></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <DashboardPerformanceStatsPie />
                </div>
                <div class="col-md-9">
                    <Spinner v-if="!showChart" />
                    <apexchart v-if="showChart" type="area" height="250" :options="chartOptions" :series="series"></apexchart>
                </div>
            </div>

            <div class="my-3">
                <a href="/attendance-records">
                    <Button noMargin>View All</Button>
                </a>
            </div>
        </div>
    </PageCard>
</template>

<script>
import { mapActions, mapState } from "vuex"
import PageCard from "../layout/PageCard.vue"
import SectionTitle from "../UI/SectionTitle.vue"
import DashboardPerformanceStatsPie from "./DashboardPerformanceStatsPie.vue"
import ColorLabelView from "./ColorLabelView.vue"
import { getMonthName } from "../../utils/common"
import Spinner from "../UI/Spinner.vue"
import Button from "../UI/Button.vue"

export default {
    components: { Button, Spinner, ColorLabelView, PageCard, SectionTitle, DashboardPerformanceStatsPie },
    data() {
        return {
            comparisonType: "daily",
            showChart: false,
            series: [],
            monthIndex: new Date().getMonth() + 1,
            chartOptions: {
                chart: {
                    type: "area",
                    height: 250,
                    zoom: {
                        enabled: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                    width: 2, // Adjust the width value to make the line thinner
                },

                // title: {
                //     text: "Attendance Comparison Chart",
                //     align: "left",
                // },
                // subtitle: {
                //     text: "Price Movements",
                //     align: "left",
                // },
                colors: [],
                labels: null,
                xaxis: {
                    // type: "datetime",
                    categories: [],
                    labels: {
                        style: {
                            fontSize: "10px", // Adjust the font size as needed
                        },
                    },
                },
                yaxis: {
                    min: 0,
                    max: 100,
                    tickAmount: 5,
                    labels: {
                        formatter: function (value) {
                            return value + "%"
                        },
                    },
                },
                legend: {
                    horizontalAlign: "left",
                },
            },
        }
    },
    computed: {
        ...mapState("misc", ["attendancePerformanceData", "dashboardStats"]),
    },
    mounted() {
        this.loadChart()
    },
    methods: {
        ...mapActions("misc", ["fetchDashboardStatsPerformance"]),
        getMonthName,
        decrementMonth() {
            if (this.monthIndex > 1) {
                this.monthIndex--
            }
        },
        incrementMonth() {
            if (this.monthIndex < 12) {
                this.monthIndex++
            }
        },
        loadChart() {
            const data = this.attendancePerformanceData

            if (data) {
                this.series = [
                    { name: "Leave", data: data.totalLeave.map((item) => item.percentage) },
                    {
                        name: "Present- On Time",
                        data: data.totalPresentOnTime.map((item) => item.percentage),
                    },
                    { name: "Absent", data: data.totalAbsent.map((item) => item.percentage) },
                    {
                        name: "Present- Late Entry",
                        data: data.totalPresentLate.map((item) => item.percentage),
                    },
                ]

                this.chartOptions.xaxis.categories = data.totalPresentOnTime.map((item) => item.title)
                const attendancePerformance = this.dashboardStats.attendancePerformance
                this.colors = attendancePerformance
                    ? ["#1E88E5", "#1A817F", "#FB9601", "#EF2B1F"]
                    : [
                          attendancePerformance.totalLeave.color,
                          attendancePerformance.totalAbsent.color,
                          attendancePerformance.totalPresentLate.color,
                          attendancePerformance.totalPresentOnTime.color,
                      ]
                this.showChart = true
            }
        },
    },
    watch: {
        async monthIndex(e) {
            this.showChart = false
            await this.fetchDashboardStatsPerformance({
                month: this.monthIndex,
            })
            this.loadChart()
        },
        attendanceComparisonData(e) {
            if (e) {
                this.loadChart()
            }
        },
        attendancePerformanceData(e) {
            if (e) {
                this.loadChart()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.arrow-btn {
    cursor: pointer;
}
</style>
