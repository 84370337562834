<template>
    <PageCard :title="`${editMode ? 'Update' : 'Create'} Work Location`">
        <div class="mb-3"></div>
        <div class="row" v-if="!editMode || editWorkLocationData">
            <div class="col-md-6">
                <custom-input v-model="name" label="Name*" />
            </div>
            <div class="col-md-6">
                <p class="my-2">Work Location Type*</p>
                <SelectDropdown
                    v-if="!editMode || editWorkLocationData"
                    :id="`form-work-location`"
                    :options="workLocationTypes.map((item) => ({ id: item.id, title: item.name }))"
                    :multiple="false"
                    field="form-work-location"
                    :value="workLocationTypeId"
                    @change="workLocationTypeId = $event"
                />
            </div>
            <CountryStateCitySelector
                v-if="!editMode || editWorkLocationData"
                :countryId="country"
                :stateId="state"
                :cityId="city"
                :hideCountry="hideCountryName"
                :disableCountry="disableCountry"
                @country="handleCountryUpdate"
                @state="state = $event"
                @city="city = $event"
            />
            <div class="col-md-6">
                <custom-input v-model="zipCode" label="Pincode*" type="number" />
            </div>
            <div class="col-md-6">
                <custom-input v-model="address" label="Address*" />
            </div>
            <div class="col-md-6">
                <custom-input v-model="latitude" label="Latitude" type="number" />
            </div>
            <div class="col-md-6">
                <custom-input v-model="longitude" label="Longitude" type="number" />
            </div>
            <div class="col-md-12">
                <Button @click="cancelEdit" class="btn btn-primary">Cancel</Button>
                <span class="mx-1"></span>
                <Button class="btn btn-primary" @click="createOrUpdateWorkLocation">{{
                    processing ? "Please wait..." : editMode ? "Update" : "Submit"
                }}</Button>
            </div>
        </div>
        <div v-else>Loading...</div>
    </PageCard>
</template>

<script>
import { http, serverURL } from "@/utils/http"
import { createField, createColumn } from "@/utils/common"
import CustomInput from "../../components/crud/custom-input.vue"
import Button from "../../components/UI/Button.vue"
import SelectDropdown from "../../components/crud/SelectDropdown.vue"
import OldImageView from "../../components/UI/OldImageView.vue"
import CountryStateCitySelector from "../../components/UI/CountryStateCitySelector.vue"
import { mapState } from "vuex"
import PageCard from "../../components/layout/PageCard.vue"

export default {
    data() {
        return {
            serverURL,
            activeTab: "personal",
            processing: false,
            loading: true,

            // master ids
            name: "",
            address: "",
            city: "",
            state: "",
            country: "",
            zipCode: "",
            latitude: "",
            longitude: "",
            workLocationTypeId: "",
            // documents
            // edit related fields
            editMode: true,
            editWorkLocationData: null,
            workLocationTypes: [],
        }
    },
    props: { videoId: { type: String, default: "" } },
    components: {
        PageCard,
        CountryStateCitySelector,
        OldImageView,
        CustomInput,
        SelectDropdown,
        Button,
    },
    async mounted() {
        await this.fetchWorkLocationTypes()
        await this.initIfEditMode()
    },
    computed: {
        ...mapState("misc", ["countries"]),
        disableCountry() {
            return this.workLocationTypes && this.workLocationTypeId == this.domesticTypeId
        },
        hideCountryName() {
            return this.workLocationTypes && this.workLocationTypeId == this.domesticTypeId
                ? ""
                : "India"
        },
        domesticTypeId() {
            const data =
                this.workLocationTypes &&
                this.workLocationTypes.find((item) => item.name.toLowerCase().includes("domestic"))
            return data && data.id
        },
    },
    methods: {
        handleCountryUpdate(update) {
            if (update) {
                this.country = update
            }
        },
        cancelEdit() {
            this.$router.push("/work-locations")
        },
        async initIfEditMode() {
            const id = this.$route.params.id
            if (id) {
                this.editMode = true
                const res = await http.get("/work-locations/" + id)
                this.editWorkLocationData = res.data.result

                // set the defaults to each field.
                if (this.editWorkLocationData) {
                    this.name = this.editWorkLocationData.name || ""
                    this.address = this.editWorkLocationData.address || ""
                    this.city = this.editWorkLocationData.city || ""
                    this.state = this.editWorkLocationData.state || ""
                    this.country = this.editWorkLocationData.country || ""
                    this.zipCode = this.editWorkLocationData.zipCode || ""
                    this.latitude = this.editWorkLocationData.latitude || ""
                    this.longitude = this.editWorkLocationData.longitude || ""
                    this.workLocationTypeId = this.editWorkLocationData.workLocationTypeId || ""
                }
            } else {
                this.editMode = false
            }
        },
        async createOrUpdateWorkLocation() {
            if (this.processing) return

            if (!this.name) {
                return this.$toast.error("Please enter work location name")
            }
            console.log("zipCode", this.zipCode.length)
            if (this.zipCode.toString().length !== 6) {
                return this.$toast.error("The zip code must be 6 digits only!")
            }
            if (!this.workLocationTypeId) {
                return this.$toast.error("Please select work location type!")
            }
            if (!this.country || !this.city || !this.state) {
                return this.$toast.error("Please enter country, state and city!")
            }
            if (!this.address) {
                return this.$toast.error("Please enter address!")
            }

            this.processing = true
            const formDataObj = {
                name: this.name,
                address: this.address,
                city: this.city,
                state: this.state,
                country: this.country,
                zipCode: this.zipCode,
                latitude: this.latitude,
                longitude: this.longitude,
                workLocationTypeId: this.workLocationTypeId,
            }
            try {
                if (this.editMode) {
                    await http.patch(`/work-locations/${this.editWorkLocationData.id}`, formDataObj)
                } else {
                    await http.post("/work-locations", formDataObj)
                }
                this.$toast.success(
                    this.editMode
                        ? "Work Location Updated Successfully"
                        : "Work Location Created Successfully"
                )
                window.location.href = "/work-locations"
            } catch (err) {
                console.log("error in creating work location", err)
                this.$toast.error(
                    (err.response && err.response.data && err.response.data.message) ||
                        "Some error occured"
                )
            }
            this.processing = false
        },
        async fetchWorkLocationTypes() {
            const res = await http.get("/work-location-types")
            this.workLocationTypes = res.data.result
        },
    },
    watch: {
        workLocationTypeId(e) {
            if (e == this.domesticTypeId) {
                this.country = this.countries.find((item) => item.title == "India").id
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/variables.scss";

.tab-container {
    display: flex;
    margin-bottom: 10px;
}
.tab-item:hover:not(.active) {
    background-color: lighten($primaryColor, 70%);
}
.tab-item.active {
    border-bottom: 5px solid $primaryColor;
    background-color: lighten($primaryColor, 50%);
}
.tab-item {
    cursor: pointer;
    width: 160px;
    text-align: center;
    padding: 10px;
}
</style>
