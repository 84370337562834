<template>
    <CustomModal title="Import Results" :show="show" @close="$emit('close')">
        <div class="my-3">
            <div class="mb-3">Import Status: {{ importStatus }}</div>
            <div style="max-height: 250px; overflow-y: scroll">
                <div v-if="importSuccess">
                    <slot />
                </div>
                <div v-if="!processing && !importSuccess" class="my-3">
                    <CustomTable>
                        <template #head>
                            <tr>
                                <th>Row Number</th>
                                <th>Error Description</th>
                            </tr>
                        </template>
                        <template #body>
                            <tr v-for="errorDesc of importErrors" :key="errorDesc.id">
                                <td>#{{ errorDesc.rowNum }}</td>
                                <td>{{ errorDesc.message }}</td>
                            </tr>
                        </template>
                    </CustomTable>
                </div>
            </div>
        </div>
    </CustomModal>
</template>

<script>
import CustomModal from "../layout/CustomModal.vue"
import CustomTable from "./CustomTable.vue"

export default {
    props: {
        show: { type: Boolean, default: false },
        importStatus: { type: String, default: "" },
        importSuccess: { type: Boolean, default: false },
        importErrors: { type: Array, default: () => [] },
        processing: { type: Boolean, default: false },
    },
    components: { CustomTable, CustomModal },
}
</script>

<style></style>
