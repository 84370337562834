<template>
    <div v-if="fileFolder">
        <div>{{ "Users Shared" }}</div>
        <div>
            <!-- <div v-for="user of users"></div> -->
            <SelectDropdown
                v-if="usersList"
                :multiple="true"
                id="user-selection"
                @change="handleUserSelection"
                :value="usersToShare"
                :options="
                    usersList.map((user) => ({
                        id: user.id,
                        title: user.firstName + ' ' + user.lastName,
                    }))
                "
            />
            <Button @click="updateShareAccess">{{
                sharing ? "Please wait..." : "Update Access"
            }}</Button>
        </div>
    </div>
</template>

<script>
import { http } from "../../utils/http"
import SelectDropdown from "../crud/SelectDropdown.vue"
import Button from "../UI/Button.vue"

export default {
    emits: ["close"],
    data() {
        return {
            usersList: [],
            usersToShare: [],
            sharing: false,
        }
    },
    props: {
        isFolder: {
            type: Boolean,
            default: false,
        },
        fileFolder: {
            type: Object,
            default: null,
        },
    },
    components: { Button, SelectDropdown },
    async mounted() {
        await this.getUsersSharedWith()
        await this.loadUsersList()
    },
    methods: {
        async getUsersSharedWith() {
            try {
                const res = await http.get(
                    `/file-management/file-sharing/info?fileFolderId=${this.fileFolder.id}&isFolder=${this.isFolder}`
                )
                this.usersToShare = res.data.result.map((item) => item.userId)
            } catch (err) {
                console.log("error in load users to share file", err)
            }
        },
        async loadUsersList() {
            try {
                const res = await http.get("/employees?limited_fields=true")
                this.usersList = res.data.result
            } catch (err) {
                console.log("error in load users to share file", err)
            }
        },
        handleUserSelection(user) {
            console.log("handle user selection", user)
            this.usersToShare = user
        },
        async updateShareAccess() {
            try {
                this.sharing = true
                const res = await http.post("/file-management/file-sharing", {
                    userIds: this.usersToShare,
                    fileFolderId: this.fileFolder.id,
                    isFolder: this.isFolder,
                })
                const shareAccess = res.data.result
                this.$toast.success("User Access Updated!")
                this.$emit("close")
            } catch (err) {
                console.log("error in update share access", err)
            }
            this.sharing = false
        },
    },
}
</script>

<style></style>
