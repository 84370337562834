<template>
    <div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="d-flex flex-column align-items-center">
                        <div class="my-5" style="display: grid; place-items: center">
                            <img src="@/assets/logo.png" style="width: 120px" alt="" />
                        </div>
                        <div class="" v-if="$route.query.email">
                            <h4 class="text-center mb-3">Reset Password</h4>
                            <form @submit.prevent="handleSubmit">
                                <div class="mb-3" style="gap: 10px">
                                    <p class="text-secondary">Reset OTP From Email</p>
                                    <div class="">
                                        <input
                                            id="password"
                                            type="text"
                                            class="form-control form-control-sm"
                                            placeholder="OTP"
                                            v-model="otp"
                                            required
                                            autocomplete=""
                                            autofocus
                                            style="min-width: 350px"
                                        />
                                    </div>
                                </div>
                                <div class="mb-0" style="gap: 10px">
                                    <div class="">
                                        <PasswordInputVue
                                            v-model="password"
                                            :editMode="false"
                                            :required="true"
                                        />
                                    </div>
                                </div>
                                <div class="my-3 mb-0" style="gap: 10px">
                                    <p class="text-secondary">Confirm Password</p>
                                    <div class="">
                                        <input
                                            id="password"
                                            type="password"
                                            class="form-control form-control-sm"
                                            placeholder="Confirm password"
                                            v-model="passwordConfirm"
                                            required
                                            autocomplete="password"
                                            autofocus
                                            style="min-width: 350px"
                                        />
                                        <div
                                            style="color: red"
                                            class="my-1"
                                            v-if="password != passwordConfirm"
                                        >
                                            Password and confirm password doesn not match
                                        </div>
                                    </div>
                                </div>

                                <div class="text-center">
                                    <Button type="submit">
                                        {{ processingForm ? "Please wait..." : "Reset Password" }}
                                    </Button>
                                </div>
                            </form>
                            <div class="text-center">
                                <a href="/login"> Login To App </a>
                            </div>
                        </div>
                        <div v-else class="text-center">
                            <div class="text-lg">No Email Found To Reset</div>
                            <div class="my-2">
                                <a href="/login">Go to Login</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import Button from "@/components/UI/Button"
import { errMsg, getPasswordError } from "../../utils/common"
import PasswordInputVue from "../crud/password-input.vue"

export default {
    data() {
        return {
            otp: "",
            password: "",
            passwordConfirm: "",
            processingForm: false,
        }
    },
    components: { Button, PasswordInputVue },
    methods: {
        // ...mapActions("auth", ["login"]),
        ...mapActions({
            resetPassword: "auth/resetPassword",
        }),
        async handleSubmit() {
            if (this.processingForm) return
            const pwErr = getPasswordError(this.password)
            if (pwErr) {
                return this.$toast.error(pwErr)
            }
            if (!this.password || !this.passwordConfirm || !this.otp) {
                return this.$toast.error("Please Fill Details!")
            }
            if (this.password != this.passwordConfirm) {
                return this.$toast.error("Password and confirm password doesn't match!")
            }
            const email = this.$route.query.email
            if (!email) {
                return this.$toast.error("Email to update doesn't exist!")
            }
            this.processingForm = true
            try {
                await this.resetPassword({
                    password: this.password,
                    otp: this.otp,
                    email,
                })
                this.$toast.success("Password reset success! Please login")
                window.location.href = "/"
            } catch (err) {
                console.log("Login err", err)
                this.$toast.error(errMsg(err, "Could Not Login!"))
            }
            this.processingForm = false
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/variables.scss";

.colored-icon {
    color: $primaryColor;
    font-size: 20px;
}
</style>
