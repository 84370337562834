import Dashboard from "../pages/dashboard.vue"
import Login from "../pages/login.vue"

// new routes
import DepartmentMaster from "../pages/departments/index.vue"
import NoticeMaster from "../pages/notices/index.vue"
import NotificationMaster from "../pages/notifications/index.vue"
import PermissionEditor from "../pages/users/permission-editor.vue"
import BankMaster from "../pages/bank-masters/index.vue"
import BankMasterEdit from "../pages/bank-masters/edit.vue"
import CCTVMaster from "../pages/cctv-masters/index.vue"

import WorkLocationTypeMaster from "../pages/work-location-types/index.vue"
import WorkLocationMaster from "../pages/work-locations/index.vue"
import WorkLocationEdit from "../pages/work-locations/_edit.vue"
import DesignationMaster from "../pages/designations/index.vue"
import CompanyMaster from "../pages/companies/index.vue"
import CompanyEdit from "../pages/companies/_edit.vue"
import Employee from "../pages/employees/index.vue"
import EmployeeEdit from "../pages/employees/_edit.vue"
import EmploymentStatuses from "../pages/employment-statuses/index.vue"
import MasterTemplate from "../pages/master-template/index.vue"
import GenerateTemplate from "../pages/master-template/generate-letter.vue"
import EditMaster from "../pages/master-template/_edit.vue"
import ReportMaster from "../pages/reports/general/report-master.vue"

import AttendanceImport from "../pages/attendance/attendance-import.vue"

import LeaveRequests from "../pages/attendance/leave-requests/leave-requests.vue"
import LeaveRequestsApproved from "../pages/attendance/leave-requests/leave-requests-approved.vue"
import LeaveRequestsRejected from "../pages/attendance/leave-requests/leave-requests-rejected.vue"
import LeaveTypes from "../pages/attendance/leave-types/index.vue"

import OvertimeRequests from "../pages/attendance/overtime-requests/overtime-requests.vue"
import OvertimeRequestsApproved from "../pages/attendance/overtime-requests/overtime-requests-approved.vue"
import OvertimeRequestsRejected from "../pages/attendance/overtime-requests/overtime-requests-rejected.vue"

// fresh ecom new components start

import UserRole from "../pages/user-role/index.vue"
import EditUserRole from "../pages/user-role/_edit.vue"

import SuperAdmins from "../pages/super-admins/index.vue"
import EditSuperAdmin from "../pages/super-admins/_edit.vue"

import MyProfile from "../pages/my-profile.vue"

/**
 * ATTENDANCE MODULE
 */

import AttendanceHolidayTypes from "../pages/attendance/attendance-holiday-types.vue"
import AttendanceStatuses from "../pages/attendance/attendance-statuses.vue"
import AttendanceShifts from "../pages/attendance/attendance-shifts.vue"
import AttendanceRecords from "../pages/attendance/attendance-records/attendance-records.vue"

import AttendanceHolidays from "../pages/attendance/attendance-holidays/index.vue"
import AttendanceHolidaysEdit from "../pages/attendance/attendance-holidays/_edit.vue"
import CheckInOutTracking from "../pages/attendance/check-in-out-tracking.vue"

/**
 * COMMUNICATION MODULE
 */
import CommunicationGroup from "../pages/communication/communication-groups.vue"
import CommunicationMessages from "../pages/communication/communication-messages.vue"
import SettingIndex from "../pages/settings/index.vue"

/**
 * PAYROLL SYSTEM
 */

import PayheadIndex from "../pages/payroll/payhead.vue"
import CreatePayroll from "../pages/payroll/create-payroll.vue"
import PayrollHistory from "../pages/payroll/payroll-history"
import PayrollHistoryDetail from "../pages/payroll/payroll-history-detail"

import SalaryUpdateHistory from "../pages/salary-update-histories/index.vue"

/**
 * ASSET MANAGEMENT
 */

import AssetMaster from "../pages/asset-management/assets.vue"
import AssetCategoryMaster from "../pages/asset-management/asset-categories.vue"

import AssetAllocation from "../pages/asset-management/asset-allocations.vue"
import AssetAllocationStatus from "../pages/asset-management/asset-allocation-statuses.vue"
import AssetLog from "../pages/asset-management/asset-logs.vue"
import AssetLogType from "../pages/asset-management/asset-log-types.vue"
import AssetLogChronological from "../pages/asset-management/asset-logs-chronological-view.vue"

/**
 * PROJECT MANAGEMENT
 */
import Project from "../pages/project-management/projects.vue"
import ProjectCategory from "../pages/project-management/project-categories.vue"
import ProjectLabel from "../pages/project-management/project-labels.vue"
import ProjectPriority from "../pages/project-management/project-priorities.vue"
import ProjectStatus from "../pages/project-management/project-statuses.vue"
import ProjectTask from "../pages/project-management/tasks/index.vue"
import ProjectTaskCreate from "../pages/project-management/tasks/create.vue"
import ProjectTaskEdit from "../pages/project-management/tasks/edit.vue"
import ProjectReports from "../pages/project-management/project-reports/index.vue"
import ProjectVisualizations from "../pages/project-management/project-reports/project-visualizations.vue"
import ProjectGanttChart from "../pages/project-management/project-reports/gantt-chart.vue"
import ProjectReportDetail from "../pages/project-management/project-reports/edit.vue"
import ProjectComparission from "../pages/project-management/project-reports/comparission.vue"

/**
 * FILE MANAGER
 */
import Files from "../pages/file-manager/Files.vue"
import AppLogs from "../pages/app-logs/AppLogs.vue"

const routes = [
    { path: "/", component: Login, redirect: "/dashboard" },
    { path: "/dashboard", component: Dashboard },
    { path: "/my-profile", component: MyProfile },
    { path: "/reports", component: ReportMaster },

    // new routes
    { path: "/departments", component: DepartmentMaster },
    { path: "/bank-masters", component: BankMaster },
    { path: "/bank-masters/create", component: BankMasterEdit },
    { path: "/bank-masters/edit/:id", component: BankMasterEdit, name: "bank-master-edit" },

    { path: "/cctv-masters", component: CCTVMaster },
    { path: "/designations", component: DesignationMaster },
    { path: "/companies", component: CompanyMaster },
    { path: "/notifications", component: NotificationMaster },
    { path: "/notices", component: NoticeMaster },
    { path: "/companies/create", component: CompanyEdit },
    { path: "/companies/:id", name: "company-edit", component: CompanyEdit },
    { path: "/work-location-types", component: WorkLocationTypeMaster },
    { path: "/employment-statuses", component: EmploymentStatuses },
    { path: "/master-templates", component: MasterTemplate },
    { path: "/permission-editor", component: PermissionEditor },
    { path: "/generate-letter", component: GenerateTemplate },
    {
        path: "/master-template/create",
        name: "master-template-create",
        component: EditMaster,
    },
    {
        path: "/master-template/edit/:id",
        name: "master-template-edit",
        component: EditMaster,
    },

    // work location
    { path: "/work-locations", component: WorkLocationMaster },
    {
        path: "/work-locations/create",
        component: WorkLocationEdit,
        name: "work-location-create",
    },
    {
        path: "/work-locations/:id",
        component: WorkLocationEdit,
        name: "work-location-edit",
    },

    { path: "/user-roles", component: UserRole },
    { path: "/employees", name: "employee", component: Employee },
    { path: "/employees/create", name: "employee", component: EmployeeEdit },
    {
        path: "/employees/edit/:id",
        name: "employee-edit",
        component: EmployeeEdit,
    },

    // old routes
    { path: "/super-admins", component: SuperAdmins },
    {
        path: "/super-admins/:id",
        // alias: "/setting/:id",
        component: EditSuperAdmin,
        name: "user-edit",
    },
    //fresh ecomnew routes path

    {
        path: "/user-roles/:id",
        // alias: "/subject-master/:id",
        component: EditUserRole,
        name: "user-role-edit",
    },

    /**
     * ATTENDANCE ROUTES
     */
    { path: "/attendance-holiday-types", component: AttendanceHolidayTypes },
    { path: "/attendance-statuses", component: AttendanceStatuses },
    { path: "/attendance-shifts", component: AttendanceShifts },
    { path: "/attendance-records", component: AttendanceRecords },
    { path: "/attendance-import", component: AttendanceImport },

    { path: "/attendance-leave-types", component: LeaveTypes },
    { path: "/attendance-leave-requests", component: LeaveRequests },
    {
        path: "/attendance-leave-requests-approved",
        component: LeaveRequestsApproved,
    },
    {
        path: "/attendance-leave-requests-rejected",
        component: LeaveRequestsRejected,
    },

    { path: "/attendance-overtime-requests", component: OvertimeRequests },
    {
        path: "/attendance-overtime-requests-approved",
        component: OvertimeRequestsApproved,
    },
    {
        path: "/attendance-overtime-requests-rejected",
        component: OvertimeRequestsRejected,
    },

    // attendance holidays
    { path: "/attendance-holidays", component: AttendanceHolidays },
    {
        path: "/attendance-holidays/create",
        component: AttendanceHolidaysEdit,
        name: "attendance-holidays-create",
    },
    {
        path: "/attendance-holidays/:id",
        component: AttendanceHolidaysEdit,
        name: "attendance-holidays-edit",
    },
    {
        path: "/check-in-out-tracking",
        component: CheckInOutTracking,
        name: "check-in-out",
    },

    /**
     * COMMUNICATION ROUTES
     */
    { path: "/communication-groups", component: CommunicationGroup },
    { path: "/communication-message/:id", component: CommunicationMessages },
    { path: "/settings", component: SettingIndex },

    /**
     * PAYROLL ROUTES
     */
    { path: "/payheads", component: PayheadIndex },
    { path: "/payroll-history", component: PayrollHistory },
    {
        path: "/payroll-history/:id",
        component: PayrollHistoryDetail,
        name: "payroll_history_detail",
    },
    { path: "/create-payroll", component: CreatePayroll },
    // salary histories
    { path: "/salary-histories", component: SalaryUpdateHistory },
    { path: "/asset-categories", component: AssetCategoryMaster },
    { path: "/assets", component: AssetMaster },
    { path: "/assets/:id/logs", component: AssetLogChronological },
    { path: "/asset-allocations", component: AssetAllocation },
    { path: "/asset-allocation-statuses", component: AssetAllocationStatus },
    { path: "/asset-logs", component: AssetLog },
    { path: "/asset-log-types", component: AssetLogType },
    // static pages

    // project management
    { path: "/projects", component: Project },
    { path: "/project-categories", component: ProjectCategory },
    { path: "/project-labels", component: ProjectLabel },
    { path: "/project-priorities", component: ProjectPriority },
    { path: "/project-statuses", component: ProjectStatus },
    { path: "/project-tasks", component: ProjectTask },
    { path: "/project-tasks/create", component: ProjectTaskCreate },
    { path: "/project-tasks/:id", component: ProjectTaskEdit },
    { path: "/project-reports", component: ProjectReports },
    { path: "/project-visualizations", component: ProjectVisualizations },
    { path: "/project-ganttchart", component: ProjectGanttChart },
    { path: "/project-report-detail/:id", component: ProjectReportDetail },
    { path: "/project-comparision", component: ProjectComparission },

    // file management
    { path: "/files", component: Files },

    // logs
    { path: "/app-logs", component: AppLogs },
]

export default routes
