<template>
    <PageCard title="Create Master Template">
        <div class="master-template-container">
            <Button noMargin @click="$router.push('/master-templates')">
                <i class="fas fa-chevron-left"></i> Back To Master Lists
            </Button>
            <div class="">
                <div class="my-3">
                    <CustomInput v-model="title" label="Title" :required="true" />
                </div>
                <div class="">
                    <div class="my-3">
                        <quill-editor
                            style="height: 250px"
                            ref="myQuillEditor"
                            v-model="content"
                            :options="editorOptions"
                        />
                    </div>
                    <div class="mt-5 buttons-container">
                        <button
                            v-for="(tag, index) in tags"
                            :key="index"
                            class="tag-button"
                            @click="insertTag(tag.value)"
                        >
                            {{ tag.label }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="">
                <Button @click="cancelEdit">Cancel</Button>
                <Button class="mx-2" @click="createOrUpdateMasterTemplate">
                    {{ processing ? "Please wait..." : editMode ? "Update" : "Submit" }}
                </Button>
            </div>
        </div></PageCard
    >
</template>

<script>
import { quillEditor } from "vue-quill-editor"
import "quill/dist/quill.core.css"
import "quill/dist/quill.snow.css"
import Button from "../../components/UI/Button.vue"
import CustomInput from "../../components/crud/custom-input.vue"
import { http } from "@/utils/http"
import PageCard from "../../components/layout/PageCard.vue"

export default {
    data() {
        return {
            title: "",
            content: "",
            processing: false,
            editMode: true,
            editTemplateData: null,
            editorOptions: {
                theme: "snow",
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        [{ header: 1 }, { header: 2 }],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ indent: "-1" }, { indent: "+1" }],
                        [{ direction: "rtl" }],

                        [{ size: ["small", false, "large", "huge"] }],

                        [{ color: [] }, { background: [] }],
                        [{ font: [] }],
                        [{ align: [] }],

                        ["clean"],
                    ],
                },
            },
            tags: [
                { label: "Full Name", value: "[Full Name]" },
                { label: "First Name", value: "[First Name]" },
                { label: "Last Name", value: "[Last Name]" },
                { label: "Salary Total", value: "[Salary Total]" },
                { label: "CTC", value: "[CTC]" },
                // Add other tags as needed
                { label: "Date of Birth", value: "[DOB]" },
                { label: "Email", value: "[Email]" },
                { label: "Mobile", value: "[Mobile]" },
                { label: "Employee Code", value: "[Employee Code]" },
                { label: "Country", value: "[Country]" },
                { label: "State", value: "[State]" },
                { label: "City", value: "[City]" },
                { label: "Address", value: "[Address]" },
                { label: "Salary Company", value: "[Salary Company]" },
                { label: "Department", value: "[Department]" },
                { label: "Designation", value: "[Designation]" },
                { label: "Supervisor/Reporter", value: "[Supervisor/Reporter]" },
                { label: "Employment Status", value: "[Employment Status]" },
                { label: "Work Location (multiple)", value: "[Work Location]" },
                { label: "Termination Start Date", value: "[Termination Start Date]" },
                { label: "Termination End Date", value: "[Termination End Date]" },
                { label: "Notice Period Days", value: "[Notice Period Days]" },
                { label: "Asset Name", value: "[Asset Name]" },
                { label: "Asset Price", value: "[Asset Price]" },
                { label: "Serial Number", value: "[Serial Number]" },
            ],
        }
    },
    components: {
        Button,
        CustomInput,
        quillEditor,
        PageCard,
    },
    async mounted() {
        await this.initIfEditMode()
    },
    methods: {
        insertTag(tag) {
            const quill = this.$refs.myQuillEditor.quill
            const range = quill.getSelection(true)
            if (range) {
                quill.insertText(range.index, tag)
                quill.focus()
            }
        },
        cancelEdit() {
            this.$router.push("/master-templates")
        },
        async initIfEditMode() {
            const id = this.$route.params.id
            console.log("id", id)
            if (id) {
                this.editMode = true
                const res = await http.get("/master-template/" + id)
                this.editTemplateData = res.data.result

                // set the defaults to each field.
                if (this.editTemplateData) {
                    this.title = this.editTemplateData.title || ""
                    this.content = this.editTemplateData.content || ""
                }
            } else {
                this.editMode = false
            }
        },
        async createOrUpdateMasterTemplate() {
            if (this.processing) return
            this.processing = true

            const formDataObj = {
                title: this.title,
                content: this.content,
            }

            try {
                if (this.editMode) {
                    await http.patch(`/master-template/${this.editTemplateData.id}`, formDataObj)
                    this.$toast.success("Master Template Updated Successfully")
                } else {
                    await http.post("/master-template", formDataObj)
                    this.$toast.success("Master Template Created Successfully")
                }
                window.location.href = "/master-templates"
            } catch (err) {
                console.log("error in creating/updating Master Template", err)
                this.$toast.error(
                    (err.response && err.response.data && err.response.data.message) ||
                        "Some error occurred"
                )
            }
            this.processing = false
        },
    },
}
</script>

<style>
/* Your existing styles */
.tag-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    /* max-height: 200px; Adjust maximum height as needed */
    overflow-y: auto; /* Enable vertical scrollbar */
}
.tag-list li {
    margin-bottom: 5px;
}
.buttons-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
}

.tag-button {
    background-color: rgb(113, 82, 82);
    color: white;
    padding: 4px 6px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
    font-size: 0.675rem; /* text-sm */
    margin-top: 10px;
}

.tag-button:hover {
    background-color: #a02e1f;
}
</style>
