<template>
    <div class="mt-3">
        <h2 class="mb-3" style="font-size: 18px">Project Reports</h2>
        <CustomTable v-if="!loadingData">
            <template #head>
                <tr>
                    <th>Project Name</th>
                    <th>Total Tasks</th>
                    <th>Total Subtasks</th>
                    <th>Project Status</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Actions</th>
                </tr>
            </template>
            <template #body>
                <tr v-for="project of projects" :key="project.id">
                    <td>{{ project.Project && project.Project.name }}</td>
                    <td>{{ getTotalTasks(project.projectId) }}</td>
                    <td>{{ getTotalSubtasks(project) }}</td>
                    <td>{{ project.Status && project.Status.name }}</td>
                    <td>{{ formatIndianDate(project.startDate) }}</td>
                    <td>{{ formatIndianDate(project.endDate) }}</td>
                    <td>
                        <i
                            class="fas fa-eye"
                            style="color: blue; margin-right: 15px; cursor: pointer"
                            @click="viewProject(project.Project.id)"
                        ></i>
                    </td>
                </tr>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import CrudIndex from "@/components/crud/index"
import { mapState, mapActions } from "vuex"
import { http } from "@/utils/http"
import Button from "../../../components/UI/Button.vue"
import CustomTable from "../../../components/other/CustomTable.vue"
import { formatIndianDate } from "../../../utils/common"

export default {
    components: { CrudIndex, Button, CustomTable },
    computed: {
        ...mapState("auth", ["user", "isAdmin"]),
    },
    data() {
        return {
            loadingData: false,
            projects: [],
        }
    },
    async mounted() {
        this.fetchProjectsWithTaskInfo()
    },
    methods: {
        formatIndianDate,
        async fetchProjectsWithTaskInfo() {
            try {
                this.loadingData = true
                const { data } = await http.get("/project-tasks")
                this.projects = data.result
                this.loadingData = false
            } catch (err) {
                console.error("Error fetching project data:", err)
                this.loadingData = false
            }
        },
        getTotalTasks(projectId) {
            return this.projects.filter((project) => project.projectId === projectId).length
        },
        getTotalSubtasks(project) {
            return project.Tasks ? project.Tasks.filter((task) => task.taskId !== null).length : 0
        },
        viewProject(id) {
            this.$router.push(`/project-report-detail/${id}`)
        },
    },
}
</script>
