<template>
    <div>
        <router-link to="/assets" class="mb-3">
            <Button class="me-2"> <i class="fas fa-chevron-left"></i> Back to Asset List </Button>
        </router-link>
        <SectionTitle>Asset Log History</SectionTitle>
        <div class="my-3">
            <p><strong>Details:</strong></p>
            <div class="my-2" v-if="asset">
                <div>Name: {{ asset.name }}</div>
                <div>Price: {{ asset.price }}</div>
                <div>Created On: {{ formatIndianDate(asset.createdAt) }}</div>
                <!-- <div>Status: {{ asset.statusId }}</div> -->
            </div>
        </div>
        <div class="my-2">
            <AssetLogChronologicalViewItem
                :key="assetLog.id"
                :assetLog="assetLog"
                v-for="assetLog of assetLogs"
                :id="assetLog.id"
            />
        </div>
        <div v-if="!assetLogs.length">No Asset Logs Found!</div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import CrudIndex from "../../components/crud/index.vue"
import Button from "../../components/UI/Button.vue"
import SectionTitle from "../../components/UI/SectionTitle.vue"
import { createField, createColumn, formatIndianDate } from "../../utils/common"
import { http } from "../../utils/http"
import AssetLogChronologicalViewItem from "./asset-log-chronological-view-item.vue"

export default {
    data() {
        return {
            loading: true,
            assetLogs: [],
            asset: null,
        }
    },
    components: { Button, SectionTitle, CrudIndex, AssetLogChronologicalViewItem },
    mounted() {
        this.init()
    },
    computed: {
        ...mapState("misc", ["companies"]),
    },
    watch: {},
    methods: {
        formatIndianDate,
        async init() {
            this.fetchAssetLogs()
        },
        async fetchAssetLogs() {
            this.loading = true
            try {
                const res = await http.get(`/asset-logs?asset=${this.$route.params.id}`)
                this.asset = res.data.asset
                this.assetLogs = res.data.result
            } catch (err) {
                console.log("error in fetching logs")
            }
            this.loading = false
        },
    },
}
</script>

<style></style>
