<template>
    <div>
        <AllowPermission :permission="PERMISSION_CODES.CREATE_MASTER_TEMPLATES">
            <div class="d-flex gap-3 align-items-start">
                <Button @click="navigateToCreateTemplate">
                    <i class="fas fa-plus"></i>
                    Create Master Template</Button
                >
                <Button @click="navigateToGenerateLetter">
                    <i class="fas fa-plus"></i>
                    Generate Letter</Button
                >
            </div>
        </AllowPermission>

        <CrudIndex
            v-if="!loading && showResults"
            title="Master Template"
            :viewColumns="columns"
            :permission="PERMISSION_CODES.VIEW_EMPLOYMENT_STATUSES"
            :fetchUrl="`/master-template/${selectedCompanyId || ''}`"
            updateRootUrl="/master-template"
            editRouteName="master-template-edit"
            deleteRootUrl="/master-template"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"
import Button from "../../components/UI/Button.vue"
import SelectDropdown from "../../components/crud/SelectDropdown.vue"
import { PERMISSION_CODES } from "../../store/permission"
import AllowPermission from "../../components/layout/AllowPermission.vue"

export default {
    data() {
        return {
            showResults: true,
            loading: false,
            videos: [],
            createFields: [],
            selectedCompanyId: null,
            updatingEmployeeCTC: false,
            PERMISSION_CODES,
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Title", "title", "string"),
            ],
        }
    },

    props: { videoId: { type: String, default: "" } },
    components: { AllowPermission, CrudIndex, Button, SelectDropdown },
    async mounted() {},
    methods: {
        navigateToCreateTemplate() {
            this.$router.push("/master-template/create")
        },
        navigateToGenerateLetter() {
            this.$router.push("/generate-letter")
        },
    },
    watch: {
        selectedCompanyId(e) {
            this.showResults = false
            setTimeout(() => {
                this.showResults = true
            }, 100)
        },
    },
}
</script>

<style></style>
