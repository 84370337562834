<template>
    <tr v-if="assetAllocation">
        <td>#{{ slNo || assetAllocation.id }}</td>
        <td>{{ assetAllocation.asset?.name }}</td>
        <td>
            {{ assetAllocation.allocatedDate && formatIndianDate(assetAllocation.allocatedDate) }}
        </td>
        <td>
            {{ assetAllocation.returned ? "Returned" : "Allocated" }}
        </td>
        <td>
            {{
                assetAllocation.returnedDate ? formatIndianDate(assetAllocation.returnedDate) : "NA"
            }}
        </td>
        <td>
            <Button :disabled="assetAllocation.returned" noMargin @click="returnAsset"
                >{{ returning ? "Please wait..." : "Return Asset" }}
            </Button>
        </td>
    </tr>
</template>

<script>
import { errMsg, formatIndianDate } from "../../utils/common"
import { http } from "../../utils/http"
import Button from "../UI/Button.vue"

export default {
    components: { Button },
    props: {
        slNo: {
            type: Number,
            default: 0,
        },
        assetAllocation: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            returning: false,
        }
    },
    methods: {
        formatIndianDate,
        async returnAsset() {
            if (this.returning) return
            this.returning = true
            try {
                const res = await http.patch(`/asset-allocations/${this.assetAllocation.id}/return`)
                const returnedAssetAllocation = res.data.result
                this.$emit("updateReturnedAssetAllocation", returnedAssetAllocation)
                this.$toast.success("Asset returned successfully!")
            } catch (err) {
                console.log("error in returning asset", err)
                this.$toast.error(errMsg(err))
            }
            this.returning = false
        },
    },
}
</script>

<style></style>
