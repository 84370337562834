<template>
    <PageCard sm title="Leave Approval Status" style="padding-top: 15px">
        <Spinner v-if="!dashboardStatsLeaveRequestData" />
        <CustomTable v-else sm style="font-size: 0.9em">
            <template #head>
                <tr>
                    <th>FOR DATE</th>
                    <th>EMPLOYEE</th>
                    <th>TYPE</th>
                    <th>DURATION</th>
                    <th>STATUS</th>
                </tr>
            </template>
            <template #body>
                <tr v-for="leaveRequest of dashboardStatsLeaveRequestData.leaveRequestResult" :key="leaveRequest.dateOfApplication">
                    <td>{{ leaveRequest.dateOfApplication }}</td>
                    <td>{{ leaveRequest.employeeName }}</td>
                    <td>{{ leaveRequest.applicationType }}</td>
                    <td>{{ leaveRequest.duration }}</td>
                    <td>
                        <div
                            :style="`padding: 5px; text-align: center; font-size: 12px;border-radius: 5px; background: ${leaveRequest.statusColor}; color: ${leaveRequest.statusTextColor}`"
                        >
                            {{ leaveRequest.status }}
                        </div>
                    </td>
                </tr>
            </template>
        </CustomTable>
        <ColorLabelView
            v-if="dashboardStatsLeaveRequestData"
            :labels="[
                { color: dashboardStatsLeaveRequestData.leaveRequestStatusTextColors.Approved, title: 'Approved' },
                { color: dashboardStatsLeaveRequestData.leaveRequestStatusTextColors.Rejected, title: 'Rejected' },
                { color: dashboardStatsLeaveRequestData.leaveRequestStatusTextColors.Pending, title: 'Pending' },
            ]"
        />
        <div class="my-3">
            <router-link to="/attendance-leave-requests">
                <Button noMargin>View Pending Tasks</Button>
            </router-link>
        </div>
    </PageCard>
</template>

<script>
import { mapState } from "vuex"
import PageCard from "../layout/PageCard.vue"
import CustomTable from "../other/CustomTable.vue"
import Button from "../UI/Button.vue"
import SectionTitle from "../UI/SectionTitle.vue"
import Spinner from "../UI/Spinner.vue"
import ColorLabelView from "./ColorLabelView.vue"

export default {
    components: { Spinner, PageCard, Button, SectionTitle, CustomTable, ColorLabelView },
    props: {},
    computed: {
        ...mapState("misc", ["dashboardStatsLeaveRequestData"]),
    },
}
</script>

<style lang="scss" scoped>
.amount-value {
    color: #444;
    font-size: 1.3em;
}
.amount-label {
    color: #444;
    font-size: 0.8em;
}
</style>
