<template>
    <div v-if="!authInitialized" class="d-flex justify-content-center align-items-center py-5">
        <img src="@/assets/Triangles-1s-200px.gif" alt="" />
    </div>
    <div v-else>
        <div class="main-bg w-full" style="min-height: 100vh">
            <div class="row">
                <div class="col-md-12 p-4" v-if="!authenticated || $route.path == '/privacy-policy'">
                    <ForgotForm v-if="$route.path == '/forgot-password'" />
                    <ResetForm v-else-if="$route.path == '/reset-password'" />
                    <PrivacyPolicy v-else-if="$route.path == '/privacy-policy'" />
                    <LoginForm v-else />
                </div>
                <div class="col-3" style="padding: 0px" v-if="authenticated && showSidebar && $route.path !== '/privacy-policy'">
                    <Sidebar />
                </div>
                <div
                    v-if="$route.path !== '/privacy-policy'"
                    :class="[{ 'col-9': authenticated && showSidebar }, { 'col-md-12': !authenticated || !showSidebar }]"
                    style="padding: 0px; display: flex; flex-direction: column"
                >
                    <Header v-if="authenticated" />
                    <div class="" style="flex: 1" v-if="authenticated">
                        <div class="py-3" style="height: 100%">
                            <div class="container-fluid">
                                <router-view v-if="authenticated" style="padding: 15px" />
                            </div>
                        </div>
                    </div>
                    <div class="border-top text-muted bg-white small" v-if="authenticated">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 p-4 text-center">&copy; Copyright Samyak, All rights reserved.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import Sidebar from "./components/layout/Sidebar.vue"
import Header from "./components/layout/Header.vue"
import LoginForm from "./components/layout/LoginForm.vue"
import ForgotForm from "./components/layout/ForgotForm.vue"
import ResetForm from "./components/layout/ResetForm.vue"
import PrivacyPolicy from "./pages/privacy-policy.vue"

export default {
    name: "App",
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
        }),
        ...mapState("auth", ["authInitialized"]),
        ...mapState("auth", ["user"]),
        ...mapState("misc", ["showSidebar"]),
    },
    components: { Sidebar, ForgotForm, ResetForm, LoginForm, Header, PrivacyPolicy },
    data() {
        return { loggingOut: false }
    },
    methods: {
        ...mapActions("auth", ["initAuth"]),
        ...mapActions("auth", ["logout"]),
        ...mapActions("misc", ["fetchDashboardStatsMasters", "fetchAttendanceInfo"]),
        async logout() {
            this.loggingOut = true
            await this.logout()
            window.location.href = "/login"
        },
    },
    watch: {
        $route(e) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            })
        },
    },
    async mounted() {
        const result = await this.initAuth()
        if (result) {
            await this.fetchAttendanceInfo()
        }
        if (this.authenticated) {
            this.fetchDashboardStatsMasters()
        }
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
body {
    font-size: 0.8em;
}
.main-bg {
    background: #f3f3f3;
}
</style>
