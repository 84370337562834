<template>
    <div>
        <CrudIndex
            v-if="!loading"
            :permission="PERMISSION_CODES.VIEW_SALARY_UPDATE_HISTORY"
            title="Salary Update History"
            :viewColumns="columns"
            :fetchUrl="`/salary-update-histories?employee_id=${employeeId}`"
            deleteRootUrl="/salary-update-histories"
            :allowEditing="false"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { PERMISSION_CODES } from "../../store/permission"
import { createField, createColumn } from "../../utils/common"

export default {
    data() {
        return {
            createFields: [
                createField("Name*", "name", "text", true),
                // createField("Status", "status", "switch", false, true),
            ],
            columns: [],
            loading: true,
            PERMISSION_CODES,
        }
    },
    mounted() {
        let columns = []
        if (!this.employeeId) {
            columns.push(createColumn("Employee ID", "employeeId", "string"))
        }
        columns = [
            ...columns,
            createColumn("Employee Name", "employeeName", "string"),
            createColumn("Old Ctc", "oldCtc", "number"),
            createColumn("New Ctc", "newCtc", "number"),
            createColumn("Increment Percent(%)", "incrementPercent", "number"),
            createColumn("Date", "date", "string"),
            // createColumn("Active", "status", "string"),
        ]
        this.columns = columns

        this.loading = false
    },
    props: { employeeId: { type: [String, Number], default: "" } },
    components: { CrudIndex },
}
</script>

<style></style>
