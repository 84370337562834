<template>
    <div>
        <nav class="navbar-header">
            <div class="container-fluid header-link-container">
                <div class="d-flex align-items-center" style="gap: 20px; flex: 1">
                    <div>
                        <i @click="toggleShowSidebar" class="fas fa-bars" style="font-size: 25px; color: #555; cursor: pointer"></i>
                    </div>
                    <!-- <HeaderSearch /> -->
                </div>
                <div style="font-size: 0.9em">
                    <Button noMargin exact-active-class="active-link" @click="$router.push('/my-profile')" class="mr-4">
                        My Profile
                    </Button>
                    <Button noMargin class="logout-btn" @click="logoutUtil">Logout</Button>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex"
import HeaderSearch from "../header/HeaderSearch.vue"
import Button from "../UI/Button.vue"

export default {
    data() {
        return {}
    },
    computed: {
        ...mapState("misc", ["pageTitle", "showSidebar"]),
    },
    methods: {
        ...mapActions("auth", ["logout"]),
        ...mapActions("misc", ["setData"]),
        logoutUtil() {
            this.logout()
            window.location.reload()
        },
        toggleShowSidebar() {
            this.setData({
                showSidebar: !this.showSidebar,
            })
        },
    },
    components: { Button, HeaderSearch },
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.navbar-header {
    // border-bottom: 3px solid lighten($primaryColor, 40%);
    box-shadow: 2px 0 2px 0 rgba(0, 0, 0, 0.3);
    background-color: rgb(225, 225, 225);
    padding: 10px;
}
.header-link-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.active-link {
    color: $primaryColor;
}
.logout-btn {
    &:hover {
        color: $primaryColor;
    }
}
</style>
