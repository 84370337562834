import { render, staticRenderFns } from "./PageCard.vue?vue&type=template&id=55151be8&scoped=true"
import script from "./PageCard.vue?vue&type=script&lang=js"
export * from "./PageCard.vue?vue&type=script&lang=js"
import style0 from "./PageCard.vue?vue&type=style&index=0&id=55151be8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55151be8",
  null
  
)

export default component.exports