<template>
    <PageCard title="Generate Letter">
        <div class="">
            <Button noMargin @click="$router.push('/master-templates')">
                <i class="fas fa-chevron-left"></i> Back To Master Lists
            </Button>
            <div class="mt-3 card-title">Select Employee</div>
            <div class="search-select-container">
                <SelectDropdown
                    v-if="filteredEmployees && filteredEmployees.length"
                    :id="`form-subject`"
                    :options="
                        filteredEmployees.map((item) => ({
                            id: item.id,
                            title: `${item.firstName} ${item.lastName}`,
                        }))
                    "
                    :value="selectedEmployeeId"
                    @change="selectEmployee"
                />
            </div>
            <div class="card-title my-3">Select Master Template</div>
            <div class="search-select-container">
                <SelectDropdown
                    v-if="templates && templates.length"
                    :id="`template`"
                    :options="
                        templates.map((item) => ({
                            id: item.id,
                            title: `${item.title}`,
                        }))
                    "
                    :value="selectedTemplateId"
                    @change="selectTemplate"
                />
            </div>
            <div class="btns">
                <Button class="generate-btn" @click="showPreview"
                    >Preview <i class="fas fa-external-link"></i
                ></Button>
                <Button class="generate-btn" @click="showPreview(true)"
                    >Download <i class="fas fa-download"></i
                ></Button>
            </div>
            <!-- Modal Component -->
            <b-modal v-model="showModal" class="custom-modal">
                <div v-html="populatedTemplate" class="template-preview"></div>
            </b-modal>
        </div>
    </PageCard>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue"
import { http } from "@/utils/http"
import VueHtml2pdf from "vue-html2pdf"
import SelectDropdown from "../../components/crud/SelectDropdown.vue"
import Button from "../../components/UI/Button.vue"
import { baseURL } from "../../utils/http"
import PageCard from "../../components/layout/PageCard.vue"

export default {
    components: { SelectDropdown, Button, PageCard },
    methods: {
        selectTemplate(id) {
            this.selectedTemplateId = id
            this.populateTemplate()
            console.log("selecte mpoatel")
        },
        selectEmployee(id) {
            this.selectedEmployeeId = id
            this.populateTemplate()
        },
        openPdfLink(downloadMode) {
            window.open(
                `${baseURL}/master-template/generated-template?download=${
                    downloadMode ? "true" : "false"
                }&employee_id=${this.selectedEmployeeId}&template_id=${this.selectedTemplateId}`,
                downloadMode ? "_self" : "_blank"
            )
        },
        showPreview(downloadMode = false) {
            if (!this.selectedTemplateId || !this.selectedEmployeeId) {
                return this.$toast.error("Please select employee and template")
            }
            this.openPdfLink(downloadMode)
        },
        async downloadPDF() {
            try {
                // not working
                // Ensure VueHtml2pdf is correctly instantiated
                const vueHtml2pdf = new VueHtml2pdf()
                await vueHtml2pdf.htmlToPdf(this.populatedTemplate, "letter.pdf", () => {
                    console.log("PDF successfully generated.")
                })
            } catch (error) {
                console.error("Error generating PDF:", error)
            }
        },
    },
    setup() {
        const employees = ref([])
        const selectedEmployeeId = ref(null)
        const templates = ref([])
        const selectedTemplateId = ref(null)
        const populatedTemplate = ref("")
        const showModal = ref(false)
        const employeeSearch = ref("")
        const templateSearch = ref("")

        onMounted(async () => {
            try {
                const responseEmployees = await http.get("/employees")
                employees.value = responseEmployees.data.result

                const responseTemplates = await http.get("/master-template")
                templates.value = responseTemplates.data.result
            } catch (error) {
                console.error("Error fetching data:", error)
            }
        })

        const filteredEmployees = computed(() => {
            if (!employeeSearch.value) return employees.value
            return employees.value.filter((e) =>
                e.fullName.toLowerCase().includes(employeeSearch.value.toLowerCase())
            )
        })

        const filteredTemplates = computed(() => {
            if (!templateSearch.value) return templates.value
            return templates.value.filter((t) =>
                t.title.toLowerCase().includes(templateSearch.value.toLowerCase())
            )
        })

        const populateTemplate = () => {
            const employee = employees.value.find((e) => e.id === selectedEmployeeId.value)
            const template = templates.value.find((t) => t.id === selectedTemplateId.value)

            if (employee && template) {
                let content = template.content

                const placeholders = [
                    "Full Name",
                    "First Name",
                    "Last Name",
                    "Salary Total",
                    "CTC",
                    "Email",
                    "Mobile",
                    "Employee Code",
                    "DOB",
                    "Country",
                    "State",
                    "City",
                    "Address",
                    "Salary Company",
                    "Department",
                    "Designation",
                    "Supervisor/Reporter",
                    "Employment Status",
                    "Work Location (multiple)",
                    "Termination Start Date",
                    "Termination End Date",
                    "Notice Period Days",
                    "Asset Name",
                    "Asset Price",
                    "Serial Number",
                ]

                placeholders.forEach((placeholder) => {
                    let value = placeholder.replace(/\s/g, "") // Remove spaces for field matching
                    let replacement = ""

                    switch (value) {
                        case "FullName":
                            replacement = employee.fullName || placeholder
                            break
                        case "FirstName":
                            replacement = employee.firstName || placeholder
                            break
                        case "LastName":
                            replacement = employee.lastName || placeholder
                            break
                        case "CTC":
                            replacement = employee.ctc || placeholder
                            break
                        case "Email":
                            replacement = employee.email || placeholder
                            break
                        case "Mobile":
                            replacement = employee.phone || placeholder // Update with 'phone' field
                        case "EmployeeCode":
                            replacement = employee.empCode || placeholder
                            break
                        case "Address":
                            replacement = employee.address || placeholder
                            break
                        case "Country":
                            replacement = employee.country || placeholder
                            break
                        case "State":
                            replacement = employee.state || placeholder
                            break
                        case "City":
                            replacement = employee.city || placeholder
                            break
                        case "Department":
                            replacement = employee.department || placeholder
                            break
                        case "Designation":
                            replacement = employee.designation || placeholder
                            break
                        case "Supervisor/Reporter":
                            replacement = employee.supervisor || placeholder
                            break
                        case "EmploymentStatus":
                            replacement = employee.employmentStatus || placeholder
                            break
                        case "WorkLocation(multiple)":
                            replacement = employee.workLocations || placeholder
                            break
                        case "TerminationStartDate":
                            replacement = employee.terminationStartDate || placeholder
                            break
                        case "TerminationEndDate":
                            replacement = employee.terminationEndDate || placeholder
                            break
                        case "NoticePeriodDays":
                            replacement = employee.noticePeriodDays || placeholder
                            break
                        case "AssetName":
                            replacement = employee.assetName || placeholder
                            break
                        case "AssetPrice":
                            replacement = employee.assetPrice || placeholder
                            break
                        case "SerialNumber":
                            replacement = employee.serialNumber || placeholder
                            break
                        default:
                            replacement = placeholder
                    }

                    content = content.replace(
                        new RegExp(`\\[${placeholder}\\]`, "g"),
                        replacement ? replacement : placeholder
                    )
                })

                populatedTemplate.value = content
            }
        }

        // Watch for changes in selectedEmployeeId and selectedTemplateId
        watch([selectedEmployeeId, selectedTemplateId], () => {
            populateTemplate()
        })

        return {
            employees,
            selectedEmployeeId,
            templates,
            selectedTemplateId,
            populatedTemplate,
            showModal,
            populateTemplate,
            employeeSearch,
            templateSearch,
            filteredEmployees,
            filteredTemplates,
        }
    },
}
</script>

<style scoped>
.card {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    width: 80%;
}

.card-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
}

.search-select-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.employee-list {
    list-style-type: none;
    padding: 0;
    max-height: 200px; /* Adjust based on your needs */
    overflow-y: auto; /* Enable scrolling if the list is too long */
}

.employee-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
}

.employee-item:hover {
    background-color: #f8f9fa;
}

.form-control.custom-select {
    width: 100%;
    padding: 7px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    background-color: #fff;
    box-sizing: border-box;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease;
    font-size: 16px;
    cursor: pointer;
    appearance: none; /* Remove default select styling */
    -webkit-appearance: none; /* For Chrome and Safari */
    -moz-appearance: none; /* For Firefox */
    background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z"/></svg>'); /* Custom arrow icon */
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
}

.form-control.custom-select:focus {
    border-color: #832b29;
    outline: none;
    box-shadow: 0 0 5px rgba(30, 144, 255, 0.25);
}

.form-control.custom-select:hover {
    border-color: #832b29;
}

.template-preview {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    min-height: 100px;
    overflow-y: auto;
}

.btns {
    display: flex;
    gap: 10px;
}

.generate-btn {
    background-color: #832b29;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.2s ease;
    width: 100px;
    display: inline-block;
}

.generate-btn:hover {
    background-color: #a02e1f;
}

.custom-modal {
    border-radius: 5px;
    background-color: #f8f9fa; /* Matches the card background */
}

.custom-modal .modal-content {
    border-radius: 5px;
}

.custom-modal .modal-header {
    border-bottom: none;
    padding: 15px 20px;
    background-color: #f8f9fa; /* Matches the card background */
}

.custom-modal .modal-body {
    padding: 20px;
}

.custom-modal .close {
    color: #333;
}

.undefined-value {
    color: red;
}
</style>
