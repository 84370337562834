<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="Communication Groups"
            :viewColumns="columns"
            :permission="PERMISSION_CODES.VIEW_COMMUNICATION_GROUPS"
            fetchUrl="/communication-groups"
            createUrl="/communication-groups"
            updateRootUrl="/communication-groups"
            deleteRootUrl="/communication-groups"
            :createFields="createFields"
            :reloadOnSuccess="true"
            v-slot="slotProps"
        >
            <Button style="background: #55f" @click="viewLogs(slotProps.itemId)" class="crud-btn">
                <i class="fas fa-eye" /> View Messages
            </Button>
        </CrudIndex>
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { createField, createColumn } from "../../utils/common"
import { http } from "@/utils/http"
import { PERMISSION_CODES } from "../../store/permission"

export default {
    data() {
        return {
            PERMISSION_CODES,
            loading: true,
            employees: [],
            managers: [],
            createFields: [
                createField("Name*", "name", "text", true),
                createField("Description*", "description", "text", true),
            ],
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Name", "name", "string"),
                createColumn("Last msg date", "lastMsgDateFormatted", "string"),
                createColumn("Msg Permissions", "onlyAllowAdminToSendMsgFormatted", "string"),
                // createColumn("Active", "status", "string"),
            ],
        }
    },
    components: { CrudIndex },
    async mounted() {
        await this.fetchUsersForGroupCreation()
        this.createFields = this.createFields.concat([
            createField(
                "Select Managers/Group Admins*",
                "managerIds",
                "multiselect",
                true,
                "",
                this.managers.map((item) => ({
                    id: item.id,
                    title: `${item.firstName || ""} ${item.lastName || ""}`,
                }))
            ),
            createField(
                "Select Employees/Users*",
                "employeeIds",
                "multiselect",
                true,
                "",
                this.employees.map((item) => ({
                    id: item.id,
                    title: `${item.firstName || ""} ${item.lastName || ""}`,
                }))
            ),
            createField("Select/update thumbnail", "thumbnailUrl", "image", false),
            createField(
                "Only Allow Admins To Send Msg",
                "onlyAllowAdminToSendMsg",
                "switch",
                false
            ),
        ])

        console.log(this.fetchUsersForGroupCreation)
        this.loading = false
    },
    methods: {
        async viewLogs(id) {
            this.$router.push(`communication-message/${id}`)
            // try {

            //     const res = await http.get(`/communication-messages?chat_id=${id}`);
            //     const resData = res.data.result;
            //     console.log(resData);
            // } catch (error) {
            //     console.log("messages not fetched:",error);
            // }
        },

        async fetchUsersForGroupCreation() {
            try {
                const res = await http.get("/communication-groups/init")
                const resData = res.data.result
                this.employees = resData.employees
                this.managers = resData.managers
            } catch (err) {
                console.log("error in fetching communication groups", err)
            }
        },
    },
}
</script>

<style></style>
