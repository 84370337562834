<template>
    <PageCard title="Location Tracking">
        <strong>Select Employees To Not Track Location</strong>
        <div style="max-width: 300px" class="my-3">
            <SelectDropdown
                v-if="employeesFetched"
                id="employee_select"
                :options="
                    employees.map((item) => ({
                        id: item.id,
                        title: `${item.firstName || ''} ${item.lastName || ''}`,
                    }))
                "
                :multiple="true"
                :value="selectedEmployees"
                @change="selectedEmployees = $event"
            />
            <Button @click="updateGeolocationTracking">{{
                processing ? "Please wait..." : "Update"
            }}</Button>
        </div>
    </PageCard>
</template>

<script>
import SelectDropdown from "../../components/crud/SelectDropdown.vue"
import PageCard from "../../components/layout/PageCard.vue"
import Button from "../../components/UI/Button.vue"
import { errMsg } from "../../utils/common"
import { http } from "../../utils/http"

export default {
    data() {
        return {
            employees: [],
            selectedEmployees: [],
            employeesFetched: false,
            processing: false,
        }
    },
    components: { SelectDropdown, Button, PageCard },
    mounted() {
        this.fetchEmployees()
    },
    methods: {
        async fetchEmployees() {
            try {
                const res = await http.get("/employees?limited_fields=true")
                this.employees = res.data.result
                this.selectedEmployees = this.employees
                    .filter((item) => !item.trackGeolocation)
                    .map((item) => item.id)
            } catch (err) {
                console.log("error in fetching communication groups", err)
            }
            this.employeesFetched = true
        },
        async updateGeolocationTracking() {
            this.processing = true
            try {
                await http.patch("/employees/check-in-out-tracking", {
                    employee_ids: this.selectedEmployees,
                })
                this.$toast.success("Updated geolocation tracking!")
            } catch (err) {
                console.log("error in fetching communication groups", err)
                this.$toast.error(errMsg(err, "Could not update geolocation tracking!"))
            }
            this.processing = false
        },
    },
}
</script>

<style></style>
