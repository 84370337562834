<template>
    <div>
        <div class="my-3">
            <div class="my-3 d-flex" style="gap: 10px">
                <CompanyDropdown :companyId="companyId" @change="companyId = $event" />
            </div>
            <div class="my-3 d-flex" style="gap: 10px">
                <Button @click="fetchAssetAllocations(false)" :noMargin="true"
                    ><i class="fas fa-search"></i> {{ loading ? "Searching..." : "Search" }}</Button
                >
                <Button @click="fetchAssetAllocations(true)" :noMargin="true"><i class="fas fa-download"></i> Export Data</Button>
            </div>
            <CustomTable v-if="!loading && result.length">
                <template #head>
                    <tr>
                        <th>Sr. No.</th>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Serial Number</th>
                        <th>Description</th>
                        <th>Price</th>
                        <th>Allocated to</th>
                        <th>Allocation Date</th>
                    </tr>
                </template>
                <template #body>
                    <tr v-for="item of result" :key="item.id">
                        <td>{{ item["Sr No"] }}</td>
                        <td>{{ item["Name"] }}</td>
                        <td>{{ item["Category"] }}</td>
                        <td>{{ item["Serial Number"] }}</td>
                        <td>{{ item["Description"] }}</td>
                        <td>{{ item["Price"] }}</td>
                        <td>{{ item["Allocated To"] }}</td>
                        <td>{{ item["Allocation Date"] }}</td>
                    </tr>
                </template>
            </CustomTable>
            <div v-else>
                <div v-if="loading">Loading...</div>
                <p v-else>No results found!</p>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyDropdown from "../../../components/dropdowns/CompanyDropdown.vue"
import CustomTable from "../../../components/other/CustomTable.vue"
import Button from "../../../components/UI/Button.vue"
import { baseURL, http } from "../../../utils/http"

export default {
    components: {
        CustomTable,
        Button,
        CompanyDropdown,
    },
    data() {
        return {
            result: [],
            companyId: "",
            loading: false,
        }
    },
    mounted() {
        // this.fetchAssetAllocations()
    },
    methods: {
        async fetchAssetAllocations(exportMode = false) {
            this.loading = true
            try {
                const url = `/reports/asset-allocations?company_id=${this.companyId}&export_mode=${exportMode ? "true" : "false"}`
                if (exportMode) {
                    window.open(baseURL + url, "_blank")
                } else {
                    const res = await http.get(url)
                    this.result = res.data.result
                }
            } catch (err) {
                console.log("error in fetching asset allocations", err)
                this.$toast.error("Could not load results")
                this.result = []
            }
            this.loading = false
        },
    },
}
</script>

<style></style>
