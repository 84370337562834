<template>
    <div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="d-flex flex-column align-items-center">
                        <div class="my-5" style="display: grid; place-items: center">
                            <img src="@/assets/logo.png" style="width: 120px" alt="" />
                        </div>
                        <div class="">
                            <h4 class="text-center mb-3">Enter Email Address</h4>
                            <div class="bg-success text-white mb-3 p-4 py-3" v-if="success">
                                Email Reset OTP Sent to <strong>{{ email }}</strong
                                >. Please check your email!
                            </div>
                            <form @submit.prevent="handleSubmit" v-else>
                                <div class="d-flex mb-0" style="gap: 10px">
                                    <label for="email" class="col-form-label text-md-end">
                                        <i class="fas fa-user colored-icon"></i>
                                    </label>
                                    <div class="">
                                        <input
                                            id="email"
                                            type="text"
                                            class="form-control form-control-sm"
                                            placeholder="Email Address"
                                            v-model="email"
                                            required
                                            autocomplete="email"
                                            autofocus
                                            style="min-width: 350px"
                                        />
                                        <span class="invalid-feedback" role="alert">
                                            <strong>Enter Valid Email Address</strong>
                                        </span>
                                    </div>
                                </div>

                                <div class="text-center">
                                    <Button type="submit">
                                        {{ processingForm ? "Please wait..." : "Send Reset OTP" }}
                                    </Button>
                                </div>
                            </form>
                            <div class="text-center">
                                <a href="/login"> Login To App </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import Button from "@/components/UI/Button"
import { errMsg, isValidEmail } from "../../utils/common"

export default {
    data() {
        return {
            email: "",
            success: false,
            processingForm: false,
        }
    },
    components: { Button },
    methods: {
        // ...mapActions("auth", ["login"]),
        ...mapActions({
            sendResetLink: "auth/sendResetLink",
        }),
        async handleSubmit() {
            if (this.processingForm) return
            if (!this.email) {
                return this.$toast.error("Please Fill Details!")
            }
            if (!isValidEmail(this.email)) {
                return this.$toast.error("Please Enter a valid email address!")
            }
            this.processingForm = true
            try {
                await this.sendResetLink({
                    email: this.email,
                })
                this.$toast.success("Email reset OTP sent!")
                window.location.href = `/reset-password?email=${this.email}`
            } catch (err) {
                console.log("Login err", err)
                this.$toast.error(errMsg(err, "Could Not Send Reset Password OTP!"))
            }
            this.processingForm = false
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/variables.scss";

.colored-icon {
    color: $primaryColor;
    font-size: 20px;
}
</style>
