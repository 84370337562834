<template>
    <div class="my-3">
        <p class="my-2">
            <strong>{{ title }}</strong>
        </p>
        <div
            v-for="(data, i) of items"
            :key="i"
            class="d-flex align-items-center"
            style="gap: 15px; width: 100%"
        >
            <div>{{ letters[i] }})</div>
            <custom-input
                style="width: 100%"
                v-model="items[i]"
                @change="emitUpdate"
                type="text"
                required
            />
            <button type="button" style="font-size: 25px" @click="removeItem(i)">&times;</button>
        </div>
        <div class="mt-2">
            <button class="add-btn" type="button" @click="items.push('')">
                <i class="fas fa-plus"></i> Add {{ title }}
            </button>
        </div>
    </div>
</template>

<script>
import CustomInput from "../crud/custom-input.vue"
export default {
    props: {
        value: { type: Array, default: [] },
        title: { type: String, default: "" },
    },
    model: {
        prop: "value",
        event: "input",
    },
    data() {
        return {
            items: [],
            letters: [
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "V",
                "W",
                "X",
                "Y",
                "Z",
            ],
        }
    },
    mounted() {
        this.items = this.value
    },
    methods: {
        removeItem(data) {
            this.items = this.items.filter((item, i) => i != data)
            this.emitUpdate()
        },
        emitUpdate() {
            this.$emit(
                "input",
                this.items.filter((item) => !!item)
            )
        },
    },
    components: { CustomInput },
}
</script>

<style lang="scss" scoped>
.add-btn {
    border-radius: 10px;
    background-color: #37f;
    color: white;
    padding: 5px 15px;
    cursor: pointer;
    font-size: 11px;
    transition: 0.3s;
}
.add-btn:hover {
    background-color: rgb(83, 139, 251);
}
</style>
