var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.sidebarLinkGroupFitlered && _vm.sidebarLinkGroupFitlered.length > 0)?_c('h4',{staticClass:"group-heading",on:{"click":function($event){_vm.showMenu = !_vm.showMenu}}},[(_vm.sidebarLinkGroupFitlered.icon)?_c('i',{class:`fas menu-icon fa-${_vm.sidebarLinkGroupFitlered.icon}`}):_vm._e(),_vm._v(" "+_vm._s(_vm.sidebarLinkGroup.title)+" "),_c('i',{class:[
                { 'fas fa-chevron-down': !_vm.showMenu },
                { 'fas fa-chevron-up': _vm.showMenu },
                'chevron-icon',
            ]})]):_vm._e(),(_vm.showMenu)?_c('div',[_vm._l((_vm.sidebarLinkGroupFitlered),function(sidebarLink){return [(sidebarLink.forceReload)?_c('a',{key:sidebarLink.link,class:[
                    'block outline-none sidebar-item',
                    {
                        active:
                            _vm.$route.path.startsWith(sidebarLink.link) ||
                            _vm.$route.path === sidebarLink.link,
                    },
                ],attrs:{"href":sidebarLink.link}},[(sidebarLink.icon)?_c('i',{class:`fas menu-icon fa-${sidebarLink.icon}`}):_vm._e(),_vm._v(" "+_vm._s(sidebarLink.title)+" ")]):_c('router-link',{key:sidebarLink.link,class:[
                    'block outline-none sidebar-item',
                    {
                        active:
                            _vm.$route.path.startsWith(sidebarLink.link) ||
                            _vm.$route.path === sidebarLink.link,
                    },
                ],attrs:{"to":sidebarLink.link},nativeOn:{"click":function($event){return _vm.scrollTopFirst.apply(null, arguments)}}},[(sidebarLink.icon)?_c('i',{class:`fas menu-icon fa-${sidebarLink.icon}`}):_vm._e(),_vm._v(" "+_vm._s(sidebarLink.title))])]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }