<template>
    <PageCard title="Payroll History Details">
        <router-link to="/payroll-history">
            <Button class="me-2"> <i class="fas fa-chevron-left"></i> Back to List </Button>
        </router-link>
        <div class="my-3">
            <div v-if="loading">Please wait...</div>
            <div v-else-if="payrollPayment">
                <div class="mb-3">
                    <div>Created At: {{ company && company.createdAt }}</div>
                    <div>Company Name: {{ company && company.name }}</div>
                    <div>Year: {{ payrollPayment.year }}</div>
                    <div>Month: {{ getMonthName(payrollPayment.month) }}</div>
                    <div>Start Date: {{ payrollPayment.startDate }}</div>
                    <div>End Date: {{ payrollPayment.endDate }}</div>
                </div>
                <hr />
                <div class="my-3">
                    <div><strong>Payslips</strong></div>
                    <table class="table table-light my-3">
                        <thead>
                            <tr>
                                <th>Employee Name</th>
                                <th>CTC</th>
                                <th>Total Leaves</th>
                                <th>Overtime Hours</th>
                                <th>Total Earnings</th>
                                <th>Total Deductions</th>
                                <th>Net Salary</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="payrollPaymentSlip of payrollPaymentSlips"
                                :key="payrollPaymentSlip.id"
                            >
                                <td>{{ payrollPaymentSlip.employeeName }}</td>
                                <td>{{ payrollPaymentSlip.ctc }}</td>
                                <td>{{ payrollPaymentSlip.totalLeaves }}</td>
                                <td>{{ round2(payrollPaymentSlip.totalOvertimeHours) }}</td>
                                <td>{{ payrollPaymentSlip.totalEarningAmount }}</td>
                                <td class="text-danger">
                                    {{ payrollPaymentSlip.totalDeductionAmount }}
                                </td>
                                <td>{{ payrollPaymentSlip.netSalaryAmount }}</td>
                                <td>
                                    <a
                                        class="text-primary"
                                        style="text-decoration: underline"
                                        target="_blank"
                                        :href="`${serverURL}/api/salary-slips/${payrollPaymentSlip.name}`"
                                    >
                                        View
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-else>No Payroll History</div>
        </div>
    </PageCard>
</template>

<script>
import PageCard from "../../components/layout/PageCard.vue"
import Button from "../../components/UI/Button.vue"
import { getMonthName, round2 } from "../../utils/common"
import { http, serverURL } from "../../utils/http"

export default {
    data() {
        return {
            company: null,
            loading: false,
            payrollPayment: null,
            payrollPaymentSlips: [],
            serverURL,
        }
    },
    mounted() {
        this.fetchPayrollHistory()
    },
    components: { Button, PageCard },
    methods: {
        getMonthName,
        round2,
        async fetchPayrollHistory() {
            this.loading = true
            try {
                const res = await http.get(`/payroll-payments/${this.$route.params.id}`)
                const { company, payrollPayment, payrollPaymentSlips } = res.data.result
                this.company = company
                this.payrollPayment = payrollPayment
                this.payrollPaymentSlips = payrollPaymentSlips
                console.log(company)
            } catch (err) {
                console.log("error in fetching payroll history", err)
            }
            this.loading = false
        },
    },
}
</script>

<style></style>
