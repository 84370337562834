<template>
    <div>
        <CustomTable v-if="!loading && data">
            <template #head>
                <tr>
                    <th>Sl.</th>
                    <th>Task</th>
                    <th>Assignees</th>
                    <th>Status</th>
                    <th>Priority</th>
                    <!-- <th>Interim Date</th> -->
                    <th>Target Date</th>
                    <th>Actions</th>
                </tr>
            </template>
            <template #body>
                <tr v-for="(task, index) of data" :key="task.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ task.name }}</td>
                    <td>
                        {{ task.AssigneeUser && task.AssigneeUser.map((item) => item.firstName).join(", ") }}
                    </td>
                    <td>{{ task.Status && task.Status.name }}</td>
                    <td>{{ task.Priority && task.Priority.name }}</td>
                    <!-- <td>{{ task.interimDate }}</td> -->
                    <td>{{ formatIndianDate(task.endDate) }}</td>
                    <td>
                        <i
                            class="fas fa-eye"
                            style="color: blue; margin-right: 15px; cursor: pointer"
                            @click="$emit('view', task.slug)"
                        ></i>
                        <i v-if="showDelete" class="fas fa-trash" style="cursor: pointer" @click="$emit('delete', task.id)"></i>
                    </td>
                </tr>
            </template>
        </CustomTable>
    </div>
</template>

<script>
import { formatIndianDate } from "../../utils/common"
import CustomTable from "../other/CustomTable.vue"

export default {
    emits: ["view", "delete"],
    props: {
        data: {
            type: Array,
        },
        showDelete: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        CustomTable,
    },
    methods: {
        formatIndianDate,
    },
}
</script>

<style></style>
