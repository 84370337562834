<template>
    <div class="sidebar-container overflow-hidden">
        <div class="user-profile-display">
            <img class="profile-dp" src="@/assets/logo.png" alt="profile image" />
            <div>
                <div class="user-name">{{ user.username }}</div>
                <div class="user-role">Role: {{ user.role && user.role.name }}</div>
            </div>
        </div>

        <div v-for="(sidebarLinkGroup, i) of sidebarLinks" :key="i" class="sidebar-link-group ml-3">
            <SidebarLinkGroup :sidebarLinkGroup="sidebarLinkGroup" :key="i" />
        </div>
    </div>
</template>

<script>
import SidebarLinkGroup from "./SidebarLinkGroup.vue"
import { mapState, mapActions } from "vuex"
import { PERMISSION_CODES } from "../../store/permission"

export default {
    computed: {
        ...mapState("auth", ["authenticated", "user"]),
    },
    components: { SidebarLinkGroup },
    data() {
        return {
            sidebarLinks: [
                {
                    title: "Dashboard",
                    icon: "menu",
                    data: [
                        {
                            link: "/dashboard",
                            forceReload: true,
                            title: "Home",
                            icon: "home",
                        },
                        {
                            link: "/employees",
                            title: "Employees",
                            permission: PERMISSION_CODES.VIEW_EMPLOYEES,
                            icon: "user",
                        },
                        {
                            link: "/notifications",
                            title: "Notifications",
                            permission: PERMISSION_CODES.VIEW_NOTIFICATIONS,
                            icon: "bell",
                        },
                        {
                            link: "/notices",
                            title: "Notices",
                            permission: PERMISSION_CODES.VIEW_NOTICES,
                            icon: "list",
                        },
                        {
                            link: "/reports",
                            permission: PERMISSION_CODES.VIEW_REPORTS,
                            icon: "line-chart",
                            title: "Reports",
                        },
                        {
                            link: "/settings",
                            title: "Settings",
                            permission: PERMISSION_CODES.VIEW_SETTINGS,
                            icon: "gear",
                        },
                        {
                            link: "/app-logs",
                            title: "Logs",
                            permission: false,
                            icon: "list",
                        },
                    ],
                },
                {
                    // only for super admin
                    title: "User Rights",
                    icon: "users",
                    data: [
                        {
                            link: "/super-admins",
                            title: "Super Admins",
                            permission: false,
                            icon: "user",
                        },
                        {
                            link: "/permission-editor",
                            title: "Permission Editor",
                            // only allow access to admin
                            permission: false,
                            icon: "pencil",
                        },
                        {
                            link: "/user-roles",
                            title: "User Roles",
                            // permissions with false are only for admins
                            permission: false,
                            icon: "users",
                        },
                    ],
                },
                {
                    title: "Manage Masters",
                    data: [
                        {
                            link: "/companies",
                            title: "Company Master",
                            permission: PERMISSION_CODES.VIEW_COMPANIES,
                            icon: "industry",
                        },
                        {
                            link: "/departments",
                            title: "Department Master",
                            permission: PERMISSION_CODES.VIEW_DEPARTMENTS,
                            icon: "list",
                        },
                        {
                            link: "/designations",
                            title: "Designation Master",
                            permission: PERMISSION_CODES.VIEW_DESIGNATIONS,
                            icon: "users",
                        },
                        {
                            link: "/work-locations",
                            title: "Work Locations",
                            permission: PERMISSION_CODES.VIEW_WORK_LOCATIONS,
                            icon: "location",
                        },
                        {
                            link: "/work-location-types",
                            title: "Work Location Types",
                            permission: PERMISSION_CODES.VIEW_WORK_LOCATION_TYPES,
                            icon: "tag",
                        },
                        {
                            link: "/employment-statuses",
                            title: "Employment Statuses",
                            permission: PERMISSION_CODES.VIEW_EMPLOYMENT_STATUSES,
                            icon: "users",
                        },
                        {
                            link: "/master-templates",
                            title: "Master Template",
                            permission: PERMISSION_CODES.VIEW_MASTER_TEMPLATES,
                            icon: "book",
                        },
                        {
                            link: "/bank-masters",
                            title: "Bank Master",
                            permission: PERMISSION_CODES.VIEW_DEPARTMENTS,
                            icon: "bank",
                        },
                        {
                            link: "/cctv-masters",
                            title: "CCTV Master",
                            permission: PERMISSION_CODES.EDIT_CCTV_MASTER,
                            icon: "camera",
                        },
                    ],
                },
                {
                    title: "Payroll System",
                    data: [
                        {
                            link: "/payheads",
                            title: "Payheads",
                            permission: PERMISSION_CODES.VIEW_PAYHEADS,
                            icon: "tag",
                        },
                        {
                            link: "/create-payroll",
                            title: "Create Payroll",
                            permission: PERMISSION_CODES.CREATE_PAYROLLS,
                            icon: "list",
                        },
                        {
                            link: "/payroll-history",
                            title: "Payroll History",
                            permission: PERMISSION_CODES.VIEW_PAYROLL_HISTORY,
                            icon: "history",
                        },
                        {
                            link: "/salary-histories",
                            title: "Salary Update History",
                            permission: PERMISSION_CODES.VIEW_MASTER_TEMPLATES,
                            icon: "history",
                        },
                    ],
                },
                {
                    title: "Asset Management",
                    data: [
                        {
                            link: "/assets",
                            title: "Asset Master",
                            permission: PERMISSION_CODES.VIEW_ASSETS,
                            icon: "list",
                        },
                        {
                            link: "/asset-categories",
                            title: "Asset Category Master",
                            permission: PERMISSION_CODES.VIEW_ASSET_CATEGORIES,
                            icon: "tag",
                        },
                        {
                            link: "/asset-allocations",
                            title: "Asset Allocations",
                            permission: PERMISSION_CODES.VIEW_ASSET_ALLOCATIONS,
                            icon: "users",
                        },
                        // {
                        //     link: "/asset-allocation-statuses",
                        //     title: "Asset Allocation Statuses",
                        // },
                        // {
                        //     link: "/asset-logs",
                        //     title: "Asset Logs",
                        //     permission: PERMISSION_CODES.VIEW_ASSET_LOGS,
                        //     icon: "history",
                        // },
                        // {
                        //     link: "/asset-log-types",
                        //     title: "Asset Log Types",
                        // },
                    ],
                },
                {
                    title: "Attendance Module",
                    // some links need force reload to work for HtmlEditor
                    data: [
                        {
                            link: "/attendance-import",
                            title: "Import Attendance",
                            permission: PERMISSION_CODES.VIEW_IMPORT_ATTENDANCE,
                            icon: "calendar",
                        },
                        {
                            link: "/attendance-records",
                            title: "Attendance Records",
                            permission: PERMISSION_CODES.VIEW_ATTENDANCE_RECORD,
                            icon: "calendar",
                        },
                        {
                            link: "/attendance-statuses",
                            title: "Attendance Statuses",
                            permission: PERMISSION_CODES.VIEW_ATTENDANCE_STATUSES,
                            icon: "tag",
                        },
                        {
                            link: "/attendance-holidays",
                            title: "Holidays",
                            permission: PERMISSION_CODES.VIEW_HOLIDAYS,
                            icon: "list",
                        },
                        {
                            link: "/check-in-out-tracking",
                            title: "Check In/Out Location Tracking",
                            permission: PERMISSION_CODES.VIEW_CHECK_INOUT_LOCATION_TRACKING,
                            icon: "location",
                        },
                        // {
                        //     link: "/attendance-holiday-types",
                        //     title: "Holiday Types",
                        // },
                        {
                            link: "/attendance-shifts",
                            title: "Attendance Shifts",
                            permission: PERMISSION_CODES.VIEW_ATTENDANCE_SHIFTS,
                            icon: "list",
                        },
                        {
                            link: "/attendance-leave-requests",
                            title: "Leave Requests",
                            permission: PERMISSION_CODES.VIEW_LEAVE_REQUESTS,
                            icon: "list",
                        },
                        {
                            link: "/attendance-overtime-requests",
                            title: "Overtime Requests",
                            permission: PERMISSION_CODES.VIEW_OVERTIME_REQUESTS,
                            icon: "clock",
                        },
                    ],
                },
                {
                    title: "Project Management",
                    // some links need force reload to work for HtmlEditor
                    data: [
                        {
                            link: "/projects",
                            title: "Projects",
                            permission: PERMISSION_CODES.VIEW_PROJECTS,
                            icon: "users",
                        },
                        {
                            link: "/project-tasks",
                            title: "Project Tasks",
                            permission: PERMISSION_CODES.VIEW_PROJECT_TASKS,
                            icon: "list",
                        },
                        {
                            link: "/project-categories",
                            title: "Project Categories",
                            permission: PERMISSION_CODES.VIEW_PROJECT_CATEGORIES,
                            icon: "tag",
                        },
                        {
                            link: "/project-labels",
                            title: "Project Labels",
                            permission: PERMISSION_CODES.VIEW_PROJECT_LABELS,
                            icon: "tag",
                        },
                        {
                            link: "/project-reports",
                            title: "Project Reports",
                            permission: PERMISSION_CODES.VIEW_PROJECT_REPORTS,
                            icon: "line-chart",
                        },
                        {
                            link: "/project-comparision",
                            title: "Project Comparision",
                            permission: PERMISSION_CODES.VIEW_PROJECT_COMPARISONS,
                            icon: "line-chart",
                        },
                        {
                            link: "/project-visualizations",
                            title: "Project Visualizations",
                            permission: PERMISSION_CODES.VIEW_PROJECT_VISUALIZATIONS,
                            icon: "line-chart",
                        },
                        {
                            link: "/project-ganttchart",
                            title: "Project Ganttchart",
                            permission: PERMISSION_CODES.VIEW_PROJECT_PROJECT_GANTTCHART,
                            icon: "line-chart",
                        },
                        // {
                        //     link: "/project-categories",
                        //     title: "Project Categories",
                        // },
                        // {
                        //     link: "/project-labels",
                        //     title: "Project Labels",
                        // },
                        // {
                        //     link: "/project-priorities",
                        //     title: "Project Priorities",
                        // },
                        // {
                        //     link: "/project-statuses",
                        //     title: "Project Statuses",
                        // },
                    ],
                },
                {
                    title: "File Management",
                    // some links need force reload to work for HtmlEditor
                    data: [
                        {
                            link: "/files",
                            title: "Files",
                            permission: PERMISSION_CODES.VIEW_FILES,
                            icon: "folder",
                        },
                    ],
                },
                {
                    title: "Communication Module",
                    // some links need force reload to work for HtmlEditor
                    data: [
                        {
                            link: "/communication-groups",
                            title: "Communication Groups",
                            permission: PERMISSION_CODES.VIEW_COMMUNICATION_GROUPS,
                            icon: "message",
                        },
                    ],
                },
            ],
        }
    },
    methods: {},
}
</script>

<style lang="scss">
@import "@/assets/variables.scss";
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
.sidebar-container {
    padding-top: 25px;
    padding-left: 35px;
    position: relative;
    z-index: 100;
    font-size: 0.8em;
    min-height: 100vh;
    height: 100%;
    // border-right: 4px solid lighten($primaryColor, 40%);
    // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}
.user-profile-display {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 15px;
}
.profile-dp {
    width: 60px;
    object-fit: contain;
    border-radius: 0%;
    height: 60px;
}
.user-name {
    font-size: 22px;
}
.user-role {
    font-size: 14px;
    text-transform: capitalize;
}
</style>
