<template>
    <div>
        <div style="min-height: 300px">
            <!-- <div v-if="!dashboardStats" class="text-center my-4">
                <span class="text-primary spinner-border"></span>
            </div> -->
            <div class="row gy-4">
                <div class="col-lg-3">
                    <PageCard>
                        <div>
                            <div class="time-display">
                                <i class="me-2 fas fa-sun" style="font-size: 20px"></i>
                                <div>
                                    <span>{{ formattedTime }}</span>
                                    <div class="small-text">Realtime Insight</div>
                                </div>
                            </div>
                            <div class="date-display">
                                <div class="my-1">Today:</div>
                                <div>{{ formattedDate }}</div>
                            </div>
                            <div class="mt-3">
                                <router-link to="/attendance-records">
                                    <Button noMargin>View Attendance</Button>
                                </router-link>
                            </div>
                        </div>
                    </PageCard>
                </div>
                <div class="col-lg-9">
                    <!-- <PageCard title="Dashboard"> -->
                    <DashboardReportNumbers />
                    <!-- </PageCard> -->
                </div>
                <div class="col-lg-12">
                    <DashboardTaskOverview />
                </div>
                <div class="col-lg-8">
                    <DashboardAttendanceComparison />
                </div>
                <div class="col-lg-4">
                    <!-- <DepartmentwiseAttendance /> -->
                    <DashboardCalendarReport />
                </div>
                <div class="col-lg-12"></div>
                <div class="col-lg-12">
                    <DashboardPerformanceStats />
                </div>
                <div class="col-lg-12">
                    <DashboardLeaveRequestApproval />
                </div>
            </div>
            <div class="my-3">
                <SectionTitle>Assets:</SectionTitle>
            </div>
            <Spinner v-if="!dashboardStatsAssetData" />
            <div class="row gy-4" v-if="dashboardStatsAssetData">
                <div class="col-md-4">
                    <DashboardAssetInfoCard
                        title="Total"
                        :link="`/assets?type=${ASSET_FILTER_TYPES.ALL}`"
                        :assetCount="dashboardStatsAssetData.totalAssetCount"
                        :assetAmount="dashboardStatsAssetData.totalAssetAmount"
                    />
                </div>
                <div class="col-md-4">
                    <DashboardAssetInfoCard
                        title="Allocated"
                        :link="`/asset-allocations`"
                        :assetCount="dashboardStatsAssetData.totalAllocatedAssetCount"
                        :assetAmount="dashboardStatsAssetData.totalAllocatedAssetAmount"
                    />
                </div>
                <div class="col-md-4">
                    <DashboardAssetInfoCard
                        title="Non-Allocated"
                        :link="`/assets?type=${ASSET_FILTER_TYPES.NOT_ALLOCATED}`"
                        :assetCount="dashboardStatsAssetData.totalNonAllocatedAssetCount"
                        :assetAmount="dashboardStatsAssetData.totalNonAllocatedAssetAmount"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { http } from "../utils/http"
import millify from "millify"
import { mapState, mapActions } from "vuex"
import DashboardReportViewCard from "../components/reports/DashboardReportViewCard.vue"
import DashboardReportNumbers from "./dashboard/DashboardReportNumbers.vue"
import DashboardChart from "./dashboard/DashboardChart.vue"
import PageCard from "../components/layout/PageCard.vue"
import Button from "../components/UI/Button.vue"
import { humanReadableDate } from "../utils/common"
import SectionTitle from "../components/UI/SectionTitle.vue"
import DashboardAssetInfoCard from "../components/reports/DashboardAssetInfoCard.vue"
import DashboardTaskOverview from "../components/reports/DashboardTaskOverview.vue"
import DashboardLeaveRequestApproval from "../components/reports/DashboardLeaveRequestApproval.vue"
import DashboardCalendarReport from "../components/reports/DashboardCalendarReport.vue"
import DashboardAttendanceComparison from "../components/reports/DashboardAttendanceComparison.vue"
import DepartmentwiseAttendance from "../components/reports/DepartmentwiseAttendance.vue"
import DashboardPerformanceStats from "../components/reports/DashboardPerformanceStats.vue"
import Spinner from "../components/UI/Spinner.vue"
import { ASSET_FILTER_TYPES } from "../utils/constants"

export default {
    data() {
        return {
            initialized: false,
            loading: true,
            formattedTime: "",
            formattedDate: "",
            ASSET_FILTER_TYPES,
        }
    },
    async mounted() {
        this.updateFormattedTime()
        this.updateFormattedDate()

        await this.fetchDashboardStats()
        await this.fetchDashboardStatsAttendance()
        await this.fetchDashboardStatsPerformance()
        await this.fetchDashboardStatsAssetData()
        await this.fetchDashboardStatsLeaveRequests()
        await this.fetchDashboardStatsAttendanceDepartmentWise()

        setInterval(() => {
            this.updateFormattedTime()
            this.updateFormattedDate()
        }, 1000)
    },
    computed: {
        ...mapState("misc", ["dashboardStats", "dashboardStatsAssetData"]),
    },
    methods: {
        ...mapActions("misc", [
            "fetchDashboardStats",
            "fetchDashboardStatsAssetData",
            "fetchDashboardStatsAttendance",
            "fetchDashboardStatsAttendanceDepartmentWise",
            "fetchDashboardStatsPerformance",
            "fetchDashboardStatsLeaveRequests",
        ]),
        millify,
        updateFormattedTime() {
            this.formattedTime = new Date().toLocaleTimeString()
        },
        updateFormattedDate() {
            this.formattedDate = humanReadableDate(new Date())
        },
    },
    components: {
        SectionTitle,
        DashboardChart,
        Button,
        DashboardAttendanceComparison,
        DepartmentwiseAttendance,
        DashboardReportViewCard,
        DashboardReportNumbers,
        PageCard,
        DashboardAssetInfoCard,
        DashboardTaskOverview,
        DashboardLeaveRequestApproval,
        DashboardCalendarReport,
        DashboardPerformanceStats,
        Spinner,
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/variables.scss";
.date-display {
    font-size: 1em;
    margin-top: 15px;
}
.time-display {
    font-size: 1.2em;
    color: #444;
    display: flex;
}
.small-text {
    font-size: 12px;
    align-items: center;
}
</style>
