<template>
    <div>
        <div class="tab-list">
            <router-link to="/attendance-overtime-requests" :class="['tab-item', { active: activeTabIndex == 0 }]"> Pending </router-link>
            <router-link to="/attendance-overtime-requests-approved" :class="['tab-item', { active: activeTabIndex == 1 }]"
                >Approved</router-link
            >
            <router-link to="/attendance-overtime-requests-rejected" :class="['tab-item', { active: activeTabIndex == 2 }]"
                >Rejected</router-link
            >
        </div>
        <div class="my-3">
            <SearchInput title="By Employee Name" @search="fetchData" />
        </div>
        <h2 class="mb-3">
            <strong>{{ title }} Overtime Requests</strong>
        </h2>
        <div v-if="loading">Loading...</div>
        <div v-else-if="!records.length">No {{ title }} Requests Found</div>
        <div>
            <AttendanceRequestItem v-for="record of records" :key="record.id" :record="record" />
        </div>
        <Paginator @change="handlePageChange" :page="page" :totalPages="totalPages" />
    </div>
</template>

<script>
import Button from "../../../components/UI/Button.vue"
import { http } from "@/utils/http"
import AttendanceRequestItem from "../attendance-records/attendance-request-item.vue"
import Paginator from "@/components/other/Paginator.vue"
import SearchInput from "../../../components/SearchInput.vue"

export default {
    components: { SearchInput, Button, AttendanceRequestItem, Paginator },
    data() {
        return {
            loading: false,
            file: null,
            records: [],
            page: 1,
            limit: 10,
            totalPages: 0,
        }
    },
    props: {
        extraRouteQuery: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: false,
        },
        activeTabIndex: {
            type: Number,
            default: 0,
        },
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        async fetchData(searchStr = "") {
            if (!!searchStr && this.page !== 1) {
                this.page = 1
            }
            if (this.loading) return
            this.loading = true
            try {
                const res = await http.get(
                    `/attendance-records/overtime-requests?page=${this.page}&limit=${this.limit}&${this.extraRouteQuery}&search=${searchStr}`
                )
                this.records = res.data.result
                this.totalPages = res.data.totalPages
            } catch (err) {
                console.log("error in fetching leave rquests", err)
            }
            this.loading = false
        },
        async handlePageChange(newPage) {
            if (this.page != newPage) {
                this.page = newPage
                this.fetchData()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/variables.scss";

.tab-list {
    margin-bottom: 17px;
    .tab-item {
        display: inline-block;
        padding: 10px 20px;
        font-size: 0.9em;
        min-width: 130px;
        text-align: center;
        border-bottom: 5px solid transparent;
        background-color: #eee;

        &:hover {
            border-bottom-color: #ccc;
        }
        &.active {
            background-color: lighten($primaryColor, 35%);
            color: white;
            border-bottom-color: lighten($primaryColor, 15%);
        }
    }
}
</style>
