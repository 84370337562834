<template>
    <div>
        <CrudIndex
            title="Asset Category Master"
            :permission="PERMISSION_CODES.VIEW_ASSET_CATEGORIES"
            :viewColumns="columns"
            fetchUrl="/asset-categories"
            createUrl="/asset-categories"
            updateRootUrl="/asset-categories"
            :createFields="createFields"
            :reloadOnSuccess="true"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { PERMISSION_CODES } from "../../store/permission"
import { createField, createColumn } from "../../utils/common"

export default {
    data() {
        return {
            PERMISSION_CODES,
            createFields: [
                createField("Name*", "name", "text", true),
                createField(
                    "Depreciation % Per Year*",
                    "depreciationPercentPerYear",
                    "number",
                    true
                ),
            ],
            columns: [
                createColumn("Name", "name", "string"),
                createColumn("Depreciation % Per Year", "depreciationPercentPerYear", "string"),
            ],
        }
    },
    components: { CrudIndex },
}
</script>

<style></style>
