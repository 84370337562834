<template>
    <div class="">
        <!-- <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8">
            <div class="card">
              <div class="card-header" style="background-color: skyBlue;">Login</div>

              <div class="card-body">
                <div class="row mb-3">
                  <label for="email" class="col-md-4 col-form-label text-md-end"
                    >Email address</label
                  >

                  <div class="col-md-6">
                    <input
                      id="email"
                      type="email"
                      class="form-control"
                      v-model="email"
                    
                      required
                      autocomplete="email"
                      autofocus
                    />

                    <span class="invalid-feedback" role="alert">
                      <strong>Enter</strong>
                    </span>
                  </div>
                </div>

                <div class="row mb-3">
                  <label
                    for="password"
                    class="col-md-4 col-form-label text-md-end"
                    >Password</label
                  >

                  <div class="col-md-6">
                    <input
                      id="password"
                      type="password"
                      class="form-control"
                      v-model="password"
                      required
                      autocomplete="current-password"
                    />

                    <span class="invalid-feedback" role="alert">
                      <strong>xs</strong>
                    </span>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-6 offset-md-4">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="remember"
                        id="remember"
                      />

                      <label class="form-check-label" for="remember">
                        remenber
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row mb-0">
                  <div class="col-md-8 offset-md-4">
                    <button type="submit" class="btn btn-primary">Login</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  -->
    </div>
</template>

<script>
export default {}
</script>

<style></style>
