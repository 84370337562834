<template>
    <div>
        <h3 class="mb-3">
            <strong>{{ editMode ? "Update" : "Create" }} Holiday</strong>
        </h3>
        <div class="row" v-if="!editMode || editData">
            <div class="col-md-6">
                <custom-input v-model="name" label="Name*" />
            </div>
            <div class="col-md-6">
                <custom-input v-model="description" label="Description" type="text" />
            </div>
            <div class="col-md-6">
                <custom-input v-model="startDate" label="Start Date*" type="date" />
            </div>
            <div class="col-md-6">
                <custom-input v-model="endDate" label="End Date*" type="date" />
            </div>
            <div class="col-md-6">
                <p class="my-2">Attendance Holiday Type*</p>
                <SelectDropdown
                    v-if="!editMode || editData"
                    :id="`form-work-location`"
                    :options="
                        attendanceHolidayTypes.map((item) => ({ id: item.id, title: item.name }))
                    "
                    :multiple="false"
                    field="form-work-location"
                    :value="holidayTypeId"
                    @change="holidayTypeId = $event"
                />
            </div>
            <CountryStateCitySelector
                v-if="holidayTypeId && (!editMode || editData)"
                :countryId="countryId"
                :stateId="stateId"
                :cityId="cityId"
                :hideCountry="hideCountryName"
                :disableCountry="disableCountry"
                :disableCity="true"
                :disableState="hideState"
                @country="handleCountryUpdate"
                @state="stateId = $event"
                @city="cityId = $event"
            />
            <div class="col-md-12">
                <Button @click="cancelEdit" class="btn btn-primary">Cancel</Button>
                <span class="mx-1"></span>
                <Button class="btn btn-primary" @click="createOrUpdateWorkLocation">{{
                    processing ? "Please wait..." : editMode ? "Update" : "Submit"
                }}</Button>
            </div>
        </div>
        <div v-else>Loading...</div>
    </div>
</template>

<script>
import { http, serverURL } from "@/utils/http"
import { createField, createColumn } from "@/utils/common"
import CustomInput from "@/components/crud/custom-input.vue"
import Button from "@/components/UI/Button.vue"
import SelectDropdown from "@/components/crud/SelectDropdown.vue"
import OldImageView from "@/components/UI/OldImageView.vue"
import CountryStateCitySelector from "@/components/UI/CountryStateCitySelector.vue"
import { mapState } from "vuex"
import { formatIsoDate } from "@/utils/common"

export default {
    data() {
        return {
            serverURL,
            processing: false,
            loading: true,

            // master ids
            name: "",
            description: "",
            startDate: formatIsoDate(),
            endDate: formatIsoDate(),
            cityId: "",
            stateId: "",
            countryId: "",

            holidayTypeId: "",
            // documents
            // edit related fields
            editMode: true,
            editData: null,
            attendanceHolidayTypes: [],
        }
    },
    props: { videoId: { type: String, default: "" } },
    components: { CountryStateCitySelector, OldImageView, CustomInput, SelectDropdown, Button },
    async mounted() {
        await this.fetchAttendanceHolidayTypes()
        await this.initIfEditMode()
    },
    computed: {
        ...mapState("misc", ["countries"]),
        hideState() {
            const regionalHoliday = this.attendanceHolidayTypes.find((item) =>
                item.name.toLowerCase().includes("regional")
            )
            return regionalHoliday && this.holidayTypeId != regionalHoliday.id
        },
    },

    methods: {
        handleCountryUpdate(update) {
            if (update) {
                this.countryId = update
            }
        },
        cancelEdit() {
            this.$router.push("/attendance-holidays")
        },
        async initIfEditMode() {
            const id = this.$route.params.id
            if (id) {
                this.editMode = true
                const res = await http.get("/attendance-holidays/" + id)
                this.editData = res.data.result

                // set the defaults to each field.
                if (this.editData) {
                    this.name = this.editData.name || ""
                    this.description = this.editData.description || ""
                    this.startDate = this.editData.startDate || ""
                    this.endDate = this.editData.endDate || ""
                    this.address = this.editData.address || ""
                    this.stateId = this.editData.stateId || ""
                    this.countryId = this.editData.countryId || ""
                    this.holidayTypeId = this.editData.holidayTypeId || ""
                }
            } else {
                this.editMode = false
            }
        },
        async createOrUpdateWorkLocation() {
            if (this.processing) return

            if (!this.name) {
                return this.$toast.error("Please enter holiday name")
            }
            if (!this.startDate || !this.endDate) {
                return this.$toast.error("Start Date and End date are required!")
            }
            if (!this.holidayTypeId) {
                return this.$toast.error("Please select holiday type!")
            }
            if (!this.countryId) {
                return this.$toast.error("Please select country!")
            }
            if (!this.hideState && !this.stateId) {
                return this.$toast.error("Please select state!")
            }
            if (this.startDate > this.endDate) {
                return this.$toast.error("Start Date cannot be greater than End date!")
            }

            this.processing = true
            const formDataObj = {
                name: this.name,
                description: this.description,
                startDate: formatIsoDate(this.startDate),
                endDate: formatIsoDate(this.endDate),
                stateId: this.stateId || null,
                countryId: this.countryId,
                holidayTypeId: this.holidayTypeId,
            }
            try {
                if (this.editMode) {
                    await http.patch(`/attendance-holidays/${this.editData.id}`, formDataObj)
                } else {
                    await http.post("/attendance-holidays", formDataObj)
                }
                this.$toast.success(
                    this.editMode
                        ? "Attendance Holiday Updated Successfully"
                        : "Attendance Holiday Created Successfully"
                )
                window.location.href = "/attendance-holidays"
            } catch (err) {
                console.log("error in creating Attendance Holiday", err)
                this.$toast.error(
                    (err.response && err.response.data && err.response.data.message) ||
                        "Some error occured"
                )
            }
            this.processing = false
        },
        async fetchAttendanceHolidayTypes() {
            const res = await http.get("/attendance-holiday-types")
            this.attendanceHolidayTypes = res.data.result
        },
    },
    watch: {
        // holidayTypeId(e) {
        //     if (e == this.domesticTypeId) {
        //         this.country = this.countries.find((item) => item.title == "India").id
        //     }
        // },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.tab-container {
    display: flex;
    margin-bottom: 10px;
}
.tab-item:hover:not(.active) {
    background-color: lighten($primaryColor, 70%);
}
.tab-item.active {
    border-bottom: 5px solid $primaryColor;
    background-color: lighten($primaryColor, 50%);
}
.tab-item {
    cursor: pointer;
    width: 160px;
    text-align: center;
    padding: 10px;
}
</style>
