<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="Leave Types"
            :viewColumns="columns"
            fetchUrl="/leave-types"
            createUrl="/leave-types"
            updateRootUrl="/leave-types"
            deleteRootUrl="/leave-types"
            :createFields="createFields"
            :reloadOnSuccess="true"
        />
    </div>
</template>

<script>
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"
import { http } from "@/utils/http"

export default {
    data() {
        return {
            loading: true,
            employees: [],
            managers: [],
            createFields: [
                createField("Code*", "code", "text", true),
                createField("Name*", "name", "text", true),
            ],
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Code", "code", "string"),
                createColumn("Name", "name", "string"),
                // createColumn("Active", "status", "string"),
            ],
        }
    },
    components: { CrudIndex },
    async mounted() {
        this.loading = false
    },
    methods: {},
}
</script>

<style></style>
