<template>
    <div class="search-input-container">
        <input
            type="search"
            class="search-input form-control"
            placeholder="Search..."
            @focus="showSuggestions = true"
            @blur="hideSuggestions"
            v-model="searchStr"
        />
        <div class="suggestion-container" v-if="showSuggestions">
            <div v-if="!result || !result.length" class="suggestion-item">No Results Found</div>
            <router-link
                class="suggestion-item"
                :to="searchResult.link"
                v-for="searchResult of result"
                :key="searchResult.title"
            >
                <div>
                    <div class="suggestion-title">{{ searchResult.title }}</div>
                    <div class="suggestion-description">{{ searchResult.description }}</div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { http } from "../../utils/http"
export default {
    data() {
        return {
            searchStr: "",
            searchTimeout: null,
            searching: false,
            result: [],
            showSuggestions: false,
        }
    },
    methods: {
        async searchApp() {
            try {
                this.searching = true
                const res = await http.get(`/search?search=${this.searchStr}`)
                this.result = res.data.result
            } catch (err) {
                console.log("error in fetching communication groups", err)
            }
            this.searching = false
        },
        hideSuggestions() {
            setTimeout(() => {
                this.showSuggestions = false
            }, 200)
        },
    },
    watch: {
        searchStr(e) {
            if (this.searchTimeout) window.clearTimeout(this.searchTimeout)
            this.searchTimeout = window.setTimeout(this.searchApp, 1000)
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/variables.scss";

.search-input {
    border-radius: 15px;
    min-width: 400px;
    flex: 1;
    font-size: 1em;
    background: #fafafa;
    box-shadow: none;
    padding: 8px 12px;
}
.search-input-container {
    position: relative;
    z-index: 100;
}
.suggestion-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.6);
}
.suggestion-item {
    padding: 10px;
    display: block;
    font-size: 10px;
    background: white;
    transition: 0.25s;
    width: 100%;
}

.suggestion-title {
    font-weight: bold;
}
.suggestion-item:hover {
    .suggestion-description {
        color: white;
    }
    color: white;
    padding-left: 15px;
    background-color: lighten($primaryColor, 10%);
}
</style>
