<template>
  <div class="multi-btn-selection">
    <div
      :class="['btn-selection', { active: value == type.value }]"
      @click="$emit('change', type.value)"
      v-for="type of types"
      :key="type.id"
    >
      <i v-if="value == type.value" class="fas fa-check"></i> {{ type.title }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    types: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.multi-btn-selection {
  display: flex;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
  border: 1px solid #aaa;
}
.btn-selection {
  min-width: 70px;
  text-align: center;
  transition: 0.3s;
  padding: 8px 15px;
  cursor: pointer;
}
.btn-selection:hover:not(.active) {
  background: #e1a7a6;
}
.btn-selection.active {
  background: #832b29;
  color: white;
}
</style>
