<template>
    <div>
        <CrudIndex
            title="Project Priorities"
            :viewColumns="columns"
            fetchUrl="/project-priorities"
            createUrl="/project-priorities"
            updateRootUrl="/project-priorities"
            deleteRootUrl="/project-priorities"
            :reloadOnSuccess="true"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { createField, createColumn } from "../../utils/common"

export default {
    data() {
        return {
            createFields: [
                createField("Name*", "name", "text", true),
                createField("Value*", "value", "number", true),
                // createField("Status", "status", "switch", false, true),
            ],
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Name", "name", "string"),
                createColumn("Value", "value", "number"),
                // createColumn("Active", "status", "string"),
            ],
        }
    },
    components: { CrudIndex },
}
</script>

<style></style>
