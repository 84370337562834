<template>
    <div>
        <h2 class="page-title"><slot /></h2>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.page-title {
    // font-weight: bold;
    font-size: 18px;
}
</style>
