<template>
    <div class="attendance-request-item" v-if="record">
        <div class="row">
            <div class="col-md-7">
                <div></div>
                <div>
                    <router-link :to="`/employees/edit/${record.user && record.user.id}`">
                        <strong
                            >Employee: {{ record.user && record.user.firstName }}
                            {{ record.user && record.user.lastName }}
                        </strong>
                    </router-link>
                </div>

                <div v-if="leaveMode">
                    <div v-if="record.paidLeavesLeft != null">Total Paid Leaves Left: {{ record.paidLeavesLeft }}</div>
                    <div>
                        Total leaves applied in last 30 days:
                        {{ record.totalLeavesAppiledLastMonth }}
                    </div>
                    Leave Date:
                    <span>
                        {{
                            formatIndianDate(record.startDate) == formatIndianDate(record.endDate)
                                ? formatIndianDate(record.startDate)
                                : `From ${formatIndianDate(record.startDate)} to ${formatIndianDate(record.endDate)}`
                        }}</span
                    >
                    <div>Leave Type: {{ record.leaveType && record.leaveType.name }}</div>
                    <div>Leave Reason: {{ record.reason }}</div>
                </div>
                <div v-else>
                    Overtime Date: {{ record.date }}/{{ record.month }}/{{ record.year }}
                    <div v-if="record.overtimeHours">Overtime Hours: {{ round2(record.overtimeHours) }}</div>
                    <div v-if="record.overtimeReason">Overtime Reason: {{ record.overtimeReason }}</div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="text-end">
                    <Button @click="accept" :class="['accept-btn']" :disabled="approvedFlag !== null">{{
                        accepting ? "Please wait..." : approvedFlag === true ? "Accepted" : "Accept"
                    }}</Button>
                    <Button @click="decline" :class="['decline-btn']" :disabled="approvedFlag !== null">{{
                        declining ? "Please wait..." : approvedFlag === false ? "Declined" : "Decline"
                    }}</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "../../../components/UI/Button.vue"
import { http } from "@/utils/http"
import { formatIndianDate } from "@/utils/common"
import { round2 } from "../../../utils/common"

export default {
    components: { Button },
    props: {
        record: { type: Object, default: () => null },
        leaveMode: { type: Boolean, default: () => false },
    },
    data() {
        return {
            accepting: false,
            declining: false,
        }
    },
    computed: {
        approvedFlag() {
            if (this.leaveMode) {
                return this.record && this.record.approved
            } else {
                return this.record && this.record.overtimeApproved
            }
        },
    },
    methods: {
        round2,
        formatIndianDate,
        async accept() {
            try {
                if (this.approvedFlag !== null) {
                    return this.$toast.error("Already processed!")
                }
                if (this.accepting) return
                this.accepting = true
                await http.patch(`/attendance-records/${this.leaveMode ? "leave" : "overtime"}-requests/${this.record.id}/respond`, {
                    approved: true,
                })
                this.record.approved = true
                this.record.overtimeApproved = true
                this.$toast.success("Request accepted!")
            } catch (err) {
                console.log("error in accepting attendance request", err)
                this.$toast.error("Could not process!")
            }
            this.accepting = false
        },
        async decline() {
            try {
                if (this.approvedFlag !== null) {
                    return this.$toast.error("Already processed!")
                }
                if (this.declining) return
                this.declining = true
                await http.patch(`/attendance-records/${this.leaveMode ? "leave" : "overtime"}-requests/${this.record.id}/respond`, {
                    approved: false,
                })
                this.record.approved = false
                this.record.overtimeApproved = false
                this.$toast.success("Request declined!")
            } catch (err) {
                console.log("error in declining attendance request", err)
                this.$toast.error("Could not process!")
            }
            this.declining = false
        },
    },
}
</script>

<style lang="scss" scoped>
.attendance-request-item {
    padding: 10px;
    padding-left: 15px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
    border-radius: 7px;
}
.accept-btn {
    background-color: rgb(2, 186, 2);
    margin-right: 10px;
    &:hover {
        background-color: rgb(2, 121, 2);
    }
}
.decline-btn {
    background-color: red;
}
</style>
