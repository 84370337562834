<template>
    <PageCard>
        <div class="row">
            <h2 class="mb-3 col-12">
                <strong
                    >Notifications ({{
                        notifications ? notifications.filter((item) => !item.read).length : 0
                    }}
                    new)</strong
                >
            </h2>
            <div>
                <div style="max-width: 400px">
                    <SearchInput title="Notifications" @search="fetchNotifications" />
                </div>
                <div class="notification-date my-3 d-flex" style="gap: 10px">
                    <div>
                        <div>Start Date</div>
                        <div>
                            <input
                                v-model="startDate"
                                class="form-control form-control-sm"
                                type="date"
                            />
                        </div>
                    </div>
                    <div>
                        <div>End Date</div>
                        <div>
                            <input
                                v-model="endDate"
                                class="form-control form-control-sm"
                                type="date"
                            />
                        </div>
                    </div>
                    <div style="align-self: flex-end">
                        <Button @click="fetchNotifications" noMargin>{{
                            loading ? "Please wait..." : "Filter"
                        }}</Button>
                    </div>
                </div>
            </div>

            <div v-if="loading">Please wait...</div>
            <div v-else-if="!loading && !notifications.length">No new notifications found</div>
            <div
                v-else
                class="col-6 col-md-3"
                v-for="notification of notifications"
                :key="notification.id"
            >
                <div :class="['notification-item', { unread: !notification.read }]">
                    <div>
                        {{ formatIndianDate(notification.createdAt) }}
                    </div>
                    <div class="mt-2">
                        {{ notification.title }}
                    </div>
                </div>
            </div>
            <Paginator @change="handlePageChange" :page="page" :totalPages="totalPages" />
        </div>
    </PageCard>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { createField, createColumn, formatIndianDate, daysToMs } from "../../utils/common"
import { http } from "@/utils/http"
import Paginator from "../../components/other/Paginator.vue"
import Button from "../../components/UI/Button.vue"
import SearchInput from "../../components/SearchInput.vue"
import PageCard from "../../components/layout/PageCard.vue"

export default {
    data() {
        return {
            startDate: "",
            endDate: "",
            loading: true,
            notifications: [],
            totalPages: 0,
            page: 1,
            limit: 50,
            initialized: false,
        }
    },
    components: { SearchInput, CrudIndex, Paginator, Button, PageCard },
    async mounted() {
        this.loading = false
        // initialiaze default dates
        this.startDate = new Date(Date.now() - daysToMs(7)).toISOString().slice(0, 10)
        this.endDate = new Date().toISOString().slice(0, 10)
        await this.fetchNotifications()
    },
    methods: {
        formatIndianDate,
        async markRead() {
            if (!this.notifications) return
            try {
                const unreadIdsCsv = this.notifications
                    .filter((item) => !item.read)
                    .map((item) => item.id)
                const res = await http.post(`/notifications/mark-read?ids=${unreadIdsCsv}`)
                this.totalPages = Math.ceil(res.data.total / this.limit)
                this.notifications = res.data.result
            } catch (err) {
                console.log("error in fetching communication groups", err)
            }
            this.initialized = true
        },
        async fetchNotifications(searchStr = "") {
            try {
                this.loading = true
                const res = await http.get(
                    `/notifications?page=${this.page}&limit=${this.limit}&start=${this.startDate}&end=${this.endDate}&search=${searchStr}`
                )
                this.totalPages = Math.ceil(res.data.total / this.limit)
                this.notifications = res.data.result
                console.log("what in notifications now?", this.notifications)
                this.markRead()
            } catch (err) {
                console.log("error in fetching communication groups", err)
            }
            this.loading = false
            this.initialized = true
        },
        async handlePageChange(page) {
            if (!this.initialized) return
            this.page = page
            this.fetchNotifications()
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/variables.scss";

.notification-item {
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-size: 0.9em;
    border: 1px solid #ddd;
    background-color: lighten($primaryColor, 55%);
    &.unread {
        background-color: lighten($primaryColor, 35%);
        border: 1px solid #aaa;
    }
}
</style>
