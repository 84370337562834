<template>
    <div>
        <div v-if="showTitle" class="my-1">Company</div>
        <SelectDropdown
            v-if="companies && companies.length"
            :id="`company`"
            :options="companiesFiltered"
            :multiple="false"
            field="company"
            :value="companyId"
            @change="handleCompanyChange"
        />
    </div>
</template>

<script>
import { mapState } from "vuex"
import SelectDropdown from "../crud/SelectDropdown.vue"

export default {
    data() {
        return {}
    },
    props: {
        showTitle: {
            type: Boolean,
            default: true,
        },
        companyId: {
            type: [String, Number],
            default: "",
        },
    },
    components: {
        SelectDropdown,
    },
    emits: ["change"],
    computed: {
        ...mapState("misc", ["companies"]),
        companiesFiltered() {
            return this.companies.map((item) => ({
                id: item.id,
                title: item.name,
            }))
        },
    },
    methods: {
        handleCompanyChange(e) {
            console.log("company change", e)
            this.companyId = e
            this.$emit("change", e)
        },
    },
}
</script>

<style></style>
