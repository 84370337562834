<template>
    <div class="comment-item-wrapper">
        <div class="comment-item" v-if="comment && !editMode">
            <UserIcon />
            <div>
                <div class="d-flex mb-2">
                    <div class="mr-3 username-text">
                        {{ comment.User.firstName }} {{ userTypeLabel }}
                    </div>
                    <div>
                        {{ new Date(comment.createdAt).toDateString() }}
                    </div>
                </div>
                <div v-html="comment.description" />
                <div class="attachment-manager">
                    <div class="attachment-container">
                        <AttachmentUploader
                            @remove="addToRemoveList('attachment1')"
                            v-if="comment.attachment1"
                            v-model="comment.attachment1"
                            readonly
                            :id="1"
                            :name="comment.attachment1"
                        />
                        <AttachmentUploader
                            @remove="addToRemoveList('attachment2')"
                            v-if="comment.attachment2"
                            v-model="comment.attachment2"
                            readonly
                            :id="2"
                            :name="comment.attachment2"
                        />
                        <AttachmentUploader
                            @remove="addToRemoveList('attachment3')"
                            v-if="comment.attachment3"
                            v-model="comment.attachment3"
                            readonly
                            :id="3"
                            :name="comment.attachment3"
                        />
                    </div>
                    <TagUser :viewMode="true" :taggedUsers="comment.taggedUsers || []" />
                    <div v-if="comment.User.id == this.user.id">
                        <button class="control-btn" @click="handleEditMode">Edit</button>
                        <button class="control-btn" @click="deleteComment">
                            {{ deleting ? "Wait..." : "Delete" }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="editMode">
            <CommentForm
                @updated="handleUpdated"
                @cancel="editMode = false"
                :commentData="comment"
                :taskId="taskId"
            />
        </div>
    </div>
</template>

<script>
import UserIcon from "./UserIcon.vue"
import AttachmentUploader from "@/components/other/AttachmentUploader"
import CommentForm from "@/components/Lists/CommentForm"
import TagUser from "@/components/other/TagUser"
import { mapState } from "vuex"
import { http } from "@/utils/http"

export default {
    props: {
        comment: {
            type: Object,
            default: () => null,
        },
        taskId: {
            type: String,
            type: [String, Number],
            default: () => "",
        },
        task: { type: Object, default: null },
    },
    data() {
        return {
            editMode: false,
            deleting: false,
        }
    },
    computed: {
        ...mapState("auth", ["user"]),
        userTypeLabel() {
            if (this.task.reporterId && String(this.task.reporterId) == String(this.comment.User)) {
                return "(creator)"
            } else if (
                this.task.assigneeUserIds &&
                this.task.assigneeUserIds
                    .map((item) => item.id)
                    .includes(this.comment.User && this.comment.User.id)
            ) {
                return "(assignee)"
            }
            return ""
        },
    },
    components: { UserIcon, AttachmentUploader, CommentForm, TagUser },
    methods: {
        handleUpdated(val) {
            this.$emit("update", val)
            this.editMode = false
        },
        async deleteComment() {
            if (this.deleting) return
            if (confirm("Are you sure?")) {
                this.deleting = true
                try {
                    await http.delete("/project-comments/" + this.comment.id)
                    this.$toast.info("Comment Deleted")
                    this.$emit("remove", this.comment)
                } catch (err) {
                    console.log("error in deleting comment", err)
                    this.$toast.error("Some error occured")
                }
            }
            this.deleting = false
        },
        handleEditMode() {
            this.editMode = true
        },
    },
}
</script>

<style lang="scss" scoped>
.comment-item {
    display: flex;
    margin: 17px 0;
}
.control-btn {
    color: #666;
    margin: 5px;
    margin-left: 0;
    margin-right: 12px;

    &:hover {
        text-decoration: underline;
    }
}
.attachment-container {
    display: flex;
    gap: 12px;
}
</style>
