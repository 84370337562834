<template>
    <div>
        <div>
            <div class="date-dashboard">
                <div class="date-controller mb-3">
                    <div>
                        <i
                            @click="handleUpdateAttendanceDate(datePrevious(attendanceDate))"
                            class="date-arrow-icons fas fa-chevron-left"
                        ></i>
                    </div>
                    <div>
                        <div>Attendance Status</div>
                        <SelectDropdown
                            v-if="attendanceStatusIdInitialized"
                            :id="`form-asset-allocation-dropdown`"
                            :options="statusFilterOptions"
                            :value="attendanceStatusId"
                            @change="handleStatusFilterChange"
                        />
                    </div>
                    <div class="mb-3">
                        <div v-if="monthMode" class="d-flex" style="gap: 10px; min-width: 300px">
                            <select v-model="year" class="form-select form-select-sm">
                                <option v-for="yearOption of yearOptions" :key="yearOption" :value="yearOption">
                                    {{ yearOption }}
                                </option>
                            </select>
                            <select v-model="month" class="form-select form-select-sm">
                                <option value="1">Jan</option>
                                <option value="2">Feb</option>
                                <option value="3">Mar</option>
                                <option value="4">Apr</option>
                                <option value="5">May</option>
                                <option value="6">Jun</option>
                                <option value="7">Jul</option>
                                <option value="8">Aug</option>
                                <option value="9">Sept</option>
                                <option value="10">Oct</option>
                                <option value="11">Nov</option>
                                <option value="12">Dec</option>
                            </select>
                        </div>
                        <div v-else class="d-flex" style="gap: 12px">
                            <div>
                                <div class="mb-1">Date</div>
                                <input
                                    class="form-control form-control-sm"
                                    :value="attendanceDate"
                                    @change="handleUpdateAttendanceDate($event.target.value)"
                                    type="date"
                                    style="width: 250px; padding: 7px"
                                />
                            </div>
                            <div class="flex-1">
                                <div class="mb-1">Company</div>
                                <SelectDropdown
                                    :id="`form-company`"
                                    :options="companies.map((item) => ({ id: item.id, title: item.name }))"
                                    :value="attendanceCompanyId"
                                    @change="updateAttendanceCompany($event)"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <i
                            v-if="new Date(formatIsoDate(attendanceDate)) < new Date(formatIsoDate())"
                            @click="handleUpdateAttendanceDate(dateNext(attendanceDate))"
                            class="date-arrow-icons fas fa-chevron-right"
                        ></i>
                    </div>
                </div>

                <div v-if="!employeeMode" class="my-3">Total Staff: {{ attendanceRecordsForDate.length }}</div>
                <div class="row">
                    <div class="col-md-3" v-for="staffStat of staffStats" :key="staffStat.id">
                        <div class="staff-stat-block">
                            <div class="title">{{ staffStat.title }}</div>
                            <div class="value">{{ staffStat.value }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { http } from "@/utils/http"
import { daysToMs, formatIsoDate } from "@/utils/common"
import { mapActions, mapState } from "vuex"
import { round2 } from "../../../utils/common"
import SelectDropdown from "../../../components/crud/SelectDropdown.vue"

export default {
    data() {
        return {
            currentDate: formatIsoDate(),
            month: new Date().getMonth() + 1,
            year: new Date().getFullYear(),
            yearOptions: [],
            attendanceStatusIdInitialized: false,
            selectedCompany: "",
            statusFilterLoaded: false,
        }
    },
    props: {
        employeeMode: { type: Boolean, default: false },
        monthMode: { type: Boolean, default: false },
        stats: { type: Object, default: null },
    },
    mounted() {
        const currentYear = new Date().getFullYear()
        this.handleUpdateAttendanceStatus(this.$route.query.attendance_status || "")
        this.attendanceStatusIdInitialized = true
        this.yearOptions = [currentYear, currentYear - 1, currentYear - 2, currentYear - 3]
        this.statusFilterLoaded = true
    },
    components: { SelectDropdown },
    watch: {
        month() {
            this.handleUpdateAttendanceDate(formatIsoDate(new Date(this.year, this.month, 1)))
            this.$emit("update")
        },
        year() {
            this.handleUpdateAttendanceDate(formatIsoDate(new Date(this.year, this.month, 1)))
            this.$emit("update")
        },
    },
    computed: {
        ...mapState("misc", [
            "attendanceDate",
            "attendanceCompanyId",
            "attendanceStatusId",
            "attendanceRecordsForDate",
            "attendanceStats",
            "attendanceStatuses",
            "companies",
        ]),
        statusFilterOptions() {
            const records = this.attendanceStatuses.map((item) => ({
                id: item.id,
                title: item.name,
            }))
            return [
                {
                    id: "",
                    title: "All",
                },
                ...records,
            ]
        },
        staffStats() {
            const attendanceStats = this.stats || this.attendanceStats
            if (!attendanceStats) return []
            return [
                {
                    title: "Present",
                    value: attendanceStats.totalDaysPresent || attendanceStats.totalPresent || 0,
                },
                {
                    title: "Absent",
                    value: attendanceStats.totalDaysAbsent || attendanceStats.totalAbsent || 0,
                },
                {
                    title: "Leave",
                    value: attendanceStats.totalLeave || 0,
                },
                {
                    title: "Overtime Hours",
                    value: round2(attendanceStats.totalOvertimeHours) || 0,
                },
            ]
        },
    },
    methods: {
        ...mapActions("misc", ["updateAttendanceDate", "fetchRecordsByDate", "updateAttendanceStatus", "updateAttendanceCompany"]),
        formatIsoDate,
        handleUpdateAttendanceStatus(data) {
            this.updateAttendanceStatus(data)
            if (!this.employeeMode) {
                this.fetchRecordsByDate()
            }
        },
        handleUpdateAttendanceDate(data) {
            this.updateAttendanceDate(data)
            if (!this.employeeMode) {
                this.fetchRecordsByDate()
            }
        },
        handleStatusFilterChange(val) {
            const url = this.$router.resolve({
                ...this.$route,
                query: {
                    ...this.$route.query,
                    attendance_status: val,
                },
            }).href
            this.handleUpdateAttendanceStatus(val)
            this.$emit("update")
            this.$router.push(url)
        },
        datePrevious(date) {
            if (this.monthMode) {
                this.month = this.month - 1
                if (this.month < 1) {
                    this.year = this.year - 1
                    this.month = 12
                }
                // this.$emit("update")
                return formatIsoDate(new Date(this.year, this.month, 1).getTime())
            } else {
                // this.$emit("update")
                return formatIsoDate(new Date(date).getTime() - daysToMs(1))
            }
        },
        dateNext(date) {
            if (this.monthMode) {
                this.month = this.month + 1
                if (this.month > 12) {
                    this.year = this.year + 1
                    this.month = 1
                }
                // this.$emit("update")
                return formatIsoDate(new Date(this.year, this.month, 1).getTime())
            } else {
                // this.$emit("update")
                return formatIsoDate(new Date(new Date(date).getTime() + daysToMs(1)))
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.date-dashboard {
    padding: 15px;
    margin: 15px 0;
    border: 2px solid #ddd;
    background: white;
    // box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
}
.staff-stat-block .title {
}
.staff-stat-block {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background-color: #eee;
}
.date-controller {
    position: relative;
}
.date-controller {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.date-arrow-icons {
    cursor: pointer;
    transition: 0.3s;
}
.date-arrow-icons:hover {
    transform: scale(1.4);
}
</style>
