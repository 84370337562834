var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.custom)?_c('div',{class:['attendance-status-card primary', { loading: _vm.loading }, { active: _vm.active }],on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"status-symbol"},[_vm._v(_vm._s(_vm.customStatusName[0]))]),_c('div',[_vm._v(_vm._s(_vm.customStatusName))])]):(_vm.attendanceStatus)?_c('div',{class:[
        'attendance-status-card',
        { loading: _vm.loading },
        { active: _vm.active },
        { danger: _vm.active && _vm.includesOneOfStatuses('absent', 'leave') },
        { success: _vm.active && !_vm.includesOneOfStatuses('absent', 'leave') },
    ],on:{"click":_vm.handleStatusUpdate}},[_c('div',{staticClass:"status-symbol"},[_vm._v(_vm._s(_vm.attendanceStatus.name[0]))]),_c('div',[_vm._v(_vm._s(_vm.attendanceStatus.name))])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }