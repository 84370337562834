<template>
    <div class="container">
        <PageCard style="max-width: 500px; margin: 20px auto">
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="d-flex flex-column align-items-center">
                        <div class="mb-3" style="display: grid; place-items: center">
                            <img src="@/assets/logo.png" style="width: 120px" alt="" />
                        </div>
                        <div class="">
                            <div class="text-center my-3 h5">Samyak Admin</div>
                            <form @submit.prevent="handleSubmit">
                                <div class="d-flex mb-3" style="gap: 10px">
                                    <label for="username" class="col-form-label text-md-end">
                                        <i class="fas fa-user colored-icon"></i>
                                    </label>
                                    <div class="">
                                        <input
                                            id="username"
                                            type="text"
                                            class="form-control form-control-sm"
                                            placeholder="Username"
                                            v-model="username"
                                            required
                                            autocomplete="username"
                                            autofocus
                                            style="min-width: 350px"
                                        />

                                        <span class="invalid-feedback" role="alert">
                                            <strong>Enter</strong>
                                        </span>
                                    </div>
                                </div>

                                <div class="d-flex" style="gap: 10px">
                                    <label for="password" class="col-form-label text-md-end">
                                        <i class="fas fa-lock colored-icon"></i
                                    ></label>

                                    <div class="">
                                        <input
                                            id="password"
                                            type="password"
                                            placeholder="Password"
                                            class="form-control form-control-sm"
                                            v-model="password"
                                            required
                                            style="min-width: 350px"
                                            autocomplete="current-password"
                                        />

                                        <span class="invalid-feedback" role="alert">
                                            <strong>xs</strong>
                                        </span>
                                    </div>
                                </div>

                                <div class="text-center">
                                    <Button type="submit">
                                        {{ processingForm ? "Please wait..." : "Login" }}
                                    </Button>
                                </div>
                            </form>
                            <div class="text-center">
                                <a href="/forgot-password"> Forgot Password </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PageCard>
    </div>
</template>

<script>
import { mapActions } from "vuex"
import Button from "@/components/UI/Button"
import { errMsg } from "../../utils/common"
import PageCard from "./PageCard.vue"

export default {
    data() {
        return {
            username: "",
            password: "",
            processingForm: false,
        }
    },

    components: { PageCard, Button },
    methods: {
        // ...mapActions("auth", ["login"]),
        ...mapActions({
            login: "auth/login",
        }),
        async handleSubmit() {
            if (this.processingForm) return

            if (!this.username || !this.password) {
                return this.$toast.error("Please Fill Details!")
            }
            this.processingForm = true
            try {
                await this.login({
                    username: this.username,
                    password: this.password,
                })
                this.$toast.success("Logged in Successfully!")
                window.location.href = "/dashboard"
            } catch (err) {
                console.log("Login err", err)
                this.$toast.error(errMsg(err, "Could Not Login!"))
            }
            this.processingForm = false
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/variables.scss";

.colored-icon {
    color: $primaryColor;
    font-size: 20px;
}
</style>
