<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="Designations"
            :viewColumns="columns"
            :permission="PERMISSION_CODES.VIEW_DESIGNATIONS"
            updateRootUrl="/designations"
            :fetchUrl="`/designations`"
            createUrl="/designations"
            deleteRootUrl="/designations"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import { http } from "@/utils/http"
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"
import { PERMISSION_CODES } from "../../store/permission"

export default {
    data() {
        return {
            loading: true,
            departments: [],
            createFields: [],
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Name", "name", "string"),
                // createColumn("Status", "status", "string"),
            ],
            PERMISSION_CODES,
        }
    },
    components: { CrudIndex },
    async mounted() {
        await this.fetchDepartments()

        this.createFields = this.createFields.concat([
            createField("Name*", "name", "text", true),
            createField(
                "Department*",
                "departmentId",
                "select",
                true,
                "",
                this.departments.map((item) => ({
                    id: item.id,
                    title: item.name,
                }))
            ),
            createField("Notice Period Days*", "noticePeriodDays", "number", false),
        ])
        this.loading = false
    },
    methods: {
        async fetchDepartments() {
            const res = await http.get("/departments")
            this.departments = res.data.result
        },
    },
}
</script>

<style></style>
