<template>
    <div v-if="employeePayrollData">
        <div class="mt-3">Select Employees (Deactivated Employees are removed from list)</div>
        <div class="d-flex align-items-center" style="gap: 10px; max-width: 600px">
            <div class="flex-1">
                <SelectDropdown
                    :id="`employee_creation`"
                    :options="
                        employeePayrollData.map((item) => ({
                            id: item.id,
                            title: `${item.firstName} ${item.lastName}`,
                        }))
                    "
                    :multiple="true"
                    :value="selectedEmployeeIds"
                    @change="selectedEmployeeIds = $event"
                />
            </div>
            <Button @click="selectedEmployeeIds = employeePayrollData.map((item) => item.id)">
                Select All
            </Button>
        </div>
        <div>
            <div v-if="!employeePayrollData.length">No Employees Found</div>
            <div v-if="!refreshingRefs">
                <CreatePayrollForm
                    :employee="employee"
                    v-for="(employee, i) of filteredEmployeePayrollData"
                    :key="employee.id"
                    :ref="inputRefs[i]"
                />
            </div>
        </div>
    </div>
</template>

<script>
import SelectDropdown from "../crud/SelectDropdown.vue"
import Button from "../UI/Button.vue"
import CreatePayrollForm from "./CreatePayrollForm.vue"

export default {
    props: {
        employeePayrollData: { type: Array, default: () => [] },
    },
    data() {
        return {
            inputValues: [],
            inputRefs: [],
            refreshingRefs: false,
            selectedEmployeeIds: [],
        }
    },
    mounted() {
        this.refreshRefs()
    },
    components: { SelectDropdown, Button, CreatePayrollForm },
    methods: {
        getData() {
            // return formatted data for parent
            let finalData = []
            for (let inputRef of this.inputRefs) {
                const inputRefData = this.$refs[inputRef][0].getData()
                finalData.push(inputRefData)
            }
            return finalData
        },
        refreshRefs() {
            this.refreshingRefs = true
            this.inputRefs = this.filteredEmployeePayrollData.map((item, i) => `payroll_form_${i}`)

            setTimeout(() => {
                this.refreshingRefs = false
            })
        },
    },
    watch: {
        selectedEmployeeIds(e) {
            this.refreshRefs()
        },
    },
    computed: {
        filteredEmployeePayrollData() {
            return this.employeePayrollData.filter((item) =>
                this.selectedEmployeeIds.map(String).includes(String(item.id))
            )
        },
    },
}
</script>

<style lang="scss" scoped></style>
