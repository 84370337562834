<template>
    <div class="d-flex justify-content-center my-5">
        <div class="spinner-border text-primary"></div>
    </div>
</template>

<script>
export default {}
</script>

<style></style>
