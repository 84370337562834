<template>
    <router-link :to="link" class="report-view-card">
        <div class="report-crunch">
            {{ value }}
        </div>
        <div class="report-title-sm">{{ title }}</div>
    </router-link>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        value: {
            type: String,
            default: "",
        },
        link: {
            type: String,
            default: "",
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.report-view-card {
    // background-color: $primaryColor;
    background-color: white;
    // color: white;
    // border: 1px solid $primaryColor;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    justify-content: space-between;
    flex-flow: column;
    height: 100%;
}
.report-view-card:hover {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
}
.report-title-sm {
    font-size: 0.9em;
}
.report-crunch {
    // font-weight: bold;
    font-size: 2em;
}
.report-title {
    font-weight: bold;
    font-size: 1.2em;
}
</style>
