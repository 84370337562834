<template>
    <div>
        <h4
            class="group-heading"
            @click="showMenu = !showMenu"
            v-if="sidebarLinkGroupFitlered && sidebarLinkGroupFitlered.length > 0"
        >
            <i
                v-if="sidebarLinkGroupFitlered.icon"
                :class="`fas menu-icon fa-${sidebarLinkGroupFitlered.icon}`"
            />
            {{ sidebarLinkGroup.title }}
            <i
                :class="[
                    { 'fas fa-chevron-down': !showMenu },
                    { 'fas fa-chevron-up': showMenu },
                    'chevron-icon',
                ]"
            />
        </h4>
        <div v-if="showMenu">
            <template v-for="sidebarLink of sidebarLinkGroupFitlered">
                <a
                    v-if="sidebarLink.forceReload"
                    :class="[
                        'block outline-none sidebar-item',
                        {
                            active:
                                $route.path.startsWith(sidebarLink.link) ||
                                $route.path === sidebarLink.link,
                        },
                    ]"
                    :key="sidebarLink.link"
                    :href="sidebarLink.link"
                >
                    <i v-if="sidebarLink.icon" :class="`fas menu-icon fa-${sidebarLink.icon}`" />
                    {{ sidebarLink.title }}
                </a>
                <router-link
                    v-else
                    :class="[
                        'block outline-none sidebar-item',
                        {
                            active:
                                $route.path.startsWith(sidebarLink.link) ||
                                $route.path === sidebarLink.link,
                        },
                    ]"
                    @click.native="scrollTopFirst"
                    :key="sidebarLink.link"
                    :to="sidebarLink.link"
                >
                    <i v-if="sidebarLink.icon" :class="`fas menu-icon fa-${sidebarLink.icon}`" />
                    {{ sidebarLink.title }}</router-link
                >
            </template>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import { checkHasPermission } from "../../store/permission"

export default {
    props: {
        sidebarLinkGroup: { type: Object, default: () => ({}) },
    },
    data() {
        return { showMenu: false }
    },
    computed: {
        ...mapState("auth", ["user"]),

        sidebarLinkGroupFitlered() {
            // filter by permissions
            const filteredLinks = this.sidebarLinkGroup.data.filter(
                (sidebarLink) => {
                    const result = checkHasPermission({
                        isAdmin: this.user.isAdmin,
                        permissionId: sidebarLink.permission,
                        userPermissionIds: this.user.permissionIds,
                    })
                    return result
                }
                // false
            )
            // console.log("filtered lnks", filteredLinks)
            return filteredLinks
        },
    },
    mounted() {
        for (let sidebarLink of this.sidebarLinkGroup.data) {
            if (this.$route.path === sidebarLink.link) {
                this.showMenu = true
            }
        }
    },
    methods: {
        scrollTopFirst() {
            window.scrollTo({ top: 0, behavior: "smooth" })
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

.group-heading {
    text-transform: uppercase;
    padding-top: 25px;
    padding: 20px;
    padding-left: 0;
    font-size: 13px;
    // font-weight: bold;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: #444;
}
.sidebar-item {
    text-transform: uppercase;
    border-radius: 20px;
    // border-top-left-radius: 20px;
    // border-bottom-left-radius: 20px;
    margin-bottom: 5px;
    font-size: 0.9em;
    padding: 15px;

    &.active {
        // color: $primaryColor;
        font-weight: bold;
        // border-right: 6px solid $primaryColor;
        background-color: #fff;
        border-bottom: 1px solid #ddd;
        color: #333;
    }
    &:hover:not(.active) {
        // background-color: lighten($secondaryColor, 10%);
        background-color: rgba(255, 255, 255, 0.4);
    }
}
.group-heading:hover {
    color: #333;
}
.menu-item-group {
    display: flex;
    align-items: center;
    width: 100%;
}
.menu-icon {
    font-size: 1.2em;
    margin-right: 6px;
}
.chevron-icon {
    color: #555;
}
</style>
