<template>
    <div>
        <div class="my-3"><strong>Projects</strong></div>
        <div style="max-width: 200px; margin-bottom: 15px">
            <p class="my-2">Filter by Company</p>
            <SelectDropdown
                :id="`form-subject`"
                v-if="companies.length"
                :options="companies.map((item) => ({ id: item.id, title: item.name }))"
                :value="selectedCompanyId"
                @change="selectedCompanyId = $event"
            />
        </div>

        <CrudIndex
            v-if="!loading && showResults"
            title="Projects"
            :permission="PERMISSION_CODES.VIEW_PROJECTS"
            :viewColumns="columns"
            :fetchUrl="`/projects?company_id=${selectedCompanyId}`"
            createUrl="/projects"
            updateRootUrl="/projects"
            deleteRootUrl="/projects"
            :createFields="createFields"
            :reloadOnSuccess="true"
            @formDataUpdated="handleFormDataUpdate"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { createField, createColumn } from "../../utils/common"
import { http } from "@/utils/http"
import { mapState } from "vuex"
import SelectDropdown from "../../components/crud/SelectDropdown.vue"
import { PERMISSION_CODES } from "../../store/permission"

export default {
    data() {
        return {
            PERMISSION_CODES,
            loading: true,
            managers: [],
            selectedCompanyId: "",
            createFields: [],
            showResults: true,
            minEndDate: "",
            maxEndDate: "",
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Name", "name", "string"),
                createColumn("Company Name", "company_name", "string"),
                // createColumn("Active", "status", "string"),
            ],
        }
    },

    computed: { ...mapState("misc", ["companies", "projectMeasurements"]) },
    components: { SelectDropdown, CrudIndex },
    async mounted() {
        this.recreateFields()
        this.loading = false
    },
    methods: {
        recreateFields() {
            this.createFields = [
                createField("Name*", "name", "text", true),
                createField("Description*", "description", "text", true),
                createField(
                    "Select Company*",
                    "companyIds",
                    "multiselect",
                    true,
                    "",
                    this.companies.map((item) => ({
                        id: item.id,
                        title: `${item.name || ""}`,
                    }))
                ),
                createField(
                    "Measurement*",
                    "measurementType",
                    "select",
                    true,
                    "",
                    this.projectMeasurements.map((item) => ({
                        id: item,
                        title: `${item || ""}`,
                    }))
                ),
                createField("Measurement Amount*", "measurementTotalValue", "number", true),
                createField("Start Date*", "startDate", "date", true, new Date().toISOString().slice(0, 10)),
                createField(
                    "Target Date (must be after start date)*",
                    "endDate",
                    `date|${this.minEndDate}|${this.maxEndDate}|disabled:false`,
                    true,
                    ""
                ),
            ]
        },

        async handleFormDataUpdate(val) {
            if (val) {
                if (val.startDate || val.endDate) {
                    if (val.startDate) {
                        this.minEndDate = val.startDate
                    }
                    this.recreateFields()
                }
            }
        },
    },
    watch: {
        selectedCompanyId(e) {
            this.showResults = false
            setTimeout(() => {
                this.showResults = true
            }, 100)
        },
    },
}
</script>

<style></style>
