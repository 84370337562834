<template>
    <AttendanceStatusCard
        v-if="attendanceStatus"
        :active="false"
        @click="handleOverTimeClick"
        :attendanceStatus="attendanceStatus"
    />
</template>

<script>
import AttendanceStatusCard from "./attendance-status-card.vue"
export default {
    components: {
        AttendanceStatusCard,
    },
    props: {
        attendanceStatus: {
            type: Object,
            default: () => null,
        },
    },
    handleOverTimeClick() {},
}
</script>

<style></style>
