<template>
    <PageCard title="Create Company">
        <TabContainer
            :tabs="Object.values(COMPANY_EDIT_TABS)"
            :selectedTab="companyEditTab"
            @change="companyEditTab = $event"
        />
        <div v-if="companyEditTab.name == COMPANY_EDIT_TABS.COMPANY.name">
            <div class="row gy-2" v-if="!editMode || editData">
                <div class="col-md-6">
                    <custom-input v-model="name" label="Company Name*" />
                </div>
                <CountryStateCitySelector
                    v-if="!editMode || editData"
                    :countryId="country"
                    :stateId="state"
                    :cityId="city"
                    @country="handleCountryUpdate"
                    @state="state = $event"
                    @city="city = $event"
                />
                <div class="col-md-6">
                    <custom-input v-model="address" label="Address*" />
                </div>
                <div class="col-md-6">
                    <custom-input type="number" v-model="addressPincode" label="Address Pincode*" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="contactNumber" label="Contact Number*" type="number" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="contactEmail" label="Contact Email*" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="contactWebsite" label="Contact Website" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="tanNo" label="Tan No" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="registrationNo" label="Registration No" />
                </div>
                <div class="col-md-6">
                    <div>Work Locations</div>
                    <SelectDropdown
                        v-if="workLocations.length"
                        :id="`form-work-location`"
                        :options="workLocations.map((item) => ({ id: item.id, title: item.name }))"
                        :multiple="true"
                        field="form-work-location"
                        :value="workLocationIds"
                        @change="workLocationIds = $event"
                    />
                </div>

                <div class="my-3">
                    <hr />
                    <h1 class="my-3"><strong>Company Bank Details</strong></h1>
                </div>

                <div class="col-md-6">
                    <div>Bank</div>
                    <SelectDropdown
                        v-if="bankMasters.length"
                        :id="`bankMasterId`"
                        :options="bankMasters.map((item) => ({ id: item.id, title: item.name }))"
                        field="bankMasterId"
                        :value="bankMasterId"
                        @change="bankMasterId = $event"
                    />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="bankAccountNumber" label="Bank Account Number" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="bankAccountBankName" label="Bank Account Name" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="bankAccountBranch" label="Bank Account Branch" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="bankAccountIfsc" label="Bank Account IFSC" />
                </div>
                <div class="col-md-6">
                    <custom-input v-model="bankAccountSwiftId" label="Bank Account Swift ID" />
                </div>
                <!-- <div class="col-md-6">
                    <div class="form-check mt-3 form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="pf"
                            role="switch"
                            v-model="pfApplicable"
                        />
                        <label class="form-check-label" :for="`pf`"> PF Applicable </label>
                    </div>
                </div> -->
                <div class="col-md-12">
                    <Button @click="cancelEdit" class="btn btn-primary">Cancel</Button>
                    <span class="mx-1"></span>
                    <Button class="btn btn-primary" @click="createOrUpdate">{{
                        processing ? "Please wait..." : editMode ? "Update" : "Submit"
                    }}</Button>
                </div>
            </div>
            <div v-else>Loading...</div>
        </div>
        <div v-if="companyEditTab.name == COMPANY_EDIT_TABS.PAYROLL.name">
            <PayheadValuesEditForm :companyId="$route.params.id" />
        </div>
    </PageCard>
</template>

<script>
import { http, serverURL } from "@/utils/http"
import { validateEmail, validatePhone } from "@/utils/common"
import CustomInput from "../../components/crud/custom-input.vue"
import Button from "../../components/UI/Button.vue"
import SelectDropdown from "../../components/crud/SelectDropdown.vue"
import OldImageView from "../../components/UI/OldImageView.vue"
import CountryStateCitySelector from "../../components/UI/CountryStateCitySelector.vue"
import { mapState } from "vuex"
import TabContainer from "../../components/layout/TabContainer.vue"
import PayheadValuesEditForm from "../../components/payroll/PayheadValuesEditForm.vue"
import PageCard from "../../components/layout/PageCard.vue"

const COMPANY_EDIT_TABS = {
    COMPANY: { name: "COMPANY", value: "Company Details" },
    PAYROLL: { name: "PAYROLL", value: "Payroll Defaults" },
}

export default {
    data() {
        return {
            COMPANY_EDIT_TABS,
            companyEditTab: COMPANY_EDIT_TABS.COMPANY,
            serverURL,
            activeTab: "personal",
            processing: false,
            loading: true,

            // master ids
            name: "",
            address: "",
            addressPincode: "",
            city: "",
            state: "",
            country: "",
            contactNumber: "",
            contactEmail: "",
            contactWebsite: "",
            workLocationIds: [],
            tanNo: "",
            registrationNo: "",
            pfApplicable: false,
            // documents
            // edit related fields
            editMode: true,
            editData: null,
            workLocationTypes: [],
            workLocations: [],

            // bank details
            bankMasterId: "",
            bankAccountNumber: "",
            bankAccountBankName: "",
            bankAccountBranch: "",
            bankAccountIfsc: "",
            bankAccountSwiftId: "",
        }
    },
    props: { videoId: { type: String, default: "" } },
    components: {
        TabContainer,
        CountryStateCitySelector,
        OldImageView,
        CustomInput,
        SelectDropdown,
        Button,
        PayheadValuesEditForm,
        PageCard,
    },
    async mounted() {
        await this.initIfEditMode()
        await this.fetchWorkLocations()
    },
    computed: {
        ...mapState("misc", ["countries", "bankMasters"]),
    },
    methods: {
        handleCountryUpdate(update) {
            if (update) {
                this.country = update
            }
        },
        cancelEdit() {
            this.$router.push("/companies")
        },
        validateTAN(tan) {
            // Define a regular expression pattern for TAN validation
            const tanPattern = /^[A-Z]{4}\d{5}[A-Z]$/

            // Use the test method to check if the TAN matches the pattern
            return tanPattern.test(tan)
        },
        async fetchWorkLocations() {
            try {
                const res = await http.get("/work-locations")
                this.workLocations = res.data.result
            } catch (err) {
                console.log("error in fetching work locations", err)
            }
        },
        async initIfEditMode() {
            const id = this.$route.params.id
            if (id) {
                this.editMode = true
                const res = await http.get("/companies/" + id)
                this.editData = res.data.result

                // set the defaults to each field.
                if (this.editData) {
                    this.name = this.editData.name || ""
                    this.address = this.editData.address || ""
                    this.addressPincode = this.editData.addressPincode || ""
                    this.city = this.editData.city || ""
                    this.state = this.editData.state || ""
                    this.country = this.editData.country || ""

                    this.contactNumber = this.editData.contactNumber || ""
                    this.contactEmail = this.editData.contactEmail || ""
                    this.contactWebsite = this.editData.contactWebsite || ""
                    this.tanNo = this.editData.tanNo || ""
                    this.registrationNo = this.editData.registrationNo || ""
                    this.pfApplicable = this.editData.pfApplicable || false
                    this.workLocationIds = this.editData.workLocationIds || []

                    // bank related data
                    this.bankMasterId = this.editData.bankMasterId || ""
                    this.bankAccountNumber = this.editData.bankAccountNumber || ""
                    this.bankAccountBankName = this.editData.bankAccountBankName || ""
                    this.bankAccountBranch = this.editData.bankAccountBranch || ""
                    this.bankAccountIfsc = this.editData.bankAccountIfsc || ""
                    this.bankAccountSwiftId = this.editData.bankAccountSwiftId || ""
                }
            } else {
                this.editMode = false
            }
        },
        async createOrUpdate() {
            if (this.processing) return

            if (!this.name) {
                return this.$toast.error("Please enter Company name")
            }
            if (!this.country || !this.city || !this.state) {
                return this.$toast.error("Please enter country, state and city!")
            }
            if (!this.contactEmail || !this.contactNumber) {
                return this.$toast.error("Please enter contact number and contact email!")
            }

            if (!validateEmail(this.contactEmail)) {
                return this.$toast.error("Contact email address is not valid!")
            }
            if (!validatePhone(this.contactNumber)) {
                return this.$toast.error("Contact number must be 10 digits!")
            }

            if (!this.address || !this.addressPincode) {
                return this.$toast.error("Please enter company address and pincode!")
            }
            if (this.addressPincode.length != 6) {
                return this.$toast.error("Pincode must be 6 digits only!")
            }
            if (this.tanNo && !this.validateTAN(this.tanNo)) {
                return this.$toast.error("Tan No. is not valid!")
            }
            if (this.registrationNo && this.registrationNo.length < 5) {
                return this.$toast.error("Registration No. is not valid!")
            }

            this.processing = true
            const formDataObj = {
                name: this.name,
                address: this.address,
                addressPincode: this.addressPincode,
                city: this.city,
                state: this.state,
                country: this.country,

                contactNumber: this.contactNumber,
                contactEmail: this.contactEmail,
                contactWebsite: this.contactWebsite,
                tanNo: this.tanNo,
                registrationNo: this.registrationNo,
                pfApplicable: this.pfApplicable,
                workLocationIds: this.workLocationIds,

                // bank details
                bankMasterId: this.bankMasterId,
                bankAccountNumber: this.bankAccountNumber,
                bankAccountBankName: this.bankAccountBankName,
                bankAccountBranch: this.bankAccountBranch,
                bankAccountIfsc: this.bankAccountIfsc,
                bankAccountSwiftId: this.bankAccountSwiftId,
            }
            try {
                if (this.editMode) {
                    await http.patch(`/companies/${this.editData.id}`, formDataObj)
                } else {
                    await http.post("/companies", formDataObj)
                }
                this.$toast.success(
                    this.editMode ? "Company Updated Successfully" : "Company Created Successfully"
                )
                window.location.href = "/companies"
            } catch (err) {
                console.log("error in creating Company", err)
                this.$toast.error(
                    (err.response && err.response.data && err.response.data.message) ||
                        "Some error occured"
                )
            }
            this.processing = false
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/variables.scss";

.tab-container {
    display: flex;
    margin-bottom: 10px;
}
.tab-item:hover:not(.active) {
    background-color: lighten($primaryColor, 70%);
}
.tab-item.active {
    border-bottom: 5px solid $primaryColor;
    background-color: lighten($primaryColor, 50%);
}
.tab-item {
    cursor: pointer;
    width: 160px;
    text-align: center;
    padding: 10px;
}
</style>
