<template>
    <div :class="['page-card', { sm }]">
        <h2 v-if="title" class="page-card-title">{{ title }}</h2>
        <hr v-if="hr" class="my-3" />
        <div class="page-card-content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        sm: {
            type: Boolean,
            default: false,
        },
        hr: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
    },
}
</script>

<style lang="scss" scoped>
.page-card {
    background: white;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.1);
    height: 100%;
    &.sm {
        padding: 5px 15px;
    }
}
.page-card-title {
    color: #333;
    font-size: 18px;
}
.page-card-content {
    margin-top: 15px;
}
</style>
