<template>
    <div class="custom-table-container">
        <table :class="['custom-table my-3 table', { scrollable }]">
            <thead>
                <slot name="head" />
            </thead>
            <tbody>
                <slot name="body" />
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        scrollable: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.custom-table {
    thead {
        border-bottom: 1px solid #e3e3e3;
        td {
            border: none;
        }
    }
    thead tr {
        background-color: #eeeeee;
    }
    th {
        font-weight: normal;
        color: #555;
    }
    th,
    td {
        height: 32px;
        border-bottom: none;
        border: 1px solid #e3e3e3 !important;
    }
    tbody tr:nth-of-type(2n) td {
        background-color: #f9f9f9;
    }
    border-bottom: 1px solid #e6e6e6;
}
.custom-table.scrollable td {
    min-width: 100px;
}
.custom-table-container {
    overflow-x: scroll;
}
</style>
