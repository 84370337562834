var render = function render(){var _vm=this,_c=_vm._self._c;return (
        _vm.payhead &&
        !_vm.payhead.disableCompanyAndEmployeeEdits &&
        _vm.payheadMasters &&
        (_vm.companyPayheadValue ? _vm.companyPayheadValue.enabled : true) &&
        !_vm.payhead.isLeavePayhead
    )?_c('div',{staticClass:"payhead-value-edit-input-container"},[_c('div',{staticClass:"payhead-name mb-1"},[_vm._v(_vm._s(_vm.payhead.placeholderTitle || _vm.payhead.name))]),_c('div',[(!_vm.payhead.isBasicSalary)?_c('div',{staticClass:"my-2"},[_c('div',{staticClass:"form-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.enabled),expression:"enabled"}],staticClass:"form-check-input me-2",attrs:{"id":`enabled_${_vm.payhead.id}`,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.enabled)?_vm._i(_vm.enabled,null)>-1:(_vm.enabled)},on:{"change":function($event){var $$a=_vm.enabled,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.enabled=$$a.concat([$$v]))}else{$$i>-1&&(_vm.enabled=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.enabled=$$c}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":`enabled_${_vm.payhead.id}`}},[_vm._v("Enabled")])])]):_vm._e(),(_vm.enabled || _vm.payhead.isBasicSalary)?_c('div',{staticClass:"row"},[(!_vm.payhead.onlyAllowFlatRate && _vm.payheadMasters.calculationTypes)?_c('div',{staticClass:"col-md-4"},[_c('p',{staticClass:"my-1"},[_vm._v("Enter Value Type")]),_c('SelectDropdown',{attrs:{"id":`calculation_type_${_vm.payhead.id}`,"options":_vm.payheadMasters.calculationTypes.map((item) => ({
                            id: item.id,
                            title: item.name,
                        })),"value":_vm.selectedCalculationTypeId},on:{"change":function($event){_vm.selectedCalculationTypeId = $event}}})],1):_vm._e(),(
                    !_vm.payhead.onlyAllowFlatRate &&
                    _vm.selectedCalculationType &&
                    _vm.selectedCalculationType.isComputedValue
                )?_c('div',{staticClass:"col-md-4"},[_c('CustomInput',{style:(`${
                        _vm.percentage <= 100 && _vm.percentage >= 0
                            ? ''
                            : 'border-bottom: 3px solid red'
                    }`),attrs:{"placeholder":"Value","label":"Enter Percentage","type":"number"},model:{value:(_vm.percentage),callback:function ($$v) {_vm.percentage=$$v},expression:"percentage"}})],1):(
                    !_vm.payhead.isOvertimePayhead &&
                    (_vm.payhead.onlyAllowFlatRate ||
                        (_vm.selectedCalculationType &&
                            !_vm.selectedCalculationType.isUserDefined &&
                            !_vm.selectedCalculationType.isComputedValue))
                )?_c('div',{staticClass:"col-md-4"},[_c('CustomInput',{attrs:{"label":"Enter Amount","placeholder":"Enter Rate","type":"number"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1):_vm._e()]):_vm._e()]),_c('div')]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }