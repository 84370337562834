var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageCard',{attrs:{"title":"Generate Letter"}},[_c('div',{},[_c('Button',{attrs:{"noMargin":""},on:{"click":function($event){return _vm.$router.push('/master-templates')}}},[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v(" Back To Master Lists ")]),_c('div',{staticClass:"mt-3 card-title"},[_vm._v("Select Employee")]),_c('div',{staticClass:"search-select-container"},[(_vm.filteredEmployees && _vm.filteredEmployees.length)?_c('SelectDropdown',{attrs:{"id":`form-subject`,"options":_vm.filteredEmployees.map((item) => ({
                        id: item.id,
                        title: `${item.firstName} ${item.lastName}`,
                    })),"value":_vm.selectedEmployeeId},on:{"change":_vm.selectEmployee}}):_vm._e()],1),_c('div',{staticClass:"card-title my-3"},[_vm._v("Select Master Template")]),_c('div',{staticClass:"search-select-container"},[(_vm.templates && _vm.templates.length)?_c('SelectDropdown',{attrs:{"id":`template`,"options":_vm.templates.map((item) => ({
                        id: item.id,
                        title: `${item.title}`,
                    })),"value":_vm.selectedTemplateId},on:{"change":_vm.selectTemplate}}):_vm._e()],1),_c('div',{staticClass:"btns"},[_c('Button',{staticClass:"generate-btn",on:{"click":_vm.showPreview}},[_vm._v("Preview "),_c('i',{staticClass:"fas fa-external-link"})]),_c('Button',{staticClass:"generate-btn",on:{"click":function($event){return _vm.showPreview(true)}}},[_vm._v("Download "),_c('i',{staticClass:"fas fa-download"})])],1),_c('b-modal',{staticClass:"custom-modal",model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"template-preview",domProps:{"innerHTML":_vm._s(_vm.populatedTemplate)}})])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }