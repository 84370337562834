<template>
    <div>
        <!-- <div id="the_div_that_will_hold_the_player_object"></div> -->

        <CrudIndex
            :permission="PERMISSION_CODES.VIEW_CCTV_MASTER"
            title="CCTV Masters"
            :viewColumns="columns"
            fetchUrl="/cctv-masters"
            createUrl="/cctv-masters"
            updateRootUrl="/cctv-masters"
            deleteRootUrl="/cctv-masters"
            :createFields="createFields"
            :reloadOnSuccess="true"
        />
    </div>
</template>

<script>
import CrudIndex from "../../components/crud/index.vue"
import { PERMISSION_CODES } from "../../store/permission"
import { createField, createColumn } from "../../utils/common"

export default {
    data() {
        return {
            createFields: [
                createField("Name*", "name", "text", true),
                createField("URL*", "url", "text", true),
                createField("Active", "status", "switch", false, true),
            ],
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Name", "name", "string"),
                createColumn("URL", "url", "string"),
                createColumn("Status", "status", "string"),
                // createColumn("Active", "status", "string"),
            ],
            PERMISSION_CODES,
        }
    },
    components: { CrudIndex },
    mounted() {
        this.loadPlayer()
    },
    methods: {
        loadPlayer() {
            // //SET THE RTSP STREAM ADDRESS HERE
            // var address = "rtsp://admin:safe@123@36.255.243.195:90/cam/realmonitor?channel=1"
            // var output =
            //     '<object width="640" height="480" id="qt" classid="clsid:02BF25D5-8C17-4B23-BC80-D3488ABDDC6B" codebase="http://www.apple.com/qtactivex/qtplugin.cab">'
            // output += '<param name="src" value="' + address + '">'
            // output += '<param name="autoplay" value="true">'
            // output += '<param name="controller" value="false">'
            // output +=
            //     '<embed id="plejer" name="plejer" src="/poster.mov" bgcolor="000000" width="640" height="480" scale="ASPECT" qtsrc="' +
            //     address +
            //     '"  kioskmode="true" showlogo=false" autoplay="true" controller="false" pluginspage="http://www.apple.com/quicktime/download/">'
            // output += "</embed></object>"
            // //SET THE DIV'S ID HERE
            // document.getElementById("the_div_that_will_hold_the_player_object").innerHTML = output
        },
    },
}
</script>

<style></style>
