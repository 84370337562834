<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="Asset Logs"
            :allowEditing="false"
            :permission="PERMISSION_CODES.VIEW_ASSET_LOGS"
            :viewColumns="columns"
            fetchUrl="/asset-logs"
            :reloadOnSuccess="true"
            :createFields="createFields"
        />
    </div>
</template>

<script>
import { mapState } from "vuex"
import CrudIndex from "../../components/crud/index.vue"
import { PERMISSION_CODES } from "../../store/permission"
import { createField, createColumn } from "../../utils/common"
import { http } from "../../utils/http"

export default {
    data() {
        return {
            createFields: [createField("Log*", "message", "text", true)],
            columns: [
                createColumn("Log", "message", "string"),
                createColumn("Asset Log Type", "asset_log_type.name", "string"),
                createColumn("Created At", "createdDateFormatted", "string"),
            ],
            loading: false,
            initialized: false,
            PERMISSION_CODES,
        }
    },
    components: { CrudIndex },
    mounted() {
        // this.init()
    },
    computed: {
        ...mapState("misc", ["companies"]),
    },
    watch: {
        companies(e) {
            // this.init()
        },
    },
    methods: {
        async init() {
            if (this.initialized) return
            if (!this.companies.length) return
            try {
                console.log("the companies are", this.companies)
                const categories = await this.fetchAssetLogTypes()
                this.createFields = [
                    ...this.createFields,
                    createField("Serial No*", "serialNo", "text", true),
                    createField(
                        "Asset Category*",
                        "assetCategoryId",
                        "select",
                        true,
                        "",
                        categories.map((item) => ({
                            id: item.id,
                            title: item.name,
                        }))
                    ),
                    createField(
                        "Company*",
                        "companyId",
                        "select",
                        true,
                        "",
                        this.companies.map((item) => ({
                            id: item.id,
                            title: item.name,
                        }))
                    ),
                    createColumn(
                        "Allow Multiple User Allocation",
                        "allowMultipleUserAllocation",
                        "switch",
                        false
                    ),
                    createColumn("Is Active", "isActive", "switch", false),
                    // createField("Quantity*", "qty", "number", true),
                    createField("Price*", "price", "number", true),
                ]
                this.loading = false
                this.initialized = false
            } catch (err) {
                console.log("error in initializing assets", err)
            }
        },
        async fetchAssetLogTypes() {
            const res = await http.get("/asset-categories")
            return res.data.result
        },
    },
}
</script>

<style></style>
