<template>
    <div class="container mx-auto px-2 py-3">
        <PageCard title="Project Progress" hr>
            <div v-for="project in projects" :key="project.id" class="mb-6">
                <h3 class="text-xl font-semibold mb-2">{{ project.name }}</h3>
                <p class="text-sm text-gray-600 mb-1">Start Date: {{ project.startDate }}</p>
                <p class="text-sm text-gray-600 mb-1">End Date: {{ project.endDate }}</p>
                <p class="text-sm text-gray-600 mb-2">
                    Progress: {{ calculateProgress(project) }}%
                </p>
                <div
                    class="progress-bar h-2 bg-yellow-500 rounded-full mb-3"
                    :style="{ width: calculateProgressOfproject(project) + '%' }"
                ></div>
                <div
                    class="progress-bar h-2 bg-green-500 rounded-full"
                    :style="{ width: calculateProgress(project) + '%' }"
                ></div>
            </div>
        </PageCard>
    </div>
</template>

<script>
import { http } from "@/utils/http"
import PageCard from "../../../components/layout/PageCard.vue"

export default {
    data() {
        return {
            projects: [],
        }
    },
    components: { PageCard },
    mounted() {
        // Fetch projects when the component is mounted
        this.fetchProjects()
    },
    methods: {
        fetchProjects() {
            // Assuming you have an API endpoint for fetching projects
            http.get("/project-tasks")
                .then((response) => {
                    this.projects = response.data.result
                })
                .catch((error) => {
                    console.error("Error fetching projects:", error)
                })
        },
        calculateProgress(project) {
            const startDate = new Date(project.startDate)
            const endDate = new Date(project.endDate)
            const totalDuration = endDate - startDate
            const currentDate = new Date()
            const elapsedDuration = currentDate - startDate
            const progress = (elapsedDuration / totalDuration) * 100
            return Math.min(100, Math.max(0, progress)).toFixed(2)
        },
        calculateProgressOfproject(project) {
            const status = project.Status && project.Status.name
            switch (status) {
                case "Pending":
                    return 50
                case "In Process":
                    return 90
                case "Completed":
                    return 100
                default:
                    return 0 // Default to 0 if status is unknown or not provided
            }
        },
    },
}
</script>

<style>
.progress-bar {
    height: 20px;
    background-color: green;
}
</style>
