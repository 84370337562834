<template>
    <div
        v-if="
            payhead &&
            !payhead.disableCompanyAndEmployeeEdits &&
            payheadMasters &&
            (companyPayheadValue ? companyPayheadValue.enabled : true) &&
            !payhead.isLeavePayhead
        "
        class="payhead-value-edit-input-container"
    >
        <div class="payhead-name mb-1">{{ payhead.placeholderTitle || payhead.name }}</div>
        <div>
            <div class="my-2" v-if="!payhead.isBasicSalary">
                <div class="form-switch">
                    <input
                        :id="`enabled_${payhead.id}`"
                        type="checkbox"
                        class="form-check-input me-2"
                        v-model="enabled"
                    />
                    <label :for="`enabled_${payhead.id}`" class="form-check-label">Enabled</label>
                </div>
            </div>
            <div class="row" v-if="enabled || payhead.isBasicSalary">
                <div
                    class="col-md-4"
                    v-if="!payhead.onlyAllowFlatRate && payheadMasters.calculationTypes"
                >
                    <p class="my-1">Enter Value Type</p>
                    <SelectDropdown
                        :id="`calculation_type_${payhead.id}`"
                        :options="
                            payheadMasters.calculationTypes.map((item) => ({
                                id: item.id,
                                title: item.name,
                            }))
                        "
                        :value="selectedCalculationTypeId"
                        @change="selectedCalculationTypeId = $event"
                    />
                </div>
                <div
                    class="col-md-4"
                    v-if="
                        !payhead.onlyAllowFlatRate &&
                        selectedCalculationType &&
                        selectedCalculationType.isComputedValue
                    "
                >
                    <CustomInput
                        placeholder="Value"
                        v-model="percentage"
                        label="Enter Percentage"
                        type="number"
                        :style="`${
                            percentage <= 100 && percentage >= 0
                                ? ''
                                : 'border-bottom: 3px solid red'
                        }`"
                    />
                </div>
                <div
                    class="col-md-4"
                    v-else-if="
                        !payhead.isOvertimePayhead &&
                        (payhead.onlyAllowFlatRate ||
                            (selectedCalculationType &&
                                !selectedCalculationType.isUserDefined &&
                                !selectedCalculationType.isComputedValue))
                    "
                >
                    <CustomInput
                        label="Enter Amount"
                        placeholder="Enter Rate"
                        v-model="value"
                        type="number"
                    />
                </div>
            </div>
        </div>
        <div></div>
    </div>
</template>

<script>
import CustomInput from "../crud/custom-input.vue"
import SelectDropdown from "../crud/SelectDropdown.vue"

export default {
    data() {
        return {
            value: "",
            percentage: "",
            selectedCalculationTypeId: "",
            defaultCalculationType: "",
            enabled: true,
        }
    },
    emits: ["change"],
    props: {
        payheads: { type: Array, default: () => [] },
        payhead: { type: Object, default: () => null },
        payheadValue: { type: Object, default: () => null },
        payheadMasters: { type: Object, default: () => null },
        companyPayheadValue: { type: Object, default: () => null },
    },
    components: { CustomInput, SelectDropdown },

    mounted() {
        this.initialize()
    },
    methods: {
        initialize() {
            this.defaultCalculationType = this.payhead.onlyAllowFlatRate
                ? this.payheadMasters.calculationTypes.find(
                      (item) => !item.isComputedValue && !item.isUserDefined
                  )
                : this.selectedCalculationType

            if (this.payheadValue) {
                this.selectedCalculationTypeId = this.payheadValue.calculationTypeId
                this.percentage = this.payheadValue.percentage
                this.value = this.payheadValue.value
                this.enabled = this.payheadValue.enabled
            }
        },
        emitUpdateData() {
            const formattedData = {
                payheadId: this.payhead.id,
                calculationTypeId:
                    this.selectedCalculationTypeId ||
                    (this.defaultCalculationType && this.defaultCalculationType.id),
                value: Number(this.value || 0),
                percentage: Number(this.percentage || 0),
                enabled: this.enabled,
            }

            this.$emit("change", {
                data: formattedData,
                payhead: this.payhead,
                selectedCalculationType:
                    this.selectedCalculationType || this.defaultCalculationType,
            })
        },
    },
    computed: {
        selectedCalculationType() {
            return this.payheadMasters.calculationTypes.find((item) =>
                this.selectedCalculationTypeId
                    ? item.id == this.selectedCalculationTypeId
                    : this.defaultCalculationTypeId
            )
        },
    },
    watch: {
        value(e) {
            this.emitUpdateData()
        },
        percentage(e) {
            this.emitUpdateData()
        },
        selectedCalculationTypeId(e) {
            this.emitUpdateData()
        },
        enabled(e) {
            this.emitUpdateData()
        },
    },
}
</script>

<style lang="scss" scoped>
.payhead-value-edit-input-container {
    margin: 14px 0;
    background: #ccc;
    padding: 15px;
    border-radius: 5px;
}
.payhead-name {
    font-weight: bold;
    font-size: 1.2em;
}
</style>
