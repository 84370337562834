<template>
    <div class="tab-container">
        <div
            :class="['tab-item', { active: selectedTab && selectedTab.name == tab.name }]"
            @click="handleTabChange(tab)"
            v-for="tab of tabs"
            :key="tab.name"
        >
            <div>{{ tab.value }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        selectedTab: {
            type: Object,
            default: null,
        },
        tabs: {
            // array of objects with name and value properties
            type: Array,
            default: () => [],
        },
    },
    mounted() {
        const tab = this.$route.query.active_tab
        if (tab) {
            this.$emit(
                "change",
                this.tabs.find((item) => item.name == tab)
            )
        }
    },
    methods: {
        handleTabChange(tab) {
            this.$emit("change", tab)
            const url = this.$router.resolve({
                ...this.$route,
                query: {
                    ...this.$route.query,
                    active_tab: tab.name,
                },
            }).href
            console.log("the full url is", url)
            console.log("active tab is", tab)
            this.$router.push(url)
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../../assets/variables.scss";

.tab-container {
    display: flex;
    margin-bottom: 10px;
}
.tab-item {
    background-color: #f2f2f2;
}
.tab-item:hover:not(.active) {
    background-color: lighten($primaryColor, 70%);
    border-bottom: 5px solid #ddd;
}
.tab-item.active {
    border-bottom: 5px solid $primaryColor;
    background-color: lighten($primaryColor, 50%);
}
.tab-item {
    cursor: pointer;
    width: 160px;
    text-align: center;
    padding: 10px;
}
</style>
