<template>
    <div class="my-3" v-if="employee">
        <hr class="mb-2" />
        <div class="d-flex justify-content-between">
            <div class="my-1 mb-3 employee-name">
                <div class="text-lg">
                    {{ employee.firstName }}
                    {{ employee.lastName }}
                </div>
                <router-link target="_blank" style="text-decoration: underline" :to="`/employees/edit/${employee.id}?active_tab=PAYROLL`">
                    <i class="fas fa-edit"></i> Edit Payroll
                </router-link>
            </div>
            <div>
                <div class="text-secondary"><strong>Total Earnings</strong>: {{ totalAmount.toLocaleString() }}</div>
                <div class="text-danger"><strong>Total Deductions</strong>: {{ totalDeductions.toLocaleString() }}</div>
                <div><strong>Net Total</strong>: {{ netTotal.toLocaleString() }}</div>
            </div>
        </div>
        <div class="ml-5">
            <div v-for="epdi of employee.payheadValues" :key="epdi.id">
                <div class="row" v-if="epdi.payheadValue.enabled">
                    <div class="col-sm-3">
                        <div style="">
                            {{ epdi.payhead.name }}
                            <span class="text-secondary">{{ subtitleMsg(epdi) }}</span>
                        </div>
                    </div>

                    <div class="col-sm-3">
                        <div>
                            <input
                                :disabled="isInputDisabled(epdi)"
                                :class="['epdi-input', { disabled: isInputDisabled(epdi) }]"
                                v-model="epdi.inputAmount"
                                type="number"
                            />
                            <span :class="['mx-2 text-secondary', { 'text-danger': epdi.payheadType.isDecrement }]">
                                {{ epdi.payheadType.isDecrement ? "Deductions" : "Earnings" }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-3 text-danger" v-if="netTotal < 0">Net Total is negative!</div>
    </div>
</template>

<script>
import { round2 } from "../../utils/common"

export default {
    props: {
        employee: { type: Object, default: () => null },
    },
    data() {
        return {
            inputValues: [],
        }
    },
    computed: {
        netTotal() {
            console.log("this.totalAmount", this.totalAmount)
            const val = round2(this.totalAmount - this.totalDeductions)
            return val < 0 ? 0 : val
        },
        totalAmount() {
            return round2(
                this.employee.payheadValues.reduce((val, epdi) => val + (epdi.payheadType.isDecrement ? 0 : Number(epdi.inputAmount)), 0)
            )
        },
        totalDeductions() {
            return round2(
                this.employee.payheadValues.reduce((val, epdi) => val + (epdi.payheadType.isDecrement ? Number(epdi.inputAmount) : 0), 0)
            )
        },
    },
    methods: {
        round2,
        isInputDisabled(epdi) {
            // enable all for now
            return false
            if (epdi.payhead.isLeavePayhead || epdi.payhead.isOvertimePayhead || epdi.payhead.disableCompanyAndEmployeeEdits) {
                return true
            } else if (epdi.calculationType) {
                return !epdi.calculationType.isUserDefined
            } else {
                return false
            }
        },
        subtitleMsg(epdi) {
            if (
                !epdi.payhead.defaultAmount &&
                !epdi.payhead.defaultPercentage &&
                (epdi.payhead.isLeavePayhead ||
                    epdi.payhead.isOvertimePayhead ||
                    (epdi.calculationType && epdi.calculationType.isComputedValue))
            ) {
                const amount = epdi.payheadValue.value || epdi.payheadValue.percentage || 0
                let sign = ""

                if (epdi.payheadValue.percentage) {
                    sign = "%"
                } else if (epdi.payhead.isLeavePayhead) {
                    sign = " rate/day"
                } else if (epdi.payhead.isOvertimePayhead) {
                    sign = " rate/hr"
                }
                return `(${amount}${sign})`
            }
            return ""
        },
        getData() {
            // return formatted data for parent
            const payheadValues = []

            for (const epdi of this.employee.payheadValues) {
                payheadValues.push({
                    payheadId: epdi.payhead.id,
                    calculationTypeId: epdi.calculationType && epdi.calculationType.id,
                    value: epdi.payheadValue.value,
                    percentage: epdi.payheadValue.percentage,
                    calculateFromPayheadId: epdi.payheadValue.calculateFromPayheadId,
                    // display related properties
                    title: epdi.payhead.name,
                    subTitle: this.subtitleMsg(epdi),
                    finalAmount: epdi.inputAmount,
                    isDecrement: epdi.payheadType.isDecrement,
                })
            }
            return {
                payheadValues,
                employeeId: this.employee.id,
                netSalaryAmount: this.netTotal,
                totalEarningAmount: this.totalAmount,
                totalDeductionAmount: this.totalDeductions,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.epdi-input {
    border: 1px solid #ccc;
    outline: none;
    padding: 0 10px;
    width: 100px;
    &.disabled {
        border-color: #fff;
    }
}
.employee-name {
    font-weight: bold;
    text-transform: capitalize;
}
</style>
