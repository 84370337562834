<template>
    <PageCard title="Task Details">
        <div class="" v-if="!loading && task && isUserAssignedForIssue">
            <Button noMargin v-if="task.taskId" @click="backToParentTask">
                <i class="fas fa-chevron-left"></i> View Parent Task
            </Button>
            <Button v-else @click="backToTasks">
                <i class="fas fa-chevron-left"></i> View All Tasks
            </Button>
            <div class="row">
                <div class="col-md-8">
                    <div class="mb-4" v-if="task">
                        <h3 class="h5">{{ task.name }}</h3>
                        <p>
                            <strong>Reported by {{ task.reporterName }}</strong>
                        </p>
                    </div>
                    <div>
                        <div class="my-2">
                            <b>Description</b>
                        </div>
                        <div>
                            <div class="card">
                                <!-- <div
                                class="card-body"
                                v-html="description"
                                v-if="isEditDisabled || !!striptags(originalDescription)"
                            /> -->
                                <HtmlEditor
                                    :id="`${task.name.replaceAll(' ', '_')}`"
                                    @change="description = $event"
                                    :value="description"
                                />
                            </div>
                            <div>
                                <div class="my-2">Assignees</div>
                                <SelectDropdown
                                    :id="`issue-assignees`"
                                    :options="
                                        taskCreateOptions.assignees.map((item) => ({
                                            id: item.id,
                                            title: item.firstName,
                                        }))
                                    "
                                    :multiple="true"
                                    :field="'issue-assignees'"
                                    :value="assigneeUserIds"
                                    :disabled="isEditDisabled"
                                    @change="assigneeUserIds = $event"
                                />
                            </div>
                            <TagUser
                                v-if="task && !isEditDisabled"
                                :updateRoute="'/issue/' + task.id"
                                :taggedUsers="task.taggedUsers || []"
                                @update="updateTaggedUsers"
                            />

                            <div class="my-2" v-if="showAttachmentManager">Attachments:</div>
                            <div class="attachment-manager" v-if="showAttachmentManager">
                                <AttachmentUploader
                                    @remove="addToRemoveList('attachment1')"
                                    v-model="attachment1"
                                    :id="1"
                                    :name="attachment1Name"
                                    :isUser="isEditDisabled"
                                />
                                <AttachmentUploader
                                    @remove="addToRemoveList('attachment2')"
                                    v-model="attachment2"
                                    :id="2"
                                    :name="attachment2Name"
                                    :isUser="isEditDisabled"
                                />
                                <AttachmentUploader
                                    @remove="addToRemoveList('attachment3')"
                                    v-model="attachment3"
                                    :id="3"
                                    :name="attachment3Name"
                                    :isUser="isEditDisabled"
                                />
                                <AttachmentUploader
                                    @remove="addToRemoveList('attachment4')"
                                    v-model="attachment4"
                                    :id="4"
                                    :name="attachment4Name"
                                    :isUser="isEditDisabled"
                                />
                                <AttachmentUploader
                                    @remove="addToRemoveList('attachment5')"
                                    v-model="attachment5"
                                    :id="5"
                                    :name="attachment5Name"
                                    :isUser="isEditDisabled"
                                />
                            </div>

                            <div class="my-2" v-if="!isEditDisabled">
                                <Button @click="saveChanges(true)">{{
                                    savingRestricted ? "Saving..." : "Save"
                                }}</Button>
                            </div>
                            <div v-if="!task.taskId">
                                <div class="my-3"><strong>Subtasks</strong></div>

                                <div class="mb-3">
                                    <Button
                                        @click="
                                            openLink(
                                                `/project-tasks/create?task_id=${
                                                    task.id
                                                }&company_id=${
                                                    taskCreateOptions.activeCompany
                                                        ? taskCreateOptions.activeCompany.id
                                                        : ''
                                                }&project_id=${task.projectId}`
                                            )
                                        "
                                        noMargin
                                    >
                                        Create Subtask
                                        <i class="fas fa-external-link"></i>
                                    </Button>
                                </div>
                                <div v-if="subTasks && subTasks.length">
                                    <div
                                        class="subtask-item"
                                        v-for="subTask of subTasks"
                                        :key="subTask.id"
                                    >
                                        <div class="subtask-title">{{ subTask.name }}</div>
                                        <div class="my-3">
                                            {{ formatIndianDate(subTask.createdAt) }}
                                        </div>
                                        <div class="text-end">
                                            <Button
                                                @click="openLink(`/project-tasks/${subTask.slug}`)"
                                                noMargin
                                                >Edit Subtask <i class="fas fa-external-link"></i
                                            ></Button>
                                            <Button
                                                @click="deleteTask(subTask.id)"
                                                style="margin-left: 10px"
                                                noMargin
                                                >Delete Subtask <i class="fas fa-trash"></i>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>No Subtasks Found</div>
                            </div>

                            <CommentSystem :taskId="task.id" />
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div style="width: 150px">
                        <SelectDropdown
                            :id="`issue-status`"
                            :options="
                                taskCreateOptions.projectStatuses.map((item) => ({
                                    id: item.id,
                                    title: item.name,
                                }))
                            "
                            :multiple="false"
                            :field="'issue-status'"
                            :value="taskStatusId"
                            @change="taskStatusId = $event"
                        />
                    </div>
                    <Accordion
                        :title="'Details'"
                        :defaultShow="true"
                        :subtitle="'Departments, Reporter, Priority'"
                    >
                        <!-- <ListView title="Assignees">
                                <SelectDropdown
                                    :id="`issue-assignees-2`"
                                    :options="
                                        taskCreateOptions.assignees.map((item) => ({
                                            id: item.id,
                                            title: item.firstName,
                                        }))
                                    "
                                    :multiple="true"
                                    :field="'issue-assignees'"
                                    :value="assigneeUserIds"
                                    :disabled="isEditDisabled"
                                    @change="assigneeUserIds = $event"
                                />
                            </ListView> -->
                        <ListView title="Departments">
                            <SelectDropdown
                                :id="`issue-departments`"
                                :options="
                                    taskCreateOptions.departments.map((item) => ({
                                        id: item.id,
                                        title: item.name,
                                    }))
                                "
                                :multiple="true"
                                :field="'issue-departments'"
                                :value="departmentIds"
                                :disabled="isEditDisabled"
                                @change="departmentIds = $event"
                        /></ListView>
                        <ListView
                            title="Task Pending Due To"
                            v-if="
                                taskStatusId ==
                                taskCreateOptions.projectStatuses.find(
                                    (item) => item.slug == 'pending'
                                )?.id
                            "
                        >
                            <SelectDropdown
                                :id="`issue-pending-task`"
                                :options="
                                    taskCreateOptions.allUsers.map((item) => ({
                                        id: item.id,
                                        title: item.firstName,
                                    }))
                                "
                                :multiple="true"
                                :field="'issue-pending-task'"
                                :value="pendingDueToUserIds"
                                :disabled="isEditDisabled"
                                @change="pendingDueToUserIds = $event"
                        /></ListView>
                        <ListView title="Start Date" v-if="startDate">
                            {{ new Date(startDate).toDateString() }}
                        </ListView>
                        <ListView title="Target Date" v-if="endDate">
                            <input
                                type="date"
                                :min="new Date(startDate).toISOString().slice(0, 10)"
                                :value="new Date(endDate).toISOString().slice(0, 10)"
                                @change="endDate = $event.target.value"
                                :disabled="isEditDisabled"
                            />
                        </ListView>
                        <!-- <ListView title="Interim Date" v-if="interimDate">
                        <input
                            type="date"
                            :max="new Date(endDate).toISOString().slice(0, 10)"
                            :value="new Date(interimDate).toISOString().slice(0, 10)"
                            @change="interimDate = $event.target.value"
                            :disabled="isEditDisabled"
                        />
                    </ListView> -->
                        <ListView title="Creator">
                            <SelectDropdown
                                :id="`issue-reporter`"
                                :options="
                                    taskCreateOptions.allUsers.map((item) => ({
                                        id: item.id,
                                        title: item.firstName,
                                    }))
                                "
                                :multiple="false"
                                :field="'issue-reporter'"
                                :value="reporterId"
                                :disabled="isEditDisabled"
                                @change="reporterId = $event"
                        /></ListView>

                        <ListView title="Priority">
                            <SelectDropdown
                                :id="`issue-priority`"
                                :options="
                                    taskCreateOptions.projectPriorities.map((item) => ({
                                        id: item.id,
                                        title: item.name,
                                    }))
                                "
                                :multiple="false"
                                :field="'issue-priority'"
                                :value="priorityId"
                                :disabled="isEditDisabled"
                                @change="priorityId = $event"
                            />
                        </ListView>

                        <!-- <ListView title="Task">
                        <SelectDropdown
                            :id="`task`"
                            :options="
                                taskCreateOptions.tasks.map((item) => ({
                                    id: item.id,
                                    title: item.name,
                                }))
                            "
                            :multiple="false"
                            :field="'task'"
                            :value="taskId"
                            :disabled="isEditDisabled"
                            @change="taskId = $event"
                        />
                    </ListView> -->
                    </Accordion>
                    <!-- <Accordion
                            :title="'More Fields'"
                            :subtitle="'Original estimate, Time Tracking'"
                        >
                            Coming Soon...
                        </Accordion> -->
                    <div v-if="processingForm"><small>Saving Changes...</small></div>
                </div>
            </div>
        </div>
        <div v-else-if="loading">Loading Task...</div>
        <div v-else-if="!task">Task Not Found</div>
        <div v-else-if="!isUserAssignedForIssue">You do not have access to this task!</div>
    </PageCard>
</template>

<script>
import striptags from "striptags"
import HtmlEditor from "@/components/other/HtmlEditor"
import Button from "@/components/UI/Button"
import SelectDropdown from "@/components/crud/SelectDropdown"
import CommentSystem from "@/components/Lists/CommentSystem"
import Accordion from "@/components/layout/Accordion"
import ListView from "@/components/layout/ListView"
import TagUser from "@/components/other/TagUser"
import { mapState, mapActions } from "vuex"
import { errMsg, hasIssuePermission } from "@/utils/common"
import AttachmentUploader from "@/components/other/AttachmentUploader"
import { http } from "@/utils/http"
import { formatIndianDate } from "../../../utils/common"
import PageCard from "../../../components/layout/PageCard.vue"

export default {
    components: {
        PageCard,
        HtmlEditor,
        Button,
        SelectDropdown,
        Accordion,
        ListView,
        CommentSystem,
        AttachmentUploader,
        TagUser,
    },
    computed: {
        ...mapState("project", ["projects", "projectStatuses"]),
        ...mapState("auth", ["user", "isAdmin"]),
        isCreatorOrAdmin() {
            return true
            // condition later enable
            // (this.task.Reporter && this.user.id == this.task.Reporter.id) || this.isAdmin
        },
    },
    data() {
        return {
            showAttachmentManager: true,
            subTasks: [],
            taskId: "",
            loading: false,
            taskCreateOptions: null,
            description: "",
            task: null,
            processingForm: false,
            taskStatusId: "",
            assigneeUserIds: [],
            departmentIds: [],
            reporterId: null,
            priorityId: null,
            savingRestricted: false,
            isEditDisabled: false,
            isUserAssignedForIssue: false,
            attachment1: null,
            attachment2: null,
            attachment3: null,
            attachment4: null,
            attachment5: null,
            attachment1Name: "",
            attachment2Name: "",
            attachment3Name: "",
            attachment4Name: "",
            attachment5Name: "",
            removeFilesList: [],
            startDate: "",
            endDate: "",
            interimDate: "",
            reportToIds: [],
            pendingDueToUserIds: [],
        }
    },
    async mounted() {
        window.document.title = "TASK | Sub TASK"
        this.updateTaskDetails(true)
    },
    methods: {
        striptags,
        backToParentTask() {
            if (this.taskCreateOptions && this.taskCreateOptions.parentTask) {
                window.location.href = `/project-tasks/${this.taskCreateOptions.parentTask.slug}`
            }
        },
        async deleteTask(id) {
            try {
                if (confirm("Are you sure?")) {
                    await http.delete(`/project-tasks/${id}`)
                    window.location.reload()
                }
            } catch (err) {
                console.log("error in deleting task", err)
            }
        },
        formatIndianDate,
        ...mapActions("auth", [
            // "checkRole"
        ]),
        backToTasks() {
            this.$router.push("/project-tasks")
            // this.$router.goBack(-1)
        },
        openLink(url) {
            window.open(url, "_blank")
        },
        updateTaggedUsers(ticketUpdate) {
            if (ticketUpdate) {
                console.log("ticket update", ticketUpdate)
                this.task.taggedUsers = ticketUpdate.taggedUsers
            }
        },
        async updateTaskDetails(fetchData) {
            this.loading = true

            if (fetchData) {
                const taskCreateOptions = await this.fetchTaskCreateOpotions()
                this.taskCreateOptions = taskCreateOptions
                this.task = taskCreateOptions.task
            }

            if (this.task) {
                const descriptionData =
                    this.task.description && this.task.description != "undefined"
                        ? this.task.description
                        : ""
                this.description = descriptionData
                this.originalDescription = descriptionData
                this.assigneeUserIds = this.task.assigneeUserIds
                this.departmentIds = this.task.departmentIds || []
                this.reporterId = this.task.reporterId
                this.priorityId = this.task.priorityId
                this.taskStatusId = this.task.statusId
                this.taskId = this.task.id
                this.subTasks = this.taskCreateOptions.subTasks

                this.startDate = this.task.startDate
                this.endDate = this.task.endDate
                this.interimDate = this.task.interimDate
                this.reportToIds = this.task.reportToIds
                this.pendingDueToUserIds = this.task.pendingDueToUserIds

                this.attachment1Name = this.task.attachment1
                this.attachment2Name = this.task.attachment2
                this.attachment3Name = this.task.attachment3
                this.attachment4Name = this.task.attachment4
                this.attachment5Name = this.task.attachment5
            }
            // todo later uncomment below condition
            this.isEditDisabled = false
            // this.isEditDisabled = await this.checkRole({ user: this.user, roleToCheck: "User" })

            // todo later uncomment below condition
            this.isUserAssignedForIssue = true

            // this.isUserAssignedForIssue =
            //     this.isAdmin ||
            //     hasIssuePermission(
            //         this.user.id,
            //         this.task.AssigneeUser,
            //         this.task.Reporter && this.task.Reporter.id
            //     )
            this.loading = false
        },
        async fetchTaskCreateOpotions() {
            let result = {}
            try {
                const res = await http.get(`/project-tasks/init?task_slug=${this.$route.params.id}`)
                result = res.data
            } catch (err) {
                console.log("error in loading customers", err)
            }
            this.initialized = true
            return result
        },
        async saveChanges(restricted = false) {
            let result = {}
            if (restricted) {
                if (this.savingRestricted) return
                this.savingRestricted = true
            }
            if (this.processingForm) return
            this.processingForm = true
            if (
                this.attachment1 ||
                this.attachment2 ||
                this.attachment3 ||
                this.attachment4 ||
                this.attachment5
            ) {
                this.showAttachmentManager = false
            }
            try {
                const formData = new FormData()

                formData.append("description", this.description || "")
                formData.append("assigneeUserIds", JSON.stringify(this.assigneeUserIds) || [])
                formData.append("departmentIds", JSON.stringify(this.departmentIds || []))
                formData.append(
                    "pendingDueToUserIds",
                    JSON.stringify(this.pendingDueToUserIds || [])
                )
                // formData.append("taskId", this.taskId || "")
                formData.append("priorityId", this.priorityId || "")
                formData.append("reporterId", this.reporterId || "")
                formData.append("statusId", this.taskStatusId || "")
                formData.append("endDate", this.endDate || "")
                formData.append("interimDate", this.interimDate || "")

                // files to remove if any
                formData.append("removeFilesList", JSON.stringify(this.removeFilesList))

                // attachments to add if any
                formData.append("attachment1", this.attachment1 || "")
                formData.append("attachment2", this.attachment2 || "")
                formData.append("attachment3", this.attachment3 || "")
                formData.append("attachment4", this.attachment4 || "")
                formData.append("attachment5", this.attachment5 || "")

                const res = await http.patch(
                    `/project-tasks/${this.task && this.task.id}`,
                    formData
                )
                result = res.data.result
                this.task = result
                await this.updateTaskDetails(false)
                if (restricted) {
                    this.$toast.info("Saved Changes")
                }
            } catch (err) {
                console.log("error in loading customers", err)
                this.$toast.error("Failed to update!")
                this.$toast.error(errMsg(err))
            }
            this.showAttachmentManager = true
            this.savingRestricted = false
            this.processingForm = false
            this.initialized = true
            return result
        },
        async addToRemoveList(item) {
            if (!this.removeFilesList.includes(item)) {
                this.removeFilesList = [...this.removeFilesList, item]
            }
            console.log("remove files list", this.removeFilesList)
        },
    },
    watch: {
        priorityId(e) {
            this.saveChanges()
        },
        taskStatusId(e) {
            this.saveChanges()
        },
        reporterId(e) {
            this.saveChanges()
        },
        assigneeUserIds(e) {
            this.saveChanges()
        },
        departmentIds(e) {
            this.saveChanges()
        },
        pendingDueToUserIds(e) {
            this.saveChanges()
        },
        taskId(e) {
            this.saveChanges()
        },
        interimDate(e) {
            this.saveChanges()
        },
        endDate(e) {
            this.saveChanges()
        },
    },
}
</script>

<style lang="scss" scoped>
.card-text {
    font-size: 20px;
    font-weight: 600;
}
.issue-toggle-btn {
    padding: 10px;
    margin-left: 15px;
    border-radius: 5px;
    font-size: 0.9em;
    cursor: pointer;
    background-color: #f8f8f8;
    &:hover {
        background-color: #eee;
    }
}
.attachment-manager {
    display: flex;
    gap: 15px;
    flex-flow: row wrap;
}
.subtask-item {
    padding: 15px;
    border: 1px solid #ddd;
    margin-bottom: 010px;
}
.subtask-title {
    font-size: 1.3em;
}
</style>
