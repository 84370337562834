<template>
  <div>
    <div>
      <PageCard title="Permission Editor">
        <div v-if="allowedUserRoles && allowedUserRoles.length">
          <div class="my-3">Select Role</div>
          <SelectDropdown
            :id="`form-subject`"
            :options="
              allowedUserRoles.map((item) => ({
                id: item.id,
                title: item.name,
              }))
            "
            :value="selectedUserRoleId"
            @change="selectedUserRoleId = $event"
          />
          <!-- <Button @click="handleInitPermissions">Load Permissions</Button> -->
        </div>
        <div class="d-flex align-items-center justify-content-end">
          <div class=" mt-2">
            <input
            class="mr-1"
              type="checkbox"
              @change="toggleSelectAll"
              v-model="selectAllPermissions"
              aria-label="selectAll"
            /> 
            <label for="selectAll" class="mr-3">Select All</label>
          </div>
          <Button @click="updatePermissions">{{
            saving ? "Please wait..." : "Update Permissions"
          }}</Button>
        </div>
      </PageCard>
      <div class="my-3">
        <div class="row gy-3">
          <div
            class="col-md-6"
            v-for="permissionsGroup of permissionsGrouped"
            :key="permissionsGroup.id"
          >
            <PageCard hr :title="permissionsGroup.title">
              <div>
                <label
                  v-for="permission of permissionsGroup.permissions"
                  :key="permission.id"
                  :class="[
                    'd-flex justify-content-between my-3',
                    {
                      'selected-checkbox-label': selectedPermissionIds.includes(
                        permission.id
                      ),
                    },
                  ]"
                >
                  <div>
                    {{ permission.name }}
                  </div>
                  <input
                    @click="handlePermissionAdd(permission.id)"
                    :checked="selectedPermissionIds.includes(permission.id)"
                    type="checkbox"
                  />
                </label>
              </div>
            </PageCard>
          </div>
        </div>
        <div class="text-end">
          <Button @click="updatePermissions">{{
            saving ? "Please wait..." : "Update Permissions"
          }}</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import CrudIndex from "@/components/crud/index.vue";
import { mapActions, mapState } from "vuex";
import SelectDropdown from "../../components/crud/SelectDropdown.vue";
import Button from "../../components/UI/Button.vue";
import PageCard from "../../components/layout/PageCard.vue";

export default {
  data() {
    return {
      loading: false,
      saving: false,
      selectedUserRoleId: "",
      selectedPermissionIds: [],
      selectAllPermissions: false,
    };
  },
  props: { videoId: { type: String, default: "" } },
  components: { PageCard, Button, SelectDropdown, CrudIndex },
  async mounted() {
    this.loading = false;
    console.log("amounted init roles");
    await this.initRoles();
  },
  methods: {
    ...mapActions("permission", ["initRoles", "initPermissions"]),
    checkAllPermissions() {},
    async updatePermissions() {
      if (this.saving) return;
      try {
        this.saving = true;
        const res = await http.patch("/permissions", {
          permissionIds: this.selectedPermissionIds,
          role_id: this.selectedUserRoleId,
        });
        this.$toast.success("Permission updated successfully!");
      } catch (err) {
        console.log("error in updating permissions", err);
      }
      this.saving = false;
    },
    handlePermissionAdd(pid) {
      if (this.selectedPermissionIds.includes(pid)) {
        this.selectedPermissionIds = this.selectedPermissionIds.filter(
          (item) => item != pid
        );
      } else {
        this.selectedPermissionIds.push(pid);
      }
    },
    async handleInitPermissions() {
      this.loading = true;
      try {
        await this.initPermissions(this.selectedUserRoleId);
      } catch (err) {}
      this.loading = false;
    },
    toggleSelectAll(event) {
      this.selectAllPermissions = event.target.checked;
      if (this.selectAllPermissions) {
        this.selectedPermissionIds = this.permissionsGrouped.flatMap((group) =>
          group.permissions.map((p) => p.id)
        );
      } else {
        this.selectedPermissionIds = [];
      }
    },
  },
  computed: {
    ...mapState("permission", [
      "userRoles",
      "permissionsGrouped",
      "allowedPermissionIds",
    ]),
    allowedUserRoles() {
      return this.userRoles.filter(
        (item) =>
          !item.name.toLowerCase().includes("admin") &&
          !item.name.toLowerCase().includes("employee")
      );
    },
  },
  watch: {
    selectedUserRoleId(e) {
      this.initPermissions(e);
    },
    allowedPermissionIds(e) {
      this.selectedPermissionIds = e;
    },
    userRoles(e) {
      if (e && e.length) {
        this.selectedUserRoleId =
          this.allowedUserRoles && this.allowedUserRoles[0]
            ? this.allowedUserRoles[0].id
            : "";
      }
    },
  },
};
</script>

<style scoped>
.selected-checkbox-label {
  font-weight: bold;
}
</style>
