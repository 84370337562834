<template>
    <div>
        <div class="d-flex justify-content-start">
            <Button @click="$router.push('/bank-masters/create')"
                ><i class="fas fa-plus"></i> Create Bank Master</Button
            >
        </div>
        <CrudIndex
            :permission="PERMISSION_CODES.VIEW_BANK_MASTER"
            title="Bank Masters"
            :viewColumns="columns"
            fetchUrl="/bank-masters"
            updateRootUrl="/bank-masters"
            deleteRootUrl="/bank-masters"
            editRouteName="bank-master-edit"
            :createFields="createFields"
            :reloadOnSuccess="true"
        />
    </div>
</template>

<script>
import Button from "../../components/UI/Button.vue"
import CrudIndex from "../../components/crud/index.vue"
import { PERMISSION_CODES } from "../../store/permission"
import { createField, createColumn } from "../../utils/common"

export default {
    data() {
        return {
            createFields: [
                createField("Name*", "name", "text", true),
                // createField("Status", "status", "switch", false, true),
            ],
            columns: [
                // createColumn("Id", "id", "number"),
                createColumn("Name", "name", "string"),
                // createColumn("Active", "status", "string"),
            ],
            PERMISSION_CODES,
        }
    },
    components: { Button, CrudIndex },
}
</script>

<style></style>
