<template>
    <div class="my-3" v-if="employee">
        <div>
            <div class="my-3">
                <hr />
            </div>
            <SectionTitle>Appointment Letter </SectionTitle>
            <div class="my-3">
                <div><strong>Fillup details:</strong></div>
                <div>
                    <form @submit.prevent="generateLetter('Appointment')">
                        <div class="row">
                            <div class="col-md-6">
                                <custom-input
                                    v-model="totalSickLeaves"
                                    type="number"
                                    required
                                    label="Total Sick Leaves"
                                />
                            </div>
                            <div class="col-md-6">
                                <custom-input
                                    v-model="companyNoticePeriodDays"
                                    type="number"
                                    required
                                    label="Company Notice Period Days"
                                />
                            </div>
                            <div class="col-md-6">
                                <custom-input
                                    v-model="employeeNoticePeriodDays"
                                    type="number"
                                    required
                                    label="Employee Notice Period Days"
                                />
                            </div>
                            <div class="col-md-6">
                                <custom-input
                                    v-model="highCourtName"
                                    type="text"
                                    required
                                    label="High Court Name"
                                />
                            </div>
                            <div class="col-md-6">
                                <custom-input
                                    v-model="highCourtState"
                                    type="text"
                                    required
                                    label="High Court State"
                                />
                            </div>
                            <div class="col-md-12">
                                <MultiTextAdder
                                    v-if="initialized"
                                    title="Duties"
                                    v-model="duties"
                                />
                                <MultiTextAdder
                                    v-if="initialized"
                                    title="Responsibilities"
                                    v-model="responsibilities"
                                />
                            </div>
                        </div>
                        <p class="my-2" v-if="missingAppointmentLetterFields.length">
                            To Continue Generating Please fix below issues:
                        </p>
                        <div v-if="missingAppointmentLetterFields.length">
                            <ul
                                class="missing-field-container"
                                v-for="missingAppointmentLetterField of missingAppointmentLetterFields"
                                :key="missingAppointmentLetterField.id"
                            >
                                <li>
                                    Missing {{ missingAppointmentLetterField }} in Personal Details!
                                </li>
                            </ul>
                        </div>
                        <div v-if="employee.appointmentLetterLastGeneratedDate">
                            Last generated on
                            {{ formatIndianDate(employee.appointmentLetterLastGeneratedDate) }}
                        </div>
                        <div class="my-2" v-if="!missingAppointmentLetterFields.length">
                            <Button
                                type="submit"
                                class="mt-3 me-2"
                                style="background-color: #444"
                                noMargin
                            >
                                <span v-if="generatingAppointment"> Please wait... </span>
                                <span v-else-if="!employee.appointmentLetterPdf">
                                    <i class="fas fa-check"></i>
                                    Generate Appointment Letter
                                </span>
                                <span v-else>
                                    <i class="fas fa-refresh"></i> Regenerate Appointment Letter
                                </span>
                            </Button>
                            <Button
                                v-if="employee.appointmentLetterPdf"
                                @click="downloadLetter(employee.appointmentLetterPdf)"
                                noMargin
                                type="button"
                            >
                                <i class="fas fa-download"></i> Download Appointment Letter
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div>
            <div class="my-4">
                <hr />
            </div>
            <SectionTitle>Offer Letter </SectionTitle>
            <div class="my-3">
                <div><strong>Fillup details:</strong></div>
                <div>
                    <form @submit.prevent="generateLetter('Offer')">
                        <div class="row">
                            <div class="col-md-6">
                                <custom-input
                                    v-model="offeredJobByPersonName"
                                    type="text"
                                    required
                                    label="Offered By Person Name"
                                />
                            </div>
                            <div class="col-md-6">
                                <custom-input
                                    v-model="offeredJobByPersonPosition"
                                    type="text"
                                    required
                                    label="Position of the person who is offering"
                                />
                            </div>
                            <div class="col-md-6">
                                <custom-input
                                    v-model="desiredJobStartingDate"
                                    type="date"
                                    :min="dateToday"
                                    required
                                    label="Desired starting date of work"
                                />
                            </div>
                            <div class="col-md-6">
                                <custom-input
                                    v-model="desiredJobStartingTime"
                                    type="time"
                                    required
                                    label="Desired starting time of work"
                                />
                            </div>
                            <div class="col-md-6">
                                <custom-input
                                    v-model="lastDateForJobOfferAcceptance"
                                    :min="dateToday"
                                    type="date"
                                    required
                                    label="Last date for accepting this offer"
                                />
                            </div>
                            <div class="col-md-12">
                                <MultiTextAdder
                                    v-if="initialized"
                                    title="Benefits"
                                    v-model="benefits"
                                />
                            </div>
                        </div>

                        <p class="my-2" v-if="missingOfferLetterFields.length">
                            To Continue Generating Please fix below issues:
                        </p>
                        <div v-if="missingOfferLetterFields.length">
                            <ul
                                class="missing-field-container"
                                v-for="missingOfferLetterField of missingOfferLetterFields"
                                :key="missingOfferLetterField.id"
                            >
                                <li>Missing {{ missingOfferLetterField }} in Personal Details!</li>
                            </ul>
                        </div>

                        <div v-if="employee.offerLetterLastGeneratedDate">
                            Last generated on
                            {{ formatIndianDate(employee.offerLetterLastGeneratedDate) }}
                        </div>
                        <div class="my-3" v-if="!missingOfferLetterFields.length">
                            <Button
                                type="submit"
                                class="mt-3 me-2"
                                style="background-color: #444"
                                noMargin
                            >
                                <span v-if="generatingOfferLetter"> Please wait... </span>
                                <span v-else-if="!employee.offerLetterPdf">
                                    <i class="fas fa-check"></i>
                                    Generate Offer Letter
                                </span>
                                <span v-else>
                                    <i class="fas fa-refresh"></i> Regenerate Offer Letter
                                </span>
                            </Button>
                            <Button
                                v-if="employee.offerLetterPdf"
                                @click="downloadLetter(employee.offerLetterPdf)"
                                noMargin
                                type="button"
                            >
                                <i class="fas fa-download"></i> Download Offer Letter
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { daysToMs, formatIndianDate, getAssetUrlPath } from "../../utils/common"
import { http, serverURL } from "../../utils/http"
import CustomInput from "../crud/custom-input.vue"
import SelectDropdown from "../crud/SelectDropdown.vue"
import CustomModal from "../layout/CustomModal.vue"
import Button from "../UI/Button.vue"
import SectionTitle from "../UI/SectionTitle.vue"
import MultiTextAdder from "./MultiTextAdder.vue"

export default {
    props: {
        employee: {
            type: Object,
            default: null,
        },
    },
    components: { MultiTextAdder, SelectDropdown, SectionTitle, Button, CustomModal, CustomInput },
    data() {
        return {
            dateToday: new Date().toISOString().slice(0, 10),
            showAppointmentLetterModal: false,
            showOfferLetterModal: false,
            generatingAppointment: false,
            generatingOfferLetter: false,
            employees: [],

            // offer letter variables
            offeredJobByPersonName: "Hemang Mehta",
            offeredJobByPersonPosition: "HR Manager",
            desiredJobStartingDate: null,
            desiredJobStartingTime: null,
            lastDateForJobOfferAcceptance: null,

            // appointment letter variables:
            totalSickLeaves: null,
            companyNoticePeriodDays: null,
            employeeNoticePeriodDays: null,
            highCourtName: null,
            highCourtState: null,

            benefits: [],
            duties: [],
            responsibilities: [],
            initialized: false,
            missingAppointmentLetterFields: [],
            missingOfferLetterFields: [],
        }
    },
    async mounted() {
        this.benefits = this.employee.positionBenefitsCsv
            ? this.employee.positionBenefitsCsv.split(",")
            : []
        this.duties = this.employee.dutiesCsv ? this.employee.dutiesCsv.split(",") : []
        this.responsibilities = this.employee.responsibilitiesCsv
            ? this.employee.responsibilitiesCsv.split(",")
            : []

        // initialize data

        this.offeredJobByPersonName = this.employee.offeredJobByPersonName || "Hemang Mehta"
        this.offeredJobByPersonPosition = this.employee.offeredJobByPersonPosition || "HR Manager"
        this.desiredJobStartingDate = this.employee.desiredJobStartingDate || null
        this.desiredJobStartingTime = this.employee.desiredJobStartingTime || null
        this.lastDateForJobOfferAcceptance = this.employee.lastDateForJobOfferAcceptance || null

        // appointment letter variables;
        this.totalSickLeaves = this.employee.totalSickLeaves || null
        this.companyNoticePeriodDays = this.employee.companyNoticePeriodDays || null
        this.employeeNoticePeriodDays = this.employee.employeeNoticePeriodDays || null
        this.highCourtName = this.employee.highCourtName || null
        this.highCourtState = this.employee.highCourtState || null

        // check if any missing fields
        const {
            workLocationId,

            salutation,
            attendanceShiftId,
            supervisorUserId,

            city,
            state,
            country,
            companyId,
            designationId,
        } = this.employee

        this.missingAppointmentLetterFields = []
        this.missingOfferLetterFields = []

        if (!workLocationId) {
            this.missingAppointmentLetterFields.push("Work Location")
        }
        // other fields required in both
        if (!salutation) {
            this.missingAppointmentLetterFields.push("Salutation")
            this.missingOfferLetterFields.push("Salutation")
        }
        if (!supervisorUserId) {
            this.missingAppointmentLetterFields.push("Supervisor/Reporter")
            this.missingOfferLetterFields.push("Supervisor/Reporter")
        }
        if (!attendanceShiftId) {
            this.missingAppointmentLetterFields.push("Attendance Shift")
            this.missingOfferLetterFields.push("Attendance Shift")
        }
        if (!city) {
            this.missingAppointmentLetterFields.push("City")
            this.missingOfferLetterFields.push("City")
        }
        if (!state) {
            this.missingAppointmentLetterFields.push("State")
            this.missingOfferLetterFields.push("State")
        }
        if (!country) {
            this.missingAppointmentLetterFields.push("Country")
            this.missingOfferLetterFields.push("Country")
        }
        if (!companyId) {
            this.missingAppointmentLetterFields.push("Salary Company")
            this.missingOfferLetterFields.push("Salary Company")
        }
        if (!designationId) {
            this.missingAppointmentLetterFields.push("Designation")
            this.missingOfferLetterFields.push("Designation")
        }

        this.initialized = true
    },
    watch: {
        desiredJobStartingDate(e) {
            this.lastDateForJobOfferAcceptance = new Date(new Date(e).getTime() + daysToMs(5))
                .toISOString()
                .slice(0, 10)
        },
    },
    methods: {
        formatIndianDate,
        async generateLetter(type) {
            try {
                if (!this.employee.salutation) {
                    return this.$toast.error("Please update salutation in Personal Details!")
                }
                if (this.generatingOfferLetter || this.generatingAppointment) return
                const isOfferLetter = type.toLowerCase() == "offer"
                if (isOfferLetter) {
                    this.generatingOfferLetter = true
                } else {
                    this.generatingAppointment = true
                }
                const res = await http.post(
                    `/employees/${this.employee.id}/generate-${type.toLowerCase()}-letter/`,
                    {
                        // for offer letter
                        offeredJobByPersonName: this.offeredJobByPersonName,
                        offeredJobByPersonPosition: this.offeredJobByPersonPosition,
                        desiredJobStartingDate: this.desiredJobStartingDate,
                        desiredJobStartingTime: this.desiredJobStartingTime,
                        lastDateForJobOfferAcceptance: this.lastDateForJobOfferAcceptance,

                        positionBenefitsCsv: this.benefits,

                        // appointment letter variables:
                        totalSickLeaves: this.totalSickLeaves,
                        companyNoticePeriodDays: this.companyNoticePeriodDays,
                        employeeNoticePeriodDays: this.employeeNoticePeriodDays,
                        highCourtName: this.highCourtName,
                        highCourtState: this.highCourtState,

                        dutiesCsv: this.duties,
                        responsibilitiesCsv: this.responsibilities,
                    }
                )
                this.$toast.success(`${type} Letter Generated!`)

                const createdBefore =
                    (!isOfferLetter && this.employee.appointmentLetterPdf) ||
                    (isOfferLetter && this.employee.offerLetterPdf)

                if (isOfferLetter) {
                    this.employee.offerLetterLastGeneratedDate = new Date()
                } else {
                    this.employee.appointmentLetterLastGeneratedDate = new Date()
                }

                // if (!createdBefore) {
                //     window.location.href = `${this.$route.path}?active_tab=WORK`
                // }
                this.generatingOfferLetter = false
                this.generatingAppointment = false
                window.location.href = `${this.$route.path}?active_tab=WORK`
            } catch (err) {
                console.log("error in generating appointment letter", err)
            }
        },
        async downloadLetter(path) {
            window.open(getAssetUrlPath(path), "_blank")
        },
    },
}
</script>

<style lang="scss" scoped>
.missing-field-container {
    color: red;
    list-style-type: disc;
    list-style-position: inside;
}
</style>
