<template>
    <div>
        <CrudIndex
            v-if="!loading"
            title="User Roles"
            :viewColumns="columns"
            :fetchUrl="`/user-roles`"
            createUrl="/user-roles"
            updateRootUrl="/user-roles"
            :createFields="createFields"
            :reloadOnSuccess="true"
        />
    </div>
</template>

<script>
import { http } from "@/utils/http"
import CrudIndex from "@/components/crud/index.vue"
import { createField, createColumn } from "@/utils/common"

export default {
    data() {
        return {
            loading: true,
            videos: [],
            createFields: [],
            userRoles: [],
            columns: [createColumn("Id", "id", "number"), createColumn("Name", "name", "string")],
        }
    },
    props: { videoId: { type: String, default: "" } },
    components: { CrudIndex },
    async mounted() {
        this.loading = false
        this.recreateFields()
    },
    methods: {
        recreateFields() {
            this.createFields = [createField("Name*", "name", "text", true)]
        },
    },
}
</script>

<style></style>
