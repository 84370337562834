<template>
    <div>
        <PageCard title="Leave Requests" class="mt-3">
            <LeaveRequestList :activeTabIndex="1" title="Approved" extraRouteQuery="approved=true" />
        </PageCard>
    </div>
</template>

<script>
import PageCard from "../../../components/layout/PageCard.vue"
import LeaveRequestList from "./leave-request-list.vue"

export default {
    components: { PageCard, LeaveRequestList },
}
</script>

<style></style>
