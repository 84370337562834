<template>
    <div>
        <PageCard v-if="!dashboardStats || !this.attendanceStatuses.length">
            <Spinner />
        </PageCard>
        <div class="row" v-else>
            <div class="col-md-12">
                <div class="report-number-grid">
                    <div class="">
                        <DashboardReportViewCard
                            link="/employees"
                            title="Total Employees"
                            :value="dashboardStats ? millify(dashboardStats.totalEmployees || 0) : '...'"
                        />
                    </div>

                    <div class="">
                        <DashboardReportViewCard
                            :link="`/attendance-records?attendance_status=${getAttendanceStatusIdByName('present')}`"
                            title="Present Today"
                            :value="dashboardStats ? millify(dashboardStats.totalPresentToday || 0) : '...'"
                        />
                    </div>
                    <div class="">
                        <DashboardReportViewCard
                            :link="`/attendance-records?attendance_status=${getAttendanceStatusIdByName('absent')}`"
                            title="Absent Today"
                            :value="dashboardStats ? millify(dashboardStats.totalAbsentToday || 0) : '...'"
                        />
                    </div>
                    <div class="">
                        <DashboardReportViewCard
                            :link="`/attendance-records?attendance_status=${getAttendanceStatusIdByName('present')}`"
                            title="Late Arrival Today"
                            :value="dashboardStats ? millify(dashboardStats.totalLateArrivalToday || 0) : '...'"
                        />
                    </div>
                    <div class="">
                        <DashboardReportViewCard
                            :link="`/attendance-records?attendance_status=${getAttendanceStatusIdByName('leave')}`"
                            title="On Leave Today"
                            :value="dashboardStats ? millify(dashboardStats.totalOnLeaveToday || 0) : '...'"
                        />
                    </div>
                    <div class="">
                        <DashboardReportViewCard
                            :link="`/attendance-records?attendance_status=${getAttendanceStatusIdByName('week off')}`"
                            title="Week Off Today"
                            :value="dashboardStats ? millify(dashboardStats.totalWeekOffToday || 0) : '...'"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex"
import DashboardReportViewCard from "@/components/reports/DashboardReportViewCard.vue"
import millify from "millify"
import Spinner from "../../components/UI/Spinner.vue"
import PageCard from "../../components/layout/PageCard.vue"

export default {
    components: {
        DashboardReportViewCard,
        Spinner,
        PageCard,
    },
    computed: {
        ...mapState("misc", ["dashboardStats", "attendanceStatuses"]),
    },
    methods: {
        millify,
        getAttendanceStatusIdByName(name) {
            const status = this.attendanceStatuses.find((item) => item.name.toUpperCase() == name.toUpperCase())
            if (status) {
                return status.id
            }
            return ""
        },
    },
}
</script>

<style lang="scss" scoped>
.report-number-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    height: 210px;
}
</style>
